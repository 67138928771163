import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const GetBodiesDocument = gql`
    query getBodies($states: [CodetableStateEnum!] = [ACTIVE]) {
  getBodies(states: $states) {
    code
    id
    label
    state
    vehicleType {
      id
      label
      state
    }
  }
}
    `;

/**
 * __useGetBodiesQuery__
 *
 * To run a query within a React component, call `useGetBodiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBodiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBodiesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetBodiesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBodiesQuery, Types.GetBodiesQueryVariables>) {
        return Apollo.useQuery<Types.GetBodiesQuery, Types.GetBodiesQueryVariables>(GetBodiesDocument, baseOptions);
      }
export function useGetBodiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBodiesQuery, Types.GetBodiesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetBodiesQuery, Types.GetBodiesQueryVariables>(GetBodiesDocument, baseOptions);
        }
export type GetBodiesQueryHookResult = ReturnType<typeof useGetBodiesQuery>;
export type GetBodiesLazyQueryHookResult = ReturnType<typeof useGetBodiesLazyQuery>;
export type GetBodiesQueryResult = Apollo.QueryResult<Types.GetBodiesQuery, Types.GetBodiesQueryVariables>;
export const CreateBodyDocument = gql`
    mutation createBody($input: BodyCreateDto!) {
  createBody(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
      vehicleType {
        id
        label
        state
      }
    }
  }
}
    `;
export type CreateBodyMutationFn = Apollo.MutationFunction<Types.CreateBodyMutation, Types.CreateBodyMutationVariables>;

/**
 * __useCreateBodyMutation__
 *
 * To run a mutation, you first call `useCreateBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBodyMutation, { data, loading, error }] = useCreateBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBodyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBodyMutation, Types.CreateBodyMutationVariables>) {
        return Apollo.useMutation<Types.CreateBodyMutation, Types.CreateBodyMutationVariables>(CreateBodyDocument, baseOptions);
      }
export type CreateBodyMutationHookResult = ReturnType<typeof useCreateBodyMutation>;
export type CreateBodyMutationResult = Apollo.MutationResult<Types.CreateBodyMutation>;
export type CreateBodyMutationOptions = Apollo.BaseMutationOptions<Types.CreateBodyMutation, Types.CreateBodyMutationVariables>;
export const UpdateBodyDocument = gql`
    mutation updateBody($input: BodyUpdateDto!) {
  updateBody(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
      vehicleType {
        id
        label
        state
      }
    }
  }
}
    `;
export type UpdateBodyMutationFn = Apollo.MutationFunction<Types.UpdateBodyMutation, Types.UpdateBodyMutationVariables>;

/**
 * __useUpdateBodyMutation__
 *
 * To run a mutation, you first call `useUpdateBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBodyMutation, { data, loading, error }] = useUpdateBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBodyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBodyMutation, Types.UpdateBodyMutationVariables>) {
        return Apollo.useMutation<Types.UpdateBodyMutation, Types.UpdateBodyMutationVariables>(UpdateBodyDocument, baseOptions);
      }
export type UpdateBodyMutationHookResult = ReturnType<typeof useUpdateBodyMutation>;
export type UpdateBodyMutationResult = Apollo.MutationResult<Types.UpdateBodyMutation>;
export type UpdateBodyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBodyMutation, Types.UpdateBodyMutationVariables>;
export const GetBonusesDocument = gql`
    query getBonuses($filter: BonusFilter) {
  getBonuses(filter: $filter) {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    name
    value
    unit
    brand {
      id
      label
    }
    model
    vehicleType {
      id
      label
    }
    equipment
    engine
    fourDriveWheel
    validTo
    forCrn
    forPhysicalPerson
  }
}
    `;

/**
 * __useGetBonusesQuery__
 *
 * To run a query within a React component, call `useGetBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBonusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBonusesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBonusesQuery, Types.GetBonusesQueryVariables>) {
        return Apollo.useQuery<Types.GetBonusesQuery, Types.GetBonusesQueryVariables>(GetBonusesDocument, baseOptions);
      }
export function useGetBonusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBonusesQuery, Types.GetBonusesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetBonusesQuery, Types.GetBonusesQueryVariables>(GetBonusesDocument, baseOptions);
        }
export type GetBonusesQueryHookResult = ReturnType<typeof useGetBonusesQuery>;
export type GetBonusesLazyQueryHookResult = ReturnType<typeof useGetBonusesLazyQuery>;
export type GetBonusesQueryResult = Apollo.QueryResult<Types.GetBonusesQuery, Types.GetBonusesQueryVariables>;
export const CreateBonusDocument = gql`
    mutation createBonus($input: BonusCreateInput!) {
  createBonus(input: $input) {
    errors {
      code
    }
    bonus {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      value
      unit
      brand {
        id
        label
      }
      model
      vehicleType {
        id
        label
      }
      equipment
      engine
      fourDriveWheel
      validTo
      forCrn
      forPhysicalPerson
    }
  }
}
    `;
export type CreateBonusMutationFn = Apollo.MutationFunction<Types.CreateBonusMutation, Types.CreateBonusMutationVariables>;

/**
 * __useCreateBonusMutation__
 *
 * To run a mutation, you first call `useCreateBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBonusMutation, { data, loading, error }] = useCreateBonusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBonusMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBonusMutation, Types.CreateBonusMutationVariables>) {
        return Apollo.useMutation<Types.CreateBonusMutation, Types.CreateBonusMutationVariables>(CreateBonusDocument, baseOptions);
      }
export type CreateBonusMutationHookResult = ReturnType<typeof useCreateBonusMutation>;
export type CreateBonusMutationResult = Apollo.MutationResult<Types.CreateBonusMutation>;
export type CreateBonusMutationOptions = Apollo.BaseMutationOptions<Types.CreateBonusMutation, Types.CreateBonusMutationVariables>;
export const UpdateBonusDocument = gql`
    mutation updateBonus($input: BonusUpdateInput!) {
  updateBonus(input: $input) {
    errors {
      code
    }
    bonus {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      value
      unit
      brand {
        id
        label
      }
      model
      vehicleType {
        id
        label
      }
      equipment
      engine
      fourDriveWheel
      validTo
      forCrn
      forPhysicalPerson
    }
  }
}
    `;
export type UpdateBonusMutationFn = Apollo.MutationFunction<Types.UpdateBonusMutation, Types.UpdateBonusMutationVariables>;

/**
 * __useUpdateBonusMutation__
 *
 * To run a mutation, you first call `useUpdateBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBonusMutation, { data, loading, error }] = useUpdateBonusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBonusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBonusMutation, Types.UpdateBonusMutationVariables>) {
        return Apollo.useMutation<Types.UpdateBonusMutation, Types.UpdateBonusMutationVariables>(UpdateBonusDocument, baseOptions);
      }
export type UpdateBonusMutationHookResult = ReturnType<typeof useUpdateBonusMutation>;
export type UpdateBonusMutationResult = Apollo.MutationResult<Types.UpdateBonusMutation>;
export type UpdateBonusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBonusMutation, Types.UpdateBonusMutationVariables>;
export const GetBrandsDocument = gql`
    query getBrands($states: [CodetableStateEnum!] = [ACTIVE]) {
  getBrands(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBrandsQuery, Types.GetBrandsQueryVariables>) {
        return Apollo.useQuery<Types.GetBrandsQuery, Types.GetBrandsQueryVariables>(GetBrandsDocument, baseOptions);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBrandsQuery, Types.GetBrandsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetBrandsQuery, Types.GetBrandsQueryVariables>(GetBrandsDocument, baseOptions);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<Types.GetBrandsQuery, Types.GetBrandsQueryVariables>;
export const CreateBrandDocument = gql`
    mutation createBrand($input: CodetableCreateDto!) {
  createBrand(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>) {
        return Apollo.useMutation<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>(CreateBrandDocument, baseOptions);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<Types.CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation updateBrand($input: CodetableUpdateDto!) {
  updateBrand(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<Types.UpdateBrandMutation, Types.UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBrandMutation, Types.UpdateBrandMutationVariables>) {
        return Apollo.useMutation<Types.UpdateBrandMutation, Types.UpdateBrandMutationVariables>(UpdateBrandDocument, baseOptions);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<Types.UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBrandMutation, Types.UpdateBrandMutationVariables>;
export const GetColorTypesDocument = gql`
    query getColorTypes($states: [CodetableStateEnum!] = [ACTIVE]) {
  getColorTypes(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetColorTypesQuery__
 *
 * To run a query within a React component, call `useGetColorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorTypesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetColorTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetColorTypesQuery, Types.GetColorTypesQueryVariables>) {
        return Apollo.useQuery<Types.GetColorTypesQuery, Types.GetColorTypesQueryVariables>(GetColorTypesDocument, baseOptions);
      }
export function useGetColorTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetColorTypesQuery, Types.GetColorTypesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetColorTypesQuery, Types.GetColorTypesQueryVariables>(GetColorTypesDocument, baseOptions);
        }
export type GetColorTypesQueryHookResult = ReturnType<typeof useGetColorTypesQuery>;
export type GetColorTypesLazyQueryHookResult = ReturnType<typeof useGetColorTypesLazyQuery>;
export type GetColorTypesQueryResult = Apollo.QueryResult<Types.GetColorTypesQuery, Types.GetColorTypesQueryVariables>;
export const CreateColorTypeDocument = gql`
    mutation createColorType($input: CodetableCreateDto!) {
  createColorType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateColorTypeMutationFn = Apollo.MutationFunction<Types.CreateColorTypeMutation, Types.CreateColorTypeMutationVariables>;

/**
 * __useCreateColorTypeMutation__
 *
 * To run a mutation, you first call `useCreateColorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateColorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createColorTypeMutation, { data, loading, error }] = useCreateColorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateColorTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateColorTypeMutation, Types.CreateColorTypeMutationVariables>) {
        return Apollo.useMutation<Types.CreateColorTypeMutation, Types.CreateColorTypeMutationVariables>(CreateColorTypeDocument, baseOptions);
      }
export type CreateColorTypeMutationHookResult = ReturnType<typeof useCreateColorTypeMutation>;
export type CreateColorTypeMutationResult = Apollo.MutationResult<Types.CreateColorTypeMutation>;
export type CreateColorTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateColorTypeMutation, Types.CreateColorTypeMutationVariables>;
export const UpdateColorTypeDocument = gql`
    mutation updateColorType($input: CodetableUpdateDto!) {
  updateColorType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateColorTypeMutationFn = Apollo.MutationFunction<Types.UpdateColorTypeMutation, Types.UpdateColorTypeMutationVariables>;

/**
 * __useUpdateColorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateColorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColorTypeMutation, { data, loading, error }] = useUpdateColorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColorTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateColorTypeMutation, Types.UpdateColorTypeMutationVariables>) {
        return Apollo.useMutation<Types.UpdateColorTypeMutation, Types.UpdateColorTypeMutationVariables>(UpdateColorTypeDocument, baseOptions);
      }
export type UpdateColorTypeMutationHookResult = ReturnType<typeof useUpdateColorTypeMutation>;
export type UpdateColorTypeMutationResult = Apollo.MutationResult<Types.UpdateColorTypeMutation>;
export type UpdateColorTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateColorTypeMutation, Types.UpdateColorTypeMutationVariables>;
export const GetColorsDocument = gql`
    query getColors($states: [CodetableStateEnum!] = [ACTIVE]) {
  getColors(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetColorsQuery__
 *
 * To run a query within a React component, call `useGetColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorsQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetColorsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetColorsQuery, Types.GetColorsQueryVariables>) {
        return Apollo.useQuery<Types.GetColorsQuery, Types.GetColorsQueryVariables>(GetColorsDocument, baseOptions);
      }
export function useGetColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetColorsQuery, Types.GetColorsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetColorsQuery, Types.GetColorsQueryVariables>(GetColorsDocument, baseOptions);
        }
export type GetColorsQueryHookResult = ReturnType<typeof useGetColorsQuery>;
export type GetColorsLazyQueryHookResult = ReturnType<typeof useGetColorsLazyQuery>;
export type GetColorsQueryResult = Apollo.QueryResult<Types.GetColorsQuery, Types.GetColorsQueryVariables>;
export const CreateColorDocument = gql`
    mutation createColor($input: CodetableCreateDto!) {
  createColor(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateColorMutationFn = Apollo.MutationFunction<Types.CreateColorMutation, Types.CreateColorMutationVariables>;

/**
 * __useCreateColorMutation__
 *
 * To run a mutation, you first call `useCreateColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createColorMutation, { data, loading, error }] = useCreateColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateColorMutation, Types.CreateColorMutationVariables>) {
        return Apollo.useMutation<Types.CreateColorMutation, Types.CreateColorMutationVariables>(CreateColorDocument, baseOptions);
      }
export type CreateColorMutationHookResult = ReturnType<typeof useCreateColorMutation>;
export type CreateColorMutationResult = Apollo.MutationResult<Types.CreateColorMutation>;
export type CreateColorMutationOptions = Apollo.BaseMutationOptions<Types.CreateColorMutation, Types.CreateColorMutationVariables>;
export const UpdateColorDocument = gql`
    mutation updateColor($input: CodetableUpdateDto!) {
  updateColor(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateColorMutationFn = Apollo.MutationFunction<Types.UpdateColorMutation, Types.UpdateColorMutationVariables>;

/**
 * __useUpdateColorMutation__
 *
 * To run a mutation, you first call `useUpdateColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColorMutation, { data, loading, error }] = useUpdateColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateColorMutation, Types.UpdateColorMutationVariables>) {
        return Apollo.useMutation<Types.UpdateColorMutation, Types.UpdateColorMutationVariables>(UpdateColorDocument, baseOptions);
      }
export type UpdateColorMutationHookResult = ReturnType<typeof useUpdateColorMutation>;
export type UpdateColorMutationResult = Apollo.MutationResult<Types.UpdateColorMutation>;
export type UpdateColorMutationOptions = Apollo.BaseMutationOptions<Types.UpdateColorMutation, Types.UpdateColorMutationVariables>;
export const GetCountriesOfOriginDocument = gql`
    query getCountriesOfOrigin($states: [CodetableStateEnum!] = [ACTIVE]) {
  getCountriesOfOrigin(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetCountriesOfOriginQuery__
 *
 * To run a query within a React component, call `useGetCountriesOfOriginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesOfOriginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesOfOriginQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetCountriesOfOriginQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountriesOfOriginQuery, Types.GetCountriesOfOriginQueryVariables>) {
        return Apollo.useQuery<Types.GetCountriesOfOriginQuery, Types.GetCountriesOfOriginQueryVariables>(GetCountriesOfOriginDocument, baseOptions);
      }
export function useGetCountriesOfOriginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountriesOfOriginQuery, Types.GetCountriesOfOriginQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetCountriesOfOriginQuery, Types.GetCountriesOfOriginQueryVariables>(GetCountriesOfOriginDocument, baseOptions);
        }
export type GetCountriesOfOriginQueryHookResult = ReturnType<typeof useGetCountriesOfOriginQuery>;
export type GetCountriesOfOriginLazyQueryHookResult = ReturnType<typeof useGetCountriesOfOriginLazyQuery>;
export type GetCountriesOfOriginQueryResult = Apollo.QueryResult<Types.GetCountriesOfOriginQuery, Types.GetCountriesOfOriginQueryVariables>;
export const CreateCountryOfOriginDocument = gql`
    mutation createCountryOfOrigin($input: CodetableCreateDto!) {
  createCountryOfOrigin(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateCountryOfOriginMutationFn = Apollo.MutationFunction<Types.CreateCountryOfOriginMutation, Types.CreateCountryOfOriginMutationVariables>;

/**
 * __useCreateCountryOfOriginMutation__
 *
 * To run a mutation, you first call `useCreateCountryOfOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryOfOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryOfOriginMutation, { data, loading, error }] = useCreateCountryOfOriginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCountryOfOriginMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCountryOfOriginMutation, Types.CreateCountryOfOriginMutationVariables>) {
        return Apollo.useMutation<Types.CreateCountryOfOriginMutation, Types.CreateCountryOfOriginMutationVariables>(CreateCountryOfOriginDocument, baseOptions);
      }
export type CreateCountryOfOriginMutationHookResult = ReturnType<typeof useCreateCountryOfOriginMutation>;
export type CreateCountryOfOriginMutationResult = Apollo.MutationResult<Types.CreateCountryOfOriginMutation>;
export type CreateCountryOfOriginMutationOptions = Apollo.BaseMutationOptions<Types.CreateCountryOfOriginMutation, Types.CreateCountryOfOriginMutationVariables>;
export const UpdateCountryOfOriginDocument = gql`
    mutation updateCountryOfOrigin($input: CodetableUpdateDto!) {
  updateCountryOfOrigin(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateCountryOfOriginMutationFn = Apollo.MutationFunction<Types.UpdateCountryOfOriginMutation, Types.UpdateCountryOfOriginMutationVariables>;

/**
 * __useUpdateCountryOfOriginMutation__
 *
 * To run a mutation, you first call `useUpdateCountryOfOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountryOfOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryOfOriginMutation, { data, loading, error }] = useUpdateCountryOfOriginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCountryOfOriginMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCountryOfOriginMutation, Types.UpdateCountryOfOriginMutationVariables>) {
        return Apollo.useMutation<Types.UpdateCountryOfOriginMutation, Types.UpdateCountryOfOriginMutationVariables>(UpdateCountryOfOriginDocument, baseOptions);
      }
export type UpdateCountryOfOriginMutationHookResult = ReturnType<typeof useUpdateCountryOfOriginMutation>;
export type UpdateCountryOfOriginMutationResult = Apollo.MutationResult<Types.UpdateCountryOfOriginMutation>;
export type UpdateCountryOfOriginMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCountryOfOriginMutation, Types.UpdateCountryOfOriginMutationVariables>;
export const GetEnginesDocument = gql`
    query getEngines {
  getEngines
}
    `;

/**
 * __useGetEnginesQuery__
 *
 * To run a query within a React component, call `useGetEnginesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnginesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnginesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnginesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEnginesQuery, Types.GetEnginesQueryVariables>) {
        return Apollo.useQuery<Types.GetEnginesQuery, Types.GetEnginesQueryVariables>(GetEnginesDocument, baseOptions);
      }
export function useGetEnginesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnginesQuery, Types.GetEnginesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetEnginesQuery, Types.GetEnginesQueryVariables>(GetEnginesDocument, baseOptions);
        }
export type GetEnginesQueryHookResult = ReturnType<typeof useGetEnginesQuery>;
export type GetEnginesLazyQueryHookResult = ReturnType<typeof useGetEnginesLazyQuery>;
export type GetEnginesQueryResult = Apollo.QueryResult<Types.GetEnginesQuery, Types.GetEnginesQueryVariables>;
export const GetEquipmentCategoriesDocument = gql`
    query getEquipmentCategories($states: [CodetableStateEnum!] = [ACTIVE]) {
  getEquipmentCategories(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetEquipmentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEquipmentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentCategoriesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetEquipmentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEquipmentCategoriesQuery, Types.GetEquipmentCategoriesQueryVariables>) {
        return Apollo.useQuery<Types.GetEquipmentCategoriesQuery, Types.GetEquipmentCategoriesQueryVariables>(GetEquipmentCategoriesDocument, baseOptions);
      }
export function useGetEquipmentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEquipmentCategoriesQuery, Types.GetEquipmentCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetEquipmentCategoriesQuery, Types.GetEquipmentCategoriesQueryVariables>(GetEquipmentCategoriesDocument, baseOptions);
        }
export type GetEquipmentCategoriesQueryHookResult = ReturnType<typeof useGetEquipmentCategoriesQuery>;
export type GetEquipmentCategoriesLazyQueryHookResult = ReturnType<typeof useGetEquipmentCategoriesLazyQuery>;
export type GetEquipmentCategoriesQueryResult = Apollo.QueryResult<Types.GetEquipmentCategoriesQuery, Types.GetEquipmentCategoriesQueryVariables>;
export const CreateEquipmentCategoryDocument = gql`
    mutation createEquipmentCategory($input: CodetableCreateDto!) {
  createEquipmentCategory(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateEquipmentCategoryMutationFn = Apollo.MutationFunction<Types.CreateEquipmentCategoryMutation, Types.CreateEquipmentCategoryMutationVariables>;

/**
 * __useCreateEquipmentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentCategoryMutation, { data, loading, error }] = useCreateEquipmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEquipmentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEquipmentCategoryMutation, Types.CreateEquipmentCategoryMutationVariables>) {
        return Apollo.useMutation<Types.CreateEquipmentCategoryMutation, Types.CreateEquipmentCategoryMutationVariables>(CreateEquipmentCategoryDocument, baseOptions);
      }
export type CreateEquipmentCategoryMutationHookResult = ReturnType<typeof useCreateEquipmentCategoryMutation>;
export type CreateEquipmentCategoryMutationResult = Apollo.MutationResult<Types.CreateEquipmentCategoryMutation>;
export type CreateEquipmentCategoryMutationOptions = Apollo.BaseMutationOptions<Types.CreateEquipmentCategoryMutation, Types.CreateEquipmentCategoryMutationVariables>;
export const UpdateEquipmentCategoryDocument = gql`
    mutation updateEquipmentCategory($input: CodetableUpdateDto!) {
  updateEquipmentCategory(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateEquipmentCategoryMutationFn = Apollo.MutationFunction<Types.UpdateEquipmentCategoryMutation, Types.UpdateEquipmentCategoryMutationVariables>;

/**
 * __useUpdateEquipmentCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentCategoryMutation, { data, loading, error }] = useUpdateEquipmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEquipmentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEquipmentCategoryMutation, Types.UpdateEquipmentCategoryMutationVariables>) {
        return Apollo.useMutation<Types.UpdateEquipmentCategoryMutation, Types.UpdateEquipmentCategoryMutationVariables>(UpdateEquipmentCategoryDocument, baseOptions);
      }
export type UpdateEquipmentCategoryMutationHookResult = ReturnType<typeof useUpdateEquipmentCategoryMutation>;
export type UpdateEquipmentCategoryMutationResult = Apollo.MutationResult<Types.UpdateEquipmentCategoryMutation>;
export type UpdateEquipmentCategoryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEquipmentCategoryMutation, Types.UpdateEquipmentCategoryMutationVariables>;
export const GetEquipmentsDocument = gql`
    query getEquipments {
  getEquipments {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    name
    category {
      id
      label
    }
  }
}
    `;

/**
 * __useGetEquipmentsQuery__
 *
 * To run a query within a React component, call `useGetEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEquipmentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEquipmentsQuery, Types.GetEquipmentsQueryVariables>) {
        return Apollo.useQuery<Types.GetEquipmentsQuery, Types.GetEquipmentsQueryVariables>(GetEquipmentsDocument, baseOptions);
      }
export function useGetEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEquipmentsQuery, Types.GetEquipmentsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetEquipmentsQuery, Types.GetEquipmentsQueryVariables>(GetEquipmentsDocument, baseOptions);
        }
export type GetEquipmentsQueryHookResult = ReturnType<typeof useGetEquipmentsQuery>;
export type GetEquipmentsLazyQueryHookResult = ReturnType<typeof useGetEquipmentsLazyQuery>;
export type GetEquipmentsQueryResult = Apollo.QueryResult<Types.GetEquipmentsQuery, Types.GetEquipmentsQueryVariables>;
export const CreateEquipmentDocument = gql`
    mutation createEquipment($input: EquipmentCreateInput!) {
  createEquipment(input: $input) {
    errors {
      code
    }
    equipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      category {
        id
        label
      }
    }
  }
}
    `;
export type CreateEquipmentMutationFn = Apollo.MutationFunction<Types.CreateEquipmentMutation, Types.CreateEquipmentMutationVariables>;

/**
 * __useCreateEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentMutation, { data, loading, error }] = useCreateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEquipmentMutation, Types.CreateEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.CreateEquipmentMutation, Types.CreateEquipmentMutationVariables>(CreateEquipmentDocument, baseOptions);
      }
export type CreateEquipmentMutationHookResult = ReturnType<typeof useCreateEquipmentMutation>;
export type CreateEquipmentMutationResult = Apollo.MutationResult<Types.CreateEquipmentMutation>;
export type CreateEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateEquipmentMutation, Types.CreateEquipmentMutationVariables>;
export const UpdateEquipmentDocument = gql`
    mutation updateEquipment($input: EquipmentUpdateInput!) {
  updateEquipment(input: $input) {
    errors {
      code
    }
    equipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      category {
        id
        label
      }
    }
  }
}
    `;
export type UpdateEquipmentMutationFn = Apollo.MutationFunction<Types.UpdateEquipmentMutation, Types.UpdateEquipmentMutationVariables>;

/**
 * __useUpdateEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentMutation, { data, loading, error }] = useUpdateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEquipmentMutation, Types.UpdateEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.UpdateEquipmentMutation, Types.UpdateEquipmentMutationVariables>(UpdateEquipmentDocument, baseOptions);
      }
export type UpdateEquipmentMutationHookResult = ReturnType<typeof useUpdateEquipmentMutation>;
export type UpdateEquipmentMutationResult = Apollo.MutationResult<Types.UpdateEquipmentMutation>;
export type UpdateEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEquipmentMutation, Types.UpdateEquipmentMutationVariables>;
export const GetFuelTypesDocument = gql`
    query getFuelTypes($states: [CodetableStateEnum!] = [ACTIVE]) {
  getFuelTypes(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetFuelTypesQuery__
 *
 * To run a query within a React component, call `useGetFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTypesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetFuelTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFuelTypesQuery, Types.GetFuelTypesQueryVariables>) {
        return Apollo.useQuery<Types.GetFuelTypesQuery, Types.GetFuelTypesQueryVariables>(GetFuelTypesDocument, baseOptions);
      }
export function useGetFuelTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFuelTypesQuery, Types.GetFuelTypesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetFuelTypesQuery, Types.GetFuelTypesQueryVariables>(GetFuelTypesDocument, baseOptions);
        }
export type GetFuelTypesQueryHookResult = ReturnType<typeof useGetFuelTypesQuery>;
export type GetFuelTypesLazyQueryHookResult = ReturnType<typeof useGetFuelTypesLazyQuery>;
export type GetFuelTypesQueryResult = Apollo.QueryResult<Types.GetFuelTypesQuery, Types.GetFuelTypesQueryVariables>;
export const CreateFuelTypeDocument = gql`
    mutation createFuelType($input: CodetableCreateDto!) {
  createFuelType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateFuelTypeMutationFn = Apollo.MutationFunction<Types.CreateFuelTypeMutation, Types.CreateFuelTypeMutationVariables>;

/**
 * __useCreateFuelTypeMutation__
 *
 * To run a mutation, you first call `useCreateFuelTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuelTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelTypeMutation, { data, loading, error }] = useCreateFuelTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFuelTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFuelTypeMutation, Types.CreateFuelTypeMutationVariables>) {
        return Apollo.useMutation<Types.CreateFuelTypeMutation, Types.CreateFuelTypeMutationVariables>(CreateFuelTypeDocument, baseOptions);
      }
export type CreateFuelTypeMutationHookResult = ReturnType<typeof useCreateFuelTypeMutation>;
export type CreateFuelTypeMutationResult = Apollo.MutationResult<Types.CreateFuelTypeMutation>;
export type CreateFuelTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateFuelTypeMutation, Types.CreateFuelTypeMutationVariables>;
export const UpdateFuelTypeDocument = gql`
    mutation updateFuelType($input: CodetableUpdateDto!) {
  updateFuelType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateFuelTypeMutationFn = Apollo.MutationFunction<Types.UpdateFuelTypeMutation, Types.UpdateFuelTypeMutationVariables>;

/**
 * __useUpdateFuelTypeMutation__
 *
 * To run a mutation, you first call `useUpdateFuelTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelTypeMutation, { data, loading, error }] = useUpdateFuelTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFuelTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFuelTypeMutation, Types.UpdateFuelTypeMutationVariables>) {
        return Apollo.useMutation<Types.UpdateFuelTypeMutation, Types.UpdateFuelTypeMutationVariables>(UpdateFuelTypeDocument, baseOptions);
      }
export type UpdateFuelTypeMutationHookResult = ReturnType<typeof useUpdateFuelTypeMutation>;
export type UpdateFuelTypeMutationResult = Apollo.MutationResult<Types.UpdateFuelTypeMutation>;
export type UpdateFuelTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFuelTypeMutation, Types.UpdateFuelTypeMutationVariables>;
export const GetMarketingTagsDocument = gql`
    query getMarketingTags($states: [CodetableStateEnum!] = [ACTIVE]) {
  getMarketingTags(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetMarketingTagsQuery__
 *
 * To run a query within a React component, call `useGetMarketingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingTagsQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetMarketingTagsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMarketingTagsQuery, Types.GetMarketingTagsQueryVariables>) {
        return Apollo.useQuery<Types.GetMarketingTagsQuery, Types.GetMarketingTagsQueryVariables>(GetMarketingTagsDocument, baseOptions);
      }
export function useGetMarketingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMarketingTagsQuery, Types.GetMarketingTagsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetMarketingTagsQuery, Types.GetMarketingTagsQueryVariables>(GetMarketingTagsDocument, baseOptions);
        }
export type GetMarketingTagsQueryHookResult = ReturnType<typeof useGetMarketingTagsQuery>;
export type GetMarketingTagsLazyQueryHookResult = ReturnType<typeof useGetMarketingTagsLazyQuery>;
export type GetMarketingTagsQueryResult = Apollo.QueryResult<Types.GetMarketingTagsQuery, Types.GetMarketingTagsQueryVariables>;
export const CreateMarketingTagDocument = gql`
    mutation createMarketingTag($input: CodetableCreateDto!) {
  createMarketingTag(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateMarketingTagMutationFn = Apollo.MutationFunction<Types.CreateMarketingTagMutation, Types.CreateMarketingTagMutationVariables>;

/**
 * __useCreateMarketingTagMutation__
 *
 * To run a mutation, you first call `useCreateMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingTagMutation, { data, loading, error }] = useCreateMarketingTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMarketingTagMutation, Types.CreateMarketingTagMutationVariables>) {
        return Apollo.useMutation<Types.CreateMarketingTagMutation, Types.CreateMarketingTagMutationVariables>(CreateMarketingTagDocument, baseOptions);
      }
export type CreateMarketingTagMutationHookResult = ReturnType<typeof useCreateMarketingTagMutation>;
export type CreateMarketingTagMutationResult = Apollo.MutationResult<Types.CreateMarketingTagMutation>;
export type CreateMarketingTagMutationOptions = Apollo.BaseMutationOptions<Types.CreateMarketingTagMutation, Types.CreateMarketingTagMutationVariables>;
export const UpdateMarketingTagDocument = gql`
    mutation updateMarketingTag($input: CodetableUpdateDto!) {
  updateMarketingTag(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateMarketingTagMutationFn = Apollo.MutationFunction<Types.UpdateMarketingTagMutation, Types.UpdateMarketingTagMutationVariables>;

/**
 * __useUpdateMarketingTagMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingTagMutation, { data, loading, error }] = useUpdateMarketingTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMarketingTagMutation, Types.UpdateMarketingTagMutationVariables>) {
        return Apollo.useMutation<Types.UpdateMarketingTagMutation, Types.UpdateMarketingTagMutationVariables>(UpdateMarketingTagDocument, baseOptions);
      }
export type UpdateMarketingTagMutationHookResult = ReturnType<typeof useUpdateMarketingTagMutation>;
export type UpdateMarketingTagMutationResult = Apollo.MutationResult<Types.UpdateMarketingTagMutation>;
export type UpdateMarketingTagMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMarketingTagMutation, Types.UpdateMarketingTagMutationVariables>;
export const GetServicePackagesDocument = gql`
    query getServicePackages($filter: ServicePackageFilter) {
  getServicePackages(filter: $filter) {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    name
    services
    state
  }
}
    `;

/**
 * __useGetServicePackagesQuery__
 *
 * To run a query within a React component, call `useGetServicePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicePackagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetServicePackagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetServicePackagesQuery, Types.GetServicePackagesQueryVariables>) {
        return Apollo.useQuery<Types.GetServicePackagesQuery, Types.GetServicePackagesQueryVariables>(GetServicePackagesDocument, baseOptions);
      }
export function useGetServicePackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetServicePackagesQuery, Types.GetServicePackagesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetServicePackagesQuery, Types.GetServicePackagesQueryVariables>(GetServicePackagesDocument, baseOptions);
        }
export type GetServicePackagesQueryHookResult = ReturnType<typeof useGetServicePackagesQuery>;
export type GetServicePackagesLazyQueryHookResult = ReturnType<typeof useGetServicePackagesLazyQuery>;
export type GetServicePackagesQueryResult = Apollo.QueryResult<Types.GetServicePackagesQuery, Types.GetServicePackagesQueryVariables>;
export const UpdateServicePackageStateDocument = gql`
    mutation updateServicePackageState($input: ServicePackageStateUpdateInput!) {
  updateServicePackageState(input: $input) {
    errors {
      code
    }
    servicePackage {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      services
      state
    }
  }
}
    `;
export type UpdateServicePackageStateMutationFn = Apollo.MutationFunction<Types.UpdateServicePackageStateMutation, Types.UpdateServicePackageStateMutationVariables>;

/**
 * __useUpdateServicePackageStateMutation__
 *
 * To run a mutation, you first call `useUpdateServicePackageStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServicePackageStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServicePackageStateMutation, { data, loading, error }] = useUpdateServicePackageStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServicePackageStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateServicePackageStateMutation, Types.UpdateServicePackageStateMutationVariables>) {
        return Apollo.useMutation<Types.UpdateServicePackageStateMutation, Types.UpdateServicePackageStateMutationVariables>(UpdateServicePackageStateDocument, baseOptions);
      }
export type UpdateServicePackageStateMutationHookResult = ReturnType<typeof useUpdateServicePackageStateMutation>;
export type UpdateServicePackageStateMutationResult = Apollo.MutationResult<Types.UpdateServicePackageStateMutation>;
export type UpdateServicePackageStateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateServicePackageStateMutation, Types.UpdateServicePackageStateMutationVariables>;
export const UpdateServicePackageDocument = gql`
    mutation updateServicePackage($input: ServicePackageUpdateInput!) {
  updateServicePackage(input: $input) {
    errors {
      code
    }
    servicePackage {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      services
      state
    }
  }
}
    `;
export type UpdateServicePackageMutationFn = Apollo.MutationFunction<Types.UpdateServicePackageMutation, Types.UpdateServicePackageMutationVariables>;

/**
 * __useUpdateServicePackageMutation__
 *
 * To run a mutation, you first call `useUpdateServicePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServicePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServicePackageMutation, { data, loading, error }] = useUpdateServicePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServicePackageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateServicePackageMutation, Types.UpdateServicePackageMutationVariables>) {
        return Apollo.useMutation<Types.UpdateServicePackageMutation, Types.UpdateServicePackageMutationVariables>(UpdateServicePackageDocument, baseOptions);
      }
export type UpdateServicePackageMutationHookResult = ReturnType<typeof useUpdateServicePackageMutation>;
export type UpdateServicePackageMutationResult = Apollo.MutationResult<Types.UpdateServicePackageMutation>;
export type UpdateServicePackageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateServicePackageMutation, Types.UpdateServicePackageMutationVariables>;
export const CreateServicePackageDocument = gql`
    mutation createServicePackage($input: ServicePackageCreateInput!) {
  createServicePackage(input: $input) {
    errors {
      code
    }
    servicePackage {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      services
      state
    }
  }
}
    `;
export type CreateServicePackageMutationFn = Apollo.MutationFunction<Types.CreateServicePackageMutation, Types.CreateServicePackageMutationVariables>;

/**
 * __useCreateServicePackageMutation__
 *
 * To run a mutation, you first call `useCreateServicePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServicePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServicePackageMutation, { data, loading, error }] = useCreateServicePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServicePackageMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateServicePackageMutation, Types.CreateServicePackageMutationVariables>) {
        return Apollo.useMutation<Types.CreateServicePackageMutation, Types.CreateServicePackageMutationVariables>(CreateServicePackageDocument, baseOptions);
      }
export type CreateServicePackageMutationHookResult = ReturnType<typeof useCreateServicePackageMutation>;
export type CreateServicePackageMutationResult = Apollo.MutationResult<Types.CreateServicePackageMutation>;
export type CreateServicePackageMutationOptions = Apollo.BaseMutationOptions<Types.CreateServicePackageMutation, Types.CreateServicePackageMutationVariables>;
export const GetTransmissionTypesDocument = gql`
    query getTransmissionTypes($states: [CodetableStateEnum!] = [ACTIVE]) {
  getTransmissionTypes(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetTransmissionTypesQuery__
 *
 * To run a query within a React component, call `useGetTransmissionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransmissionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransmissionTypesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetTransmissionTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTransmissionTypesQuery, Types.GetTransmissionTypesQueryVariables>) {
        return Apollo.useQuery<Types.GetTransmissionTypesQuery, Types.GetTransmissionTypesQueryVariables>(GetTransmissionTypesDocument, baseOptions);
      }
export function useGetTransmissionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransmissionTypesQuery, Types.GetTransmissionTypesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetTransmissionTypesQuery, Types.GetTransmissionTypesQueryVariables>(GetTransmissionTypesDocument, baseOptions);
        }
export type GetTransmissionTypesQueryHookResult = ReturnType<typeof useGetTransmissionTypesQuery>;
export type GetTransmissionTypesLazyQueryHookResult = ReturnType<typeof useGetTransmissionTypesLazyQuery>;
export type GetTransmissionTypesQueryResult = Apollo.QueryResult<Types.GetTransmissionTypesQuery, Types.GetTransmissionTypesQueryVariables>;
export const CreateTransmissionTypeDocument = gql`
    mutation createTransmissionType($input: CodetableCreateDto!) {
  createTransmissionType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateTransmissionTypeMutationFn = Apollo.MutationFunction<Types.CreateTransmissionTypeMutation, Types.CreateTransmissionTypeMutationVariables>;

/**
 * __useCreateTransmissionTypeMutation__
 *
 * To run a mutation, you first call `useCreateTransmissionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransmissionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransmissionTypeMutation, { data, loading, error }] = useCreateTransmissionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransmissionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTransmissionTypeMutation, Types.CreateTransmissionTypeMutationVariables>) {
        return Apollo.useMutation<Types.CreateTransmissionTypeMutation, Types.CreateTransmissionTypeMutationVariables>(CreateTransmissionTypeDocument, baseOptions);
      }
export type CreateTransmissionTypeMutationHookResult = ReturnType<typeof useCreateTransmissionTypeMutation>;
export type CreateTransmissionTypeMutationResult = Apollo.MutationResult<Types.CreateTransmissionTypeMutation>;
export type CreateTransmissionTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateTransmissionTypeMutation, Types.CreateTransmissionTypeMutationVariables>;
export const UpdateTransmissionTypeDocument = gql`
    mutation updateTransmissionType($input: CodetableUpdateDto!) {
  updateTransmissionType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateTransmissionTypeMutationFn = Apollo.MutationFunction<Types.UpdateTransmissionTypeMutation, Types.UpdateTransmissionTypeMutationVariables>;

/**
 * __useUpdateTransmissionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTransmissionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransmissionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransmissionTypeMutation, { data, loading, error }] = useUpdateTransmissionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransmissionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTransmissionTypeMutation, Types.UpdateTransmissionTypeMutationVariables>) {
        return Apollo.useMutation<Types.UpdateTransmissionTypeMutation, Types.UpdateTransmissionTypeMutationVariables>(UpdateTransmissionTypeDocument, baseOptions);
      }
export type UpdateTransmissionTypeMutationHookResult = ReturnType<typeof useUpdateTransmissionTypeMutation>;
export type UpdateTransmissionTypeMutationResult = Apollo.MutationResult<Types.UpdateTransmissionTypeMutation>;
export type UpdateTransmissionTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTransmissionTypeMutation, Types.UpdateTransmissionTypeMutationVariables>;
export const GetWheelbaseTypesDocument = gql`
    query getWheelbaseTypes($states: [CodetableStateEnum!] = [ACTIVE]) {
  getWheelbaseTypes(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetWheelbaseTypesQuery__
 *
 * To run a query within a React component, call `useGetWheelbaseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWheelbaseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWheelbaseTypesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetWheelbaseTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetWheelbaseTypesQuery, Types.GetWheelbaseTypesQueryVariables>) {
        return Apollo.useQuery<Types.GetWheelbaseTypesQuery, Types.GetWheelbaseTypesQueryVariables>(GetWheelbaseTypesDocument, baseOptions);
      }
export function useGetWheelbaseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWheelbaseTypesQuery, Types.GetWheelbaseTypesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetWheelbaseTypesQuery, Types.GetWheelbaseTypesQueryVariables>(GetWheelbaseTypesDocument, baseOptions);
        }
export type GetWheelbaseTypesQueryHookResult = ReturnType<typeof useGetWheelbaseTypesQuery>;
export type GetWheelbaseTypesLazyQueryHookResult = ReturnType<typeof useGetWheelbaseTypesLazyQuery>;
export type GetWheelbaseTypesQueryResult = Apollo.QueryResult<Types.GetWheelbaseTypesQuery, Types.GetWheelbaseTypesQueryVariables>;
export const CreateWheelbaseTypeDocument = gql`
    mutation createWheelbaseType($input: CodetableCreateDto!) {
  createWheelbaseType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateWheelbaseTypeMutationFn = Apollo.MutationFunction<Types.CreateWheelbaseTypeMutation, Types.CreateWheelbaseTypeMutationVariables>;

/**
 * __useCreateWheelbaseTypeMutation__
 *
 * To run a mutation, you first call `useCreateWheelbaseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWheelbaseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWheelbaseTypeMutation, { data, loading, error }] = useCreateWheelbaseTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWheelbaseTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWheelbaseTypeMutation, Types.CreateWheelbaseTypeMutationVariables>) {
        return Apollo.useMutation<Types.CreateWheelbaseTypeMutation, Types.CreateWheelbaseTypeMutationVariables>(CreateWheelbaseTypeDocument, baseOptions);
      }
export type CreateWheelbaseTypeMutationHookResult = ReturnType<typeof useCreateWheelbaseTypeMutation>;
export type CreateWheelbaseTypeMutationResult = Apollo.MutationResult<Types.CreateWheelbaseTypeMutation>;
export type CreateWheelbaseTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateWheelbaseTypeMutation, Types.CreateWheelbaseTypeMutationVariables>;
export const UpdateWheelbaseTypeDocument = gql`
    mutation updateWheelbaseType($input: CodetableUpdateDto!) {
  updateWheelbaseType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateWheelbaseTypeMutationFn = Apollo.MutationFunction<Types.UpdateWheelbaseTypeMutation, Types.UpdateWheelbaseTypeMutationVariables>;

/**
 * __useUpdateWheelbaseTypeMutation__
 *
 * To run a mutation, you first call `useUpdateWheelbaseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWheelbaseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWheelbaseTypeMutation, { data, loading, error }] = useUpdateWheelbaseTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWheelbaseTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWheelbaseTypeMutation, Types.UpdateWheelbaseTypeMutationVariables>) {
        return Apollo.useMutation<Types.UpdateWheelbaseTypeMutation, Types.UpdateWheelbaseTypeMutationVariables>(UpdateWheelbaseTypeDocument, baseOptions);
      }
export type UpdateWheelbaseTypeMutationHookResult = ReturnType<typeof useUpdateWheelbaseTypeMutation>;
export type UpdateWheelbaseTypeMutationResult = Apollo.MutationResult<Types.UpdateWheelbaseTypeMutation>;
export type UpdateWheelbaseTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWheelbaseTypeMutation, Types.UpdateWheelbaseTypeMutationVariables>;
export const GetVehicleTypesDocument = gql`
    query getVehicleTypes($states: [CodetableStateEnum!] = [ACTIVE]) {
  getVehicleTypes(states: $states) {
    id
    label
    state
  }
}
    `;

/**
 * __useGetVehicleTypesQuery__
 *
 * To run a query within a React component, call `useGetVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleTypesQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetVehicleTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVehicleTypesQuery, Types.GetVehicleTypesQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleTypesQuery, Types.GetVehicleTypesQueryVariables>(GetVehicleTypesDocument, baseOptions);
      }
export function useGetVehicleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleTypesQuery, Types.GetVehicleTypesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleTypesQuery, Types.GetVehicleTypesQueryVariables>(GetVehicleTypesDocument, baseOptions);
        }
export type GetVehicleTypesQueryHookResult = ReturnType<typeof useGetVehicleTypesQuery>;
export type GetVehicleTypesLazyQueryHookResult = ReturnType<typeof useGetVehicleTypesLazyQuery>;
export type GetVehicleTypesQueryResult = Apollo.QueryResult<Types.GetVehicleTypesQuery, Types.GetVehicleTypesQueryVariables>;
export const CreateVehicleTypeDocument = gql`
    mutation createVehicleType($input: CodetableCreateDto!) {
  createVehicleType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type CreateVehicleTypeMutationFn = Apollo.MutationFunction<Types.CreateVehicleTypeMutation, Types.CreateVehicleTypeMutationVariables>;

/**
 * __useCreateVehicleTypeMutation__
 *
 * To run a mutation, you first call `useCreateVehicleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleTypeMutation, { data, loading, error }] = useCreateVehicleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleTypeMutation, Types.CreateVehicleTypeMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleTypeMutation, Types.CreateVehicleTypeMutationVariables>(CreateVehicleTypeDocument, baseOptions);
      }
export type CreateVehicleTypeMutationHookResult = ReturnType<typeof useCreateVehicleTypeMutation>;
export type CreateVehicleTypeMutationResult = Apollo.MutationResult<Types.CreateVehicleTypeMutation>;
export type CreateVehicleTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleTypeMutation, Types.CreateVehicleTypeMutationVariables>;
export const UpdateVehicleTypeDocument = gql`
    mutation updateVehicleType($input: CodetableUpdateDto!) {
  updateVehicleType(input: $input) {
    errors {
      code
    }
    data {
      label
      id
      state
    }
  }
}
    `;
export type UpdateVehicleTypeMutationFn = Apollo.MutationFunction<Types.UpdateVehicleTypeMutation, Types.UpdateVehicleTypeMutationVariables>;

/**
 * __useUpdateVehicleTypeMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleTypeMutation, { data, loading, error }] = useUpdateVehicleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleTypeMutation, Types.UpdateVehicleTypeMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleTypeMutation, Types.UpdateVehicleTypeMutationVariables>(UpdateVehicleTypeDocument, baseOptions);
      }
export type UpdateVehicleTypeMutationHookResult = ReturnType<typeof useUpdateVehicleTypeMutation>;
export type UpdateVehicleTypeMutationResult = Apollo.MutationResult<Types.UpdateVehicleTypeMutation>;
export type UpdateVehicleTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleTypeMutation, Types.UpdateVehicleTypeMutationVariables>;
export const UploadUsedVehicleImageDocument = gql`
    mutation uploadUsedVehicleImage($input: UsedVehicleImageUploadInput!) {
  uploadUsedVehicleImage(input: $input) {
    errors {
      code
    }
    imageId
    publicUrl
    fileName
  }
}
    `;
export type UploadUsedVehicleImageMutationFn = Apollo.MutationFunction<Types.UploadUsedVehicleImageMutation, Types.UploadUsedVehicleImageMutationVariables>;

/**
 * __useUploadUsedVehicleImageMutation__
 *
 * To run a mutation, you first call `useUploadUsedVehicleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUsedVehicleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUsedVehicleImageMutation, { data, loading, error }] = useUploadUsedVehicleImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUsedVehicleImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadUsedVehicleImageMutation, Types.UploadUsedVehicleImageMutationVariables>) {
        return Apollo.useMutation<Types.UploadUsedVehicleImageMutation, Types.UploadUsedVehicleImageMutationVariables>(UploadUsedVehicleImageDocument, baseOptions);
      }
export type UploadUsedVehicleImageMutationHookResult = ReturnType<typeof useUploadUsedVehicleImageMutation>;
export type UploadUsedVehicleImageMutationResult = Apollo.MutationResult<Types.UploadUsedVehicleImageMutation>;
export type UploadUsedVehicleImageMutationOptions = Apollo.BaseMutationOptions<Types.UploadUsedVehicleImageMutation, Types.UploadUsedVehicleImageMutationVariables>;
export const DuplicateUsedVehicleImageDocument = gql`
    mutation duplicateUsedVehicleImage($input: UsedVehicleImageDuplicateInput!) {
  duplicateUsedVehicleImage(input: $input) {
    errors {
      code
    }
    imageId
    publicUrl
    fileName
  }
}
    `;
export type DuplicateUsedVehicleImageMutationFn = Apollo.MutationFunction<Types.DuplicateUsedVehicleImageMutation, Types.DuplicateUsedVehicleImageMutationVariables>;

/**
 * __useDuplicateUsedVehicleImageMutation__
 *
 * To run a mutation, you first call `useDuplicateUsedVehicleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateUsedVehicleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateUsedVehicleImageMutation, { data, loading, error }] = useDuplicateUsedVehicleImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateUsedVehicleImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DuplicateUsedVehicleImageMutation, Types.DuplicateUsedVehicleImageMutationVariables>) {
        return Apollo.useMutation<Types.DuplicateUsedVehicleImageMutation, Types.DuplicateUsedVehicleImageMutationVariables>(DuplicateUsedVehicleImageDocument, baseOptions);
      }
export type DuplicateUsedVehicleImageMutationHookResult = ReturnType<typeof useDuplicateUsedVehicleImageMutation>;
export type DuplicateUsedVehicleImageMutationResult = Apollo.MutationResult<Types.DuplicateUsedVehicleImageMutation>;
export type DuplicateUsedVehicleImageMutationOptions = Apollo.BaseMutationOptions<Types.DuplicateUsedVehicleImageMutation, Types.DuplicateUsedVehicleImageMutationVariables>;
export const DeleteUsedVehicleImageDocument = gql`
    mutation deleteUsedVehicleImage($input: UsedVehicleImageDeleteInput!) {
  deleteUsedVehicleImage(input: $input) {
    errors {
      code
    }
    success
  }
}
    `;
export type DeleteUsedVehicleImageMutationFn = Apollo.MutationFunction<Types.DeleteUsedVehicleImageMutation, Types.DeleteUsedVehicleImageMutationVariables>;

/**
 * __useDeleteUsedVehicleImageMutation__
 *
 * To run a mutation, you first call `useDeleteUsedVehicleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsedVehicleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsedVehicleImageMutation, { data, loading, error }] = useDeleteUsedVehicleImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUsedVehicleImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUsedVehicleImageMutation, Types.DeleteUsedVehicleImageMutationVariables>) {
        return Apollo.useMutation<Types.DeleteUsedVehicleImageMutation, Types.DeleteUsedVehicleImageMutationVariables>(DeleteUsedVehicleImageDocument, baseOptions);
      }
export type DeleteUsedVehicleImageMutationHookResult = ReturnType<typeof useDeleteUsedVehicleImageMutation>;
export type DeleteUsedVehicleImageMutationResult = Apollo.MutationResult<Types.DeleteUsedVehicleImageMutation>;
export type DeleteUsedVehicleImageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUsedVehicleImageMutation, Types.DeleteUsedVehicleImageMutationVariables>;
export const GetUsedVehicleCardsDocument = gql`
    query getUsedVehicleCards($filter: UsedVehicleCardFilter, $offset: Int, $limit: Int) {
  getUsedVehicleCards(filter: $filter, offset: $offset, limit: $limit) {
    data {
      id
      state
      created
      createdBy
      updated
      updatedBy
      vehicleDefinition {
        id
        vin
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        modelKey
        modelKeyExtension
        equipment {
          id
          name
          equipments {
            description
            count
            equipment {
              id
              name
              category {
                label
                id
              }
            }
          }
        }
        modelKey
        price
        body {
          code
          id
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        emissionUntil
        stkUntil
        intoOperation
        cubicCapacity
        currency
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        ownerCount
        mileage
        serviceBookFlag
        noCrashFlag
        vatExpelFlag
        wheelbase {
          id
          label
        }
        trunkLength
        trunkSpace
        numberOfPallets
        auctionPrice
        newVehiclePrice
        notes
        vehicleState
        refVehicle
        countryOfOrigin {
          id
          label
        }
        doorsNumber
        range
        shifts
        cubicCapacityDescription
        color {
          name
          id
          color {
            id
            label
          }
          colorType {
            id
            label
          }
        }
        customAttributes {
          id
          value
          order
          dataType
          label
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        autoCmsDiscountCRN
        autoCmsDiscountPP
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        lease {
          servicePackage {
            id
          }
          contraAccount
          leasingGroups {
            type
            items {
              totalKmPerYear
              monthlyInstalment
              month
            }
          }
        }
        credit {
          servicePackage {
            id
          }
          creditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        deferredCredit {
          servicePackage {
            id
          }
          deferredCreditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        coinsurance
      }
      images {
        id
        fileName
        mainImage
        order
        publicUrl
      }
      marketing {
        id
        marketingTags {
          id
        }
      }
      seller {
        id
        name
        address
      }
    }
    pageInfo {
      offset
      limit
      totalCount
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetUsedVehicleCardsQuery__
 *
 * To run a query within a React component, call `useGetUsedVehicleCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedVehicleCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedVehicleCardsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUsedVehicleCardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUsedVehicleCardsQuery, Types.GetUsedVehicleCardsQueryVariables>) {
        return Apollo.useQuery<Types.GetUsedVehicleCardsQuery, Types.GetUsedVehicleCardsQueryVariables>(GetUsedVehicleCardsDocument, baseOptions);
      }
export function useGetUsedVehicleCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUsedVehicleCardsQuery, Types.GetUsedVehicleCardsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUsedVehicleCardsQuery, Types.GetUsedVehicleCardsQueryVariables>(GetUsedVehicleCardsDocument, baseOptions);
        }
export type GetUsedVehicleCardsQueryHookResult = ReturnType<typeof useGetUsedVehicleCardsQuery>;
export type GetUsedVehicleCardsLazyQueryHookResult = ReturnType<typeof useGetUsedVehicleCardsLazyQuery>;
export type GetUsedVehicleCardsQueryResult = Apollo.QueryResult<Types.GetUsedVehicleCardsQuery, Types.GetUsedVehicleCardsQueryVariables>;
export const GetUsedVehicleCardDocument = gql`
    query getUsedVehicleCard($id: ID!) {
  getUsedVehicleCard(id: $id) {
    id
    state
    created
    createdBy
    updated
    updatedBy
    vehicleDefinition {
      id
      vin
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      modelKey
      modelKeyExtension
      equipment {
        id
        name
        equipments {
          description
          count
          equipment {
            id
            name
            category {
              label
              id
            }
          }
        }
      }
      modelKey
      price
      body {
        code
        id
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      emissionUntil
      cubicCapacity
      currency
      fuelConsumption
      seats
      stkUntil
      intoOperation
      driveWheel
      doorsNumber
      range
      shifts
      cubicCapacityDescription
      fourDriveWheel
      ownerCount
      mileage
      serviceBookFlag
      noCrashFlag
      vatExpelFlag
      wheelbase {
        id
        label
      }
      trunkLength
      trunkSpace
      numberOfPallets
      auctionPrice
      newVehiclePrice
      notes
      vehicleState
      refVehicle
      countryOfOrigin {
        id
        label
      }
      color {
        id
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
      }
      customAttributes {
        id
        value
        order
        dataType
        label
      }
    }
    extraEquipment {
      id
      items {
        name
        price
      }
    }
    finance {
      id
      autoCmsDiscountCRN
      autoCmsDiscountPP
      bonuses {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        value
        unit
        brand {
          id
          label
        }
        model
        vehicleType {
          id
          label
        }
        equipment
        engine
        fourDriveWheel
        validTo
        forCrn
        forPhysicalPerson
      }
      lease {
        servicePackage {
          id
        }
        contraAccount
        leasingGroups {
          type
          items {
            totalKmPerYear
            monthlyInstalment
            month
          }
        }
      }
      credit {
        servicePackage {
          id
        }
        creditGroups {
          items {
            month
            monthlyInstalment
            advancePayment
          }
          type
        }
        extension
      }
      deferredCredit {
        servicePackage {
          id
        }
        deferredCreditGroups {
          items {
            month
            monthlyInstalment
            advancePayment
          }
          type
        }
        extension
      }
      coinsurance
    }
    images {
      id
      fileName
      mainImage
      order
      publicUrl
    }
    marketing {
      id
      marketingTags {
        id
      }
    }
    seller {
      id
      name
      address
    }
  }
}
    `;

/**
 * __useGetUsedVehicleCardQuery__
 *
 * To run a query within a React component, call `useGetUsedVehicleCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedVehicleCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedVehicleCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsedVehicleCardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUsedVehicleCardQuery, Types.GetUsedVehicleCardQueryVariables>) {
        return Apollo.useQuery<Types.GetUsedVehicleCardQuery, Types.GetUsedVehicleCardQueryVariables>(GetUsedVehicleCardDocument, baseOptions);
      }
export function useGetUsedVehicleCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUsedVehicleCardQuery, Types.GetUsedVehicleCardQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUsedVehicleCardQuery, Types.GetUsedVehicleCardQueryVariables>(GetUsedVehicleCardDocument, baseOptions);
        }
export type GetUsedVehicleCardQueryHookResult = ReturnType<typeof useGetUsedVehicleCardQuery>;
export type GetUsedVehicleCardLazyQueryHookResult = ReturnType<typeof useGetUsedVehicleCardLazyQuery>;
export type GetUsedVehicleCardQueryResult = Apollo.QueryResult<Types.GetUsedVehicleCardQuery, Types.GetUsedVehicleCardQueryVariables>;
export const CreateUsedVehicleCardDocument = gql`
    mutation createUsedVehicleCard($input: UsedVehicleCardInput!) {
  createUsedVehicleCard(input: $input) {
    id
    state
    created
    createdBy
    updated
    updatedBy
    vehicleDefinition {
      id
      vin
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      modelKey
      modelKeyExtension
      equipment {
        id
        name
        equipments {
          description
          count
          equipment {
            id
            name
            category {
              label
              id
            }
          }
        }
      }
      modelKey
      price
      body {
        code
        id
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      emissionUntil
      stkUntil
      intoOperation
      cubicCapacity
      currency
      fuelConsumption
      seats
      driveWheel
      fourDriveWheel
      ownerCount
      mileage
      serviceBookFlag
      noCrashFlag
      vatExpelFlag
      wheelbase {
        id
        label
      }
      trunkLength
      trunkSpace
      numberOfPallets
      auctionPrice
      newVehiclePrice
      notes
      vehicleState
      refVehicle
      countryOfOrigin {
        id
        label
      }
      cubicCapacityDescription
      doorsNumber
      range
      shifts
      color {
        name
        id
        color {
          id
          label
        }
        colorType {
          id
          label
        }
      }
    }
    extraEquipment {
      id
      items {
        name
        price
      }
    }
    finance {
      id
      autoCmsDiscountCRN
      autoCmsDiscountPP
      bonuses {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        value
        unit
        brand {
          id
          label
        }
        model
        vehicleType {
          id
          label
        }
        equipment
        engine
        fourDriveWheel
        validTo
        forCrn
        forPhysicalPerson
      }
      lease {
        servicePackage {
          id
        }
        contraAccount
        leasingGroups {
          type
          items {
            totalKmPerYear
            monthlyInstalment
            month
          }
        }
      }
      credit {
        servicePackage {
          id
        }
        creditGroups {
          items {
            month
            monthlyInstalment
            advancePayment
          }
          type
        }
        extension
      }
      deferredCredit {
        servicePackage {
          id
        }
        deferredCreditGroups {
          items {
            month
            monthlyInstalment
            advancePayment
          }
          type
        }
        extension
      }
      coinsurance
    }
    images {
      id
      fileName
      mainImage
      order
      publicUrl
    }
    marketing {
      id
      marketingTags {
        id
      }
    }
    seller {
      id
      name
      address
    }
  }
}
    `;
export type CreateUsedVehicleCardMutationFn = Apollo.MutationFunction<Types.CreateUsedVehicleCardMutation, Types.CreateUsedVehicleCardMutationVariables>;

/**
 * __useCreateUsedVehicleCardMutation__
 *
 * To run a mutation, you first call `useCreateUsedVehicleCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsedVehicleCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsedVehicleCardMutation, { data, loading, error }] = useCreateUsedVehicleCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsedVehicleCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUsedVehicleCardMutation, Types.CreateUsedVehicleCardMutationVariables>) {
        return Apollo.useMutation<Types.CreateUsedVehicleCardMutation, Types.CreateUsedVehicleCardMutationVariables>(CreateUsedVehicleCardDocument, baseOptions);
      }
export type CreateUsedVehicleCardMutationHookResult = ReturnType<typeof useCreateUsedVehicleCardMutation>;
export type CreateUsedVehicleCardMutationResult = Apollo.MutationResult<Types.CreateUsedVehicleCardMutation>;
export type CreateUsedVehicleCardMutationOptions = Apollo.BaseMutationOptions<Types.CreateUsedVehicleCardMutation, Types.CreateUsedVehicleCardMutationVariables>;
export const UpdateUsedVehicleCardDocument = gql`
    mutation updateUsedVehicleCard($input: UsedVehicleCardUpdateInput!) {
  updateUsedVehicleCard(input: $input) {
    errors {
      code
    }
    usedVehicleCard {
      id
      state
      created
      createdBy
      updated
      updatedBy
      vehicleDefinition {
        id
        vin
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        modelKey
        modelKeyExtension
        equipment {
          id
          name
          equipments {
            description
            count
            equipment {
              id
              name
              category {
                label
                id
              }
            }
          }
        }
        modelKey
        price
        body {
          code
          id
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        emissionUntil
        stkUntil
        intoOperation
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        ownerCount
        mileage
        serviceBookFlag
        noCrashFlag
        vatExpelFlag
        wheelbase {
          id
          label
        }
        trunkLength
        trunkSpace
        numberOfPallets
        auctionPrice
        newVehiclePrice
        notes
        vehicleState
        refVehicle
        countryOfOrigin {
          id
          label
        }
        doorsNumber
        range
        shifts
        cubicCapacityDescription
        color {
          name
          id
          color {
            id
            label
          }
          colorType {
            id
            label
          }
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        autoCmsDiscountCRN
        autoCmsDiscountPP
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        lease {
          servicePackage {
            id
          }
          contraAccount
          leasingGroups {
            type
            items {
              totalKmPerYear
              monthlyInstalment
              month
            }
          }
        }
        credit {
          servicePackage {
            id
          }
          creditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        deferredCredit {
          servicePackage {
            id
          }
          deferredCreditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        coinsurance
      }
      images {
        id
        fileName
        mainImage
        order
        publicUrl
      }
      marketing {
        id
        marketingTags {
          id
        }
      }
      seller {
        id
        name
        address
      }
    }
  }
}
    `;
export type UpdateUsedVehicleCardMutationFn = Apollo.MutationFunction<Types.UpdateUsedVehicleCardMutation, Types.UpdateUsedVehicleCardMutationVariables>;

/**
 * __useUpdateUsedVehicleCardMutation__
 *
 * To run a mutation, you first call `useUpdateUsedVehicleCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsedVehicleCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsedVehicleCardMutation, { data, loading, error }] = useUpdateUsedVehicleCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsedVehicleCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUsedVehicleCardMutation, Types.UpdateUsedVehicleCardMutationVariables>) {
        return Apollo.useMutation<Types.UpdateUsedVehicleCardMutation, Types.UpdateUsedVehicleCardMutationVariables>(UpdateUsedVehicleCardDocument, baseOptions);
      }
export type UpdateUsedVehicleCardMutationHookResult = ReturnType<typeof useUpdateUsedVehicleCardMutation>;
export type UpdateUsedVehicleCardMutationResult = Apollo.MutationResult<Types.UpdateUsedVehicleCardMutation>;
export type UpdateUsedVehicleCardMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUsedVehicleCardMutation, Types.UpdateUsedVehicleCardMutationVariables>;
export const UpdateUsedVehicleCardStateDocument = gql`
    mutation updateUsedVehicleCardState($input: UsedVehicleCardStateUpdateInput!) {
  updateUsedVehicleCardState(input: $input) {
    errors {
      code
    }
    usedVehicleCard {
      id
      state
      created
      createdBy
      updated
      updatedBy
      vehicleDefinition {
        id
        vin
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        modelKey
        modelKeyExtension
        equipment {
          id
          name
          equipments {
            description
            count
            equipment {
              id
              name
              category {
                label
                id
              }
            }
          }
        }
        modelKey
        price
        body {
          code
          id
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        emissionUntil
        stkUntil
        intoOperation
        cubicCapacity
        currency
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        ownerCount
        mileage
        serviceBookFlag
        noCrashFlag
        vatExpelFlag
        wheelbase {
          id
          label
        }
        trunkLength
        trunkSpace
        numberOfPallets
        auctionPrice
        newVehiclePrice
        notes
        vehicleState
        refVehicle
        countryOfOrigin {
          id
          label
        }
        doorsNumber
        range
        shifts
        cubicCapacityDescription
        color {
          name
          id
          color {
            id
            label
          }
          colorType {
            id
            label
          }
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        autoCmsDiscountCRN
        autoCmsDiscountPP
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        lease {
          servicePackage {
            id
          }
          contraAccount
          leasingGroups {
            type
            items {
              totalKmPerYear
              monthlyInstalment
              month
            }
          }
        }
        credit {
          servicePackage {
            id
          }
          creditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        deferredCredit {
          servicePackage {
            id
          }
          deferredCreditGroups {
            items {
              month
              monthlyInstalment
              advancePayment
            }
            type
          }
          extension
        }
        coinsurance
      }
      images {
        id
        fileName
        mainImage
        order
        publicUrl
      }
      marketing {
        id
        marketingTags {
          id
        }
      }
      seller {
        id
        name
        address
      }
    }
  }
}
    `;
export type UpdateUsedVehicleCardStateMutationFn = Apollo.MutationFunction<Types.UpdateUsedVehicleCardStateMutation, Types.UpdateUsedVehicleCardStateMutationVariables>;

/**
 * __useUpdateUsedVehicleCardStateMutation__
 *
 * To run a mutation, you first call `useUpdateUsedVehicleCardStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsedVehicleCardStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsedVehicleCardStateMutation, { data, loading, error }] = useUpdateUsedVehicleCardStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsedVehicleCardStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUsedVehicleCardStateMutation, Types.UpdateUsedVehicleCardStateMutationVariables>) {
        return Apollo.useMutation<Types.UpdateUsedVehicleCardStateMutation, Types.UpdateUsedVehicleCardStateMutationVariables>(UpdateUsedVehicleCardStateDocument, baseOptions);
      }
export type UpdateUsedVehicleCardStateMutationHookResult = ReturnType<typeof useUpdateUsedVehicleCardStateMutation>;
export type UpdateUsedVehicleCardStateMutationResult = Apollo.MutationResult<Types.UpdateUsedVehicleCardStateMutation>;
export type UpdateUsedVehicleCardStateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUsedVehicleCardStateMutation, Types.UpdateUsedVehicleCardStateMutationVariables>;
export const CreateVehicleCardExtraEquipmentDocument = gql`
    mutation createVehicleCardExtraEquipment($input: VehicleCardExtraEquipmentCreateInput!) {
  createVehicleCardExtraEquipment(input: $input) {
    errors {
      code
    }
    extraEquipment {
      id
      items {
        name
        price
      }
    }
  }
}
    `;
export type CreateVehicleCardExtraEquipmentMutationFn = Apollo.MutationFunction<Types.CreateVehicleCardExtraEquipmentMutation, Types.CreateVehicleCardExtraEquipmentMutationVariables>;

/**
 * __useCreateVehicleCardExtraEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCardExtraEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCardExtraEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCardExtraEquipmentMutation, { data, loading, error }] = useCreateVehicleCardExtraEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCardExtraEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleCardExtraEquipmentMutation, Types.CreateVehicleCardExtraEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleCardExtraEquipmentMutation, Types.CreateVehicleCardExtraEquipmentMutationVariables>(CreateVehicleCardExtraEquipmentDocument, baseOptions);
      }
export type CreateVehicleCardExtraEquipmentMutationHookResult = ReturnType<typeof useCreateVehicleCardExtraEquipmentMutation>;
export type CreateVehicleCardExtraEquipmentMutationResult = Apollo.MutationResult<Types.CreateVehicleCardExtraEquipmentMutation>;
export type CreateVehicleCardExtraEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleCardExtraEquipmentMutation, Types.CreateVehicleCardExtraEquipmentMutationVariables>;
export const UpdateVehicleCardExtraEquipmentDocument = gql`
    mutation updateVehicleCardExtraEquipment($input: VehicleCardExtraEquipmentUpdateInput!) {
  updateVehicleCardExtraEquipment(input: $input) {
    errors {
      code
    }
    extraEquipment {
      id
      items {
        name
        price
      }
    }
  }
}
    `;
export type UpdateVehicleCardExtraEquipmentMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardExtraEquipmentMutation, Types.UpdateVehicleCardExtraEquipmentMutationVariables>;

/**
 * __useUpdateVehicleCardExtraEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardExtraEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardExtraEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardExtraEquipmentMutation, { data, loading, error }] = useUpdateVehicleCardExtraEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardExtraEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardExtraEquipmentMutation, Types.UpdateVehicleCardExtraEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardExtraEquipmentMutation, Types.UpdateVehicleCardExtraEquipmentMutationVariables>(UpdateVehicleCardExtraEquipmentDocument, baseOptions);
      }
export type UpdateVehicleCardExtraEquipmentMutationHookResult = ReturnType<typeof useUpdateVehicleCardExtraEquipmentMutation>;
export type UpdateVehicleCardExtraEquipmentMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardExtraEquipmentMutation>;
export type UpdateVehicleCardExtraEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardExtraEquipmentMutation, Types.UpdateVehicleCardExtraEquipmentMutationVariables>;
export const CreateVehicleCardFinanceDocument = gql`
    mutation createVehicleCardFinance($input: VehicleCardFinanceCreateInput!) {
  createVehicleCardFinance(input: $input) {
    errors {
      code
    }
    finance {
      id
    }
  }
}
    `;
export type CreateVehicleCardFinanceMutationFn = Apollo.MutationFunction<Types.CreateVehicleCardFinanceMutation, Types.CreateVehicleCardFinanceMutationVariables>;

/**
 * __useCreateVehicleCardFinanceMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCardFinanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCardFinanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCardFinanceMutation, { data, loading, error }] = useCreateVehicleCardFinanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCardFinanceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleCardFinanceMutation, Types.CreateVehicleCardFinanceMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleCardFinanceMutation, Types.CreateVehicleCardFinanceMutationVariables>(CreateVehicleCardFinanceDocument, baseOptions);
      }
export type CreateVehicleCardFinanceMutationHookResult = ReturnType<typeof useCreateVehicleCardFinanceMutation>;
export type CreateVehicleCardFinanceMutationResult = Apollo.MutationResult<Types.CreateVehicleCardFinanceMutation>;
export type CreateVehicleCardFinanceMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleCardFinanceMutation, Types.CreateVehicleCardFinanceMutationVariables>;
export const UpdateVehicleCardFinanceDocument = gql`
    mutation updateVehicleCardFinance($input: VehicleCardFinanceUpdateInput!) {
  updateVehicleCardFinance(input: $input) {
    errors {
      code
    }
    finance {
      id
    }
  }
}
    `;
export type UpdateVehicleCardFinanceMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardFinanceMutation, Types.UpdateVehicleCardFinanceMutationVariables>;

/**
 * __useUpdateVehicleCardFinanceMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardFinanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardFinanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardFinanceMutation, { data, loading, error }] = useUpdateVehicleCardFinanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardFinanceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardFinanceMutation, Types.UpdateVehicleCardFinanceMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardFinanceMutation, Types.UpdateVehicleCardFinanceMutationVariables>(UpdateVehicleCardFinanceDocument, baseOptions);
      }
export type UpdateVehicleCardFinanceMutationHookResult = ReturnType<typeof useUpdateVehicleCardFinanceMutation>;
export type UpdateVehicleCardFinanceMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardFinanceMutation>;
export type UpdateVehicleCardFinanceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardFinanceMutation, Types.UpdateVehicleCardFinanceMutationVariables>;
export const GetImageDocument = gql`
    query getImage($id: ID!) {
  getImage(id: $id) {
    id
    content
  }
}
    `;

/**
 * __useGetImageQuery__
 *
 * To run a query within a React component, call `useGetImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetImageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetImageQuery, Types.GetImageQueryVariables>) {
        return Apollo.useQuery<Types.GetImageQuery, Types.GetImageQueryVariables>(GetImageDocument, baseOptions);
      }
export function useGetImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetImageQuery, Types.GetImageQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetImageQuery, Types.GetImageQueryVariables>(GetImageDocument, baseOptions);
        }
export type GetImageQueryHookResult = ReturnType<typeof useGetImageQuery>;
export type GetImageLazyQueryHookResult = ReturnType<typeof useGetImageLazyQuery>;
export type GetImageQueryResult = Apollo.QueryResult<Types.GetImageQuery, Types.GetImageQueryVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($input: ImageUploadInput!) {
  uploadImage(input: $input) {
    errors {
      code
    }
    imageId
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<Types.UploadImageMutation, Types.UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadImageMutation, Types.UploadImageMutationVariables>) {
        return Apollo.useMutation<Types.UploadImageMutation, Types.UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<Types.UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<Types.UploadImageMutation, Types.UploadImageMutationVariables>;
export const DeleteImageDocument = gql`
    mutation deleteImage($input: ImageDeleteInput!) {
  deleteImage(input: $input) {
    errors {
      code
    }
    success
  }
}
    `;
export type DeleteImageMutationFn = Apollo.MutationFunction<Types.DeleteImageMutation, Types.DeleteImageMutationVariables>;

/**
 * __useDeleteImageMutation__
 *
 * To run a mutation, you first call `useDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageMutation, { data, loading, error }] = useDeleteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteImageMutation, Types.DeleteImageMutationVariables>) {
        return Apollo.useMutation<Types.DeleteImageMutation, Types.DeleteImageMutationVariables>(DeleteImageDocument, baseOptions);
      }
export type DeleteImageMutationHookResult = ReturnType<typeof useDeleteImageMutation>;
export type DeleteImageMutationResult = Apollo.MutationResult<Types.DeleteImageMutation>;
export type DeleteImageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteImageMutation, Types.DeleteImageMutationVariables>;
export const CreateVehicleCardMarketingDocument = gql`
    mutation createVehicleCardMarketing($input: VehicleCardMarketingCreateInput!) {
  createVehicleCardMarketing(input: $input) {
    errors {
      code
    }
    marketing {
      id
    }
  }
}
    `;
export type CreateVehicleCardMarketingMutationFn = Apollo.MutationFunction<Types.CreateVehicleCardMarketingMutation, Types.CreateVehicleCardMarketingMutationVariables>;

/**
 * __useCreateVehicleCardMarketingMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCardMarketingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCardMarketingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCardMarketingMutation, { data, loading, error }] = useCreateVehicleCardMarketingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCardMarketingMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleCardMarketingMutation, Types.CreateVehicleCardMarketingMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleCardMarketingMutation, Types.CreateVehicleCardMarketingMutationVariables>(CreateVehicleCardMarketingDocument, baseOptions);
      }
export type CreateVehicleCardMarketingMutationHookResult = ReturnType<typeof useCreateVehicleCardMarketingMutation>;
export type CreateVehicleCardMarketingMutationResult = Apollo.MutationResult<Types.CreateVehicleCardMarketingMutation>;
export type CreateVehicleCardMarketingMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleCardMarketingMutation, Types.CreateVehicleCardMarketingMutationVariables>;
export const UpdateVehicleCardMarketingDocument = gql`
    mutation updateVehicleCardMarketing($input: VehicleCardMarketingUpdateInput!) {
  updateVehicleCardMarketing(input: $input) {
    errors {
      code
    }
    marketing {
      id
    }
  }
}
    `;
export type UpdateVehicleCardMarketingMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardMarketingMutation, Types.UpdateVehicleCardMarketingMutationVariables>;

/**
 * __useUpdateVehicleCardMarketingMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardMarketingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardMarketingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardMarketingMutation, { data, loading, error }] = useUpdateVehicleCardMarketingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardMarketingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardMarketingMutation, Types.UpdateVehicleCardMarketingMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardMarketingMutation, Types.UpdateVehicleCardMarketingMutationVariables>(UpdateVehicleCardMarketingDocument, baseOptions);
      }
export type UpdateVehicleCardMarketingMutationHookResult = ReturnType<typeof useUpdateVehicleCardMarketingMutation>;
export type UpdateVehicleCardMarketingMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardMarketingMutation>;
export type UpdateVehicleCardMarketingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardMarketingMutation, Types.UpdateVehicleCardMarketingMutationVariables>;
export const GetVehicleCardsDocument = gql`
    query getVehicleCards($filter: VehicleCardFilter, $sort: [SortBy!], $offset: Int, $limit: Int) {
  getVehicleCards(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    data {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      commission
      inStoreCard
      offerValidTo
      deliveryDate {
        exactDate
        plusDays
        immediatePickUp
      }
      definition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        state
        modelDefinition {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          brand {
            id
            label
          }
          vehicleType {
            id
            label
          }
          line
          model
          modelYear
          colors {
            id
            name
            price
            modelDefinitionId
          }
        }
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelDefinitionId
          name
          sortNumber
          equipments {
            id
            count
            description
          }
        }
        vehicleSpec {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelKey
          optionCode
          price
          body {
            code
            id
            label
          }
          engine
          fuelType {
            id
            label
          }
          transmission
          transmissionType {
            id
            label
          }
          power
          emission
          cubicCapacity
          fuelConsumption
          seats
          driveWheel
          fourDriveWheel
          range
          trunkSpace
          trunkLength
          numberOfPallets
          wheelbase {
            id
            label
          }
        }
      }
      colors {
        id
        commissionColorId
        modelColor {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
        mainImageId
        images {
          id
          publicUrl
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        autoCmsDiscountCRN
        autoCmsDiscountPP
        coinsurance
        financeGroups {
          groupPrice
          lease {
            contraAccount
          }
          credit {
            servicePackage {
              id
            }
            extension
          }
          deferredCredit {
            servicePackage {
              id
            }
            extension
          }
        }
      }
    }
    pageInfo {
      offset
      limit
      totalCount
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetVehicleCardsQuery__
 *
 * To run a query within a React component, call `useGetVehicleCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleCardsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVehicleCardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVehicleCardsQuery, Types.GetVehicleCardsQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleCardsQuery, Types.GetVehicleCardsQueryVariables>(GetVehicleCardsDocument, baseOptions);
      }
export function useGetVehicleCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleCardsQuery, Types.GetVehicleCardsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleCardsQuery, Types.GetVehicleCardsQueryVariables>(GetVehicleCardsDocument, baseOptions);
        }
export type GetVehicleCardsQueryHookResult = ReturnType<typeof useGetVehicleCardsQuery>;
export type GetVehicleCardsLazyQueryHookResult = ReturnType<typeof useGetVehicleCardsLazyQuery>;
export type GetVehicleCardsQueryResult = Apollo.QueryResult<Types.GetVehicleCardsQuery, Types.GetVehicleCardsQueryVariables>;
export const GetVehicleCardDocument = gql`
    query getVehicleCard($id: ID!) {
  getVehicleCard(id: $id) {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    state
    commission
    inStoreCard
    offerValidTo
    deliveryDate {
      exactDate
      plusDays
      immediatePickUp
    }
    definition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      modelDefinition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        colors {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
      }
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelDefinitionId
        name
        sortNumber
        equipments {
          id
          equipment {
            id
            name
          }
          count
          description
        }
      }
      vehicleSpec {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelKey
        optionCode
        price
        body {
          code
          id
          label
          vehicleType {
            id
            label
          }
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        range
        trunkSpace
        trunkLength
        numberOfPallets
        wheelbase {
          id
          label
        }
      }
    }
    colors {
      id
      commissionColorId
      modelColor {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
        price
        modelDefinitionId
      }
      mainImageId
      images {
        id
        publicUrl
      }
    }
    extraEquipment {
      id
      items {
        name
        price
      }
    }
    finance {
      id
      bonuses {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        value
        unit
        brand {
          id
          label
        }
        model
        vehicleType {
          id
          label
        }
        equipment
        engine
        fourDriveWheel
        validTo
        forCrn
        forPhysicalPerson
      }
      autoCmsDiscountCRN
      autoCmsDiscountPP
      coinsurance
      financeGroups {
        groupPrice
        lease {
          servicePackage {
            id
          }
          contraAccount
          leasingGroups {
            type
            items {
              month
              monthlyInstalment
              totalKmPerYear
            }
          }
        }
        credit {
          servicePackage {
            id
          }
          extension
          creditGroups {
            type
            items {
              month
              monthlyInstalment
              advancePayment
            }
          }
        }
        deferredCredit {
          servicePackage {
            id
          }
          extension
          deferredCreditGroups {
            type
            items {
              month
              monthlyInstalment
              advancePayment
              deferredPayment
              purchasePrice
            }
          }
        }
      }
    }
    marketing {
      id
      marketingTags
    }
  }
}
    `;

/**
 * __useGetVehicleCardQuery__
 *
 * To run a query within a React component, call `useGetVehicleCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleCardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetVehicleCardQuery, Types.GetVehicleCardQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleCardQuery, Types.GetVehicleCardQueryVariables>(GetVehicleCardDocument, baseOptions);
      }
export function useGetVehicleCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleCardQuery, Types.GetVehicleCardQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleCardQuery, Types.GetVehicleCardQueryVariables>(GetVehicleCardDocument, baseOptions);
        }
export type GetVehicleCardQueryHookResult = ReturnType<typeof useGetVehicleCardQuery>;
export type GetVehicleCardLazyQueryHookResult = ReturnType<typeof useGetVehicleCardLazyQuery>;
export type GetVehicleCardQueryResult = Apollo.QueryResult<Types.GetVehicleCardQuery, Types.GetVehicleCardQueryVariables>;
export const CreateVehicleCardDocument = gql`
    mutation createVehicleCard($input: VehicleCardCreateInput!) {
  createVehicleCard(input: $input) {
    errors {
      code
    }
    vehicleCard {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      commission
      inStoreCard
      offerValidTo
      deliveryDate {
        exactDate
        plusDays
        immediatePickUp
      }
      definition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        state
        modelDefinition {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          brand {
            id
            label
          }
          vehicleType {
            id
            label
          }
          line
          model
          modelYear
          colors {
            id
            name
            price
            modelDefinitionId
          }
        }
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelDefinitionId
          name
          sortNumber
          equipments {
            id
            count
            description
          }
        }
        vehicleSpec {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelKey
          optionCode
          price
          body {
            code
            id
            label
          }
          engine
          fuelType {
            id
            label
          }
          transmission
          transmissionType {
            id
            label
          }
          power
          emission
          cubicCapacity
          fuelConsumption
          seats
          driveWheel
          fourDriveWheel
          range
          trunkSpace
          trunkLength
          numberOfPallets
          wheelbase {
            id
            label
          }
        }
      }
      colors {
        id
        modelColor {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
        mainImageId
        images {
          id
          publicUrl
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        autoCmsDiscountCRN
        autoCmsDiscountPP
        coinsurance
        financeGroups {
          groupPrice
          lease {
            servicePackage {
              id
            }
            contraAccount
          }
          credit {
            servicePackage {
              id
            }
            extension
          }
          deferredCredit {
            servicePackage {
              id
            }
            extension
          }
        }
      }
    }
  }
}
    `;
export type CreateVehicleCardMutationFn = Apollo.MutationFunction<Types.CreateVehicleCardMutation, Types.CreateVehicleCardMutationVariables>;

/**
 * __useCreateVehicleCardMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCardMutation, { data, loading, error }] = useCreateVehicleCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleCardMutation, Types.CreateVehicleCardMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleCardMutation, Types.CreateVehicleCardMutationVariables>(CreateVehicleCardDocument, baseOptions);
      }
export type CreateVehicleCardMutationHookResult = ReturnType<typeof useCreateVehicleCardMutation>;
export type CreateVehicleCardMutationResult = Apollo.MutationResult<Types.CreateVehicleCardMutation>;
export type CreateVehicleCardMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleCardMutation, Types.CreateVehicleCardMutationVariables>;
export const UpdateVehicleCardDocument = gql`
    mutation updateVehicleCard($input: VehicleCardUpdateInput!) {
  updateVehicleCard(input: $input) {
    errors {
      code
    }
    vehicleCard {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      commission
      inStoreCard
      offerValidTo
      deliveryDate {
        exactDate
        plusDays
        immediatePickUp
      }
      definition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        state
        modelDefinition {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          brand {
            id
            label
          }
          vehicleType {
            id
            label
          }
          line
          model
          modelYear
          colors {
            id
            name
            price
            modelDefinitionId
          }
        }
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelDefinitionId
          name
          sortNumber
          equipments {
            id
            count
            description
          }
        }
        vehicleSpec {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelKey
          optionCode
          price
          body {
            code
            id
            label
          }
          engine
          fuelType {
            id
            label
          }
          transmission
          transmissionType {
            id
            label
          }
          power
          emission
          cubicCapacity
          fuelConsumption
          seats
          driveWheel
          fourDriveWheel
          range
          trunkSpace
          trunkLength
          numberOfPallets
          wheelbase {
            id
            label
          }
        }
      }
      colors {
        id
        commissionColorId
        modelColor {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
        mainImageId
        images {
          id
          publicUrl
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        autoCmsDiscountCRN
        autoCmsDiscountPP
        coinsurance
        financeGroups {
          groupPrice
          lease {
            servicePackage {
              id
            }
            contraAccount
          }
          credit {
            servicePackage {
              id
            }
            extension
          }
          deferredCredit {
            servicePackage {
              id
            }
            extension
          }
        }
      }
    }
  }
}
    `;
export type UpdateVehicleCardMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardMutation, Types.UpdateVehicleCardMutationVariables>;

/**
 * __useUpdateVehicleCardMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardMutation, { data, loading, error }] = useUpdateVehicleCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardMutation, Types.UpdateVehicleCardMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardMutation, Types.UpdateVehicleCardMutationVariables>(UpdateVehicleCardDocument, baseOptions);
      }
export type UpdateVehicleCardMutationHookResult = ReturnType<typeof useUpdateVehicleCardMutation>;
export type UpdateVehicleCardMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardMutation>;
export type UpdateVehicleCardMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardMutation, Types.UpdateVehicleCardMutationVariables>;
export const UpdateVehicleCardStateDocument = gql`
    mutation updateVehicleCardState($input: VehicleCardStateUpdateInput!) {
  updateVehicleCardState(input: $input) {
    errors {
      code
    }
    vehicleCard {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      commission
      inStoreCard
      offerValidTo
      deliveryDate {
        exactDate
        plusDays
        immediatePickUp
      }
      definition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        state
        modelDefinition {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          brand {
            id
            label
          }
          vehicleType {
            id
            label
          }
          line
          model
          modelYear
          colors {
            id
            name
            price
            modelDefinitionId
          }
        }
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelDefinitionId
          name
          sortNumber
          equipments {
            id
            count
            description
          }
        }
        vehicleSpec {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          modelKey
          optionCode
          price
          body {
            code
            id
            label
          }
          engine
          fuelType {
            id
            label
          }
          transmission
          transmissionType {
            id
            label
          }
          power
          emission
          cubicCapacity
          fuelConsumption
          seats
          driveWheel
          fourDriveWheel
          range
          trunkSpace
          trunkLength
          numberOfPallets
          wheelbase {
            id
            label
          }
        }
      }
      colors {
        id
        commissionColorId
        modelColor {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
        mainImageId
        images {
          id
          publicUrl
        }
      }
      extraEquipment {
        id
        items {
          name
          price
        }
      }
      finance {
        id
        bonuses {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          value
          unit
          brand {
            id
            label
          }
          model
          vehicleType {
            id
            label
          }
          equipment
          engine
          fourDriveWheel
          validTo
          forCrn
          forPhysicalPerson
        }
        autoCmsDiscountCRN
        autoCmsDiscountPP
        coinsurance
        financeGroups {
          groupPrice
          lease {
            servicePackage {
              id
            }
            contraAccount
          }
          credit {
            servicePackage {
              id
            }
            extension
          }
          deferredCredit {
            servicePackage {
              id
            }
            extension
          }
        }
      }
    }
  }
}
    `;
export type UpdateVehicleCardStateMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardStateMutation, Types.UpdateVehicleCardStateMutationVariables>;

/**
 * __useUpdateVehicleCardStateMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardStateMutation, { data, loading, error }] = useUpdateVehicleCardStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardStateMutation, Types.UpdateVehicleCardStateMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardStateMutation, Types.UpdateVehicleCardStateMutationVariables>(UpdateVehicleCardStateDocument, baseOptions);
      }
export type UpdateVehicleCardStateMutationHookResult = ReturnType<typeof useUpdateVehicleCardStateMutation>;
export type UpdateVehicleCardStateMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardStateMutation>;
export type UpdateVehicleCardStateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardStateMutation, Types.UpdateVehicleCardStateMutationVariables>;
export const CreateInStoreVehicleCardDocument = gql`
    mutation createInStoreVehicleCard($input: InStoreVehicleCardInput!) {
  createInStoreVehicleCard(input: $input) {
    errors {
      code
    }
    vehicleCard {
      id
    }
  }
}
    `;
export type CreateInStoreVehicleCardMutationFn = Apollo.MutationFunction<Types.CreateInStoreVehicleCardMutation, Types.CreateInStoreVehicleCardMutationVariables>;

/**
 * __useCreateInStoreVehicleCardMutation__
 *
 * To run a mutation, you first call `useCreateInStoreVehicleCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInStoreVehicleCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInStoreVehicleCardMutation, { data, loading, error }] = useCreateInStoreVehicleCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInStoreVehicleCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateInStoreVehicleCardMutation, Types.CreateInStoreVehicleCardMutationVariables>) {
        return Apollo.useMutation<Types.CreateInStoreVehicleCardMutation, Types.CreateInStoreVehicleCardMutationVariables>(CreateInStoreVehicleCardDocument, baseOptions);
      }
export type CreateInStoreVehicleCardMutationHookResult = ReturnType<typeof useCreateInStoreVehicleCardMutation>;
export type CreateInStoreVehicleCardMutationResult = Apollo.MutationResult<Types.CreateInStoreVehicleCardMutation>;
export type CreateInStoreVehicleCardMutationOptions = Apollo.BaseMutationOptions<Types.CreateInStoreVehicleCardMutation, Types.CreateInStoreVehicleCardMutationVariables>;
export const CreateVehicleCardColorDocument = gql`
    mutation createVehicleCardColor($input: VehicleCardColorCreateInput!) {
  createVehicleCardColor(input: $input) {
    errors {
      code
    }
    cardColor {
      id
    }
  }
}
    `;
export type CreateVehicleCardColorMutationFn = Apollo.MutationFunction<Types.CreateVehicleCardColorMutation, Types.CreateVehicleCardColorMutationVariables>;

/**
 * __useCreateVehicleCardColorMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCardColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCardColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCardColorMutation, { data, loading, error }] = useCreateVehicleCardColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCardColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleCardColorMutation, Types.CreateVehicleCardColorMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleCardColorMutation, Types.CreateVehicleCardColorMutationVariables>(CreateVehicleCardColorDocument, baseOptions);
      }
export type CreateVehicleCardColorMutationHookResult = ReturnType<typeof useCreateVehicleCardColorMutation>;
export type CreateVehicleCardColorMutationResult = Apollo.MutationResult<Types.CreateVehicleCardColorMutation>;
export type CreateVehicleCardColorMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleCardColorMutation, Types.CreateVehicleCardColorMutationVariables>;
export const DeleteVehicleCardColorDocument = gql`
    mutation deleteVehicleCardColor($input: VehicleCardColorDeleteInput!) {
  deleteVehicleCardColor(input: $input) {
    success
    errors {
      code
    }
  }
}
    `;
export type DeleteVehicleCardColorMutationFn = Apollo.MutationFunction<Types.DeleteVehicleCardColorMutation, Types.DeleteVehicleCardColorMutationVariables>;

/**
 * __useDeleteVehicleCardColorMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleCardColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleCardColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleCardColorMutation, { data, loading, error }] = useDeleteVehicleCardColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVehicleCardColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteVehicleCardColorMutation, Types.DeleteVehicleCardColorMutationVariables>) {
        return Apollo.useMutation<Types.DeleteVehicleCardColorMutation, Types.DeleteVehicleCardColorMutationVariables>(DeleteVehicleCardColorDocument, baseOptions);
      }
export type DeleteVehicleCardColorMutationHookResult = ReturnType<typeof useDeleteVehicleCardColorMutation>;
export type DeleteVehicleCardColorMutationResult = Apollo.MutationResult<Types.DeleteVehicleCardColorMutation>;
export type DeleteVehicleCardColorMutationOptions = Apollo.BaseMutationOptions<Types.DeleteVehicleCardColorMutation, Types.DeleteVehicleCardColorMutationVariables>;
export const UpdateVehicleCardColorDocument = gql`
    mutation updateVehicleCardColor($input: VehicleCardColorUpdateInput!) {
  updateVehicleCardColor(input: $input) {
    errors {
      code
    }
    cardColor {
      id
      mainImageId
    }
  }
}
    `;
export type UpdateVehicleCardColorMutationFn = Apollo.MutationFunction<Types.UpdateVehicleCardColorMutation, Types.UpdateVehicleCardColorMutationVariables>;

/**
 * __useUpdateVehicleCardColorMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCardColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCardColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCardColorMutation, { data, loading, error }] = useUpdateVehicleCardColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCardColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleCardColorMutation, Types.UpdateVehicleCardColorMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleCardColorMutation, Types.UpdateVehicleCardColorMutationVariables>(UpdateVehicleCardColorDocument, baseOptions);
      }
export type UpdateVehicleCardColorMutationHookResult = ReturnType<typeof useUpdateVehicleCardColorMutation>;
export type UpdateVehicleCardColorMutationResult = Apollo.MutationResult<Types.UpdateVehicleCardColorMutation>;
export type UpdateVehicleCardColorMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleCardColorMutation, Types.UpdateVehicleCardColorMutationVariables>;
export const CreateModelColorDocument = gql`
    mutation createModelColor($input: ModelColorCreateInput!) {
  createModelColor(input: $input) {
    errors {
      code
    }
    modelColor {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      color {
        id
        label
      }
      colorType {
        id
        label
      }
      price
      modelDefinitionId
    }
  }
}
    `;
export type CreateModelColorMutationFn = Apollo.MutationFunction<Types.CreateModelColorMutation, Types.CreateModelColorMutationVariables>;

/**
 * __useCreateModelColorMutation__
 *
 * To run a mutation, you first call `useCreateModelColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelColorMutation, { data, loading, error }] = useCreateModelColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateModelColorMutation, Types.CreateModelColorMutationVariables>) {
        return Apollo.useMutation<Types.CreateModelColorMutation, Types.CreateModelColorMutationVariables>(CreateModelColorDocument, baseOptions);
      }
export type CreateModelColorMutationHookResult = ReturnType<typeof useCreateModelColorMutation>;
export type CreateModelColorMutationResult = Apollo.MutationResult<Types.CreateModelColorMutation>;
export type CreateModelColorMutationOptions = Apollo.BaseMutationOptions<Types.CreateModelColorMutation, Types.CreateModelColorMutationVariables>;
export const UpdateModelColorDocument = gql`
    mutation updateModelColor($input: ModelColorUpdateInput!) {
  updateModelColor(input: $input) {
    errors {
      code
    }
    modelColor {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      color {
        id
        label
      }
      colorType {
        id
        label
      }
      price
      modelDefinitionId
    }
  }
}
    `;
export type UpdateModelColorMutationFn = Apollo.MutationFunction<Types.UpdateModelColorMutation, Types.UpdateModelColorMutationVariables>;

/**
 * __useUpdateModelColorMutation__
 *
 * To run a mutation, you first call `useUpdateModelColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelColorMutation, { data, loading, error }] = useUpdateModelColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModelColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateModelColorMutation, Types.UpdateModelColorMutationVariables>) {
        return Apollo.useMutation<Types.UpdateModelColorMutation, Types.UpdateModelColorMutationVariables>(UpdateModelColorDocument, baseOptions);
      }
export type UpdateModelColorMutationHookResult = ReturnType<typeof useUpdateModelColorMutation>;
export type UpdateModelColorMutationResult = Apollo.MutationResult<Types.UpdateModelColorMutation>;
export type UpdateModelColorMutationOptions = Apollo.BaseMutationOptions<Types.UpdateModelColorMutation, Types.UpdateModelColorMutationVariables>;
export const DeleteModelColorDocument = gql`
    mutation deleteModelColor($input: ModelColorDeleteInput!) {
  deleteModelColor(input: $input) {
    errors {
      code
    }
    success
  }
}
    `;
export type DeleteModelColorMutationFn = Apollo.MutationFunction<Types.DeleteModelColorMutation, Types.DeleteModelColorMutationVariables>;

/**
 * __useDeleteModelColorMutation__
 *
 * To run a mutation, you first call `useDeleteModelColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelColorMutation, { data, loading, error }] = useDeleteModelColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteModelColorMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteModelColorMutation, Types.DeleteModelColorMutationVariables>) {
        return Apollo.useMutation<Types.DeleteModelColorMutation, Types.DeleteModelColorMutationVariables>(DeleteModelColorDocument, baseOptions);
      }
export type DeleteModelColorMutationHookResult = ReturnType<typeof useDeleteModelColorMutation>;
export type DeleteModelColorMutationResult = Apollo.MutationResult<Types.DeleteModelColorMutation>;
export type DeleteModelColorMutationOptions = Apollo.BaseMutationOptions<Types.DeleteModelColorMutation, Types.DeleteModelColorMutationVariables>;
export const GetModelDefinitionsDocument = gql`
    query getModelDefinitions {
  getModelDefinitions {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    brand {
      id
      label
    }
    vehicleType {
      id
      label
    }
    line
    model
    modelYear
    colors {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      name
      color {
        id
        label
      }
      colorType {
        id
        label
      }
      price
      modelDefinitionId
    }
  }
}
    `;

/**
 * __useGetModelDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetModelDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelDefinitionsQuery, Types.GetModelDefinitionsQueryVariables>) {
        return Apollo.useQuery<Types.GetModelDefinitionsQuery, Types.GetModelDefinitionsQueryVariables>(GetModelDefinitionsDocument, baseOptions);
      }
export function useGetModelDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelDefinitionsQuery, Types.GetModelDefinitionsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelDefinitionsQuery, Types.GetModelDefinitionsQueryVariables>(GetModelDefinitionsDocument, baseOptions);
        }
export type GetModelDefinitionsQueryHookResult = ReturnType<typeof useGetModelDefinitionsQuery>;
export type GetModelDefinitionsLazyQueryHookResult = ReturnType<typeof useGetModelDefinitionsLazyQuery>;
export type GetModelDefinitionsQueryResult = Apollo.QueryResult<Types.GetModelDefinitionsQuery, Types.GetModelDefinitionsQueryVariables>;
export const CreateModelDefinitionDocument = gql`
    mutation createModelDefinition($input: ModelDefinitionCreateInput!) {
  createModelDefinition(input: $input) {
    errors {
      code
    }
    modelDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      colors {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
        price
        modelDefinitionId
      }
    }
  }
}
    `;
export type CreateModelDefinitionMutationFn = Apollo.MutationFunction<Types.CreateModelDefinitionMutation, Types.CreateModelDefinitionMutationVariables>;

/**
 * __useCreateModelDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateModelDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelDefinitionMutation, { data, loading, error }] = useCreateModelDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateModelDefinitionMutation, Types.CreateModelDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.CreateModelDefinitionMutation, Types.CreateModelDefinitionMutationVariables>(CreateModelDefinitionDocument, baseOptions);
      }
export type CreateModelDefinitionMutationHookResult = ReturnType<typeof useCreateModelDefinitionMutation>;
export type CreateModelDefinitionMutationResult = Apollo.MutationResult<Types.CreateModelDefinitionMutation>;
export type CreateModelDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.CreateModelDefinitionMutation, Types.CreateModelDefinitionMutationVariables>;
export const UpdateModelDefinitionDocument = gql`
    mutation updateModelDefinition($input: ModelDefinitionUpdateInput!) {
  updateModelDefinition(input: $input) {
    errors {
      code
    }
    modelDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      colors {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
        price
        modelDefinitionId
      }
    }
  }
}
    `;
export type UpdateModelDefinitionMutationFn = Apollo.MutationFunction<Types.UpdateModelDefinitionMutation, Types.UpdateModelDefinitionMutationVariables>;

/**
 * __useUpdateModelDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateModelDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelDefinitionMutation, { data, loading, error }] = useUpdateModelDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModelDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateModelDefinitionMutation, Types.UpdateModelDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.UpdateModelDefinitionMutation, Types.UpdateModelDefinitionMutationVariables>(UpdateModelDefinitionDocument, baseOptions);
      }
export type UpdateModelDefinitionMutationHookResult = ReturnType<typeof useUpdateModelDefinitionMutation>;
export type UpdateModelDefinitionMutationResult = Apollo.MutationResult<Types.UpdateModelDefinitionMutation>;
export type UpdateModelDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateModelDefinitionMutation, Types.UpdateModelDefinitionMutationVariables>;
export const GetModelDefinitionBrandsDocument = gql`
    query getModelDefinitionBrands {
  getModelDefinitionBrands {
    id
    label
  }
}
    `;

/**
 * __useGetModelDefinitionBrandsQuery__
 *
 * To run a query within a React component, call `useGetModelDefinitionBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDefinitionBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDefinitionBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelDefinitionBrandsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelDefinitionBrandsQuery, Types.GetModelDefinitionBrandsQueryVariables>) {
        return Apollo.useQuery<Types.GetModelDefinitionBrandsQuery, Types.GetModelDefinitionBrandsQueryVariables>(GetModelDefinitionBrandsDocument, baseOptions);
      }
export function useGetModelDefinitionBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelDefinitionBrandsQuery, Types.GetModelDefinitionBrandsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelDefinitionBrandsQuery, Types.GetModelDefinitionBrandsQueryVariables>(GetModelDefinitionBrandsDocument, baseOptions);
        }
export type GetModelDefinitionBrandsQueryHookResult = ReturnType<typeof useGetModelDefinitionBrandsQuery>;
export type GetModelDefinitionBrandsLazyQueryHookResult = ReturnType<typeof useGetModelDefinitionBrandsLazyQuery>;
export type GetModelDefinitionBrandsQueryResult = Apollo.QueryResult<Types.GetModelDefinitionBrandsQuery, Types.GetModelDefinitionBrandsQueryVariables>;
export const GetModelDefinitionModelsDocument = gql`
    query getModelDefinitionModels {
  getModelDefinitionModels
}
    `;

/**
 * __useGetModelDefinitionModelsQuery__
 *
 * To run a query within a React component, call `useGetModelDefinitionModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDefinitionModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDefinitionModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelDefinitionModelsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelDefinitionModelsQuery, Types.GetModelDefinitionModelsQueryVariables>) {
        return Apollo.useQuery<Types.GetModelDefinitionModelsQuery, Types.GetModelDefinitionModelsQueryVariables>(GetModelDefinitionModelsDocument, baseOptions);
      }
export function useGetModelDefinitionModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelDefinitionModelsQuery, Types.GetModelDefinitionModelsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelDefinitionModelsQuery, Types.GetModelDefinitionModelsQueryVariables>(GetModelDefinitionModelsDocument, baseOptions);
        }
export type GetModelDefinitionModelsQueryHookResult = ReturnType<typeof useGetModelDefinitionModelsQuery>;
export type GetModelDefinitionModelsLazyQueryHookResult = ReturnType<typeof useGetModelDefinitionModelsLazyQuery>;
export type GetModelDefinitionModelsQueryResult = Apollo.QueryResult<Types.GetModelDefinitionModelsQuery, Types.GetModelDefinitionModelsQueryVariables>;
export const GetModelDefinitionLinesDocument = gql`
    query getModelDefinitionLines {
  getModelDefinitionLines
}
    `;

/**
 * __useGetModelDefinitionLinesQuery__
 *
 * To run a query within a React component, call `useGetModelDefinitionLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDefinitionLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDefinitionLinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelDefinitionLinesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelDefinitionLinesQuery, Types.GetModelDefinitionLinesQueryVariables>) {
        return Apollo.useQuery<Types.GetModelDefinitionLinesQuery, Types.GetModelDefinitionLinesQueryVariables>(GetModelDefinitionLinesDocument, baseOptions);
      }
export function useGetModelDefinitionLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelDefinitionLinesQuery, Types.GetModelDefinitionLinesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelDefinitionLinesQuery, Types.GetModelDefinitionLinesQueryVariables>(GetModelDefinitionLinesDocument, baseOptions);
        }
export type GetModelDefinitionLinesQueryHookResult = ReturnType<typeof useGetModelDefinitionLinesQuery>;
export type GetModelDefinitionLinesLazyQueryHookResult = ReturnType<typeof useGetModelDefinitionLinesLazyQuery>;
export type GetModelDefinitionLinesQueryResult = Apollo.QueryResult<Types.GetModelDefinitionLinesQuery, Types.GetModelDefinitionLinesQueryVariables>;
export const GetModelDefinitionModelYearsDocument = gql`
    query getModelDefinitionModelYears {
  getModelDefinitionModelYears
}
    `;

/**
 * __useGetModelDefinitionModelYearsQuery__
 *
 * To run a query within a React component, call `useGetModelDefinitionModelYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDefinitionModelYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDefinitionModelYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelDefinitionModelYearsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelDefinitionModelYearsQuery, Types.GetModelDefinitionModelYearsQueryVariables>) {
        return Apollo.useQuery<Types.GetModelDefinitionModelYearsQuery, Types.GetModelDefinitionModelYearsQueryVariables>(GetModelDefinitionModelYearsDocument, baseOptions);
      }
export function useGetModelDefinitionModelYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelDefinitionModelYearsQuery, Types.GetModelDefinitionModelYearsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelDefinitionModelYearsQuery, Types.GetModelDefinitionModelYearsQueryVariables>(GetModelDefinitionModelYearsDocument, baseOptions);
        }
export type GetModelDefinitionModelYearsQueryHookResult = ReturnType<typeof useGetModelDefinitionModelYearsQuery>;
export type GetModelDefinitionModelYearsLazyQueryHookResult = ReturnType<typeof useGetModelDefinitionModelYearsLazyQuery>;
export type GetModelDefinitionModelYearsQueryResult = Apollo.QueryResult<Types.GetModelDefinitionModelYearsQuery, Types.GetModelDefinitionModelYearsQueryVariables>;
export const GetModelEquipmentsDocument = gql`
    query getModelEquipments($modelDefinitionId: ID) {
  getModelEquipments(modelDefinitionId: $modelDefinitionId) {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    modelDefinitionId
    name
    sortNumber
    equipments {
      id
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        category {
          id
          label
        }
      }
      count
      description
    }
  }
}
    `;

/**
 * __useGetModelEquipmentsQuery__
 *
 * To run a query within a React component, call `useGetModelEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelEquipmentsQuery({
 *   variables: {
 *      modelDefinitionId: // value for 'modelDefinitionId'
 *   },
 * });
 */
export function useGetModelEquipmentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelEquipmentsQuery, Types.GetModelEquipmentsQueryVariables>) {
        return Apollo.useQuery<Types.GetModelEquipmentsQuery, Types.GetModelEquipmentsQueryVariables>(GetModelEquipmentsDocument, baseOptions);
      }
export function useGetModelEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelEquipmentsQuery, Types.GetModelEquipmentsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelEquipmentsQuery, Types.GetModelEquipmentsQueryVariables>(GetModelEquipmentsDocument, baseOptions);
        }
export type GetModelEquipmentsQueryHookResult = ReturnType<typeof useGetModelEquipmentsQuery>;
export type GetModelEquipmentsLazyQueryHookResult = ReturnType<typeof useGetModelEquipmentsLazyQuery>;
export type GetModelEquipmentsQueryResult = Apollo.QueryResult<Types.GetModelEquipmentsQuery, Types.GetModelEquipmentsQueryVariables>;
export const CreateModelEquipmentDocument = gql`
    mutation createModelEquipment($input: ModelEquipmentCreateInput!) {
  createModelEquipment(input: $input) {
    errors {
      code
    }
    modelEquipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelDefinitionId
      name
      sortNumber
      equipments {
        id
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          category {
            id
            label
          }
        }
        count
        description
      }
    }
  }
}
    `;
export type CreateModelEquipmentMutationFn = Apollo.MutationFunction<Types.CreateModelEquipmentMutation, Types.CreateModelEquipmentMutationVariables>;

/**
 * __useCreateModelEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateModelEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelEquipmentMutation, { data, loading, error }] = useCreateModelEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateModelEquipmentMutation, Types.CreateModelEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.CreateModelEquipmentMutation, Types.CreateModelEquipmentMutationVariables>(CreateModelEquipmentDocument, baseOptions);
      }
export type CreateModelEquipmentMutationHookResult = ReturnType<typeof useCreateModelEquipmentMutation>;
export type CreateModelEquipmentMutationResult = Apollo.MutationResult<Types.CreateModelEquipmentMutation>;
export type CreateModelEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateModelEquipmentMutation, Types.CreateModelEquipmentMutationVariables>;
export const UpdateModelEquipmentDocument = gql`
    mutation updateModelEquipment($input: ModelEquipmentUpdateInput!) {
  updateModelEquipment(input: $input) {
    errors {
      code
    }
    modelEquipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelDefinitionId
      name
      sortNumber
      equipments {
        id
        equipment {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          category {
            id
            label
          }
        }
        count
        description
      }
    }
  }
}
    `;
export type UpdateModelEquipmentMutationFn = Apollo.MutationFunction<Types.UpdateModelEquipmentMutation, Types.UpdateModelEquipmentMutationVariables>;

/**
 * __useUpdateModelEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateModelEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelEquipmentMutation, { data, loading, error }] = useUpdateModelEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModelEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateModelEquipmentMutation, Types.UpdateModelEquipmentMutationVariables>) {
        return Apollo.useMutation<Types.UpdateModelEquipmentMutation, Types.UpdateModelEquipmentMutationVariables>(UpdateModelEquipmentDocument, baseOptions);
      }
export type UpdateModelEquipmentMutationHookResult = ReturnType<typeof useUpdateModelEquipmentMutation>;
export type UpdateModelEquipmentMutationResult = Apollo.MutationResult<Types.UpdateModelEquipmentMutation>;
export type UpdateModelEquipmentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateModelEquipmentMutation, Types.UpdateModelEquipmentMutationVariables>;
export const CreateModelEquipmentItemDocument = gql`
    mutation createModelEquipmentItem($input: EquipmentItemCreateInput!) {
  createModelEquipmentItem(input: $input) {
    errors {
      code
    }
    equipmentItem {
      id
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        category {
          id
          label
        }
      }
      count
      description
    }
  }
}
    `;
export type CreateModelEquipmentItemMutationFn = Apollo.MutationFunction<Types.CreateModelEquipmentItemMutation, Types.CreateModelEquipmentItemMutationVariables>;

/**
 * __useCreateModelEquipmentItemMutation__
 *
 * To run a mutation, you first call `useCreateModelEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelEquipmentItemMutation, { data, loading, error }] = useCreateModelEquipmentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateModelEquipmentItemMutation, Types.CreateModelEquipmentItemMutationVariables>) {
        return Apollo.useMutation<Types.CreateModelEquipmentItemMutation, Types.CreateModelEquipmentItemMutationVariables>(CreateModelEquipmentItemDocument, baseOptions);
      }
export type CreateModelEquipmentItemMutationHookResult = ReturnType<typeof useCreateModelEquipmentItemMutation>;
export type CreateModelEquipmentItemMutationResult = Apollo.MutationResult<Types.CreateModelEquipmentItemMutation>;
export type CreateModelEquipmentItemMutationOptions = Apollo.BaseMutationOptions<Types.CreateModelEquipmentItemMutation, Types.CreateModelEquipmentItemMutationVariables>;
export const UpdateModelEquipmentItemDocument = gql`
    mutation updateModelEquipmentItem($input: EquipmentItemUpdateInput!) {
  updateModelEquipmentItem(input: $input) {
    errors {
      code
    }
    equipmentItem {
      id
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        category {
          id
          label
        }
      }
      count
      description
    }
  }
}
    `;
export type UpdateModelEquipmentItemMutationFn = Apollo.MutationFunction<Types.UpdateModelEquipmentItemMutation, Types.UpdateModelEquipmentItemMutationVariables>;

/**
 * __useUpdateModelEquipmentItemMutation__
 *
 * To run a mutation, you first call `useUpdateModelEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelEquipmentItemMutation, { data, loading, error }] = useUpdateModelEquipmentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModelEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateModelEquipmentItemMutation, Types.UpdateModelEquipmentItemMutationVariables>) {
        return Apollo.useMutation<Types.UpdateModelEquipmentItemMutation, Types.UpdateModelEquipmentItemMutationVariables>(UpdateModelEquipmentItemDocument, baseOptions);
      }
export type UpdateModelEquipmentItemMutationHookResult = ReturnType<typeof useUpdateModelEquipmentItemMutation>;
export type UpdateModelEquipmentItemMutationResult = Apollo.MutationResult<Types.UpdateModelEquipmentItemMutation>;
export type UpdateModelEquipmentItemMutationOptions = Apollo.BaseMutationOptions<Types.UpdateModelEquipmentItemMutation, Types.UpdateModelEquipmentItemMutationVariables>;
export const DeleteModelEquipmentItemDocument = gql`
    mutation deleteModelEquipmentItem($input: EquipmentItemDeleteInput!) {
  deleteModelEquipmentItem(input: $input) {
    errors {
      code
    }
    success
  }
}
    `;
export type DeleteModelEquipmentItemMutationFn = Apollo.MutationFunction<Types.DeleteModelEquipmentItemMutation, Types.DeleteModelEquipmentItemMutationVariables>;

/**
 * __useDeleteModelEquipmentItemMutation__
 *
 * To run a mutation, you first call `useDeleteModelEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelEquipmentItemMutation, { data, loading, error }] = useDeleteModelEquipmentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteModelEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteModelEquipmentItemMutation, Types.DeleteModelEquipmentItemMutationVariables>) {
        return Apollo.useMutation<Types.DeleteModelEquipmentItemMutation, Types.DeleteModelEquipmentItemMutationVariables>(DeleteModelEquipmentItemDocument, baseOptions);
      }
export type DeleteModelEquipmentItemMutationHookResult = ReturnType<typeof useDeleteModelEquipmentItemMutation>;
export type DeleteModelEquipmentItemMutationResult = Apollo.MutationResult<Types.DeleteModelEquipmentItemMutation>;
export type DeleteModelEquipmentItemMutationOptions = Apollo.BaseMutationOptions<Types.DeleteModelEquipmentItemMutation, Types.DeleteModelEquipmentItemMutationVariables>;
export const GetModelEquipmentNamesDocument = gql`
    query getModelEquipmentNames {
  getModelEquipmentNames
}
    `;

/**
 * __useGetModelEquipmentNamesQuery__
 *
 * To run a query within a React component, call `useGetModelEquipmentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelEquipmentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelEquipmentNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelEquipmentNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModelEquipmentNamesQuery, Types.GetModelEquipmentNamesQueryVariables>) {
        return Apollo.useQuery<Types.GetModelEquipmentNamesQuery, Types.GetModelEquipmentNamesQueryVariables>(GetModelEquipmentNamesDocument, baseOptions);
      }
export function useGetModelEquipmentNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModelEquipmentNamesQuery, Types.GetModelEquipmentNamesQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetModelEquipmentNamesQuery, Types.GetModelEquipmentNamesQueryVariables>(GetModelEquipmentNamesDocument, baseOptions);
        }
export type GetModelEquipmentNamesQueryHookResult = ReturnType<typeof useGetModelEquipmentNamesQuery>;
export type GetModelEquipmentNamesLazyQueryHookResult = ReturnType<typeof useGetModelEquipmentNamesLazyQuery>;
export type GetModelEquipmentNamesQueryResult = Apollo.QueryResult<Types.GetModelEquipmentNamesQuery, Types.GetModelEquipmentNamesQueryVariables>;
export const CreateTechnicalSpecDocument = gql`
    mutation createTechnicalSpec($input: TechnicalSpecificationCreateInput!) {
  createTechnicalSpec(input: $input) {
    errors {
      code
    }
    technicalSpecification {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelKey
      modelKeyExtension
      optionCode
      price
      body {
        code
        id
        label
        vehicleType {
          id
          label
        }
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      cubicCapacity
      fuelConsumption
      seats
      driveWheel
      fourDriveWheel
      range
      trunkSpace
      trunkLength
      numberOfPallets
      wheelbase {
        id
        label
      }
    }
  }
}
    `;
export type CreateTechnicalSpecMutationFn = Apollo.MutationFunction<Types.CreateTechnicalSpecMutation, Types.CreateTechnicalSpecMutationVariables>;

/**
 * __useCreateTechnicalSpecMutation__
 *
 * To run a mutation, you first call `useCreateTechnicalSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechnicalSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechnicalSpecMutation, { data, loading, error }] = useCreateTechnicalSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechnicalSpecMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTechnicalSpecMutation, Types.CreateTechnicalSpecMutationVariables>) {
        return Apollo.useMutation<Types.CreateTechnicalSpecMutation, Types.CreateTechnicalSpecMutationVariables>(CreateTechnicalSpecDocument, baseOptions);
      }
export type CreateTechnicalSpecMutationHookResult = ReturnType<typeof useCreateTechnicalSpecMutation>;
export type CreateTechnicalSpecMutationResult = Apollo.MutationResult<Types.CreateTechnicalSpecMutation>;
export type CreateTechnicalSpecMutationOptions = Apollo.BaseMutationOptions<Types.CreateTechnicalSpecMutation, Types.CreateTechnicalSpecMutationVariables>;
export const UpdateTechnicalSpecDocument = gql`
    mutation updateTechnicalSpec($input: TechnicalSpecificationUpdateInput!) {
  updateTechnicalSpec(input: $input) {
    errors {
      code
    }
    technicalSpecification {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelKey
      modelKeyExtension
      optionCode
      price
      body {
        code
        id
        label
        vehicleType {
          id
          label
        }
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      cubicCapacity
      fuelConsumption
      seats
      driveWheel
      fourDriveWheel
      range
      trunkSpace
      trunkLength
      numberOfPallets
      wheelbase {
        id
        label
      }
    }
  }
}
    `;
export type UpdateTechnicalSpecMutationFn = Apollo.MutationFunction<Types.UpdateTechnicalSpecMutation, Types.UpdateTechnicalSpecMutationVariables>;

/**
 * __useUpdateTechnicalSpecMutation__
 *
 * To run a mutation, you first call `useUpdateTechnicalSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnicalSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnicalSpecMutation, { data, loading, error }] = useUpdateTechnicalSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTechnicalSpecMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTechnicalSpecMutation, Types.UpdateTechnicalSpecMutationVariables>) {
        return Apollo.useMutation<Types.UpdateTechnicalSpecMutation, Types.UpdateTechnicalSpecMutationVariables>(UpdateTechnicalSpecDocument, baseOptions);
      }
export type UpdateTechnicalSpecMutationHookResult = ReturnType<typeof useUpdateTechnicalSpecMutation>;
export type UpdateTechnicalSpecMutationResult = Apollo.MutationResult<Types.UpdateTechnicalSpecMutation>;
export type UpdateTechnicalSpecMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTechnicalSpecMutation, Types.UpdateTechnicalSpecMutationVariables>;
export const GetVehicleDefinitionsDocument = gql`
    query getVehicleDefinitions($filter: VehicleDefinitionFilter, $sort: [SortBy!], $offset: Int, $limit: Int) {
  getVehicleDefinitions(
    filter: $filter
    sort: $sort
    offset: $offset
    limit: $limit
  ) {
    data {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      modelDefinition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        colors {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
      }
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelDefinitionId
        name
        sortNumber
        equipments {
          id
          equipment {
            id
            name
            category {
              id
            }
            auditInfo {
              createdBy
              created
              updatedBy
              updated
            }
          }
          count
          description
        }
      }
      vehicleSpec {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelKey
        optionCode
        price
        body {
          code
          id
          label
          vehicleType {
            id
            label
          }
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        range
        trunkSpace
        trunkLength
        numberOfPallets
        wheelbase {
          id
          label
        }
      }
    }
    pageInfo {
      offset
      limit
      totalCount
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetVehicleDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetVehicleDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDefinitionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVehicleDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVehicleDefinitionsQuery, Types.GetVehicleDefinitionsQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleDefinitionsQuery, Types.GetVehicleDefinitionsQueryVariables>(GetVehicleDefinitionsDocument, baseOptions);
      }
export function useGetVehicleDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleDefinitionsQuery, Types.GetVehicleDefinitionsQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleDefinitionsQuery, Types.GetVehicleDefinitionsQueryVariables>(GetVehicleDefinitionsDocument, baseOptions);
        }
export type GetVehicleDefinitionsQueryHookResult = ReturnType<typeof useGetVehicleDefinitionsQuery>;
export type GetVehicleDefinitionsLazyQueryHookResult = ReturnType<typeof useGetVehicleDefinitionsLazyQuery>;
export type GetVehicleDefinitionsQueryResult = Apollo.QueryResult<Types.GetVehicleDefinitionsQuery, Types.GetVehicleDefinitionsQueryVariables>;
export const GetVehicleDefinitionDocument = gql`
    query getVehicleDefinition($id: ID!) {
  getVehicleDefinition(id: $id) {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    state
    modelDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      colors {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
        price
        modelDefinitionId
      }
    }
    equipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelDefinitionId
      name
      sortNumber
      equipments {
        id
        equipment {
          id
          name
          category {
            id
            label
          }
        }
        count
        description
      }
    }
    vehicleSpec {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelKey
      modelKeyExtension
      optionCode
      price
      body {
        code
        id
        label
        vehicleType {
          id
          label
        }
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      cubicCapacity
      fuelConsumption
      seats
      driveWheel
      fourDriveWheel
      range
      trunkSpace
      trunkLength
      numberOfPallets
      wheelbase {
        id
        label
      }
    }
  }
}
    `;

/**
 * __useGetVehicleDefinitionQuery__
 *
 * To run a query within a React component, call `useGetVehicleDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleDefinitionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetVehicleDefinitionQuery, Types.GetVehicleDefinitionQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleDefinitionQuery, Types.GetVehicleDefinitionQueryVariables>(GetVehicleDefinitionDocument, baseOptions);
      }
export function useGetVehicleDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleDefinitionQuery, Types.GetVehicleDefinitionQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleDefinitionQuery, Types.GetVehicleDefinitionQueryVariables>(GetVehicleDefinitionDocument, baseOptions);
        }
export type GetVehicleDefinitionQueryHookResult = ReturnType<typeof useGetVehicleDefinitionQuery>;
export type GetVehicleDefinitionLazyQueryHookResult = ReturnType<typeof useGetVehicleDefinitionLazyQuery>;
export type GetVehicleDefinitionQueryResult = Apollo.QueryResult<Types.GetVehicleDefinitionQuery, Types.GetVehicleDefinitionQueryVariables>;
export const CreateVehicleDefinitionDocument = gql`
    mutation createVehicleDefinition {
  createVehicleDefinition {
    id
    auditInfo {
      createdBy
      created
      updatedBy
      updated
    }
    state
    modelDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      brand {
        id
        label
      }
      vehicleType {
        id
        label
      }
      line
      model
      modelYear
      colors {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        name
        color {
          id
          label
        }
        colorType {
          id
          label
        }
        price
        modelDefinitionId
      }
    }
    equipment {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelDefinitionId
      name
      sortNumber
      equipments {
        id
        equipment {
          id
          name
          category {
            id
            label
          }
        }
        count
        description
      }
    }
    vehicleSpec {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      modelKey
      modelKeyExtension
      optionCode
      price
      body {
        code
        id
        label
        vehicleType {
          id
          label
        }
      }
      engine
      fuelType {
        id
        label
      }
      transmission
      transmissionType {
        id
        label
      }
      power
      emission
      cubicCapacity
      fuelConsumption
      seats
      driveWheel
      fourDriveWheel
      range
      trunkSpace
      trunkLength
      numberOfPallets
      wheelbase {
        id
        label
      }
    }
  }
}
    `;
export type CreateVehicleDefinitionMutationFn = Apollo.MutationFunction<Types.CreateVehicleDefinitionMutation, Types.CreateVehicleDefinitionMutationVariables>;

/**
 * __useCreateVehicleDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateVehicleDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleDefinitionMutation, { data, loading, error }] = useCreateVehicleDefinitionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateVehicleDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVehicleDefinitionMutation, Types.CreateVehicleDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.CreateVehicleDefinitionMutation, Types.CreateVehicleDefinitionMutationVariables>(CreateVehicleDefinitionDocument, baseOptions);
      }
export type CreateVehicleDefinitionMutationHookResult = ReturnType<typeof useCreateVehicleDefinitionMutation>;
export type CreateVehicleDefinitionMutationResult = Apollo.MutationResult<Types.CreateVehicleDefinitionMutation>;
export type CreateVehicleDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.CreateVehicleDefinitionMutation, Types.CreateVehicleDefinitionMutationVariables>;
export const UpdateVehicleDefinitionDocument = gql`
    mutation updateVehicleDefinition($input: VehicleDefinitionUpdateInput!) {
  updateVehicleDefinition(input: $input) {
    errors {
      code
    }
    vehicleDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      modelDefinition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        colors {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
      }
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelDefinitionId
        name
        sortNumber
        equipments {
          id
          equipment {
            id
            auditInfo {
              createdBy
              created
              updatedBy
              updated
            }
            name
            category {
              id
              label
            }
          }
          count
          description
        }
      }
      vehicleSpec {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelKey
        optionCode
        price
        body {
          code
          id
          label
          vehicleType {
            id
            label
          }
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        range
        trunkSpace
        trunkLength
        numberOfPallets
        wheelbase {
          id
          label
        }
      }
    }
  }
}
    `;
export type UpdateVehicleDefinitionMutationFn = Apollo.MutationFunction<Types.UpdateVehicleDefinitionMutation, Types.UpdateVehicleDefinitionMutationVariables>;

/**
 * __useUpdateVehicleDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleDefinitionMutation, { data, loading, error }] = useUpdateVehicleDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVehicleDefinitionMutation, Types.UpdateVehicleDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.UpdateVehicleDefinitionMutation, Types.UpdateVehicleDefinitionMutationVariables>(UpdateVehicleDefinitionDocument, baseOptions);
      }
export type UpdateVehicleDefinitionMutationHookResult = ReturnType<typeof useUpdateVehicleDefinitionMutation>;
export type UpdateVehicleDefinitionMutationResult = Apollo.MutationResult<Types.UpdateVehicleDefinitionMutation>;
export type UpdateVehicleDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVehicleDefinitionMutation, Types.UpdateVehicleDefinitionMutationVariables>;
export const UpdateArchivedStateVehicleDefinitionDocument = gql`
    mutation updateArchivedStateVehicleDefinition($input: VehicleDefinitionArchivedStateUpdateInput!) {
  updateArchivedStateVehicleDefinition(input: $input) {
    errors {
      code
    }
    vehicleDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      modelDefinition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        colors {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
      }
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelDefinitionId
        name
        sortNumber
        equipments {
          id
          equipment {
            id
            name
          }
          count
          description
        }
      }
      vehicleSpec {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelKey
        optionCode
        price
        body {
          code
          id
          label
          vehicleType {
            id
            label
          }
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        range
        trunkSpace
        trunkLength
        numberOfPallets
        wheelbase {
          id
          label
        }
      }
    }
  }
}
    `;
export type UpdateArchivedStateVehicleDefinitionMutationFn = Apollo.MutationFunction<Types.UpdateArchivedStateVehicleDefinitionMutation, Types.UpdateArchivedStateVehicleDefinitionMutationVariables>;

/**
 * __useUpdateArchivedStateVehicleDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateArchivedStateVehicleDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArchivedStateVehicleDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArchivedStateVehicleDefinitionMutation, { data, loading, error }] = useUpdateArchivedStateVehicleDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArchivedStateVehicleDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArchivedStateVehicleDefinitionMutation, Types.UpdateArchivedStateVehicleDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.UpdateArchivedStateVehicleDefinitionMutation, Types.UpdateArchivedStateVehicleDefinitionMutationVariables>(UpdateArchivedStateVehicleDefinitionDocument, baseOptions);
      }
export type UpdateArchivedStateVehicleDefinitionMutationHookResult = ReturnType<typeof useUpdateArchivedStateVehicleDefinitionMutation>;
export type UpdateArchivedStateVehicleDefinitionMutationResult = Apollo.MutationResult<Types.UpdateArchivedStateVehicleDefinitionMutation>;
export type UpdateArchivedStateVehicleDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArchivedStateVehicleDefinitionMutation, Types.UpdateArchivedStateVehicleDefinitionMutationVariables>;
export const GetVehicleDefinitionsForCardCreateDocument = gql`
    query getVehicleDefinitionsForCardCreate($filter: VehicleDefinitionFilter) {
  getVehicleDefinitions(filter: $filter, sort: null, offset: 0, limit: 25) {
    data {
      id
      modelDefinition {
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
      }
      equipment {
        name
        sortNumber
      }
      vehicleSpec {
        optionCode
      }
    }
    pageInfo {
      offset
      limit
      totalCount
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetVehicleDefinitionsForCardCreateQuery__
 *
 * To run a query within a React component, call `useGetVehicleDefinitionsForCardCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDefinitionsForCardCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDefinitionsForCardCreateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVehicleDefinitionsForCardCreateQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVehicleDefinitionsForCardCreateQuery, Types.GetVehicleDefinitionsForCardCreateQueryVariables>) {
        return Apollo.useQuery<Types.GetVehicleDefinitionsForCardCreateQuery, Types.GetVehicleDefinitionsForCardCreateQueryVariables>(GetVehicleDefinitionsForCardCreateDocument, baseOptions);
      }
export function useGetVehicleDefinitionsForCardCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVehicleDefinitionsForCardCreateQuery, Types.GetVehicleDefinitionsForCardCreateQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetVehicleDefinitionsForCardCreateQuery, Types.GetVehicleDefinitionsForCardCreateQueryVariables>(GetVehicleDefinitionsForCardCreateDocument, baseOptions);
        }
export type GetVehicleDefinitionsForCardCreateQueryHookResult = ReturnType<typeof useGetVehicleDefinitionsForCardCreateQuery>;
export type GetVehicleDefinitionsForCardCreateLazyQueryHookResult = ReturnType<typeof useGetVehicleDefinitionsForCardCreateLazyQuery>;
export type GetVehicleDefinitionsForCardCreateQueryResult = Apollo.QueryResult<Types.GetVehicleDefinitionsForCardCreateQuery, Types.GetVehicleDefinitionsForCardCreateQueryVariables>;
export const CopyVehicleDefinitionDocument = gql`
    mutation copyVehicleDefinition($input: VehicleDefinitionCopyInput!) {
  copyVehicleDefinition(input: $input) {
    errors {
      code
    }
    vehicleDefinition {
      id
      auditInfo {
        createdBy
        created
        updatedBy
        updated
      }
      state
      modelDefinition {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        brand {
          id
          label
        }
        vehicleType {
          id
          label
        }
        line
        model
        modelYear
        colors {
          id
          auditInfo {
            createdBy
            created
            updatedBy
            updated
          }
          name
          color {
            id
            label
          }
          colorType {
            id
            label
          }
          price
          modelDefinitionId
        }
      }
      equipment {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelDefinitionId
        name
        sortNumber
        equipments {
          id
          equipment {
            id
            auditInfo {
              createdBy
              created
              updatedBy
              updated
            }
            name
            category {
              id
              label
            }
          }
          count
          description
        }
      }
      vehicleSpec {
        id
        auditInfo {
          createdBy
          created
          updatedBy
          updated
        }
        modelKey
        optionCode
        price
        body {
          code
          id
          label
          vehicleType {
            id
            label
          }
        }
        engine
        fuelType {
          id
          label
        }
        transmission
        transmissionType {
          id
          label
        }
        power
        emission
        cubicCapacity
        fuelConsumption
        seats
        driveWheel
        fourDriveWheel
        range
        trunkSpace
        trunkLength
        numberOfPallets
        wheelbase {
          id
          label
        }
      }
    }
  }
}
    `;
export type CopyVehicleDefinitionMutationFn = Apollo.MutationFunction<Types.CopyVehicleDefinitionMutation, Types.CopyVehicleDefinitionMutationVariables>;

/**
 * __useCopyVehicleDefinitionMutation__
 *
 * To run a mutation, you first call `useCopyVehicleDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyVehicleDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyVehicleDefinitionMutation, { data, loading, error }] = useCopyVehicleDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyVehicleDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CopyVehicleDefinitionMutation, Types.CopyVehicleDefinitionMutationVariables>) {
        return Apollo.useMutation<Types.CopyVehicleDefinitionMutation, Types.CopyVehicleDefinitionMutationVariables>(CopyVehicleDefinitionDocument, baseOptions);
      }
export type CopyVehicleDefinitionMutationHookResult = ReturnType<typeof useCopyVehicleDefinitionMutation>;
export type CopyVehicleDefinitionMutationResult = Apollo.MutationResult<Types.CopyVehicleDefinitionMutation>;
export type CopyVehicleDefinitionMutationOptions = Apollo.BaseMutationOptions<Types.CopyVehicleDefinitionMutation, Types.CopyVehicleDefinitionMutationVariables>;