import { AppPageNameRoutes, AppPageNameRouteType } from '../../routes/paths';
import PageHeader from '../../components/pageHeader/PageHeader';
import { Box, Container, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CoreEntityEnum } from '../../utils/enums';
import { CodetableEditTab } from '../../components';
import {
    CreateBodyMutationOptions,
    CreateBrandMutationOptions,
    CreateColorMutationOptions,
    CreateColorTypeMutationOptions,
    CreateCountryOfOriginMutationOptions,
    CreateEquipmentCategoryMutationOptions,
    CreateFuelTypeMutationOptions,
    CreateMarketingTagMutationOptions,
    CreateTransmissionTypeMutationOptions,
    CreateVehicleTypeMutationOptions,
    CreateWheelbaseTypeMutationOptions,
    UpdateBodyMutationOptions,
    UpdateBrandMutationOptions,
    UpdateColorMutationOptions,
    UpdateColorTypeMutationOptions,
    UpdateCountryOfOriginMutationOptions,
    UpdateEquipmentCategoryMutationOptions,
    UpdateFuelTypeMutationOptions,
    UpdateMarketingTagMutationOptions,
    UpdateTransmissionTypeMutationOptions,
    UpdateVehicleTypeMutationOptions,
    UpdateWheelbaseTypeMutationOptions,
    useCreateBodyMutation,
    useCreateBrandMutation,
    useCreateColorMutation,
    useCreateColorTypeMutation,
    useCreateCountryOfOriginMutation,
    useCreateEquipmentCategoryMutation,
    useCreateFuelTypeMutation,
    useCreateMarketingTagMutation,
    useCreateTransmissionTypeMutation,
    useCreateVehicleTypeMutation,
    useCreateWheelbaseTypeMutation,
    useGetBodiesQuery,
    useGetBrandsQuery,
    useGetColorsQuery,
    useGetColorTypesQuery,
    useGetCountriesOfOriginQuery,
    useGetEquipmentCategoriesQuery,
    useGetFuelTypesQuery,
    useGetMarketingTagsQuery,
    useGetTransmissionTypesQuery,
    useGetVehicleTypesQuery,
    useGetWheelbaseTypesQuery,
    useUpdateBodyMutation,
    useUpdateBrandMutation,
    useUpdateColorMutation,
    useUpdateColorTypeMutation,
    useUpdateCountryOfOriginMutation,
    useUpdateEquipmentCategoryMutation,
    useUpdateFuelTypeMutation,
    useUpdateMarketingTagMutation,
    useUpdateTransmissionTypeMutation,
    useUpdateVehicleTypeMutation,
    useUpdateWheelbaseTypeMutation,
} from '../../api/graphql/generated/hooks';
import { setLoadingStatus } from '../../redux/loading';
import { CodetableDto, CodetableStateEnum, LanguageEnum } from '../../api/graphql/generated/schema';
import { setSnackbar, SnackbarStates } from '../../redux/snackbar';
import { getApolloErrorMessages, getBusinessErrorMessages, getGQLErrorMessages } from '../../utils/graphGL/graphQLHelper';
import CodetableDepenEditTab from '../../components/codetable/edit/CodetableDepenEditTab';
import { ApolloError } from '@apollo/client';
import { ascendingComparator } from '../../components/codetable/edit/codetable.utils';


const breadcrumbs = [
    AppPageNameRoutes.CODETABLES,
];

export interface CodetableItem {
    label: string,
    value: CoreEntityEnum,
    items: any,
    handleCreate: Function,
    handleUpdate: Function,
    error?: ApolloError,
    dependencies?: Array<CodetableDto>,
    dependenciesError?: ApolloError,
}

export default function CodetableEditor() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [value, setValue] = React.useState<number>(0);
    const [sortedCodetables, setSortedCodetables] = React.useState<Array<CodetableItem>>([]);

    const { loading: loadingBodies, data: gqlBodies, error: errorBodies, refetch: refetchBodies } = useGetBodiesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingBrands, data: gqlBrands, error: errorBrands, refetch: refetchBrands } = useGetBrandsQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingColors, data: gqlColors, error: errorColors, refetch: refetchColors } = useGetColorsQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingColorTypes, data: gqlColorTypes, error: errorColorTypes, refetch: refetchColorTypes } = useGetColorTypesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingCountries, data: gqlCountries, error: errorCountries, refetch: refetchCountries } = useGetCountriesOfOriginQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingEquipmentCategories, data: gqlEquipmentCategories, error: errorEquipmentCategories, refetch: refetchECs } = useGetEquipmentCategoriesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingFuelTypes, data: gqlFuelTypes, error: errorFuelTypes, refetch: refetchFuelTypes } = useGetFuelTypesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingMarketingTags, data: gqlMarketingTags, error: errorMarketingTag, refetch: refetchMarketingTags } = useGetMarketingTagsQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingTT, data: gqlTransmissionTypes, error: errorTT, refetch: refetchTTs } = useGetTransmissionTypesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingVehicleTypes, data: gqlVehicleTypes, error: errorVehicleTypes, refetch: refetchVehicleTypes } = useGetVehicleTypesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});
    const { loading: loadingWheelbaseTypes, data: gqlWheelbaseTypes, error: errorWheelbaseType, refetch: refetchWheelbaseTypes } = useGetWheelbaseTypesQuery({variables: {states: [CodetableStateEnum.Active, CodetableStateEnum.Archived]}});


    const [createBody, { loading: loadingCreateBody }] = useCreateBodyMutation();
    const [updateBody, { loading: loadingUpdateBody }] = useUpdateBodyMutation();

    const [createBrand, { loading: loadingCreateBrand }] = useCreateBrandMutation();
    const [updateBrand, { loading: loadingUpdateBrand }] = useUpdateBrandMutation();

    const [createColor, { loading: loadingCreateColor }] = useCreateColorMutation();
    const [updateColor, { loading: loadingUpdateColor }] = useUpdateColorMutation();

    const [createColorType, { loading: loadingCreateColorType }] = useCreateColorTypeMutation();
    const [updateColorType, { loading: loadingUpdateColorType }] = useUpdateColorTypeMutation();

    const [createCountry, { loading: loadingCreateCountry }] = useCreateCountryOfOriginMutation();
    const [updateCountry, { loading: loadingUpdateCountry }] = useUpdateCountryOfOriginMutation();

    const [createEquipmentCategory, { loading: loadingCreateEquipmentCategory }] = useCreateEquipmentCategoryMutation();
    const [updateEquipmentCategory, { loading: loadingUpdateEquipmentCategory }] = useUpdateEquipmentCategoryMutation();

    const [createFuelType, { loading: loadingCreateFuelType }] = useCreateFuelTypeMutation();
    const [updateFuelType, { loading: loadingUpdateFuelType }] = useUpdateFuelTypeMutation();

    const [createMarketingTag, { loading: loadingCreateMarketingTag }] = useCreateMarketingTagMutation();
    const [updateMarketingTag, { loading: loadingUpdateMarketingTag }] = useUpdateMarketingTagMutation();

    const [createTransmissionType, { loading: loadingCreateTransmissionType }] = useCreateTransmissionTypeMutation();
    const [updateTransmissionType, { loading: loadingUpdateTransmissionType }] = useUpdateTransmissionTypeMutation();

    const [createVehicleType, { loading: loadingCreateVehicleType }] = useCreateVehicleTypeMutation();
    const [updateVehicleType, { loading: loadingUpdateVehicleType }] = useUpdateVehicleTypeMutation();

    const [createWheelbaseType, { loading: loadingCreateWheelbaseType }] = useCreateWheelbaseTypeMutation();
    const [updateWheelbaseType, { loading: loadingUpdateWheelbaseType }] = useUpdateWheelbaseTypeMutation();


    useEffect(() => {
        dispatch(setLoadingStatus(
            loadingCreateBody || loadingUpdateBody || loadingBodies ||
            loadingBrands || loadingCreateBrand || loadingUpdateBrand ||
            loadingTT || loadingCreateTransmissionType || loadingUpdateTransmissionType ||
            loadingVehicleTypes || loadingCreateVehicleType || loadingUpdateVehicleType ||
            loadingEquipmentCategories || loadingCreateEquipmentCategory || loadingUpdateEquipmentCategory ||
            loadingFuelTypes || loadingCreateFuelType || loadingUpdateFuelType ||
            loadingCountries || loadingCreateCountry || loadingUpdateCountry ||
            loadingColors || loadingCreateColor || loadingUpdateColor ||
            loadingColorTypes || loadingCreateColorType || loadingUpdateColorType ||
            loadingMarketingTags || loadingCreateMarketingTag || loadingUpdateMarketingTag ||
            loadingWheelbaseTypes || loadingCreateWheelbaseType || loadingUpdateWheelbaseType,
        ));
    }, [
        loadingCreateBody || loadingUpdateBody || loadingBodies ||
        loadingBrands || loadingCreateBrand || loadingUpdateBrand ||
        loadingTT || loadingCreateTransmissionType || loadingUpdateTransmissionType ||
        loadingVehicleTypes || loadingCreateVehicleType || loadingUpdateVehicleType ||
        loadingEquipmentCategories || loadingCreateEquipmentCategory || loadingUpdateEquipmentCategory ||
        loadingFuelTypes || loadingCreateFuelType || loadingUpdateFuelType ||
        loadingCountries || loadingCreateCountry || loadingUpdateCountry ||
        loadingColors || loadingCreateColor || loadingUpdateColor ||
        loadingColorTypes || loadingCreateColorType || loadingUpdateColorType ||
        loadingMarketingTags || loadingCreateMarketingTag || loadingUpdateMarketingTag ||
        loadingWheelbaseTypes || loadingCreateWheelbaseType || loadingUpdateWheelbaseType,
        dispatch]);

    useEffect(() => {
        const allTabs = [
            {
                'label': t(`fieldName.${CoreEntityEnum.body}`),
                'value': CoreEntityEnum.body,
                'items': gqlBodies?.getBodies || [],
                'handleCreate': handleCreateBody,
                'handleUpdate': handleUpdateBody,
                'error': errorBodies,
                'dependencies': gqlVehicleTypes?.getVehicleTypes || [],
                'dependenciesError': errorTT,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.brand}`),
                'value': CoreEntityEnum.brand,
                'items': gqlBrands?.getBrands || [],
                'handleCreate': handleCreateBrand,
                'handleUpdate': handleUpdateBrand,
                'error': errorBrands,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.color}`),
                'value': CoreEntityEnum.color,
                'items': gqlColors?.getColors || [],
                'handleCreate': handleCreateColor,
                'handleUpdate': handleUpdateColor,
                'error': errorColors,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.colorType}`),
                'value': CoreEntityEnum.colorType,
                'items': gqlColorTypes?.getColorTypes || [],
                'handleCreate': handleCreateColorType,
                'handleUpdate': handleUpdateColorType,
                'error': errorColorTypes,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.countryOfOrigin}`),
                'value': CoreEntityEnum.countryOfOrigin,
                'items': gqlCountries?.getCountriesOfOrigin || [],
                'handleCreate': handleCreateCountry,
                'handleUpdate': handleUpdateCountry,
                'error': errorCountries,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.equipmentCategory}`),
                'value': CoreEntityEnum.equipmentCategory,
                'items': gqlEquipmentCategories?.getEquipmentCategories || [],
                'handleCreate': handleCreateEquipmentCategory,
                'handleUpdate': handleUpdateEquipmentCategory,
                'error': errorEquipmentCategories,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.fuelType}`),
                'value': CoreEntityEnum.fuelType,
                'items': gqlFuelTypes?.getFuelTypes || [],
                'handleCreate': handleCreateFuelType,
                'handleUpdate': handleUpdateFuelType,
                'error': errorFuelTypes,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.marketingTag}`),
                'value': CoreEntityEnum.marketingTag,
                'items': gqlMarketingTags?.getMarketingTags || [],
                'handleCreate': handleCreateMarketingTag,
                'handleUpdate': handleUpdateMarketingTag,
                'error': errorMarketingTag,
            },

            {
                'label': t(`fieldName.${CoreEntityEnum.transmissionType}`),
                'value': CoreEntityEnum.transmissionType,
                'items': gqlTransmissionTypes?.getTransmissionTypes || [],
                'handleCreate': handleCreateTransmissionType,
                'handleUpdate': handleUpdateTransmissionType,
                'error': errorTT,
            },

            {
                'label': t(`fieldName.${CoreEntityEnum.vehicleType}`),
                'value': CoreEntityEnum.vehicleType,
                'items': gqlVehicleTypes?.getVehicleTypes || [],
                'handleCreate': handleCreateVehicleType,
                'handleUpdate': handleUpdateVehicleType,
                'error': errorVehicleTypes,
            },
            {
                'label': t(`fieldName.${CoreEntityEnum.wheelbaseType}`),
                'value': CoreEntityEnum.wheelbaseType,
                'items': gqlWheelbaseTypes?.getWheelbaseTypes || [],
                'handleCreate': handleCreateWheelbaseType,
                'handleUpdate': handleUpdateWheelbaseType,
                'error': errorWheelbaseType,
            },
        ];
        setSortedCodetables(allTabs.sort(ascendingComparator));
    }, [gqlBodies, gqlBrands, gqlColors, gqlColorTypes, gqlCountries, gqlEquipmentCategories, gqlFuelTypes, gqlMarketingTags, gqlTransmissionTypes, gqlVehicleTypes, gqlWheelbaseTypes])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleCreateBody = async (code: string, label: string, vehicleTypeId: string) => {
        const inputOptions: CreateBodyMutationOptions = {
            variables: {
                input: {
                    code: code,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    vehicleTypeId: vehicleTypeId,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        }
        await createBody(inputOptions).then(async response => {
            if (response && response.data && response.data.createBody && response.data.createBody.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.body.created')));
                await refetchBodies();
            } else if (response?.data?.createBody?.errors) {
                response.data.createBody.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateBody = async (bodyId: string, vehicleTypeId: string, label?: string, state?: CodetableStateEnum) => {
        const inputOptions: UpdateBodyMutationOptions = {
            variables: {
                input: {
                    id: bodyId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    vehicleTypeId: vehicleTypeId,
                    state: state,

                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        }
        await updateBody(inputOptions).then(async response => {
            if (response && response.data && response.data.updateBody && response.data.updateBody.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.body.updated')));
                await refetchBodies();
            } else if (response?.data?.updateBody?.errors) {
                response.data.updateBody.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }


    const handleCreateBrand = (brandId: string, label: string) => {
        let inputOptions: CreateBrandMutationOptions = {
            variables: {
                input: {
                    id: brandId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        }
        createBrand(inputOptions)
            .then(async response => {
                if (response && response.data && response.data.createBrand && response.data.createBrand.data) {
                    dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.brand.created')));
                    await refetchBrands();
                } else if (response?.data?.createBrand?.errors) {
                    response.data.createBrand.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                } else if (response.errors) {
                    if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                    }
                }
            })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateBrand = (brandId: string, label?: string, state?: CodetableStateEnum) => {
        const inputOptions: UpdateBrandMutationOptions = {
            variables: {
                input: {
                    id: brandId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),

        };
        updateBrand(inputOptions).then(async response => {
            if (response && response.data && response.data.updateBrand && response.data.updateBrand.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.brand.updated')));
                await refetchBrands();
            } else if (response?.data?.updateBrand?.errors) {
                response.data.updateBrand.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateColor = (colorId: string, label: string) => {
        const inputOptions: CreateColorMutationOptions = {
            variables: {
                input: {
                    id: colorId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },

            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createColor(inputOptions).then(async response => {
            if (response && response.data && response.data.createColor && response.data.createColor.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.color.created')));
                await refetchColors();
            } else if (response?.data?.createColor?.errors) {
                response.data.createColor.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateColor = (colorId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateColorMutationOptions = {
            variables: {
                input: {
                    id: colorId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        }
        updateColor(inputOptions).then(async response => {
            if (response && response.data && response.data.updateColor && response.data.updateColor.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.color.updated')));
                await refetchColors();
            } else if (response?.data?.updateColor?.errors) {
                response.data.updateColor.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateColorType = (colorTypeId: string, label: string) => {
        const inputOptions: CreateColorTypeMutationOptions = {
            variables: {
                input: {
                    id: colorTypeId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createColorType(inputOptions).then(async response => {
            if (response && response.data && response.data.createColorType && response.data.createColorType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.colorType.created')));
                await refetchColorTypes();
            } else if (response?.data?.createColorType?.errors) {
                response.data.createColorType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateColorType = (colorTypeId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateColorTypeMutationOptions = {
            variables: {
                input: {
                    id: colorTypeId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateColorType(inputOptions).then(async response => {
            if (response && response.data && response.data.updateColorType && response.data.updateColorType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.colorType.updated')));
                await refetchColorTypes();
            } else if (response?.data?.updateColorType?.errors) {
                response.data.updateColorType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateCountry = (countryId: string, label: string) => {
        const inputOptions: CreateCountryOfOriginMutationOptions = {
            variables: {
                input: {
                    id: countryId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createCountry(inputOptions).then(async response => {
            if (response && response.data && response.data.createCountryOfOrigin && response.data.createCountryOfOrigin.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.countryOfOrigin.created')));
                await refetchCountries();
            } else if (response?.data?.createCountryOfOrigin?.errors) {
                response.data.createCountryOfOrigin.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateCountry = (countryId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateCountryOfOriginMutationOptions = {
            variables: {
                input: {
                    id: countryId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateCountry(inputOptions).then(async response => {
            if (response && response.data && response.data.updateCountryOfOrigin && response.data.updateCountryOfOrigin.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.countryOfOrigin.updated')));
                await refetchCountries();
            } else if (response?.data?.updateCountryOfOrigin?.errors) {
                response.data.updateCountryOfOrigin.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateEquipmentCategory = (equipmentCat: string, label: string) => {
        const inputOptions: CreateEquipmentCategoryMutationOptions = {
            variables: {
                input: {
                    id: equipmentCat,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createEquipmentCategory(inputOptions).then(async response => {
            if (response && response.data && response.data.createEquipmentCategory && response.data.createEquipmentCategory.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.equipmentCategory.created')));
                await refetchECs();
            } else if (response?.data?.createEquipmentCategory?.errors) {
                response.data.createEquipmentCategory.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateEquipmentCategory = (equipmentCat: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateEquipmentCategoryMutationOptions = {
            variables: {
                input: {
                    id: equipmentCat,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateEquipmentCategory(inputOptions).then(async response => {
            if (response && response.data && response.data.updateEquipmentCategory && response.data.updateEquipmentCategory.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.equipmentCategory.updated')));
                await refetchECs();
            } else if (response?.data?.updateEquipmentCategory?.errors) {
                response.data.updateEquipmentCategory.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateFuelType = (fuelTypeId: string, label: string) => {
        const inputOptions: CreateFuelTypeMutationOptions = {
            variables: {
                input: {
                    id: fuelTypeId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createFuelType(inputOptions).then(async response => {
            if (response && response.data && response.data.createFuelType && response.data.createFuelType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.fuelType.created')));
                await refetchFuelTypes();
            } else if (response?.data?.createFuelType?.errors) {
                response.data.createFuelType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateFuelType = (fuelTypeId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateFuelTypeMutationOptions = {
            variables: {
                input: {
                    id: fuelTypeId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateFuelType(inputOptions).then(async response => {
            if (response && response.data && response.data.updateFuelType && response.data.updateFuelType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.fuelType.updated')));
                await refetchFuelTypes();
            } else if (response?.data?.updateFuelType?.errors) {
                response.data.updateFuelType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateMarketingTag = (marketingTagId: string, label: string) => {
        const inputOptions: CreateMarketingTagMutationOptions = {
            variables: {
                input: {
                    id: marketingTagId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createMarketingTag(inputOptions).then(async response => {
            if (response && response.data && response.data.createMarketingTag && response.data.createMarketingTag.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.marketingTag.created')));
                await refetchMarketingTags();
            } else if (response?.data?.createMarketingTag?.errors) {
                response.data.createMarketingTag.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateMarketingTag = (marketingTagId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateMarketingTagMutationOptions = {
            variables: {
                input: {
                    id: marketingTagId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateMarketingTag(inputOptions).then(async response => {
            if (response && response.data && response.data.updateMarketingTag && response.data.updateMarketingTag.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.marketingTag.updated')));
                await refetchMarketingTags();
            } else if (response?.data?.updateMarketingTag?.errors) {
                response.data.updateMarketingTag.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateTransmissionType = (transmissionTypeId: string, label: string) => {
        const inputOptions: CreateTransmissionTypeMutationOptions = {
            variables: {
                input: {
                    id: transmissionTypeId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createTransmissionType(inputOptions).then(async response => {
            if (response && response.data && response.data.createTransmissionType && response.data.createTransmissionType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.transmissionType.created')));
                await refetchTTs();
            } else if (response?.data?.createTransmissionType?.errors) {
                response.data.createTransmissionType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateTransmissionType = (transmissionTypeId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateTransmissionTypeMutationOptions = {
            variables: {
                input: {
                    id: transmissionTypeId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateTransmissionType(inputOptions).then(async response => {
            if (response && response.data && response.data.updateTransmissionType && response.data.updateTransmissionType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.transmissionType.updated')));
                await refetchTTs();
            } else if (response?.data?.updateTransmissionType?.errors) {
                response.data.updateTransmissionType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateVehicleType = (vehicleTypeId: string, label: string) => {
        const inputOptions: CreateVehicleTypeMutationOptions = {
            variables: {
                input: {
                    id: vehicleTypeId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createVehicleType(inputOptions).then(async response => {
            if (response && response.data && response.data.createVehicleType && response.data.createVehicleType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.vehicleType.created')));
                await refetchVehicleTypes();
            } else if (response?.data?.createVehicleType?.errors) {
                response.data.createVehicleType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateVehicleType = (vehicleTypeId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateVehicleTypeMutationOptions = {
            variables: {
                input: {
                    id: vehicleTypeId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateVehicleType(inputOptions).then(async response => {
            if (response && response.data && response.data.updateVehicleType && response.data.updateVehicleType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.vehicleType.updated')));
                await refetchVehicleTypes();
            } else if (response?.data?.updateVehicleType?.errors) {
                response.data.updateVehicleType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }


    const handleCreateWheelbaseType = (wheelbaseTypeId: string, label: string) => {
        const inputOptions: CreateWheelbaseTypeMutationOptions = {
            variables: {
                input: {
                    id: wheelbaseTypeId,
                    labels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        createWheelbaseType(inputOptions).then(async response => {
            if (response && response.data && response.data.createWheelbaseType && response.data.createWheelbaseType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.wheelbaseType.created')));
                await refetchWheelbaseTypes();
            } else if (response?.data?.createWheelbaseType?.errors) {
                response.data.createWheelbaseType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));

    }

    const handleUpdateWheelbaseType = (wheelbaseTypeId: string, label: string, state: CodetableStateEnum) => {
        const inputOptions: UpdateWheelbaseTypeMutationOptions = {
            variables: {
                input: {
                    id: wheelbaseTypeId,
                    updatedLabels: label ? [{ label: label, language: LanguageEnum.Cs }] : undefined,
                    state,
                },
            },
            onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
        };
        updateWheelbaseType(inputOptions).then(async response => {
            if (response && response.data && response.data.updateWheelbaseType && response.data.updateWheelbaseType.data) {
                dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.codetable.wheelbaseType.updated')));
                await refetchWheelbaseTypes();
            } else if (response?.data?.updateWheelbaseType?.errors) {
                response.data.updateWheelbaseType.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
            } else if (response.errors) {
                if (Object.prototype.toString.call(response.errors) === '[object Array]') {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            }
        })
            .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
    }

    return (
        <Container>
            <Grid item>
                <PageHeader headline={'codetables'} breadcrumbs={breadcrumbs as unknown as AppPageNameRouteType[]}/>
            </Grid>
            <Grid item>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', mt: 2 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {sortedCodetables.map(codetable => (<Tab label={codetable.label}/>))}
                    </Tabs>
                    {sortedCodetables.map((codetable, index) => {
                        if (codetable.value === CoreEntityEnum.body) {
                            return <CodetableDepenEditTab
                                sortedValue={value}
                                enumValue={codetable.value}
                                items={codetable.items}
                                handleCreate={codetable.handleCreate}
                                handleUpdate={codetable.handleUpdate}
                                possibleDependencies={codetable.dependencies || []}
                                error={codetable.error}
                                dependencyError={codetable.dependenciesError}
                                index={index}
                            />
                        }
                        return (
                            <CodetableEditTab
                                sortedValue={value}
                                enumValue={codetable.value}
                                items={codetable.items}
                                handleCreate={codetable.handleCreate}
                                handleUpdate={codetable.handleUpdate}
                                index={index}
                                error={codetable.error}
                            />
                        )
                    })}
                </Box>
            </Grid>
        </Container>

    );
}


