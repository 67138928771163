import { combineReducers, createStore } from "redux";
import snackbarReducer from "./snackbar";
import loadingReducer from "./loading";

const reducer = combineReducers({
  snackbar: snackbarReducer,
  loading: loadingReducer,
});

const store = createStore(reducer, {});

export default store;
