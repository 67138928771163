

export const environment = {
  production: false,

  port: process.env.PORT,

  backendEndpoint: process.env.REACT_APP_BACK_END_ENDPOINT,
  graphQLEndpoint: process.env.REACT_APP_GRAPH_QL_ENDPOINT,
  loginEndpoint: process.env.REACT_APP_LOGIN_ENDPOINT,

  nodeEnv: process.env.NODE_ENV,
  mockedJWT: process.env.REACT_APP_MOCK_AUTHENTICATION,
};
