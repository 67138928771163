import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    Popover,
    TextField,
    Tooltip, Typography,
} from '@mui/material';
import { openErrorSnackbar, openSuccessSnackbar } from '../../redux/snackbar';
import { validateModelYear } from './modelDefinition/ModelDefinition';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../utils/graphGL/graphQLHelper';
import { ApolloError } from '../index';
import { AppPageNameRoutes } from '../../routes/paths';
import { setLoadingStatus } from '../../redux/loading';
import { AddOutlined, CloseRounded, DoubleArrowOutlined } from '@mui/icons-material';
import { MAX_MODEL_YEAR, MIN_MODEL_YEAR } from '../../utils/const';
import { CopyVehicleDefinitionMutationOptions, useCopyVehicleDefinitionMutation } from '../../api/graphql/generated/hooks';


const intState = {
    userModelYear: '',

}

export default function CopyVehicleDefinitionToNewModelYear(props: {vehicleDefinitionId: string, sourceOptionCode?: string, sourceModelYear?: number, refetch?: Function}) {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const copyToNewModelYear = t("pages.definitionTable.copyToNewModelYear", {
        sourceOptionCode: props.sourceOptionCode
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userModelYear, setUserModelYear] = React.useState<string>(intState.userModelYear);
    const [copyToNMY, {loading: loadingCopyVD}] = useCopyVehicleDefinitionMutation();

    useEffect(() => {
        dispatch(setLoadingStatus(loadingCopyVD))
    }, [loadingCopyVD, dispatch]);

    const handleOpenPopover = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const redirectToEditVD = (id) => {
        let path = AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS.pageRoute + "/" + id;
        history.push(path);
    }
    const handleSubmit = () => {
        if (userModelYear) {
            if(validateModelYear(userModelYear)) {
                const cvdOptions: CopyVehicleDefinitionMutationOptions = {
                    variables: {
                        input: {
                            id: props.vehicleDefinitionId,
                            model: {
                                modelYear: parseInt(userModelYear)
                            }
                        }
                    }
                };
                copyToNMY(cvdOptions)
                    .then(response => {
                            if (response?.data?.copyVehicleDefinition?.vehicleDefinition) {
                                dispatch(openSuccessSnackbar(t(
                                    "userMessages.vehicleDefinition.copiedToNewModelYear",
                                    {
                                        sourceOptionCode: props.sourceOptionCode,
                                        optionCode: response.data.copyVehicleDefinition.vehicleDefinition.vehicleSpec?.optionCode
                                    })
                                ));
                                redirectToEditVD(response.data.copyVehicleDefinition.vehicleDefinition.id);
                                handleClosePopover();
                                if (props.refetch){
                                    props.refetch(response?.data?.copyVehicleDefinition?.vehicleDefinition.id);
                                }
                            } else if (response?.data?.copyVehicleDefinition?.errors) {
                                response.data.copyVehicleDefinition.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleDefinition"))));
                            } else if(response.errors){
                                response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                            }
                        }
                    )
                    .catch(error => <ApolloError error={error}/>)
            } else {
                dispatch(openErrorSnackbar(t("userError.valueOutOfRange", {fieldName: t("fieldName.modelYear"), min: MIN_MODEL_YEAR, max: MAX_MODEL_YEAR})));
            }
        } else {
            dispatch(openErrorSnackbar(t("userError.missingRequiredField")));
        }

        return true;
    };


    const renderPopoverEdit = () => {
        return (
            <Card className="vehicle-definitions">
                <CardHeader
                    title={copyToNewModelYear}
                    action={<IconButton edge="start" color="inherit" onClick={handleClosePopover} aria-label="close"><CloseRounded /></IconButton>}
                />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl className={"w-50"}>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label={t("fieldName.modelYear")}
                                    value={userModelYear}
                                    required={true}
                                    error={!userModelYear}
                                    onChange={event => setUserModelYear(event.target.value)}
                                    inputProps={{
                                        min: MIN_MODEL_YEAR,
                                        max: MAX_MODEL_YEAR,
                                        step: "1"
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent={'flex-end'} direction={'row'}>
                        <Grid item >
                            <Button disabled={!userModelYear} startIcon={<AddOutlined/>} onClick={handleSubmit} variant={"contained"} className="button new-definition">
                                <Typography variant={"button"}>{t('actions.create')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        )
    }

    return (
        <>
            <IconButton onClick={handleOpenPopover}>
                <Tooltip title={copyToNewModelYear}>
                    <DoubleArrowOutlined/>
                </Tooltip>
            </IconButton>
            <Popover
                id={"bonus-add-new"}
                open={Boolean(anchorEl)}
                onClick={event => event.stopPropagation()}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {renderPopoverEdit()}
            </Popover>
        </>
    );

}
