import { DeferredCreditGroupDto } from './DeferredCreditGroupDto';
import {
    CreditGroupInput as CreditGroupInputType,
    CreditInput as CreditInputType,
    DeferredCreditGroupInput as DeferredCreditGroupInputType,
    DeferredCreditInput as DeferredCreditInputType,
} from '../../api/graphql/generated/schema';
import { DeferredCreditDto } from './DeferredCreditDto';
import { CreditGroupDto } from './CreditGroupDto';
import { CreditDto } from './CreditDto';

const convertToCreditGroupInput = (creditGroup: CreditGroupDto): CreditGroupInputType => {
    return {
        type: creditGroup.type,
        items: creditGroup.items.map(item => {
            return {
                month: item.month,
                monthlyInstalment: item.monthlyInstalment,
                advancePayment: item.advancedPayment,
            }})
    }
}

const convertToDeferredCreditGroupInput = (creditGroup: DeferredCreditGroupDto): DeferredCreditGroupInputType => {
    return {
        type: creditGroup.type,
        items: creditGroup.items.map(item => {
            return {
                month: item.month,
                monthlyInstalment: item.monthlyInstalment,
                advancePayment: item.advancedPayment,
                deferredPayment: item.deferredPayment,
                purchasePrice: item.purchasePrice,
            }})
    }
}

export const convertToCreditInput = (credit: CreditDto): CreditInputType | undefined => {
    if (credit.serviceId){
        return {
            serviceId: credit.serviceId,
            creditGroups: credit.creditGroups.map(cg => convertToCreditGroupInput(cg)),
        }
    } else {
        return undefined;
    }
}

export const convertToDeferredCreditInput = (credit: DeferredCreditDto): DeferredCreditInputType | undefined => {
    if (credit.serviceId){
        return {
            serviceId: credit.serviceId,
            deferredCreditGroups: credit.creditGroups.map(cg => convertToDeferredCreditGroupInput(cg)),
        }
    } else {
        return undefined;
    }
}
