import React from "react";
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { ServicePackageSelectDto } from "../../../modelView/codetable/servicePackage/ServicePackageSelectDto";
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 400,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function ServicePackageSelect(
    props:{
      inputValues: ServicePackageSelectDto[],
      filteredValues: string | string[],
      setFilteredValues,
      disabled?: boolean,
      multiselect?: boolean,
      required?: boolean
    }) {

  const classes = useStyles();

  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    props.setFilteredValues(event.target.value as string | string[]);
  };

  return (
      <FormControl className={classes.formControl} required={props.required} error={props.required && !props.filteredValues}>
        <InputLabel id="service-package-select-label">{t('fieldName.servicePackage')}</InputLabel>
        <Select
            labelId="service-package-select-label"
            id="service-package-select"
            multiple={props.multiselect}
            value={props.filteredValues}
            onChange={handleChange}
            input={<Input />}
            MenuProps={MenuProps}
            disabled={props.disabled}
        >
          {props?.inputValues?.map((servicePackage) => (
              <MenuItem key={servicePackage.id} value={servicePackage.id}>
                {servicePackage.name}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );

}
