import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { VehicleDelivery as VehicleDeliveryType } from "../../api/graphql/generated/schema";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    Popover,
    TextField,
    Tooltip
} from '@mui/material';
import VehicleDeliveryView from "./VehicleDeliveryView";
import { SaveAltOutlined } from '@mui/icons-material';

const initState = {
    "exactDate": null,
    "plusDays": null,
    "immediatePickUp": false,
    "editable": false,
}

export enum DeliveryInputTypeEnum {
    EXACT_DATE = 'EXACT_DATE',
    PLUS_DAYS = 'PLUS_DAYS',
    IMMEDIATE_PICK_UP = 'IMMEDIATE_PICK_UP',
}

export default function VehicleDelivery(props: { deliveryDate: VehicleDeliveryType | null | undefined, setVehicleDelivery: Function, required?: boolean, hideInputs?: Array<DeliveryInputTypeEnum> }) {

    const { t } = useTranslation();

    const [userExactDate, setUserExactDate] = useState<string | null>(initState.exactDate);
    const [userPlusDays, setUserPlusDays] = useState<number | null>(initState.plusDays);
    const [userImmediatePickUp, setUserImmediatePickUp] = useState<boolean>(initState.immediatePickUp);
    const openEditorTooltipTitle = t("userMessages.vehicleCard.vehicleDelivery.openEditorTooltipTitle");
    const saveVehicleDeliveryTooltipTitle = t("actions.save");

    useEffect(() => {
        initUserData(props.deliveryDate);
    }, [props.deliveryDate]);

    const initUserData = (deliveryDate: VehicleDeliveryType | null | undefined) => {
        if (deliveryDate?.exactDate) {
            setUserExactDate(deliveryDate.exactDate);
        } else {
            setUserExactDate(initState.exactDate);
        }
        if (deliveryDate?.plusDays) {
            setUserPlusDays(deliveryDate.plusDays);
        } else {
            setUserPlusDays(initState.plusDays);
        }

        if (deliveryDate?.immediatePickUp) {
            setUserImmediatePickUp(deliveryDate.immediatePickUp);
        } else {
            setUserImmediatePickUp(initState.immediatePickUp);
        }
    }

    const handlePlusDaysChange = (event) => {
        setUserPlusDays(parseInt(event.target.value));
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        initUserData(props.deliveryDate);
        setAnchorEl(null);
    };

    function handleSaveDelivery() {
        let deliveryDate: VehicleDeliveryType = {
            exactDate: isExactDateEnabled() && userExactDate ? userExactDate : null,
            plusDays: isPlusDaysEnabled() && userPlusDays ? userPlusDays : null,
            immediatePickUp: isImmediatePickUpEnabled() && userImmediatePickUp
        }
        props.setVehicleDelivery(deliveryDate);
        handleClose();
    }

    const isInputEnabled = (type: DeliveryInputTypeEnum) => {
        return !props.hideInputs?.includes(type);
    }

    const isExactDateEnabled = () => {
        return isInputEnabled(DeliveryInputTypeEnum.EXACT_DATE);
    }

    const isPlusDaysEnabled = () => {
        return isInputEnabled(DeliveryInputTypeEnum.PLUS_DAYS);
    }

    const isImmediatePickUpEnabled = () => {
        return isInputEnabled(DeliveryInputTypeEnum.IMMEDIATE_PICK_UP);
    }

    const renderExactDateInput = () => {
        return isExactDateEnabled() && (
            <Grid item direction={"row"}>
                <FormControl className="filled">
                    <TextField
                        id="delivery-date"
                        label={t('fieldName.exactDate')}
                        type="date"
                        value={userExactDate}
                        onChange={event => setUserExactDate(event.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>
        );
    };

    const renderPlusDaysInput = () => {
        return isPlusDaysEnabled() && (
            <Grid item direction={"row"}>
                <TextField
                    variant="outlined"
                    type={'number'}
                    sx={{
                        '& input[type=number]': {
                            '-moz-appearance': 'textfield'
                        },
                        '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                        }
                    }}
                    label={t("fieldName.plusDays")}
                    InputLabelProps={{
                        shrink: !!userPlusDays,
                    }}
                    value={userPlusDays}
                    onChange={handlePlusDaysChange}
                />
            </Grid>
        );
    };

    const renderImmediatePickUpInput = () => {
        return isImmediatePickUpEnabled() && (
            <Grid item direction={"row"}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={userImmediatePickUp}
                            onChange={() => setUserImmediatePickUp(!userImmediatePickUp)}
                            name="immediate-pick-up-chbx"
                            color={"primary"}
                        />
                    }
                    label={t("fieldName.immediatePickUp")}
                />
            </Grid>
        );
    }

    const editor = () => {
        return (
            <Grid container direction={"row"} >
                {renderExactDateInput()}
                {renderPlusDaysInput()}
                {renderImmediatePickUpInput()}
                <Grid item direction={"row"} justifyContent={"flex-end"}>
                    <IconButton onClick={handleSaveDelivery}>
                        <Tooltip title={saveVehicleDeliveryTooltipTitle}>
                            <SaveAltOutlined />
                        </Tooltip>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <div onDoubleClick={handleClick}>
            <Tooltip title={openEditorTooltipTitle}>
                <VehicleDeliveryView deliveryDate={props.deliveryDate} textInput={true} required={props.required}/>
            </Tooltip>
            <Popover
                id={"extra-equipment-add-new"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItem>
                        {editor()}
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}
