import { Button, Card, FormControl, Grid, TextField } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinalPrices } from '../../modelView';
import { UsedVehicleImage } from '../../api/graphql/generated/schema';

export default function CardSummary (props: {
    refetch?: () => void,
    editMode: boolean,
    handleSaveCard: () => void,
    finalPrices: FinalPrices | null,
    brand: string | null | undefined,
    vin: string | null | undefined,
    images: Array<UsedVehicleImage> | undefined | null,
    model: string | null | undefined,
    cardState: string,
    changed: boolean,
    isValid: Function,
}) {

    const { t } = useTranslation();

    const [mainImageUrl, setMainImageUrl] = useState<string | null | undefined>(null);

    useEffect(() => {
        if (props.images && props.images.length > 0) {
            const firstMainImage: UsedVehicleImage | undefined = props.images
                .find((image) => image.mainImage);
            if (firstMainImage) {
                const publicUrl = firstMainImage.publicUrl;
                setMainImageUrl(publicUrl ? publicUrl : null);
            }
        }
    },[props.images])
    return (
        <Card
            className="carOverview"
        >
            {mainImageUrl
                ? <img src={mainImageUrl} alt="car-heading" className="car-heading" height={'auto'}/>
                : t('userMessages.vehicleCard.photos.missingCardMainImage')
            }

            <div className="mt-3">
                <Grid container
                      direction="row"
                     >
                    <Grid item xs={12} sm={12}>
                        <FormControl className="outlined">
                            <TextField
                                id="card-info-option-code"
                                label={t('fieldName.vin')}
                                type="text"
                                value={props.vin || ''}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div className="mt-4">
                <Grid container
                      direction="row"
                      >
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id="state"
                                label={t('fieldName.model')}
                                type="text"
                                value={props.model || ''}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id="commission"
                                label={t('fieldName.brand')}
                                type="text"
                                value={props.brand || ''}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>

            <div className="mt-3">
                <Grid container
                      direction="row"
                      >
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id="finalPriceTAX"
                                label={t('fieldName.totalPriceCRN')}
                                type="text"
                                disabled
                                value={props.finalPrices?.totalPriceCRN || ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id="finalPriceSO"
                                label={t('fieldName.totalPricePP')}
                                type="text"
                                disabled
                                value={props.finalPrices?.totalPricePP || ''}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>

            <div className="mt-3">
                <Grid container
                      direction="row"
                      >
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id="state"
                                label={t('fieldName.state')}
                                type="text"
                                disabled
                                value={t('pages.vehicleCards.states.' + props.cardState)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>

            <div className="mt-4">
                <Grid container
                      direction="row"
                     >
                    {props.editMode
                        ? <Grid item xs={12} sm={12}>
                            <div className="mt-4">
                                <Grid item xs={12} sm={12} className="text-right">
                                    <Button disabled={!props.changed} className="reset-button"
                                            onClick={props.refetch}>{t('pages.usedVehicleCards.actions.reset')}</Button>
                                    <Button disabled={!props.changed || !props.isValid()} className="icon-button"
                                            onClick={props.handleSaveCard}><SaveOutlined/> {t('pages.usedVehicleCards.actions.save')}</Button>
                                </Grid>
                            </div>
                        </Grid>
                        : <Grid item xs={12} sm={12}>
                            <div className="mt-4">
                                <Grid item xs={12} sm={12} className="text-right">
                                    <Button disabled={!props.changed || !props.isValid()} className="icon-button"
                                            onClick={props.handleSaveCard}><SaveOutlined/> {t('pages.usedVehicleCards.actions.create')}</Button>
                                </Grid>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>

        </Card>
    );
}
