
export function NotFound() {
  return (
    <h1>
      Page not found!
    </h1>
  );
}

export default NotFound
