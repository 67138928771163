import React, { useEffect, useState } from 'react';
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { ModelEquipment as ModelEquipmentType } from '../../api/graphql/generated/schema';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function initFilteredIds(filteredValues: ModelEquipmentType | ModelEquipmentType[]) {
    let fvIds: string[] = [];
    if (filteredValues instanceof Array) {
        filteredValues.forEach(fv => fvIds.push(fv.id));
    } else if (filteredValues) {
        fvIds.push(filteredValues.id);
    }

    return fvIds;
}

export const displayEquipmentNameWithSortNumber = (name: string, sortNumber: number | null) => {
    return name + ' (' + sortNumber + ')';
}

export default function ModelEquipmentSelect(props: {
    inputValues,
    filteredValues: null | ModelEquipmentType | ModelEquipmentType[],
    setFilteredValues,
    label: string,
    disabled?: boolean,
    multiselect?: boolean,
    sorted?: string
}) {

    const classes = useStyles();

    const [filteredValueIds, setFilteredValueIds] = useState<string[]>([]);

    useEffect(() => {
        if (props.filteredValues && props.inputValues) {
            setFilteredValueIds(initFilteredIds(props.filteredValues));
        } else {
            setFilteredValueIds([]);
        }
    }, [props.filteredValues, props.inputValues])

    const handleChange = (event: SelectChangeEvent<string | string []>) => {
        if (props.multiselect) {
            let fValues: string[] = event.target.value as string[];
            props.setFilteredValues(fValues.map(fv => props.inputValues.find(iv => iv.id === fv)));
        } else {
            let fValue: string = event.target.value as string;
            props.setFilteredValues(props.inputValues.find(iv => iv.id === fValue));
        }

    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="model-equipment-select-label">{props.label}</InputLabel>
            <Select
                labelId="model-equipment-select-label"
                id="model-equipment-select"
                multiple={props.multiselect}
                value={filteredValueIds}
                onChange={handleChange}
                input={<Input/>}
                MenuProps={MenuProps}
            >
                {props?.inputValues?.sort((a, b) => a.sortNumber - b.sortNumber).map((me) => (
                    <MenuItem key={me.id} value={me.id}>
                        {displayEquipmentNameWithSortNumber(me.name, me.sortNumber)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

}
