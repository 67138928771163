import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Grid, IconButton, TextField, Tooltip} from '@mui/material';
import {ExtraEquipmentItemDto} from "../../../modelView/ExtraEquipmentItemDto";
import { ClearOutlined, SaveAltOutlined } from '@mui/icons-material';

const initState = {
  userName: "",
  userPrice: "",
  changed: false
}

export default function ExtraEquipmentItemEditor(props: {editHandler: Function, existingItem?: ExtraEquipmentItemDto}) {

  const {t} = useTranslation();

  const [userName, setUserName] = useState<string | null>(initState.userName);
  const [userPrice, setUserPrice] = useState<string | null>(initState.userPrice);
  const [changed, setChanged] = useState<boolean>(initState.changed);
  const saveExtraEquipmentTooltipTitle = t("actions.save");
  const discardExtraEquipmentTooltipTitle = t("actions.discard");

  useEffect(() => {
    if (props.existingItem){
      setUserName(props.existingItem.name);
      setUserPrice(props.existingItem.price.toPrecision(2));
    }
  }, [props.existingItem])

  const resetState = () => {
    setUserName(initState.userName);
    setUserPrice(initState.userPrice);
    setChanged(initState.changed);
  }

  const handleSave = () => {
    if (!!userName && !!userPrice) {
      let newItem = new ExtraEquipmentItemDto(userName, Number(userPrice));
      if(props.editHandler(newItem)) {
        resetState()
      }
    }
  }

  const handleChange = (origValue: any, newValue: any) => {
    if (origValue === newValue){
      setChanged(false)
    } else {
      setChanged(true);
    }
  }

  const handleNameChange = (event) => {
    const newValue = event.target.value;
    setUserName(newValue);
    handleChange(props.existingItem?.name, newValue)
  }

  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    setUserPrice(newValue);
    handleChange(props.existingItem?.price, newValue)
  }

  return (
      <form>
        <Grid container direction="row">
          <Grid item>
            <TextField autoFocus={true}
                id={"extra-equip-item-name"}
                variant="outlined"
                label={t("fieldName.name")}
                type="text"
                required
                InputLabelProps={{
                  shrink: !!userName,
                }}
                value={userName}
                onChange={handleNameChange}
                tabIndex={0}
            />
          </Grid>
          <Grid item>
          <TextField
              id={"extra-equip-item-price"}
              variant="outlined"
              label={t("fieldName.price")}
              type="number"
              required
              InputLabelProps={{
                shrink: !!userPrice,
              }}
              value={userPrice}
              onChange={handlePriceChange}
              tabIndex={1}
          />
          </Grid>
          <Grid item justifyContent={"flex-end"} >
            <IconButton
                disabled={!changed || !userName || !userPrice}
                onClick={handleSave}
                tabIndex={2}
            >
              <Tooltip title={saveExtraEquipmentTooltipTitle}>
                <SaveAltOutlined />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item justifyContent={"flex-end"}>
            <IconButton
                disabled={!changed}
                onClick={resetState}
                tabIndex={3}
            >
              <Tooltip title={discardExtraEquipmentTooltipTitle}>
                <ClearOutlined/>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </form>
  );

}
