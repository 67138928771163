import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControl, Grid, TextField, Tooltip, Typography } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import {
    useGetEnginesQuery,
    useGetModelDefinitionBrandsQuery,
    useGetModelDefinitionLinesQuery,
    useGetModelDefinitionModelsQuery,
    useGetModelDefinitionModelYearsQuery,
    useGetModelEquipmentNamesQuery,
} from '../../../../../api/graphql/generated/hooks';
import {
    Brand,
    Maybe,
    Scalars,
    VehicleDefinitionFilter as VehicleDefinitionFilterType,
    VehicleDefinitionFilter,
    VehicleDefinitionStateEnum,
} from '../../../../../api/graphql/generated/schema';
import { CodetableSelect, EnumSelect, MultiSelect } from '../../../../../components';
import { getUnique } from '../../../../../utils/functionHelper';
import { dateFormat, vehicleDefinitionInitFilterStates, vehicleDefinitionStates } from '../../../../../utils/const';
import 'date-fns';
import { setLoadingStatus } from '../../../../../redux/loading';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { VisibilityOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);


const getEngines = (getEngines: Maybe<Array<Scalars['String']>> | undefined) => {
    const engines = getEngines?.filter(getUnique);
    return engines ? engines : [];
}

const initFilterState = {
    optionCode: null,
    brands: [],
    modelLines: [],
    models: [],
    modelYears: [],
    states: vehicleDefinitionInitFilterStates,
    modelEquipments: [],
    engines: [],
    priceFrom: '',
    priceTo: '',
    updatedFrom: null,
    updatedTo: null,
}


export default function VehicleDefinitionsFilter(props: { handleSubmitFilter, appliedFilter: VehicleDefinitionFilterType }) {

    const { handleSubmitFilter, appliedFilter } = props;
    const [optionCode, setOptionCode] = React.useState<string | null>(appliedFilter?.optionCode || initFilterState.optionCode);
    const [filterBrandIds, setFilterBrandIds] = React.useState<string[]>(appliedFilter?.brand || initFilterState.brands);
    const [inputBrands, setInputBrands] = React.useState<Brand[]>([]);
    const [filterModeLines, setFilterModelLines] = React.useState<string[]>(appliedFilter?.line || initFilterState.modelLines);
    const [inputsModeLines, setInputModeLines] = React.useState<string[]>([]);
    const [filterModels, setFilterModels] = React.useState<string[]>(appliedFilter?.model || initFilterState.models);
    const [inputModels, setInputModels] = React.useState<string[]>([]);
    const [filterModelYears, setFilterModelYears] = React.useState<number[]>(appliedFilter?.modelYear || initFilterState.modelYears);
    const [inputModelYears, setInputModelYears] = React.useState<number[]>([]);
    const [filterStates, setFilterStates] = React.useState<VehicleDefinitionStateEnum[]>(appliedFilter?.state || initFilterState.states);
    const [inputStates] = React.useState<VehicleDefinitionStateEnum[]>(vehicleDefinitionStates);
    const [filterModelEquipments, setFilterModelEquipments] = React.useState<string[]>(appliedFilter?.equipment || initFilterState.modelEquipments);
    const [inputModelEquipments, setInputModelEquipments] = React.useState<string[]>([]);
    const [filterEngines, setFilterEngines] = React.useState<string[]>(appliedFilter?.engine || initFilterState.engines);
    const [inputEngines, setInputEngines] = React.useState<string[]>([]);
    const [filterPriceFrom, setFilterPriceFrom] = React.useState<string>(appliedFilter?.priceFrom || initFilterState.priceFrom);
    const [filterPriceTo, setFilterPriceTo] = React.useState<string>(appliedFilter?.priceTo || initFilterState.priceTo);
    const [filterUpdatedFrom, setFilterUpdatedFrom] = React.useState<Date | null>(appliedFilter?.updatedFrom || initFilterState.updatedFrom);
    const [filterUpdatedTo, setFilterUpdatedTo] = React.useState<Date | null>(appliedFilter?.updatedTo || initFilterState.updatedTo);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const asteriskFilterTooltip = t('pages.vehicleCards.filters.tooltips.asterisk');

    const { loading: loadingMDBrands, data: gqlModelDefinitionBrands, error: errorMDBrands } = useGetModelDefinitionBrandsQuery();
    const { loading: loadingMDModels, data: gqlModelDefinitionModels, error: errorMDModels } = useGetModelDefinitionModelsQuery();
    const { loading: loadingMDLines, data: gqlModelDefinitionLines, error: errorMDLines } = useGetModelDefinitionLinesQuery();
    const { loading: loadingMDModelYear, data: gqlModelDefinitionModelYears, error: errorMDModelYears } = useGetModelDefinitionModelYearsQuery();
    const { loading: loadingEngines, data: gqlEngines, error: errorEngines } = useGetEnginesQuery();
    const { loading: loadingModelEquipmentNames, data: gqlModelEquipmentNames, error: errorModelEquipmentNames } = useGetModelEquipmentNamesQuery();

    useEffect(() => {
        if (!loadingMDBrands && gqlModelDefinitionBrands) {
            setInputBrands(gqlModelDefinitionBrands.getModelDefinitionBrands);
        }

    }, [loadingMDBrands, gqlModelDefinitionBrands]);

    useEffect(() => {
        if (!loadingMDModels && gqlModelDefinitionModels) {
            setInputModels(gqlModelDefinitionModels.getModelDefinitionModels);
        }

    }, [loadingMDModels, gqlModelDefinitionModels]);

    useEffect(() => {
        if (!loadingMDLines && gqlModelDefinitionLines) {
            setInputModeLines(gqlModelDefinitionLines.getModelDefinitionLines);
        }

    }, [loadingMDLines, gqlModelDefinitionLines]);

    useEffect(() => {
        if (!loadingMDModelYear && gqlModelDefinitionModelYears) {
            setInputModelYears(gqlModelDefinitionModelYears.getModelDefinitionModelYears);
        }

    }, [loadingMDModelYear, gqlModelDefinitionModelYears]);

    useEffect(() => {
        if (!loadingEngines && gqlEngines) {
            setInputEngines(getEngines(gqlEngines.getEngines))
        }

    }, [loadingEngines, gqlEngines]);

    useEffect(() => {
        if (!loadingModelEquipmentNames && gqlModelEquipmentNames) {
            setInputModelEquipments(gqlModelEquipmentNames.getModelEquipmentNames)
        }

    }, [loadingModelEquipmentNames, gqlModelEquipmentNames]);

    const resetFilter = () => {
        setOptionCode(initFilterState.optionCode);
        setFilterBrandIds(initFilterState.brands);
        setFilterModelLines(initFilterState.modelLines);
        setFilterModels(initFilterState.models);
        setFilterModelYears(initFilterState.modelYears);
        setFilterStates(initFilterState.states);
        setFilterModelEquipments(initFilterState.modelEquipments);
        setFilterEngines(initFilterState.engines);
        setFilterPriceFrom(initFilterState.priceFrom);
        setFilterPriceTo(initFilterState.priceTo);
        setFilterUpdatedFrom(initFilterState.updatedFrom);
        setFilterUpdatedTo(initFilterState.updatedTo);
    }

    const handleFilterOptionCode = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
        setOptionCode(event.target.value);
    }

    const checkFilterStates = () => {
        if (filterStates.includes('' as VehicleDefinitionStateEnum)) {
            return undefined;
        }
        return filterStates;
    }

    const submitFilter = () => {
        const submittedFilter: VehicleDefinitionFilter = {
            optionCode: optionCode ? optionCode : undefined,
            brand: filterBrandIds?.length !== 0 ? filterBrandIds : undefined,
            line: filterModeLines?.length !== 0 ? filterModeLines : undefined,
            model: filterModels?.length !== 0 ? filterModels : undefined,
            modelYear: filterModelYears?.length !== 0 ? filterModelYears : undefined,
            state: filterStates?.length !== 0 ? checkFilterStates() : undefined,
            equipment: filterModelEquipments?.length !== 0 ? filterModelEquipments : undefined,
            engine: filterEngines?.length !== 0 ? filterEngines : undefined,
            priceFrom: filterPriceFrom?.length !== 0 ? Number(filterPriceFrom) : undefined,
            priceTo: filterPriceTo?.length !== 0 ? Number(filterPriceTo) : undefined,
            updatedFrom: filterUpdatedFrom ? filterUpdatedFrom : undefined,
            updatedTo: filterUpdatedTo ? filterUpdatedTo : undefined,
        };

        handleSubmitFilter(submittedFilter);
    }

    //Handle loading
    useEffect(() => {
        dispatch(setLoadingStatus(loadingMDBrands || loadingMDModels || loadingMDLines || loadingMDModelYear || loadingEngines || loadingModelEquipmentNames));
    }, [loadingMDBrands, loadingMDModels, loadingMDLines, loadingMDModelYear, loadingEngines, loadingModelEquipmentNames])
    //Handle errors
    if (errorMDBrands || errorMDModels || errorMDLines || errorMDModelYears || errorEngines || errorModelEquipmentNames) {
        return <div>Error! ${errorMDBrands?.message}, ${errorMDModels?.message}, ${errorMDLines?.message}, ${errorMDModelYears?.message}, ${errorEngines?.message},
            ${errorModelEquipmentNames?.message}</div>;
    }
    //Handle rendering
    return (
        <Grid className="filter__holder">
            <Box mb={3}>
                <Typography variant="h5" className="text-left">
                    {t('pages.definitionTable.filters.title')}
                </Typography>
            </Box>
            <Grid container direction="row" justifyContent="flex-start">
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Tooltip title={asteriskFilterTooltip}>
                            <TextField
                                id={'card-filter-option-code'}
                                label={t('fieldName.optionCode')}
                                type={'text'}
                                name={'optionCode'}
                                value={optionCode}
                                onChange={handleFilterOptionCode}
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                        <EnumSelect
                            inputValues={inputStates}
                            filteredValues={filterStates}
                            setFilteredValues={setFilterStates}
                            label={t('fieldName.state')}
                            enumLabelsPath="vehicleDefinition.definitionState.values"
                            multiselect={true}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                        <CodetableSelect
                            inputValues={inputBrands}
                            filteredValues={filterBrandIds}
                            setFilteredValues={setFilterBrandIds}
                            label={t('fieldName.brand')}
                            multiselect={true}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <MultiSelect inputValues={inputsModeLines} filteredValues={filterModeLines} setFilteredValues={setFilterModelLines} label={t('fieldName.line')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <MultiSelect inputValues={inputModels} filteredValues={filterModels} setFilteredValues={setFilterModels} label={t('fieldName.model')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <MultiSelect inputValues={inputModelYears} filteredValues={filterModelYears} setFilteredValues={setFilterModelYears} label={t('fieldName.modelYear')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <MultiSelect inputValues={inputModelEquipments} filteredValues={filterModelEquipments} setFilteredValues={setFilterModelEquipments} label={t('fieldName.modelEquipment')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <MultiSelect inputValues={inputEngines} filteredValues={filterEngines} setFilteredValues={setFilterEngines} label={t('fieldName.engine')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="number"
                            label={t('fieldName.priceFrom')}
                            value={filterPriceFrom}
                            onChange={event => setFilterPriceFrom(event.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="number"
                            label={t('fieldName.priceTo')}
                            value={filterPriceTo}
                            onChange={event => setFilterPriceTo(event.target.value)}
                        />
                    </FormControl>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
                    <Grid item xs={12} sm={2} className="text-left">
                        <FormControl className={classes.formControl}>
                            <DatePicker
                                disabled={true}
                                disableToolbar
                                variant="inline"
                                format={dateFormat}
                                id="updated-from-picker-inline"
                                label={t('fieldName.updatedFrom')}
                                value={filterUpdatedFrom}
                                onChange={(date: Date | null) => setFilterUpdatedFrom(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2} className="text-left">
                        <FormControl className={classes.formControl}>
                            <DatePicker
                                disabled={true}
                                disableToolbar
                                variant="inline"
                                format={dateFormat}
                                id="updated-to-picker-inline"
                                label={t('fieldName.updatedTo')}
                                value={filterUpdatedTo}
                                onChange={(date: Date | null) => setFilterUpdatedTo(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />

                        </FormControl>
                    </Grid>
                </LocalizationProvider>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"

                component={'div'}
            >
                <Grid item xs={12} sm={12} className="text-right">
                    <Button className="reset-button" onClick={resetFilter}>{t('pages.definitionTable.filters.resetButton')}</Button>
                    <Button className="icon-button" onClick={submitFilter}>
                        <VisibilityOutlined/>
                        {t('pages.definitionTable.filters.searchButton')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
