import { CoreEntityEnum } from '../../../utils/enums';
import { Button, Grid, Typography } from '@mui/material';
import { CodetableDto, CodetableStateEnum } from '../../../api/graphql/generated/schema';
import { useTranslation } from 'react-i18next';
import { CodetableItemsTable } from '../index';
import React from 'react';
import CodetableEditDialog from './CodetableEditDialog';
import { Add } from '@mui/icons-material';
import { ApolloError } from '../../index';
import { ApolloError as ApolloErrorType } from '@apollo/client';

export default function CodetableEditTab(props: {
    sortedValue: number,
    enumValue: CoreEntityEnum,
    index: number,
    items?: Array<CodetableDto>,
    handleCreate: Function,
    handleUpdate: Function,
    error?: ApolloErrorType,
}) {
    const { t } = useTranslation();
    const { sortedValue, index } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentItem, setCurrentItem] = React.useState<CodetableDto | null | undefined>(null);

    const handleSave = (id: string, label: string, state: CodetableStateEnum) => {
        if (currentItem) {
            props.handleUpdate(id, label, state);
        } else {
            props.handleCreate(id, label);
        }
    }

    const updateItemState = (id: string, state: CodetableStateEnum) => {
        props.handleUpdate(id, undefined, state);
    }


    const handleEditDialog = (id?: string | null) => {
        if (!id) {
            setCurrentItem(null);
            setOpen(true);
            return;
        }
        const codetableItem = props.items?.filter(item => item.id === id)[0];
        setCurrentItem(codetableItem);
        setOpen(true);
    }

    return (
        <Grid
            hidden={sortedValue !== index}
            id={`vertical-tabpanel-${index}`}
            sx={{ width: '100%' }}
        >
            {sortedValue === index && (
                <>
                    {props.error
                        ? <ApolloError error={[props.error]}/>
                        : <>
                            <Grid container sx={{ p: 3 }} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Grid item sx={{ m: 1 }}>
                                    <Typography variant={'h5'}>
                                        {t(`fieldName.${props.enumValue}`)}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ m: 1 }}>
                                    <Button
                                        startIcon={<Add color={'secondary'}/>}
                                        onClick={() => handleEditDialog()}
                                        sx={{ backgroundColor: 'black', color: 'white', boxShadow: '0 0 5px black', '&:hover': { backgroundColor: 'black' } }}
                                    >
                                        {t('actions.add')} {t(`codetables.addActions.${props.enumValue}`)}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container sx={{ p: 3 }}>
                                <CodetableItemsTable
                                    data={props.items || []}
                                    handleUpdateState={updateItemState}
                                    handleDialogOpen={handleEditDialog}
                                />
                            </Grid>
                            <CodetableEditDialog open={open} data={currentItem} enumValue={props.enumValue} handleClose={() => setOpen(false)} handleSave={handleSave}/>
                        </>
                    }
                </>
            )}
        </Grid>
    );
}
