import { DeferredCreditGroupDto } from './DeferredCreditGroupDto';
import { DeferredCredit as DeferredCreditType } from '../../api/graphql/generated/schema';

export class DeferredCreditDto {

  private _serviceId: string;
  private _creditGroups: Array<DeferredCreditGroupDto>;


  constructor(serviceId: string, creditGroups: Array<DeferredCreditGroupDto>) {
    this._serviceId = serviceId;
    this._creditGroups = creditGroups;
  }

  static convertFromCredit(credit: DeferredCreditType) {
    let _creditGroups: Array<DeferredCreditGroupDto> = [];
    credit?.deferredCreditGroups?.map(cg => cg && DeferredCreditGroupDto.convertFromDeferredCreditGroup(cg)).forEach(cgdto => cgdto && _creditGroups.push(cgdto))
    return new DeferredCreditDto(credit.servicePackage.id, _creditGroups);
  }

  get serviceId(): string {
    return this._serviceId;
  }

  set serviceId(value: string) {
    this._serviceId = value;
  }

  get creditGroups(): Array<DeferredCreditGroupDto> {
    return this._creditGroups;
  }

  set creditGroups(value: Array<DeferredCreditGroupDto>) {
    this._creditGroups = value;
  }

  hasAllValidGroups(){
    return this._creditGroups && !this._creditGroups.some((item) => item.hasAnyInvalidItem());
  }
}
