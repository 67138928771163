import { LeasingGroupDto } from './LeasingGroupDto';
import { LeasingTypeEnum } from '../../api/graphql/generated/schema';
import { LeasingGroupItemDto } from './LeasingGroupItemDto';
import { leasingContraAccountEnums } from '../../utils/graphGL/graphQLHelper';

const generateLeasingGroup = (type: LeasingTypeEnum) => {
  let _items: Array<LeasingGroupItemDto> = new Array<LeasingGroupItemDto>();
  _items.push(new LeasingGroupItemDto(36, 35000, null));
  _items.push(new LeasingGroupItemDto(36, 30000, null));
  _items.push(new LeasingGroupItemDto(36, 25000, null));
  _items.push(new LeasingGroupItemDto(36, 20000, null));
  _items.push(new LeasingGroupItemDto(36, 15000, null));
  _items.push(new LeasingGroupItemDto(36, 10000, null));
  _items.push(new LeasingGroupItemDto(48, 25000, null));
  _items.push(new LeasingGroupItemDto(48, 15000, null));
  _items.push(new LeasingGroupItemDto(48, 10000, null));
  _items.push(new LeasingGroupItemDto(60, 10000, null));


  return new LeasingGroupDto(type, _items);
}

const generateCRNLeasingGroup = () => {
  return generateLeasingGroup(LeasingTypeEnum.Crn);
}

const generatePhysicalPersonLeasingGroup = () => {
  return generateLeasingGroup(LeasingTypeEnum.PhysicalPerson);
}

const generateContraAccountCrnLeasingGroup = () => {
  return generateLeasingGroup(LeasingTypeEnum.ContraAccountCrn);
}

const generateContraAccountPhysicalPersonLeasingGroup = () => {
  return generateLeasingGroup(LeasingTypeEnum.ContraAccountPhysicalPerson);
}

export const generateLeasingGroupsEmptyStructure = () => {

  let _leasingGroups:Array<LeasingGroupDto> = new Array<LeasingGroupDto>();
  _leasingGroups.push(generateCRNLeasingGroup());
  _leasingGroups.push(generatePhysicalPersonLeasingGroup());

  return _leasingGroups;
}

export const removeContraAccountGroups = (leasingGroups: Array<LeasingGroupDto>): Array<LeasingGroupDto> => {
  return leasingGroups.filter((item) => !leasingContraAccountEnums.includes(item.type));
}

export const addContraAccountGroups = (leasingGroups: Array<LeasingGroupDto>): Array<LeasingGroupDto> => {
  const newLeasingGroups: Array<LeasingGroupDto> = [];
  if (!leasingGroups.map((lg) => lg.type).some((lgt) => lgt === LeasingTypeEnum.ContraAccountCrn)){
    newLeasingGroups.push(generateContraAccountCrnLeasingGroup());
  }
  if (!leasingGroups.map((lg) => lg.type).some((lgt) => lgt === LeasingTypeEnum.ContraAccountPhysicalPerson)){
    newLeasingGroups.push(generateContraAccountPhysicalPersonLeasingGroup());
  }

  return [...leasingGroups, ...newLeasingGroups];

}

export const isValidMonthlyInstalment = (monthlyInstalment: number | null, mustExists: boolean) => {
  return (!mustExists && !monthlyInstalment) || (monthlyInstalment && (monthlyInstalment >= minMonthlyInstalment && monthlyInstalment <= maxMonthlyInstalment));
}

export const minMonthlyInstalment: number = 3500;
export const maxMonthlyInstalment: number = 60000;
