import { CreditDto, DeferredCreditDto, FinalPrices, FinanceGroupDto, LeasingDto } from '../../../modelView';
import { Box, Button, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeasingDialog } from '../../index';
import DeferredCreditDialog from './credit/DeferredCreditDialog';
import CreditDialog from './credit/CreditDialog';
import { ReactComponent as AirBalloon } from '../../../components/icon/airballoon-outline.svg';
import { AccountBalance, DoneOutlined, EventAvailableOutlined, WarningOutlined } from '@mui/icons-material';

export type TotalPricesType = {
  model: number,
  totalBonusesCRN: number,
  totalBonusesPP: number,
  extraEquipment: number,
  discountOcCrn: number,
  discountOcPP: number
}

export default function FinanceGroup(props:{ financeGroup: FinanceGroupDto, totalPrices: TotalPricesType, handleUpdate: Function }) {

  const {t} = useTranslation();
  const [openLeasingDialog, setOpenLeasingDialog] = useState<boolean>(false);
  const [openCreditDialog, setOpenCreditDialog] = useState<boolean>(false);
  const [openDeferredCreditDialog, setOpenDeferredCreditDialog] = useState<boolean>(false);
  const [finalPrices, setFinalPrices] = useState<FinalPrices>(FinalPrices.assembleFinalPrices(props.totalPrices, props.financeGroup.groupPrice));

  useEffect(() => {
    setFinalPrices(FinalPrices.assembleFinalPrices(props.totalPrices, props.financeGroup.groupPrice))
  }, [props.totalPrices, props.financeGroup.groupPrice]);

  const handleOpenLeasingDialog = () => {
    setOpenLeasingDialog(true);
  };

  const handleCloseLeasingDialog = () => {
    setOpenLeasingDialog(false);
  };

  const handleUpdateLeasingData = (leaseData: LeasingDto) => {
    props.handleUpdate(new FinanceGroupDto(props.financeGroup.groupPrice, props.financeGroup.colors, leaseData, props.financeGroup.credit, props.financeGroup.deferredCredit));
    handleCloseLeasingDialog();
  };

  const handleOpenCreditDialog = () => {
    setOpenCreditDialog(true);
  };

  const handleCloseCreditDialog = () => {
    setOpenCreditDialog(false);
  };

  const handleOpenDeferredCreditDialog = () => {
    setOpenDeferredCreditDialog(true);
  };

  const handleCloseDeferredCreditDialog = () => {
    setOpenDeferredCreditDialog(false);
  };

  const handleUpdateCreditData = (creditData: CreditDto) => {
    props.handleUpdate(new FinanceGroupDto(props.financeGroup.groupPrice, props.financeGroup.colors, props.financeGroup.lease, creditData, props.financeGroup.deferredCredit));
    handleCloseLeasingDialog();
  };

  const handleUpdateDeferredCreditData = (deferredCreditData: DeferredCreditDto) => {
    props.handleUpdate(new FinanceGroupDto(props.financeGroup.groupPrice, props.financeGroup.colors, props.financeGroup.lease, props.financeGroup.credit, deferredCreditData));
    handleCloseLeasingDialog();
  };

  const renderEndIcon = (object) => {
    return object ? <DoneOutlined color={"primary"}/> : <WarningOutlined color={"error"}/>;
  }

  return (
      <Box mb={2}>
        {props.totalPrices &&
            <Grid container direction="row">
              <Grid item xs={12} sm={3}>
                <FormControl className="outlined">
                  <TextField
                      id="priceTAX"
                      label={t('fieldName.priceVAT')}
                      type="text"
                      disabled
                      value={finalPrices.priceWithVat}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className="outlined">
                  <TextField
                      id="price"
                      label={t('fieldName.vat')}
                      type="text"
                      disabled
                      value={finalPrices.vat}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className="outlined">
                  <TextField
                      id="finalPriceTAX"
                      label={t('fieldName.totalPriceCRN')}
                      type="text"
                      disabled
                      value={finalPrices.totalPriceCRN}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className="outlined">
                  <TextField
                      id="finalPriceSO"
                      label={t('fieldName.totalPricePP')}
                      type="text"
                      disabled
                      value={finalPrices.totalPricePP}
                  />
                </FormControl>
              </Grid>
            </Grid>
        }
        <Grid container spacing={5} xs={12} lg={12} justifyContent={"space-evenly"}>
          <Grid item >
            <FormControl>
              <Button startIcon={<EventAvailableOutlined />} endIcon={renderEndIcon(props.financeGroup.lease?.hasAllValidGroups())} onClick={handleOpenLeasingDialog}>
                {t("pages.vehicleCards.detail.finance.buttonLeasingTitle")}
              </Button>
              <LeasingDialog leaseData={props.financeGroup.lease} open={openLeasingDialog} handleClose={handleCloseLeasingDialog} handleUpdate={handleUpdateLeasingData}/>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Button startIcon={<AccountBalance />} endIcon={renderEndIcon(props.financeGroup.credit?.hasAllValidGroups())} onClick={handleOpenCreditDialog}>
                {t("pages.vehicleCards.detail.finance.buttonCreditTitle")}
              </Button>
              <CreditDialog creditData={props.financeGroup.credit} open={openCreditDialog} handleClose={handleCloseCreditDialog} handleUpdate={handleUpdateCreditData}/>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Button startIcon={<AirBalloon />} endIcon={renderEndIcon(props.financeGroup.deferredCredit?.hasAllValidGroups())} onClick={handleOpenDeferredCreditDialog}>
                {t("pages.vehicleCards.detail.finance.buttonDeferredCreditTitle")}
              </Button>
              <DeferredCreditDialog deferredCreditData={props.financeGroup.deferredCredit} open={openDeferredCreditDialog} handleClose={handleCloseDeferredCreditDialog} handleUpdate={handleUpdateDeferredCreditData}/>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
  );
}
