import { CoreEntityEnum } from '../../../utils/enums';
import { Button, Grid, Typography } from '@mui/material';
import { Body, CodetableStateEnum, VehicleType } from '../../../api/graphql/generated/schema';
import { useTranslation } from 'react-i18next';
import React from 'react';
import CodetableDepenEditDialog from './CodetableDepenEditDialog';
import { Add } from '@mui/icons-material';
import CodetableDepenItemsTable from './CodetableDepenItemsTable';
import { ApolloError as ApolloErrorType } from '@apollo/client';
import ApolloError from '../../common/ApolloError';
import { BodyTable } from '../../../utils/typings';

export default function CodetableDepenEditTab(props: {
    sortedValue: number,
    enumValue: CoreEntityEnum,
    index: number,
    items: Array<Body>,
    handleCreate: Function,
    handleUpdate: Function,
    possibleDependencies: Array<VehicleType>,
    error?: ApolloErrorType,
    dependencyError?: ApolloErrorType,
}) {
    const { t } = useTranslation();
    const { sortedValue, index } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentItem, setCurrentItem] = React.useState<Body | null | undefined>(null);

    const handleSave = (code: string, label: string, vehicleTypeId: string, state: CodetableStateEnum) => {
        if (currentItem) {
            props.handleUpdate(code, vehicleTypeId, label, state);
        } else {
            props.handleCreate(code, label, vehicleTypeId);
        }
    }

    const updateItemState = (id: string, state: CodetableStateEnum) => {
        const codetableItem = props.items?.filter(item => item.id === id)[0];
        props.handleUpdate(id, codetableItem?.vehicleType.id, undefined, state);

    }


    const handleEditDialog = (id?: string | null) => {
        if (!id) {
            setCurrentItem(null);
            setOpen(true);
            return;
        }
        const codetableItem = props.items?.filter(item => item.id === id)[0];
        setCurrentItem(codetableItem);
        setOpen(true);
    }

    return (
        <Grid
            hidden={sortedValue !== index}
            id={`vertical-tabpanel-${index}`}
            sx={{ width: '100%' }}
        >
            {sortedValue === index && (
                <>
                    {props.dependencyError && <ApolloError error={[props.dependencyError]}/>}
                    {props.error ? <ApolloError error={[props.error]}/> :
                        <>
                            <Grid container sx={{ p: 3 }} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Grid item sx={{ m: 1 }}>
                                    <Typography variant={'h5'}>
                                        {t(`fieldName.${props.enumValue}`)}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ m: 1 }}>
                                    <Button
                                        startIcon={<Add color={'secondary'}/>}
                                        onClick={() => handleEditDialog()}
                                        sx={{ backgroundColor: 'black', color: 'white', boxShadow: '0 0 5px black', '&:hover': { backgroundColor: 'black' } }}
                                    >
                                        {t('actions.add')} {t(`codetables.addActions.${props.enumValue}`)}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container sx={{ p: 3 }}>
                                <CodetableDepenItemsTable
                                    data={props.items?.map(bodyItem => { return {...bodyItem, vehicleTypeLabel: bodyItem.vehicleType.label} as BodyTable}) || []}
                                    handleUpdateState={updateItemState}
                                    handleDialogOpen={handleEditDialog}
                                />
                            </Grid>
                            <CodetableDepenEditDialog
                                open={open} data={currentItem}
                                handleClose={() => setOpen(false)}
                                handleSave={handleSave}
                                possibleDependencies={props.possibleDependencies}
                            />
                        </>
                    }
                </>
            )}
        </Grid>
    );
}
