import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import {
    MarketingTag,
} from '../../../api/graphql/generated/schema';
import { ExpandMore } from '@mui/icons-material';
import MarketingTagList from '../../../components/usedVehicleCard/marketing/MarketingTags';
import { MarketingTagDto } from '../../../modelView/marketing/MarketingTagDto';


export default function UsedCarMarketing(props: { marketingTags: Array<MarketingTag>, handleAddItem: Function, handleRemoveItem: Function, initiallyExpanded?: boolean }) {

    const { t } = useTranslation();

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
    const [changed, setChanged] = React.useState(false);

    const handleMarketingTagAdd = (marketingTag: MarketingTagDto) => {
        if ( props.marketingTags.filter((mt) => mt.id === marketingTag.id).length === 0)  {
            props.handleAddItem({ id: marketingTag.id, label: marketingTag.label });
        }
        marketingTag.isApplied = true;
        setChanged(!changed);
    };

    const handleMarketingTagRemove = (marketingTag: MarketingTagDto) => {
        const newTags = props.marketingTags.filter((mt) => mt.id !== marketingTag.id);
        props.handleRemoveItem(newTags);
        marketingTag.isApplied = false;
        setChanged(!changed);
    };

    return (
        <form id="extra-equipment-form">
            <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)} >
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandMore color='secondary' />}
                >
                    <Grid container justifyContent={"space-between"}>
                        <Grid item >
                            <h2>{t("pages.vehicleCards.detail.marketing.title")}</h2>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <MarketingTagList appliedMarketingTags={props.marketingTags} handleAdd={handleMarketingTagAdd} handleRemove={handleMarketingTagRemove} />
                </AccordionDetails>
            </Accordion>
        </form>

    );

}
