
export class UserDto {

  private readonly _username: string;
  private readonly _name: string;
  private readonly _surname: string;
  private readonly _roles: Array<string>;

  constructor(username: string ,name: string, surname: string, roles: Array<string>) {
    this._username = username;
    this._name = name;
    this._surname = surname;
    this._roles = roles;
  }

  get userName(): string {
    return this._username;
  }

  get name(): string {
    return this._name;
  }

  get surname(): string {
    return this._surname;
  }

  get roles(): Array<string> {
    return this._roles;
  }

  get fullName() {
    return this._name + " " + this._surname;
  }
}
