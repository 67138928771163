import React from "react";
import { Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ColorViewerDto } from "../../modelView";
import { BlockOutlined, Clear, ColorLensRounded } from '@mui/icons-material';

const colorTypeMapper = (colorType: string) => {
  return colorType === "WHITE" ? "#faefef" : colorType;
}

export default function ColorsViewer(
    props:{
      inputValues: Array<ColorViewerDto>,
      maxDisplayed?: number,
      displayMissingInfo: boolean
    }) {

  const { t } = useTranslation();

  const assembleColorName = (color: ColorViewerDto) => {
      return color.additionalInfo ? color.name + '(' + color.additionalInfo + ')' : color.name;
  }

  const displayColors = (colors: Array<ColorViewerDto>) => {
    return (
        colors.map(
            color =>
                <Tooltip title={assembleColorName(color)}>
                  <ColorLensRounded htmlColor={colorTypeMapper(color.type)} />
                </Tooltip>
          )
    );
  };

  const displayAlias = (colors: Array<ColorViewerDto>) => {
    let title = colors.map(color => assembleColorName(color)).join(', ');

    return (
        <Tooltip title={title}>
          <BlockOutlined htmlColor={"grey"}/>
        </Tooltip>
    );
  };

  const handleMissing = () => {
    let title = t("userMessages.modelColor.missingColor");
    return (props.displayMissingInfo &&
        <Tooltip title={title}>
          <Clear />
        </Tooltip>
    );
  };

  const handleExisting = (colors: Array<ColorViewerDto>, maxDisplayed: number | null | undefined) => {
    return maxDisplayed && maxDisplayed < colors.length ? displayAlias(colors) : displayColors(colors)
  }

  return (
      <div>
        {props.inputValues && props.inputValues.length > 0 ? handleExisting(props.inputValues, props.maxDisplayed) : handleMissing()}
      </div>
  );

}
