import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ApolloError,
  ModelDefinition,
  ModelEquipment,
  PageHeader,
  TechnicalSpecification,
  VehicleDefinitionDetailHeader,
} from '../../../../components';
import { AppPageNameRoutes } from '../../../../routes/paths';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BrandEnum,
  ModelDefinition as ModelDefinitionType,
  ModelEquipment as ModelEquipmentType,
  TechnicalSpecification as TechnicalSpecificationType,
  VehicleDefinitionStateEnum,
} from '../../../../api/graphql/generated/schema';
import {
  UpdateVehicleDefinitionMutationOptions,
  useGetVehicleDefinitionQuery,
  useUpdateVehicleDefinitionMutation,
} from '../../../../api/graphql/generated/hooks';
import { setLoadingStatus } from '../../../../redux/loading';
import { setSnackbar, SnackbarStates } from '../../../../redux/snackbar';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../../utils/graphGL/graphQLHelper';
import CopyVehicleDefinitionToNewModelYear
  from '../../../../components/vehicleDefinition/copyVehicleDefinitionToNewModelYear';

const breadcrumbs = [
    AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS,
    AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL,

];

export default function VehicleDefinitionDetailEditor(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [vehicleDefinitionId, setVehicleDefinitionId] = React.useState<string |  null>(props?.match?.params?.vehicleDefinitionId);
  const [vehicleDefinitionState, setVehicleDefinitionState] = React.useState<VehicleDefinitionStateEnum |  null>(null);
  const [modelDefinition, setModelDefinition] = React.useState<ModelDefinitionType |  undefined>(undefined);
  const [modelEquipment, setModelEquipment] = React.useState<ModelEquipmentType | null>(null);
  const [technicalSpecification, setTechnicalSpecification] = React.useState<TechnicalSpecificationType |  undefined>(undefined);
  const [updateVehicleDefinitionMutation, { loading: loadingUpdateVehicleDef, error: errorUpdateVehicleDef }] = useUpdateVehicleDefinitionMutation();
  const headline = vehicleDefinitionId ? "editDefinition" : "createDefinition";

  const { loading: loadingVehicleDefinition, data: gqlVehicleDefinition, error: errorVehicleDefinition, refetch } = useGetVehicleDefinitionQuery({
    skip: !vehicleDefinitionId,
    variables: {id: vehicleDefinitionId ? vehicleDefinitionId : ""}
  });


  const handleUpdateVehicleDefinition = (vehicleDefinitionId: string, modelDefinitionId?: string, modelEquipmentId?: string, technicalSpecificationId?: string) => {
    let inputOptions: UpdateVehicleDefinitionMutationOptions = {
      variables: {
        input: {
          id: vehicleDefinitionId,
          modelDefinitionId: modelDefinitionId,
          equipmentId: modelEquipmentId,
          vehicleSpecId: technicalSpecificationId
        }
      },
      onError: (error) => <ApolloError error={[error]} />,
    };
    updateVehicleDefinitionMutation(inputOptions)
        .then(response => {
          if (response?.data?.updateVehicleDefinition?.vehicleDefinition) {
            setVehicleDefinitionData(response?.data?.updateVehicleDefinition?.vehicleDefinition);
            dispatch( setSnackbar( true, SnackbarStates.SUCCESS, t("userMessages.vehicleDefinitionSaved")));
          } else if (response?.data?.updateVehicleDefinition?.errors) {
            response.data.updateVehicleDefinition.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
          } else if(response.errors){
            response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
          }
        })
        .catch(error => <ApolloError error={error} />);
  }

  const handleCreateOrUpdateVehicleDefinition = (modelDefinitionId?: string, modelEquipmentId?: string, technicalSpecificationId?: string) => {
    if (vehicleDefinitionId){
      handleUpdateVehicleDefinition(vehicleDefinitionId, modelDefinitionId, modelEquipmentId, technicalSpecificationId);
    } else {
      dispatch(setSnackbar(true, SnackbarStates.ERROR, t("userError.vehicleDefinition.missingId")));
    }

  }

  const handleModelDefinitionUpdate = useCallback((modelDefinition: ModelDefinitionType) => {
    setModelDefinition(modelDefinition);
    handleCreateOrUpdateVehicleDefinition(modelDefinition.id, undefined, undefined);
  }, []);

  const handleModelEquipmentUpdate = useCallback((modelEquipment: ModelEquipmentType) => {
    setModelEquipment(modelEquipment);
    handleCreateOrUpdateVehicleDefinition(undefined, modelEquipment.id, undefined);
  }, []);

  const handleTechnicalSpecificationUpdate = useCallback((technicalSpecification: TechnicalSpecificationType) => {
    setTechnicalSpecification(technicalSpecification);
    handleCreateOrUpdateVehicleDefinition(undefined, undefined, technicalSpecification.id);
  }, []);


  const setVehicleDefinitionData = (vehicleDefinition) => {
    setVehicleDefinitionId(vehicleDefinition.id);
    setVehicleDefinitionState(vehicleDefinition.state);
    if (vehicleDefinition.modelDefinition) {
      setModelDefinition(vehicleDefinition.modelDefinition);
    }

    if (vehicleDefinition.equipment) {
      setModelEquipment(vehicleDefinition.equipment);
    }

    if (vehicleDefinition.vehicleSpec) {
      setTechnicalSpecification(vehicleDefinition.vehicleSpec);
    }
  }

  useEffect(() => {
    if (!loadingVehicleDefinition && gqlVehicleDefinition && gqlVehicleDefinition.getVehicleDefinition){
      setVehicleDefinitionData(gqlVehicleDefinition.getVehicleDefinition);
    }

  }, [loadingVehicleDefinition, gqlVehicleDefinition]);

  const refetchVehicleDefinition = (newVehicleDefinitionId: string) => {
    setVehicleDefinitionId(newVehicleDefinitionId);
    refetch({
      id: newVehicleDefinitionId
    });
  }

  dispatch(setLoadingStatus(loadingVehicleDefinition || loadingUpdateVehicleDef));

  if (errorVehicleDefinition) return <ApolloError error={[errorVehicleDefinition]} />;
  if (errorUpdateVehicleDef) return <ApolloError error={[errorUpdateVehicleDef]} />;


  return (
      <div className="page">
        <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
        >
          <Grid item>
            <PageHeader headline={headline} breadcrumbs={breadcrumbs}/>
          </Grid>
          <Grid item>
          {
              modelDefinition?.id &&
              modelEquipment?.id &&
              technicalSpecification?.id &&
              vehicleDefinitionId &&
              <CopyVehicleDefinitionToNewModelYear vehicleDefinitionId={vehicleDefinitionId}
                                                   sourceModelYear={modelDefinition?.modelYear}
                                                   sourceOptionCode={technicalSpecification?.optionCode}
                                                   refetch={refetchVehicleDefinition}
              />
          }
        </Grid>
      </Grid>
        <Card variant="outlined">
          <CardHeader title={t("pages.definitionEditor.info.title")} />
          <CardContent>
            <VehicleDefinitionDetailHeader vehicleDefinitionState={vehicleDefinitionState}
                                           model={modelDefinition?.model}
                                           equipmentName={modelEquipment?.name}
                                           optionCode={technicalSpecification?.optionCode}
            />
            <ModelDefinition
                modelDefinition={modelDefinition ? modelDefinition : null}
                initiallyExpanded={true}
                handleUpdateVehicleDefinition={handleModelDefinitionUpdate}
                refetch={refetch}
            />
            {modelDefinition &&
              <ModelEquipment
                modelDefinitionId={modelDefinition.id}
                modelEquipment={modelEquipment ? modelEquipment : null}
                initiallyExpanded={true}
                handleUpdateVehicleDefinition={handleModelEquipmentUpdate}
              />
            }
            {modelDefinition && modelEquipment &&
              <TechnicalSpecification
                modelEquipmentId={modelEquipment.id}
                technicalSpecification={technicalSpecification ? technicalSpecification : null}
                vehicleType={modelDefinition.vehicleType.id}
                modelYear={modelDefinition.modelYear}
                initiallyExpanded={true}
                handleUpdateVehicleDefinition={handleTechnicalSpecificationUpdate}
                hasModelKeyExtension={BrandEnum.Skoda === modelDefinition.brand.id}
              />
            }
          </CardContent>
        </Card>
      </div>
  );

}
