import { Add, DeleteOutline, ExpandMore, SaveOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField, Tooltip,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CreateVehicleCardExtraEquipmentMutationOptions,
  UpdateVehicleCardExtraEquipmentMutationOptions,
  useCreateVehicleCardExtraEquipmentMutation,
  useUpdateVehicleCardExtraEquipmentMutation
} from '../../../api/graphql/generated/hooks';
import { ExtraEquipmentItemInput as ExtraEquipmentItemInputType, VehicleCardExtraEquipment as VehicleCardExtraEquipmentType } from '../../../api/graphql/generated/schema';
import { ApolloError, EmptyDataListInfo, ExtraEquipmentItemEditor, StyledTableCell, StyledTableRow } from '../../../components';
import { ExtraEquipmentItemDto } from '../../../modelView/ExtraEquipmentItemDto';
import { setLoadingStatus } from '../../../redux/loading';
import { openErrorSnackbar, openSuccessSnackbar, setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import { createUnexpectedErrorMessage, roundToTwoDecimals } from '../../../utils/functionHelper';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';

const initUserDataState = {
  totalExtraEquipmentPrice: 0,
  userExtraEquipment: []
}

const convertTo = (items: Array<ExtraEquipmentItemDto>) => {
  return items.map(item => {
    return { name: item.name, price: item.price }
  });
}

const validateItems = (items: Array<ExtraEquipmentItemDto>) => {
  return items.filter(item => !item.name || !item.price).length === 0;
}


export default function VehicleCardExtraEquipment(props: { cardId: string, cardExtraEquipment: VehicleCardExtraEquipmentType | null | undefined, initiallyExpanded?: boolean, handleUpdate: Function }) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [totalExtraEquipmentPrice, setTotalExtraEquipmentPrice] = React.useState<number | null>(initUserDataState.totalExtraEquipmentPrice);
  const [userExtraEquipments, setUserExtraEquipments] = React.useState<Array<ExtraEquipmentItemDto>>(initUserDataState.userExtraEquipment);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [gqlCreateVCEEMut, { loading: gqlCreateVCEEMLoading }] = useCreateVehicleCardExtraEquipmentMutation();
  const [gqlUpdateVCEEMut, { loading: gqlUpdateVCEEMLoading }] = useUpdateVehicleCardExtraEquipmentMutation();

  useEffect(() => {
    dispatch(setLoadingStatus(gqlCreateVCEEMLoading || gqlUpdateVCEEMLoading))
  }, [dispatch, gqlCreateVCEEMLoading, gqlUpdateVCEEMLoading])


  useEffect(() => {
    if (props.cardExtraEquipment?.items) {
      setUserExtraEquipments(props.cardExtraEquipment.items.map(item => ExtraEquipmentItemDto.convertFromExtraEquipment(item)));
    } else {
      setUserExtraEquipments(initUserDataState.userExtraEquipment);
    }
  }, [props.cardExtraEquipment]);

  useEffect(() => {
    if (userExtraEquipments && userExtraEquipments.length > 0) {
      let sumTEE = userExtraEquipments.map(eei => eei.price).reduce((price1: number, price2: number) => Number(price1) + Number(price2), 0);
      setTotalExtraEquipmentPrice(roundToTwoDecimals(sumTEE));
    } else {
      setTotalExtraEquipmentPrice(initUserDataState.totalExtraEquipmentPrice);
    }
  }, [userExtraEquipments]);


  const handleOpenExtraEquipmentEditor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExtraEquipmentEditor = () => {
    setAnchorEl(null);
  };

  const addNewItem = (item: ExtraEquipmentItemDto) => {
    setUserExtraEquipments([item, ...userExtraEquipments]);
    handleCloseExtraEquipmentEditor();
    return true;
  };

  const removeItem = (index: number) => {
    const currentList = [...userExtraEquipments];
    currentList.splice(index, 1);
    setUserExtraEquipments(currentList);
  };

  const updateItemName = (event, index: number) => {
    const currentList = [...userExtraEquipments];
    currentList[index] = new ExtraEquipmentItemDto(event.target.value, currentList[index].price);
    setUserExtraEquipments([...currentList]);
  };

  const updateItemPrice = (event, index: number) => {
    const currentList = [...userExtraEquipments];
    currentList[index] = new ExtraEquipmentItemDto(currentList[index].name, event.target.value);
    setUserExtraEquipments([...currentList]);

  };

  const updateCardExtraEquipment = (cardExtraEquipmentId: string, items: Array<ExtraEquipmentItemInputType>) => {
    const updateOptions: UpdateVehicleCardExtraEquipmentMutationOptions = {
      variables: {
        input: {
          extraEquipmentId: cardExtraEquipmentId,
          items: items
        }
      }
    }

    gqlUpdateVCEEMut(updateOptions)
      .then(response => {
        if (response.data?.updateVehicleCardExtraEquipment) {
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.extraEquipment.updated")));
          props.handleUpdate();
        } else if (response?.data?.updateVehicleCardExtraEquipment?.errors) {
          response.data.updateVehicleCardExtraEquipment.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCardExtraEquipment"))));
        } else if (response.errors) {
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .catch(error => <ApolloError error={error}/>)
  }

  const createCardEquipment = (cardId: string, items: Array<ExtraEquipmentItemInputType>) => {
    const createOptions: CreateVehicleCardExtraEquipmentMutationOptions = {
      variables: {
        input: {
          cardId: cardId,
          items: items
        }
      }
    }

    gqlCreateVCEEMut(createOptions)
      .then(response => {
        if (response.data?.createVehicleCardExtraEquipment) {
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.extraEquipment.created")));
          props.handleUpdate();
        } else if (response?.data?.createVehicleCardExtraEquipment?.errors) {
          response.data.createVehicleCardExtraEquipment.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCardExtraEquipment"))));
        } else if (response.errors) {
          response.errors.map(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
  }

  const handleSubmit = () => {
    if (validateItems(userExtraEquipments)) {
      const itemsToSave = convertTo(userExtraEquipments);
      if (props.cardExtraEquipment?.id) {
        updateCardExtraEquipment(props.cardExtraEquipment?.id, itemsToSave);
      } else {
        createCardEquipment(props.cardId, itemsToSave);
      }
    } else {
      dispatch(setSnackbar(true, SnackbarStates.ERROR, t("userError.vehicleCard.extraEquipment.invalidItems")))
    }
  }

  const handleReset = () => {
    if (props.cardExtraEquipment?.items) {
      setUserExtraEquipments(props.cardExtraEquipment.items.map(item => ExtraEquipmentItemDto.convertFromExtraEquipment(item)));
    } else {
      setUserExtraEquipments(initUserDataState.userExtraEquipment);
    }
  }

  const renderTableBody = () => {
    if (userExtraEquipments && userExtraEquipments.length > 0) {
      return userExtraEquipments.map((row, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell>
              <TextField
                key={"name-" + index}
                type={"text"}
                required={true}
                error={!row.name}
                value={row.name}
                onChange={event => updateItemName(event, index)}
                inputProps={{
                  maxLength: 100
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                key={"price-" + index}
                required={true}
                error={!(row.price?.toString())}
                value={row.price}
                type="number"
                onChange={event => updateItemPrice(event, index)}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <IconButton onClick={() => removeItem(index)}>
                <DeleteOutline/>
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        )
      );
    } else {
      return (<EmptyDataListInfo/>);
    }

  }

  return (
    <form id="extra-equipment-form">
      <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
        <AccordionSummary
          sx={{ flexDirection: 'row-reverse' }}
          expandIcon={<ExpandMore color='secondary'/>}
        >
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <h2>{t('pages.vehicleCards.detail.equipment.title')}</h2>
            </Grid>
            <Grid item>
              <FormControl className="outlined">
                <TextField
                  className="section-header-info"
                  id="totalExtraEquipmentPrice"
                  label={t('pages.vehicleCards.detail.equipment.totalExtraEquipmentPrice')}
                  type="number"
                  value={totalExtraEquipmentPrice}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table className="dataTable__holder">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('fieldName.name')}</StyledTableCell>
                  <StyledTableCell align="center">{t('fieldName.price')}</StyledTableCell>
                  <StyledTableCell align="center">{t('fieldName.actions')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableBody()}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
        <AccordionActions>
          <Grid container justifyContent={'space-between'} direction={'row'}>
            <Grid item>
              <IconButton onClick={handleOpenExtraEquipmentEditor} color={"secondary"}>
                <Tooltip title={t('pages.vehicleCards.detail.equipment.actions.addItem')}>
                  <Add />
                </Tooltip>
              </IconButton>
              <Popover
                id={'extra-equipment-add-new'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseExtraEquipmentEditor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <List>
                  <ListItem>
                    <ExtraEquipmentItemEditor editHandler={addNewItem}/>
                  </ListItem>
                </List>
              </Popover>
            </Grid>
            <Grid item>
              <Button className="reset-button" onClick={handleReset}>{t('pages.vehicleCards.detail.actions.reset')}</Button>
              <Button className="icon-button" onClick={handleSubmit}><SaveOutlined/> {t('pages.vehicleCards.detail.actions.save')}</Button>
            </Grid>
          </Grid>
        </AccordionActions>
      </Accordion>
    </form>

  );


}
