import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { setLoadingStatus } from '../../../../redux/loading';
import { DeferredCreditDto, DeferredCreditGroupDto, DeferredCreditGroupItemDto } from '../../../../modelView';
import { ServicePackage, ServicePackageStateEnum } from '../../../../api/graphql/generated/schema';
import { ServicePackageSelect } from '../../../codetable';
import { ServicePackageSelectDto } from '../../../../modelView/codetable/servicePackage/ServicePackageSelectDto';
import { openErrorSnackbar } from '../../../../redux/snackbar';
import { generateDeferredCreditGroupsEmptyStructure } from '../../../../modelView/credit/CreditUtil';
import DeferredCreditGroups from './DeferredCreditGroups';
import { CloseRounded } from '@mui/icons-material';
import { useGetServicePackagesQuery } from '../../../../api/graphql/generated/hooks';

const initState = {
  possibleServicePackages: [],
  selectedServicePackageId : "",
  deferredCreditGroups : [],
}

const sortCreditGroupItems = (o1: DeferredCreditGroupItemDto, o2: DeferredCreditGroupItemDto) => {
  let result: number;

  if (!o1.month) {
    result = -1;
  } else if (!o2.month){
    result = 1
  } else if (o1.month === o2.month){
    result = o1.advancedPayment && o2.advancedPayment && o1.advancedPayment <= o2.advancedPayment ? -1 : 1;
  } else if (o1.month < o2.month){
    result = -1;
  } else {
    result = 1;
  }

  return result;
}

export default function DeferredCreditDialog(props:{ deferredCreditData: DeferredCreditDto | null | undefined, open: boolean, handleClose, handleUpdate: Function }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [selectedServiceId, setSelectedServiceId] = useState<string>(initState.selectedServicePackageId);
  const [userDeferredCreditGroups, setUserDeferredCreditGroups] = useState<Array<DeferredCreditGroupDto>>(initState.deferredCreditGroups);
  const [possibleServices, setPossibleServices] = useState<Array<ServicePackage>>(initState.possibleServicePackages);
  const { loading: loadingServicePackages, data: gqlServicePackages } = useGetServicePackagesQuery({variables: {filter: {states: [ServicePackageStateEnum.Active]}}});

  const initCreditData = () => {
    if (props.deferredCreditData) {
      setSelectedServiceId(props.deferredCreditData.serviceId);
      setUserDeferredCreditGroups(props.deferredCreditData.creditGroups);
    } else {
      setUserDeferredCreditGroups(generateDeferredCreditGroupsEmptyStructure());
      setSelectedServiceId(initState.selectedServicePackageId);
    }
    // added sort here because list of items are managed here
    userDeferredCreditGroups.forEach((group) => {
      group.items.sort(sortCreditGroupItems);
    });
  };

  useEffect(() => {
    initCreditData();
  }, [])

  useEffect(() => {
    dispatch(setLoadingStatus(loadingServicePackages));
  }, [loadingServicePackages, dispatch])

  useEffect(() => {
    if (!loadingServicePackages && gqlServicePackages){
      setPossibleServices(gqlServicePackages.getServicePackages);
    }
  }, [gqlServicePackages, loadingServicePackages]);

  const handleSave = (event) => {
    event.preventDefault();
    const errorGroupTypeNames = userDeferredCreditGroups
        .filter((group) => group.items.some((item) => item.hasError()))
        .map((group) => t("creditGroup.typeEnum.values." + group.type));
    if (errorGroupTypeNames.length > 0){
      dispatch(openErrorSnackbar(t("userError.vehicleCard.finance.credit.errorCreditTableRow",
          {creditTypeNames: errorGroupTypeNames.join(", ")}))
      );
    } else {
      let _creditData = new DeferredCreditDto(selectedServiceId, userDeferredCreditGroups);
      props.handleUpdate(_creditData);
      props.handleClose();
    }
  }

  const reset = () => {
    initCreditData();
  }

  const handleCreditGroupsUpdate = (updatedCreditGroups: Array<DeferredCreditGroupDto>) => {
    setUserDeferredCreditGroups(updatedCreditGroups);
  }

  return (
      <Dialog
          open={props.open}
          onClose={props.handleClose}
          fullWidth={true}
          maxWidth={"md"}
      >
        <DialogTitle>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
              {t("pages.vehicleCards.detail.finance.deferredCredit.dialogTitle")}
            </Grid>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={"flex-start"}>
            <Grid item>
             <ServicePackageSelect 
                inputValues={possibleServices.map((sp) => ServicePackageSelectDto.createFromAPI(sp))}
                filteredValues={selectedServiceId} 
                disabled={false}
                setFilteredValues={setSelectedServiceId}
                required={true}
              />
            </Grid>
          </Grid>
          <DeferredCreditGroups deferredCreditGroups={userDeferredCreditGroups} handleUpdate={handleCreditGroupsUpdate} />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"flex-end"}>
            <Grid item >
              <Button onClick={reset}>{t("actions.discard")}</Button>
              <Button disabled={!selectedServiceId} onClick={handleSave}>{t("pages.vehicleCards.detail.finance.actions.confirmBonusMapping")}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
  );

}
