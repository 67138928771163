import { styled, TableRow, Theme } from '@mui/material';


const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default StyledTableRow;
