import { LeasingGroupDto } from "./LeasingGroupDto";
import { Leasing as LeasingType } from "../../api/graphql/generated/schema";

export class LeasingDto {

  private _serviceId: string;
  private _contraAccount: boolean;
  private _leasingGroups: Array<LeasingGroupDto>;


  constructor(serviceId: string, contraAccount: boolean, leasingGroups: Array<LeasingGroupDto>) {
    this._serviceId = serviceId;
    this._contraAccount = contraAccount;
    this._leasingGroups = leasingGroups;
  }

  static convertFromLease(lease: LeasingType) {
    let _contraAccount: boolean = lease.contraAccount ? lease.contraAccount : false;
    let _leasingGroups: Array<LeasingGroupDto> = [];
    lease?.leasingGroups?.map(lg => lg && LeasingGroupDto.convertFromLeasingGroup(lg)).forEach(lgdto => lgdto && _leasingGroups.push(lgdto))
    return new LeasingDto(lease.servicePackage.id, _contraAccount, _leasingGroups);
  }

  get serviceId(): string {
    return this._serviceId;
  }

  set serviceId(value: string) {
    this._serviceId = value;
  }

  get contraAccount(): boolean {
    return this._contraAccount;
  }

  set contraAccount(value: boolean) {
    this._contraAccount = value;
  }

  get leasingGroups(): Array<LeasingGroupDto> {
    return this._leasingGroups;
  }

  set leasingGroups(value: Array<LeasingGroupDto>) {
    this._leasingGroups = value;
  }

  hasAllValidGroups(){
    return this._leasingGroups && !this._leasingGroups.some((item) => item.hasAnyInvalidItem());
  }
}
