import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../routes/paths';
import { Breadcrumbs, Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Home, NavigateNext } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      link: {
        display: 'flex',
      },
      icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
      },
    }),
);


export default function PageHeader(props: {headline: string, breadcrumbs: AppPageNameRouteType[], headlineParams?: Record<string, any>}) {

  const {headline, breadcrumbs, headlineParams} = props

  const {t} = useTranslation();
  const classes = useStyles();

  const home: AppPageNameRouteType = AppPageNameRoutes.HOME;
  const slicedBreadCrumbs: AppPageNameRouteType[] = breadcrumbs.slice(0, breadcrumbs.length - 1);
  const lastPageName: AppPageNameRouteType = breadcrumbs[breadcrumbs.length - 1];
  return (
      <div className={"page-header"}>
        <h1>{t('pageNames.' + headline, headlineParams)}</h1>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href={home.pageRoute} className={classes.link}><Home className={classes.icon}/> {t("pageNames." + home.pageName)}</Link>
          {slicedBreadCrumbs.length > 0 && slicedBreadCrumbs.map(function(breadcrumb){
            return <Link key={breadcrumb.pageName} color="inherit" href={breadcrumb.pageRoute} className={classes.link}>{t("pageNames." + breadcrumb.pageName)}</Link>;
          })}
          <Typography color="textPrimary" className="link">
            {t("pageNames." + lastPageName.pageName, headlineParams)}
          </Typography>
        </Breadcrumbs>
      </div>
  );

}
