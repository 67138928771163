export const SET_LOADINGSTATUS = "teamly/settings/SET_LOADINGSTATUS";

const initialState = {
  loadingStatus: false,
};

export default function loader(state = initialState, action) {
  switch (action.type) {
    case SET_LOADINGSTATUS:
      const { loadingStatus } = action;
      return {
        ...state,
        loadingStatus,
      };
    default:
      return state;
  }
};

export const setLoadingStatus = (
  loadingStatus,
) => ({
  type: SET_LOADINGSTATUS,
  loadingStatus,
});
