import React, { useEffect } from 'react';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { EquipmentCategory as EquipmentCategoryType } from '../../../api/graphql/generated/schema';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { assembleEquipmentsByCode } from './equipment.utils';

export type EquipmentItemShortenedType = {
    id: string,
    name: string;
    relationId?: string;
    description: string | null | undefined;
    count: number | null | undefined;
    category: {
        id: string,
        label: string | null | undefined,
    }
}

export type EquipmentsInCategoryType = {
    category: EquipmentCategoryType,
    equipmentItems: Array<EquipmentItemShortenedType>
}

export default function EquipmentViewer(props: { vehicleEquipments: Array<EquipmentItemShortenedType> | undefined | null }) {

    const { t } = useTranslation();
    const [equipmentsByCategory, setEquipmentsByCategory] = React.useState<EquipmentsInCategoryType[]>([]);

    useEffect(() => {
        console.log('Props Model Equipments changed effect 1');
        if (props.vehicleEquipments) {
            setEquipmentsByCategory(assembleEquipmentsByCode(props.vehicleEquipments));
        }
    }, [props.vehicleEquipments]);

    function renderCategoryEquipmentItem(equipmentItem: EquipmentItemShortenedType) {
        return (
            <ListItem key={equipmentItem.id}>
                <ListItemIcon>
                    <Check/>
                </ListItemIcon>
                <ListItemText>
                    <Typography variant="subtitle1">
                        {equipmentItem.name}
                    </Typography>
                    <span>
                      <Typography variant="body2">{equipmentItem.description}</Typography>
                      <Typography variant="body2">{equipmentItem.count}</Typography>
                    </span>
                </ListItemText>
            </ListItem>
        );
    }

    const renderCategoryEquipment = (categoryEquipment: EquipmentsInCategoryType) => {

        return <Grid item id={categoryEquipment.category.id}>
            <Box>
                <h4>{categoryEquipment.category.label}</h4>
                <List>
                    {categoryEquipment.equipmentItems.map(equipmentItem => renderCategoryEquipmentItem(equipmentItem))}
                </List>
            </Box>
        </Grid>
    }

    return (
        <Grid container justifyContent={'space-around'}>
            {(!equipmentsByCategory || equipmentsByCategory.length === 0) && <Grid item><Typography variant="h6">{t('equipmentTable.empty')}</Typography></Grid>}
            {equipmentsByCategory.map(categoryEquipment => renderCategoryEquipment(categoryEquipment))}
        </Grid>
    );

}
