import React, { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from '@mui/material/Alert';
import { ISnackbarState } from '../../modelView';
import { setSnackbar } from "../../redux/snackbar";
import { logger } from "../../utils/functionHelper";
import { makeStyles } from '@mui/styles';
import { Snackbar, SnackbarCloseReason, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const AutoCmsSnackbars = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state: ISnackbarState) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state: ISnackbarState) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector((state: ISnackbarState) => state.snackbar.snackbarMessage);
  const handleClose = (event?: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };

  logger(snackbarMessage);

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AutoCmsSnackbars;
