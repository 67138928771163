import React from "react";
import { VehicleDefinitionStateEnum } from '../../api/graphql/generated/schema';
import {useTranslation} from "react-i18next";
import {Tooltip} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { PauseCircleFilled, PlayCircleFilled, Stop } from '@mui/icons-material';


export default function VehicleDefinitionStateColor(props: {state: VehicleDefinitionStateEnum}) {

  const {t} = useTranslation();

  const {state} = props;

  function evaluateColor(state: VehicleDefinitionStateEnum) {
    let tooltipTitle = t("vehicleDefinition.definitionState.values." + state);
    let stateIcon;

    if(VehicleDefinitionStateEnum.Active === state){
      stateIcon = <PlayCircleFilled style={{ color: green[500]}} />;
    } else if(VehicleDefinitionStateEnum.Archived === state){
      stateIcon = <Stop style={{ color: red[500]}} aria-label={t("vehicleDefinition.definitionState.values." + state)}/>
    } else {
      stateIcon = <PauseCircleFilled style={{ color: orange[500]}} aria-label={t("vehicleDefinition.definitionState.values." + state)}/>;
    }

    return <Tooltip title={tooltipTitle}>
      {stateIcon}
    </Tooltip>
  }

  return evaluateColor(state);

}
