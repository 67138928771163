import { defaultVat } from "../../utils/const";
import { formatToZeroDecimalsString } from "../../utils/formatterHelper";
import { TotalPricesType } from "../../components/vehicleCard/finance/FinanceGroup";

export class FinalPrices {
  private readonly _priceWithVat: string;
  private readonly _vat: string;
  private readonly _totalPriceCRN: string;
  private readonly _totalPricePP: string;

  constructor(priceWithVat: string, vat: string, totalPriceCRN: string, totalPricePP: string) {
    this._priceWithVat = priceWithVat;
    this._vat = vat;
    this._totalPriceCRN = totalPriceCRN;
    this._totalPricePP = totalPricePP;
  }


  get priceWithVat(): string {
    return this._priceWithVat;
  }

  get vat(): string {
    return this._vat;
  }

  get totalPriceCRN(): string {
    return this._totalPriceCRN;
  }

  get totalPricePP(): string {
    return this._totalPricePP;
  }

  static assembleFinalPrices = (totalPrices: TotalPricesType, groupPrice: number) => {
    let _priceWithVat: number = totalPrices.model + totalPrices.extraEquipment + groupPrice;
    let _vat: number = _priceWithVat * defaultVat;
    let _totalPriceCRN: number = _priceWithVat - totalPrices.totalBonusesCRN - totalPrices.discountOcCrn;
    let _totalPricePP: number = _priceWithVat - totalPrices.totalBonusesPP - totalPrices.discountOcPP;

    return new FinalPrices(formatToZeroDecimalsString(_priceWithVat), formatToZeroDecimalsString(_vat), formatToZeroDecimalsString(_totalPriceCRN), formatToZeroDecimalsString(_totalPricePP));
  }
}
