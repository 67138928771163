import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField, Typography,
} from '@mui/material';
import {
  SortBy,
  SortEnum,
  VehicleDefinitionFilter as VehicleDefinitionFilterType,
  VehicleDefinitionStateEnum,
  VehicleDelivery as VehicleDeliveryType
} from "../../../api/graphql/generated/schema";
import { setLoadingStatus } from "../../../redux/loading";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages, searchLikeValue } from "../../../utils/graphGL/graphQLHelper";
import { ApolloError } from "../../index";
import { AppPageNameRoutes } from "../../../routes/paths";
import { OptionCodeAutocomplete } from "./dto/OptionCodeAutocomplete";
import VehicleDeliveryFakeCommission from "../VehicleDeliveryFakeCommission";
import { Autocomplete } from '@mui/lab';
import { AddOutlined, CloseRounded } from '@mui/icons-material';
import { CreateVehicleCardMutationOptions, useCreateVehicleCardMutation, useGetVehicleDefinitionsForCardCreateLazyQuery } from '../../../api/graphql/generated/hooks';

const initState = {
  "filterOptionCode": "",
  "userVehicleDefinitionId": null,
  "userCommissionCode": "",
  "userDeliveryDate": null,
  "userOfferedValidTo": "",
  "optionCodePossibilities": [],

}

export default function VehicleCardCreate(props: {open: boolean, handleClose}) {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterOptionCode, setFilterOptionCode] = React.useState<string>(initState.filterOptionCode);
  const [userVehicleDefinitionId, setUserVehicleDefinitionId] = React.useState<OptionCodeAutocomplete | null>(initState.userVehicleDefinitionId);
  const [userCommissionCode, setUserCommissionCode] = React.useState<string>(initState.userCommissionCode);
  const [userDeliveryDate, setUserDeliveryDate] = React.useState<VehicleDeliveryType | null>(initState.userDeliveryDate);
  const [userOfferedValidTo, setUserOfferedValidTo] = React.useState<string>(initState.userOfferedValidTo);
  const [optionCodePossibilities, setOptionCodePossibilities] = React.useState<OptionCodeAutocomplete[]>(initState.optionCodePossibilities);
  const [gqlLoadVdOc, {data: gqlVdOc, refetch: refetchVdOc, loading: gqlVdOcLoading}] = useGetVehicleDefinitionsForCardCreateLazyQuery({ variables: { filter: { state: [VehicleDefinitionStateEnum.Active] } } });
  const [createVehicleCardMut, {loading: gqlCreateVCM}] = useCreateVehicleCardMutation();

  function reset() {
    setFilterOptionCode(initState.filterOptionCode);
    setUserVehicleDefinitionId(initState.userVehicleDefinitionId);
    setUserDeliveryDate(initState.userDeliveryDate);
    setUserCommissionCode(initState.userCommissionCode);
    setOptionCodePossibilities(initState.optionCodePossibilities);
  }

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  useEffect(() => {
    if (gqlVdOc?.getVehicleDefinitions?.data) {
      setOptionCodePossibilities(gqlVdOc.getVehicleDefinitions.data.filter(data => data.modelDefinition && data.equipment && data.vehicleSpec)
          .map(data => new OptionCodeAutocomplete(data.id, data.modelDefinition?.brand, data.modelDefinition?.model, data.modelDefinition?.line, data.modelDefinition?.modelYear, data.vehicleSpec?.optionCode)))
    } else {
      setOptionCodePossibilities([]);
    }
  }, [gqlVdOc]);

  useEffect(() => {
    dispatch(setLoadingStatus(gqlVdOcLoading || gqlCreateVCM))
  }, [gqlVdOcLoading, gqlCreateVCM, dispatch]);

  const handleOptionCodeChange = (value: string) => {
    setFilterOptionCode(value);

    if (!value || value.length <= 2) {
      setOptionCodePossibilities([]);
      return;
    }


    if (value && value.length > 2) {
      let filter: VehicleDefinitionFilterType = {
        state: [VehicleDefinitionStateEnum.Active],
        optionCode: searchLikeValue(value, true, true)
      };
      let sortByOptionCode: SortBy = {
        key: "vehicleSpec.optionCode",
        sort: SortEnum.Asc
      }
      let currentInput = { filter: filter, sort: [sortByOptionCode] };
      if (!refetchVdOc) {
        gqlLoadVdOc({ variables: currentInput });
      } else {
        refetchVdOc(currentInput).catch(error => <ApolloError error={error}/>);
      }
    }
  }

  const createVehicleCard = (vehicleDefinitionId: string, commission: string, offeredValidTo: string, deliveryDate: VehicleDeliveryType | null) => {
    let mutOptions: CreateVehicleCardMutationOptions = {
      variables: {
        input: {
          commission: commission,
          definitionId: vehicleDefinitionId,
          offerValidTo: offeredValidTo ? offeredValidTo : null,
          deliveryDate: deliveryDate
        }
      }
    };

    createVehicleCardMut(mutOptions)
    .then(response => {
          if (response?.data?.createVehicleCard?.vehicleCard) {
            dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.created")));
            redirectToEditVC(response.data.createVehicleCard.vehicleCard.id)
            handleClose();
          } else if (response?.data?.createVehicleCard?.errors) {
            response.data.createVehicleCard.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
          } else if(response.errors){
            response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
          }
        }
    ).catch(error => <ApolloError error={error}/>)
  }

  let redirectToEditVC = (id) => {
    let path = AppPageNameRoutes.VEHICLE_NEW_CARDS.pageRoute + "/" + id;
    history.push(path);
  }

  const submitCreatedVD = (event) => {
    event.preventDefault();

    if (!userVehicleDefinitionId?.vehicleDefinitionId){
      dispatch(openErrorSnackbar(t("userError.vehicleCard.missingVehicleDefinition")))
    } else if (!userCommissionCode) {
      dispatch(openErrorSnackbar(t("userError.vehicleCard.missingCommissionCode")))
    } else {
      createVehicleCard(userVehicleDefinitionId.vehicleDefinitionId, userCommissionCode, userOfferedValidTo, userDeliveryDate);
    }
  }

  return (
      <Dialog open={props.open} onClose={handleClose} className={"vehicle-card-create"}>
        <DialogTitle>
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h4">
              {t("pageNames.createCard")}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseRounded />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{pt: '8px !important'}}>
          <Grid container spacing={4}>
            <Grid item>
              <FormControl>
                <Autocomplete
                    value={userVehicleDefinitionId}
                    onChange={(event, newValue) => {
                      setUserVehicleDefinitionId(newValue);
                    }}
                    inputValue={filterOptionCode}
                    onInputChange={(event, newInputValue) => {
                      handleOptionCodeChange(newInputValue);
                    }}
                    getOptionLabel={(option) => option.optionCode}
                    renderOption={(props, option) => (
                        <li {...props}>{option.displayValue()}</li>
                    )}
                    id="controllable-states-demo"
                    options={optionCodePossibilities}
                    className={"autocomplete-option-code"}
                    renderInput={(params) => <TextField {...params} label={t("fieldName.optionCode")} variant="outlined" error={!userVehicleDefinitionId}/>}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <FormControl>
                <TextField
                    variant="outlined"
                    label={t("fieldName.commission")}
                    required
                    error={!userCommissionCode}
                    value={userCommissionCode}
                    onChange={event => setUserCommissionCode(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <VehicleDeliveryFakeCommission deliveryDate={userDeliveryDate} setVehicleDelivery={setUserDeliveryDate} />
            </Grid>
            <Grid item>
              <FormControl className="filled">
                <TextField
                    id="offer-valid-to"
                    label={t('fieldName.offerValidTo')}
                    type="date"
                    value={userOfferedValidTo}
                    onChange={event => setUserOfferedValidTo(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<AddOutlined/>} onClick={submitCreatedVD} variant={"contained"} className="button create">
            <Typography variant={"button"}>{t('actions.create')}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
  );
}
