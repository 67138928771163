import React from 'react';
import EquipmentEditorDialog from './EquipmentEditorDialog';
import { EquipmentDto } from '../../../modelView';
import EquipmentViewer, { EquipmentItemShortenedType } from './EquipmentViewer';

export default function Equipment(
    props: {
        open: boolean,
        userEquipment: EquipmentDto | null,
        userEquipmentItems: Array<EquipmentItemShortenedType> | undefined | null,
        handleClose,
        handleReset,
        handleSubmit,
        handleEquipmentUpdate?: () => void,
    }) {

    return (
      <>
          <EquipmentEditorDialog
              open={props.open}
              equipment={props.userEquipment}
              handleClose={props.handleClose}
              handleReset={props.handleReset}
              handleSubmit={props.handleSubmit}
              handleEquipmentUpdate={props.handleEquipmentUpdate}
          />

          <EquipmentViewer vehicleEquipments={props.userEquipmentItems}/>
      </>
    );
}
