import {Bonus, BonusUnitEnum, CodetableDto} from "../api/graphql/generated/schema";

export class BonusDto {

  private readonly _id: string;
  private readonly _name: string;
  private readonly _price: number;
  private readonly _unit: BonusUnitEnum;
  private readonly _vehicleType: CodetableDto | null | undefined;
  private readonly _brand: CodetableDto | null | undefined;
  private readonly _forCrn: boolean;
  private readonly _forPhysicalPerson: boolean;

  constructor(id: string, name: string, price: number, unit: BonusUnitEnum, brand: CodetableDto | null | undefined, vehicleType: CodetableDto | null | undefined, forCrn: boolean, forPhysicalPerson: boolean) {
    this._id = id;
    this._name = name;
    this._price = price;
    this._unit = unit;
    this._brand = brand;
    this._vehicleType = vehicleType;
    this._forCrn = forCrn;
    this._forPhysicalPerson = forPhysicalPerson;
  }

  static convertFromBonusGQL(bonus: Bonus){
    let _forCRN: boolean = !!bonus.forCrn;
    let _forPhysicalPerson: boolean = !!bonus.forPhysicalPerson;

    return new BonusDto(bonus.id, bonus.name, bonus.value, bonus.unit, bonus.brand, bonus.vehicleType, _forCRN, _forPhysicalPerson)
  }

  get id(): string {
    return this._id;
  }

  get price(): number {
    return this._price;
  }

  get name(): string {
    return this._name;
  }

  get unit(): BonusUnitEnum {
    return this._unit;
  }

  get brand(): CodetableDto | null | undefined {
    return this._brand;
  }

  get vehicleType(): CodetableDto | null | undefined {
    return this._vehicleType;
  }

  get forCrn(): boolean {
    return this._forCrn;
  }

  get forPhysicalPerson(): boolean {
    return this._forPhysicalPerson;
  }
}
