import {BonusEditor} from "../../index";
import React from "react";
import {useTranslation} from "react-i18next";
import {Bonus} from "../../../api/graphql/generated/schema";
import { Button, Popover } from '@mui/material';
import { Add } from '@mui/icons-material';

export default function BonusAddNew(props: {handleUpdate: Function }) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const addNewItem = (item: Bonus) => {
    handleClosePopover();
    props.handleUpdate();
    return true;
  };

  return (
      <div>
        <Button className="addColorButton" onClick={handleOpenPopover}>
          <Add/>{t("layout.codetable.bonus.addBonus")}
        </Button>
        <Popover
          id={"bonus-add-new"}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <BonusEditor handleBonusSave={addNewItem}/>
        </Popover>
      </div>
  );
}
