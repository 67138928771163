import { CreditGroup as CreditGroupType, CreditTypeEnum } from "../../api/graphql/generated/schema";
import { CreditGroupItemDto } from "./CreditGroupItemDto";

export class CreditGroupDto {

  private readonly _type: CreditTypeEnum;
  private _items: Array<CreditGroupItemDto>;


  constructor(type: CreditTypeEnum, items:  Array<CreditGroupItemDto>) {
    this._type = type;
    this._items = items;
  }

  static convertFromCreditGroup(leasingGroup: CreditGroupType) {
    let _items = leasingGroup.items ? leasingGroup.items.map(item => CreditGroupItemDto.convertFromCreditGroupItem(item)) : [];
    return new CreditGroupDto(leasingGroup.type, _items);
  }

  get type(): CreditTypeEnum {
    return this._type;
  }

  get items():  Array<CreditGroupItemDto> {
    return this._items;
  }

  set items(value:  Array<CreditGroupItemDto>) {
    this._items = value;
  }

  hasAnyInvalidItem(){
    return this._items && this._items.some((item) => !item.isValid());
  }
}
