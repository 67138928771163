import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BonusDto, FinanceBonusDto } from "../../../modelView";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { useGetBonusesLazyQuery } from '../../../api/graphql/generated/hooks';
import { setLoadingStatus } from "../../../redux/loading";
import { BonusAddNew, BonusEditPopover } from "../../index";
import { formatToZeroDecimalsString } from "../../../utils/formatterHelper";
import { CloseRounded } from '@mui/icons-material';
import { setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import { Bonus } from '../../../api/graphql/generated/schema';

const initState = {
  possibleBonuses: [],
  totalBonusesCRN: "0",
  totalBonusesPP: "0"

}

export default function BonusDialog(props:{ bonuses: Array<BonusDto>, brandId: string, vehicleTypeId: string, open: boolean, handleClose, handleUpdate: Function }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [possibleBonuses, setPossibleBonuses] = useState<Array<FinanceBonusDto>>(initState.possibleBonuses);
  const [gqlPossibleBonuses, setgqlPossibleBonuses] = useState<Array<Bonus>>(initState.possibleBonuses);

  const [totalBonusesCRN, setTotalBonusesCRN] = React.useState<string>(initState.totalBonusesCRN);
  const [totalBonusesPP, setTotalBonusesPP] = React.useState<string>(initState.totalBonusesPP);

  const [ getBonuses, {loading: loadingBonuses, refetch: refetchBonuses}] = useGetBonusesLazyQuery();

  const initPossibleBonuses = useCallback(() => {
    let _possibleBonuses = new Array<FinanceBonusDto>();
    let _alreadyAddedBonusIds = new Set<string>()
    if (props.bonuses && props.bonuses.length > 0){
      props.bonuses.map(bonus => {
        _alreadyAddedBonusIds.add(bonus.id);
        let fbdto = FinanceBonusDto.convertFromBonus(bonus);
        fbdto.isApplied = true;
        return fbdto;
      })
          .forEach(fbdto => _possibleBonuses.push(fbdto));
    }

    if (gqlPossibleBonuses){
      gqlPossibleBonuses
          .filter(bonus => !_alreadyAddedBonusIds.has(bonus.id))
          .map(bonus => FinanceBonusDto.convertFromBonusGQL(bonus))
          .forEach(fbdto => _possibleBonuses.push(fbdto));
    }

    setPossibleBonuses(_possibleBonuses);
  }, [gqlPossibleBonuses, props.bonuses])

  useEffect(() => {
    dispatch(setLoadingStatus(loadingBonuses));
  }, [loadingBonuses, dispatch])

  useEffect(() => {
    if (props.open){
      getBonuses({ variables: {filter: {brandId: props.brandId, vehicleTypeId: props.vehicleTypeId}} })
          .then((bonuses) => {
            if(bonuses.data?.getBonuses) {
              setgqlPossibleBonuses(bonuses.data.getBonuses);
            }
          })
          .catch((error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
      initPossibleBonuses();
    }
  }, [initPossibleBonuses,props.open]);

  useEffect(() => {
    let _possibleBonuses = possibleBonuses.filter(bonus => bonus.isApplied);
    let _totalBonusesCRN = _possibleBonuses.filter(bonus => bonus.forCrn).map(bonus => bonus.price).reduce((b1,b2) => b1 + b2, 0);
    setTotalBonusesCRN(formatToZeroDecimalsString(_totalBonusesCRN));
    let _totalBonusesPP = _possibleBonuses.filter(bonus => bonus.forPhysicalPerson).map(bonus => bonus.price).reduce((b1,b2) => b1 + b2, 0);
    setTotalBonusesPP(formatToZeroDecimalsString(_totalBonusesPP));
  }, [possibleBonuses]);

  const handleSave = () => {
    props.handleUpdate(possibleBonuses.filter(fbdto => fbdto.isApplied));
    props.handleClose();
  }

  const reset = () => {
    initPossibleBonuses();
  }

  const handleIsApplied = (bonus) => {
    let bonusIndex = possibleBonuses.findIndex(cb => cb.id === bonus.id);
    let newPossibleBonuses = [...possibleBonuses];
    bonus.isApplied = !bonus.isApplied;
    newPossibleBonuses[bonusIndex] = bonus;
    setPossibleBonuses(newPossibleBonuses);
  }

  const renderBonus = (bonus: FinanceBonusDto) => {
    return (
        <Grid item justifyContent={"space-between"} sm={12} md={3}>
          <Card variant={"outlined"}>
            <CardContent>
              <Typography variant={"body2"}>
                <Checkbox
                    checked={bonus.isApplied}
                    onChange={() => handleIsApplied(bonus)}
                    color={"primary"}
                />
                {bonus.name} ({bonus.price} {t("bonus.unitEnum.values." + bonus.unit)})
              </Typography>
            </CardContent>
            <CardActions>
              <BonusEditPopover bonus={bonus} handleUpdate={refetchBonuses}/>
            </CardActions>
          </Card>
        </Grid>
    );
  }


  const renderBonuses = () => {
    return (
        <Grid container direction={"row"} sm={12} >
          {possibleBonuses.map(bonus => renderBonus(bonus))}
        </Grid>
    );
  }

  return (
      <Dialog
          open={props.open}
          onClose={props.handleClose}
          fullScreen={true}
      >
        <DialogTitle>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
            {t("pages.vehicleCards.detail.finance.bonusDialog.dialogTitle")}
            </Grid>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={"flex-end"}>
            <BonusAddNew handleUpdate={refetchBonuses}/>
          </Grid>
          {!loadingBonuses && renderBonuses()}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"flex-end"}>
            <Grid item>
              <TextField
                  id="total-bonuses-crn"
                  label={t('fieldName.totalBonusesCRN')}
                  type="text"
                  value={totalBonusesCRN}
                  disabled
                  variant={"outlined"}
              />
              <TextField
                  id="total-bonuses-pp"
                  label={t('fieldName.totalBonusesPP')}
                  type="text"
                  value={totalBonusesPP}
                  disabled
                  variant={"outlined"}
              />
            </Grid>
            <Grid item >
              <Button onClick={reset}>{t("actions.discard")}</Button>
              <Button onClick={handleSave}>{t("pages.vehicleCards.detail.finance.actions.confirmBonusMapping")}</Button>
            </Grid>
          </Grid>
      </DialogActions>
      </Dialog>
  );
}
