import React from "react";
import {Box, Grid, List, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import { UserEquipmentItemDto } from './UserEquipmentItemDto';
import {EquipmentCategory as EquipmentCategoryType} from "../../../../api/graphql/generated/schema";
import SingularEquipmentItemEditor from './SingularEquipmentItemEditor';


export type UserEquipmentsCategoryType = {
  categoryId: string,
  categoryLabel: string
  equipmentItems: Array<UserEquipmentItemDto>
}


export default function EquipmentItemsEditor(props: {equipmentsByCategories: UserEquipmentsCategoryType[], possibleEquipmentsCategories: EquipmentCategoryType[], updateRow}) {

  const {t} = useTranslation();

  function renderCategoryEquipmentItem(userEquipmentItem: UserEquipmentItemDto) {
    return <SingularEquipmentItemEditor userEquipmentItem={userEquipmentItem} possibleEquipmentsCategories={props.possibleEquipmentsCategories} updateRow={props.updateRow}/>
  }

  const renderCategoryEquipment = (categoryModelEquipments: UserEquipmentsCategoryType) => {

    return <Grid item id={categoryModelEquipments.categoryId}>
      <Box>
        <h4>{categoryModelEquipments.categoryLabel}</h4>
        <List>
          {categoryModelEquipments.equipmentItems.map(equipmentItem => renderCategoryEquipmentItem(equipmentItem))}
        </List>
      </Box>
    </Grid>
  }

  return (
      <Grid container justifyContent={"flex-start"}>
        {(!props.equipmentsByCategories || props.equipmentsByCategories.length === 0) &&
            <Grid item>
              <Typography variant="h6">{t("datatable.empty")}</Typography>
            </Grid>
        }
        {props.equipmentsByCategories.map(categoryEquipment => renderCategoryEquipment(categoryEquipment))}
      </Grid>
  );
}
