import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton } from '@mui/material';
import { setLoadingStatus } from '../../../../redux/loading';
import { generateLeasingGroupsEmptyStructure, LeasingDto, LeasingGroupDto, LeasingGroupItemDto } from '../../../../modelView';
import { ServicePackage, ServicePackageStateEnum } from '../../../../api/graphql/generated/schema';
import { LeasingGroups } from '../../../index';
import { ServicePackageSelect } from '../../../codetable';
import { ServicePackageSelectDto } from '../../../../modelView/codetable/servicePackage/ServicePackageSelectDto';
import { addContraAccountGroups, removeContraAccountGroups } from '../../../../modelView/leasing/LeasingUtil';
import { openErrorSnackbar } from '../../../../redux/snackbar';
import { CloseRounded } from '@mui/icons-material';
import { useGetServicePackagesQuery } from '../../../../api/graphql/generated/hooks';

const initState = {
  possibleServicePackages: [],
  selectedServicePackageId : "",
  leasingGroups : [],
  contraAccount: false
}

const sortLeasingGroupItems = (o1: LeasingGroupItemDto, o2: LeasingGroupItemDto) => {
  let result: number;

  if (!o1.month) {
    result = -1;
  } else if (!o2.month){
    result = 1
  } else if (o1.month === o2.month){
    result = o1.totalKmPerYear && o2.totalKmPerYear && o1.totalKmPerYear <= o2.totalKmPerYear ? -1 : 1;
  } else if (o1.month < o2.month){
    result = -1;
  } else {
    result = 1;
  }

  return result;
}

export default function LeasingDialog(props:{ leaseData: LeasingDto | null | undefined, open: boolean, handleClose, handleUpdate: Function }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [selectedServiceId, setSelectedServiceId] = useState<string>(initState.selectedServicePackageId);
  const [userContraAccount, setContraAccount] = useState<boolean>(initState.contraAccount);
  const [userLeaseGroups, setUserLeaseGroups] = useState<Array<LeasingGroupDto>>(initState.leasingGroups);
  const [possibleServices, setPossibleServices] = useState<Array<ServicePackage>>(initState.possibleServicePackages);
  const { loading: loadingServicePackages, data: gqlServicePackages } = useGetServicePackagesQuery({variables: {filter: {states: [ServicePackageStateEnum.Active]}}});

  const initLeaseData = () => {
    if (props.leaseData) {
      setSelectedServiceId(props.leaseData.serviceId);
      setContraAccount(props.leaseData.contraAccount);
      setUserLeaseGroups(props.leaseData.leasingGroups);
    } else {
      setUserLeaseGroups(generateLeasingGroupsEmptyStructure());
      setSelectedServiceId(initState.selectedServicePackageId);
      setContraAccount(initState.contraAccount);
    }
    // added sort here because list of items are managed here
    userLeaseGroups.forEach((group) => {
      group.items.sort(sortLeasingGroupItems)
    });
  };

  useEffect(() => {
    initLeaseData();
  }, [])

  useEffect(() => {
    dispatch(setLoadingStatus(loadingServicePackages));
  }, [loadingServicePackages, dispatch])

  useEffect(() => {
    if (!loadingServicePackages && gqlServicePackages){
      setPossibleServices(gqlServicePackages.getServicePackages);
    }
  }, [gqlServicePackages, loadingServicePackages]);

  const handleContraAccountChange = () => {
    let newValue = !userContraAccount;
    if (newValue){
      handleLeasingGroupsUpdate(addContraAccountGroups(userLeaseGroups));
    } else {
      handleLeasingGroupsUpdate(removeContraAccountGroups(userLeaseGroups));
      
    }
    setContraAccount(newValue);
  }

  const handleSave = (event) => {
    event.preventDefault();
    const errorGroupTypeNames = userLeaseGroups
        .filter((group) => group.items.some((item) => item.hasError()))
        .map((group) => t("leasingGroup.typeEnum.values." + group.type));
    if (errorGroupTypeNames.length > 0){
      dispatch(openErrorSnackbar(t("userError.vehicleCard.finance.leasing.errorLeasingTableRow",
          {leasingTypeNames: errorGroupTypeNames.join(", ")}))
      );
    } else {
      let _leaseData = new LeasingDto(selectedServiceId, userContraAccount, userLeaseGroups);
      props.handleUpdate(_leaseData);
      props.handleClose();
    }
  }

  const reset = () => {
    initLeaseData();
  }

  const handleLeasingGroupsUpdate = (updatedLeasingGroups: Array<LeasingGroupDto>) => {
    setUserLeaseGroups(updatedLeasingGroups);
  }

  return (
      <Dialog
          open={props.open}
          onClose={props.handleClose}
          fullWidth={true}
          maxWidth={"md"}
      >
        <DialogTitle>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
              {t("pages.vehicleCards.detail.finance.leasing.dialogTitle")}
            </Grid>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={"flex-start"}>
            <Grid item>
             <ServicePackageSelect 
                inputValues={possibleServices.map((sp) => ServicePackageSelectDto.createFromAPI(sp))}
                filteredValues={selectedServiceId} 
                disabled={false}
                setFilteredValues={setSelectedServiceId}
                required={true}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                  id="contra-account-chbx"
                  control={
                    <Checkbox
                        checked={userContraAccount}
                        onChange={handleContraAccountChange}
                        name="is-contra-account-chbx"
                        color={"primary"}
                    />
                  }
                  label={t("fieldName.leasing.contraAccount")}
              />
            </Grid>
          </Grid>
          <LeasingGroups leasingGroups={userLeaseGroups} handleUpdate={handleLeasingGroupsUpdate} />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"flex-end"}>
            <Grid item >
              <Button onClick={reset}>{t("actions.discard")}</Button>
              <Button disabled={!selectedServiceId} onClick={handleSave}>{t("pages.vehicleCards.detail.finance.actions.confirmBonusMapping")}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
  );

}
