import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetImageLazyQuery } from "../../../api/graphql/generated/hooks";
import { setLoadingStatus } from "../../../redux/loading";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from '@mui/icons-material';

const initState = {
  imageBase64: ""
}
export default function ColorImage(props: { imageId: string, imageUrl?: string | null | undefined, isMainImage: boolean, handleUpdate: Function, handleRemove: Function}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const removeButtonTitle = t("actions.remove");

  const [fetchImage, {loading: loadingImage, data: gqlImage}] = useGetImageLazyQuery({variables: {id: props.imageId}});
  const [imageBase64, setImageBase64] = useState<string>(initState.imageBase64);

  useEffect(() => {
    dispatch(setLoadingStatus(loadingImage));
  }, [dispatch, loadingImage])

  useEffect(() => {
    if (gqlImage?.getImage?.content){
      setImageBase64(gqlImage.getImage.content);
    } else {
      setImageBase64(initState.imageBase64);
    }
  }, [gqlImage]);

  useEffect(() => {
    if (!props.imageUrl){
      fetchImage();
    }
  }, [props.imageUrl]);

  return (
      <Card className={"h-100"}>
        <CardContent>
          <img src={props.imageUrl ? props.imageUrl : imageBase64} alt="car-heading" className="car-heading" width={200} height={"auto"}/>
        </CardContent>
        <CardActions>
          <Grid container justifyContent={"space-between"} spacing={1}>
            <FormControlLabel id="main-img-chbx"
                              control={
                                <Checkbox
                                    checked={props.isMainImage}
                                    onChange={() => props.handleUpdate(props.imageId)}
                                    name="is-main-img-chbx"
                                    color={"primary"}
                                />
                              }
                              label={t("pages.vehicleCards.detail.photos.imageDetail.isMainImage")}
            />
            <IconButton onClick={() => props.handleRemove(props.imageId)}>
              <Tooltip title={removeButtonTitle}>
                <DeleteOutlined />
              </Tooltip>
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
  )
}
