import * as React from 'react';
import { useTranslation } from "react-i18next";
import { VehicleCardColor, VehicleCardStateEnum } from "../../api/graphql/generated/schema";
import { Button, Grid, Popover } from '@mui/material';
import { InStoreVehicleCreate } from "../index";
import { ArchiveOutlined, ArrowForwardOutlined, LocalTaxiOutlined, UnarchiveOutlined } from '@mui/icons-material';

const initState = {
    inStoreCardPopAnchorEl: null,
}

export default function VehicleCardActionButtons(
    props: {
        vehicleCardId: string,
        state: VehicleCardStateEnum,
        inStoreCard: boolean,
        vehicleCardColors: Array<VehicleCardColor>
        handleUpdateVCS: Function
    }) {

    const {t} = useTranslation();
    const [inStoreCardPopAnchorEl, setInStoreCardPopAnchorEl] = React.useState(initState.inStoreCardPopAnchorEl);

    const handleToInProgress = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.InProgress);
    }

    const handleToReview = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.AwaitApproval);
    }

    const renderStateHandlerButton = (actionName: string, onClickHandler) => {
        return <Button className="icon-button" onClick={onClickHandler}><ArrowForwardOutlined/> {t(actionName)}</Button>;
    }

    const handleToApprove = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.Approved);
    }

    const handleSold = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.Sold);
    }

    const handleArchive = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.Archived);
    }

    const handleUnarchive = () => {
        props.handleUpdateVCS(VehicleCardStateEnum.Unarchive);
    }


    const handleOpenInStoreCardPop = (event) => {
        setInStoreCardPopAnchorEl(event.currentTarget);
    }

    const handleCloseInStoreCardPop = () => {
        setInStoreCardPopAnchorEl(null);
    };

    return (
        <Grid item >
            {props.state !== VehicleCardStateEnum.InProgress && props.state !== VehicleCardStateEnum.Archived && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.backToInProgress', handleToInProgress) }
            {props.state === VehicleCardStateEnum.InProgress && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.toReview', handleToReview) }
            {props.state === VehicleCardStateEnum.AwaitApproval && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.readyForWeb', handleToApprove) }
            {props.state === VehicleCardStateEnum.Published && props.inStoreCard && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.sold', handleSold) }
            {!props.inStoreCard && <Button className="icon-button" onClick={handleOpenInStoreCardPop}><LocalTaxiOutlined/> {t('pages.vehicleCards.detail.actions.createInStoreCard')}</Button>}
            {!props.inStoreCard &&
            <Popover
                id={"in-store-card-create-pop"}
                open={Boolean(inStoreCardPopAnchorEl)}
                anchorEl={inStoreCardPopAnchorEl}
                onClose={handleCloseInStoreCardPop}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <InStoreVehicleCreate cardId={props.vehicleCardId} colors={props.vehicleCardColors} onSuccess={handleCloseInStoreCardPop}/>
            </Popover>
            }
            {props.state !== VehicleCardStateEnum.Archived && <Button className="icon-button only-icon" onClick={handleArchive}><ArchiveOutlined/></Button>}
            {props.state === VehicleCardStateEnum.Archived && <Button className="icon-button only-icon" onClick={handleUnarchive}><UnarchiveOutlined/></Button>}
        </Grid>
    );
}
