import { Card, Container, Grid } from '@mui/material';
import PageHeader from '../pageHeader/PageHeader';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../routes/paths';
import UsedCarDefinition from '../../pages/vehicleUsed/edit/usedVehicleCardDetailDefinition';
import UsedVehicleCardDetailExtraEquipment from '../../pages/vehicleUsed/edit/usedVehicleCardDetailExtraEquipment';
import UsedCardSeller from '../../pages/vehicleUsed/edit/usedVehicleCardDetailSeller';
import UsedCarPhotos from '../../pages/vehicleUsed/edit/usedVehicleCardDetailPhotos';
import UsedCarMarketing from '../../pages/vehicleUsed/edit/usedVehicleCardDetailMarketing';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FinalPrices } from '../../modelView';
import { TotalPricesType } from '../vehicleCard/finance/FinanceGroup';
import { useTranslation } from 'react-i18next';
import { GeneralSeller, GeneralVehicleCard, GeneralVehicleDefinition } from '../../utils/typings';
import UsedCarFinance from '../../pages/vehicleUsed/edit/usedVehicleCardDetailFinance';
import UsedVehicleCardActionButtons from './usedVehicleCardActionButtons';
import { openErrorSnackbar } from '../../redux/snackbar';
import {
    CustomAttribute,
    CustomAttributeInput,
    ExtraEquipmentItem,
    MarketingTag,
    UsedVehicleCardFinance,
    UsedVehicleCardFinanceInput,
    UsedVehicleCardInput,
    UsedVehicleEquipmentInput,
    UsedVehicleImage,
    UsedVehicleImageInput,
    UsedVehicleSellerInput, VehicleTypeEnum,
} from '../../api/graphql/generated/schema';
import CardSummary from './CardSummary';
import { sortedCAbyOrder } from '../../utils/utils';

const breadcrumbs = [
    AppPageNameRoutes.VEHICLE_USED_CARDS,
    AppPageNameRoutes.VEHICLE_USED_CARD_DETAIL,
];

const initState = {
    mainImageUrl: '',
    changed: false,
    cardState: '',
    usedVehicleCard: null,
    id: '',
    vin: '',
    brand: '',
    model: '',
    priceWithVat: '',
    vat: '',
    totalPriceCRN: '',
    totalPricePP: '',
}

export default function CardDetails(props: {
    usedVehicleCard: GeneralVehicleCard,
    handleUpdateCard?: Function,
    handleCreateCard?: Function,
    refetch?: () => void,
    handleUpdateUVCS?: Function,
    handleDuplication?: () => void,
    duplicated?: boolean
}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [usedVehicleCardDto, setUsedVehicleCardDto] = React.useState<GeneralVehicleCard>(props.usedVehicleCard);
    const [finalPrices, setFinalPrices] = useState<FinalPrices | null>(null);
    const [totalExtraEquipmentPrice, setTotalExtraEquipmentPrice] = React.useState<number>(0);
    const [totalBonusesCRN, setTotalBonusesCRN] = React.useState<string>('0');
    const [totalBonusesPP, setTotalBonusesPP] = React.useState<string>('0');
    const [changed, setChanged] = React.useState(initState.changed);

    useEffect(() => {
        const prices = {
            model: Number(usedVehicleCardDto.vehicleDefinition?.price) || 0,
            extraEquipment: totalExtraEquipmentPrice,
            totalBonusesCRN: Number(totalBonusesCRN),
            totalBonusesPP: Number(totalBonusesPP),
            discountOcCrn: Number(usedVehicleCardDto.finance?.autoCmsDiscountCRN) || 0,
            discountOcPP: Number(usedVehicleCardDto.finance?.autoCmsDiscountPP) || 0,
        };
        handlePriceUpdate(prices);
    }, [
        totalBonusesCRN,
        totalBonusesPP,
        usedVehicleCardDto.finance,
        usedVehicleCardDto.vehicleDefinition?.price,
        totalExtraEquipmentPrice,
    ])

    useEffect(() => {
        if (props?.usedVehicleCard) {
            setUsedVehicleCardDto(props.usedVehicleCard);
            if (props.duplicated) {
                setChanged(true);
            }

        } else if (!usedVehicleCardDto) {
            initComponentState();
        }
    }, [props.usedVehicleCard]);


    const isValid = (): boolean => {
        return !!usedVehicleCardDto.vehicleDefinition?.vin && usedVehicleCardDto.vehicleDefinition?.vin.length >= 17
            && !!usedVehicleCardDto.vehicleDefinition?.brand?.id && !!usedVehicleCardDto.vehicleDefinition?.vehicleType
            && (usedVehicleCardDto.vehicleDefinition?.vehicleType === VehicleTypeEnum.Commercial
                ? validateNumberValue(usedVehicleCardDto.vehicleDefinition?.trunkLength)
                    && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.trunkSpace)
                    && !!usedVehicleCardDto.vehicleDefinition?.wheelbase
                : true
            )
            && !!usedVehicleCardDto.vehicleDefinition?.line && !!usedVehicleCardDto.vehicleDefinition?.model
            && !!usedVehicleCardDto.vehicleDefinition?.modelYear && !!usedVehicleCardDto.vehicleDefinition?.modelKey
            && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.price)
            && !!usedVehicleCardDto.vehicleDefinition?.body
            && !!usedVehicleCardDto.vehicleDefinition?.engine && !!usedVehicleCardDto.vehicleDefinition?.fuelType
            && !!usedVehicleCardDto.vehicleDefinition?.transmission && !!usedVehicleCardDto.vehicleDefinition?.transmissionType
            && !!usedVehicleCardDto.vehicleDefinition?.power
            && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.emission)
            && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.cubicCapacity)
            && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.fuelConsumption)
            && !!usedVehicleCardDto.vehicleDefinition?.seats && !!usedVehicleCardDto.vehicleDefinition?.driveWheel
            && !!usedVehicleCardDto.vehicleDefinition?.ownerCount && !!usedVehicleCardDto.vehicleDefinition.currency
            && validateNumberValue(usedVehicleCardDto.vehicleDefinition?.mileage);
    }

    const validateNumberValue = (value: number | null | undefined) => {
        return value === 0 || !!value;
    }

    const initComponentState = () => {
        setChanged(initState.changed);
    }

    const convertDefinition = () => {
        if (usedVehicleCardDto.vehicleDefinition && usedVehicleCardDto.vehicleDefinition.brand && isValid()) {
            return {
                vin: usedVehicleCardDto.vehicleDefinition.vin,
                brandId: usedVehicleCardDto.vehicleDefinition.brand.id,
                model: usedVehicleCardDto.vehicleDefinition.model,
                vehicleType: usedVehicleCardDto.vehicleDefinition.vehicleType,
                line: usedVehicleCardDto.vehicleDefinition.line,
                modelYear: usedVehicleCardDto.vehicleDefinition.modelYear,
                color: usedVehicleCardDto.vehicleDefinition.color,
                equipment: convertEquipment(),
                modelKey: usedVehicleCardDto.vehicleDefinition.modelKey,
                modelKeyExtension: usedVehicleCardDto.vehicleDefinition.modelKeyExtension,
                price: usedVehicleCardDto.vehicleDefinition.price,
                currency: usedVehicleCardDto.vehicleDefinition.currency,
                body: usedVehicleCardDto.vehicleDefinition.body,
                engine: usedVehicleCardDto.vehicleDefinition.engine,
                fuelType: usedVehicleCardDto.vehicleDefinition.fuelType,
                transmission: usedVehicleCardDto.vehicleDefinition.transmission,
                transmissionType: usedVehicleCardDto.vehicleDefinition.transmissionType,
                power: usedVehicleCardDto.vehicleDefinition.power,
                emission: usedVehicleCardDto.vehicleDefinition.emission,
                cubicCapacity: usedVehicleCardDto.vehicleDefinition.cubicCapacity,
                fuelConsumption: usedVehicleCardDto.vehicleDefinition.fuelConsumption,
                seats: usedVehicleCardDto.vehicleDefinition.seats,
                driveWheel: usedVehicleCardDto.vehicleDefinition.driveWheel,
                fourDriveWheel: Boolean(usedVehicleCardDto.vehicleDefinition.fourDriveWheel),
                range: usedVehicleCardDto.vehicleDefinition.range,
                trunkSpace: usedVehicleCardDto.vehicleDefinition.vehicleType === VehicleTypeEnum.Personal && usedVehicleCardDto.vehicleDefinition.trunkSpace
                    ? null
                    : usedVehicleCardDto.vehicleDefinition.trunkSpace,
                trunkLength: usedVehicleCardDto.vehicleDefinition.vehicleType === VehicleTypeEnum.Personal && usedVehicleCardDto.vehicleDefinition.trunkLength
                    ? null
                    : usedVehicleCardDto.vehicleDefinition.trunkLength,
                numberOfPallets: usedVehicleCardDto.vehicleDefinition.vehicleType === VehicleTypeEnum.Personal && usedVehicleCardDto.vehicleDefinition.numberOfPallets
                    ? null
                    : usedVehicleCardDto.vehicleDefinition.numberOfPallets,
                wheelbase: usedVehicleCardDto.vehicleDefinition.vehicleType === VehicleTypeEnum.Personal && usedVehicleCardDto.vehicleDefinition.wheelbase
                    ? null
                    : usedVehicleCardDto.vehicleDefinition.wheelbase,
                mileage: usedVehicleCardDto.vehicleDefinition.mileage,
                cubicCapacityDescription: usedVehicleCardDto.vehicleDefinition.cubicCapacityDescription,
                countryOfOrigin: usedVehicleCardDto.vehicleDefinition.countryOfOrigin,
                intoOperation: usedVehicleCardDto.vehicleDefinition.intoOperation,
                stkUntil: usedVehicleCardDto.vehicleDefinition.stkUntil,
                emissionUntil: usedVehicleCardDto.vehicleDefinition.emissionUntil,
                doorsNumber: usedVehicleCardDto.vehicleDefinition.doorsNumber,
                shifts: usedVehicleCardDto.vehicleDefinition.shifts,
                serviceBookFlag: usedVehicleCardDto.vehicleDefinition.serviceBookFlag,
                noCrashFlag: usedVehicleCardDto.vehicleDefinition.noCrashFlag,
                vatExpelFlag: usedVehicleCardDto.vehicleDefinition.vatExpelFlag,
                auctionPrice: usedVehicleCardDto.vehicleDefinition.auctionPrice,
                newVehiclePrice: usedVehicleCardDto.vehicleDefinition.newVehiclePrice,
                notes: usedVehicleCardDto.vehicleDefinition.notes,
                vehicleState: usedVehicleCardDto.vehicleDefinition.vehicleState,
                ownerCount: usedVehicleCardDto.vehicleDefinition.ownerCount,
                refVehicle: usedVehicleCardDto.vehicleDefinition.refVehicle,
                customAttributes: convertCustomAttributes(),
            }
        }
    }

    const convertCustomAttributes = (): Array<CustomAttributeInput> | undefined => {
        return usedVehicleCardDto.vehicleDefinition?.customAttributes?.map(attribute => ({
            id: attribute.id,
            value: attribute.value,
            dataType: attribute.dataType,
            label: attribute.label,
            order: attribute.order,
        }))
    }

    const convertEquipment = (): UsedVehicleEquipmentInput | undefined => {
        if (!usedVehicleCardDto.vehicleDefinition?.equipment) {
            return undefined;
        }
        return {
            name: usedVehicleCardDto.vehicleDefinition?.equipment?.name,
            equipments: usedVehicleCardDto.vehicleDefinition?.equipment?.equipments?.map((equipment) => ({
                equipmentId: equipment.equipment.id,
                description: equipment.description,
                count: equipment.count,
            })),

        }
    }

    const convertSeller = (): UsedVehicleSellerInput => {
        return {
            name: usedVehicleCardDto.seller?.name,
            address: usedVehicleCardDto.seller?.address,
            email: usedVehicleCardDto.seller?.email,
            phone: usedVehicleCardDto.seller?.phone,
        }
    }

    const handleSaveCard = () => {
        if (!usedVehicleCardDto?.vehicleDefinition) {
            dispatch(openErrorSnackbar(t('userError.missingRequiredField')));
            return;
        }

        const convertedDefinition = convertDefinition();
        if (!convertedDefinition) {
            dispatch(openErrorSnackbar(t('userError.missingRequiredField')));
            return;
        }
        const cardInput: UsedVehicleCardInput = {
            state: usedVehicleCardDto.state,
            // @ts-ignore
            vehicleDefinition: convertedDefinition,
            extraEquipment: usedVehicleCardDto.extraEquipment?.map(item => ({ name: item.name, price: item.price })),
            marketing: usedVehicleCardDto?.marketing ? { marketingTags: usedVehicleCardDto.marketing.map(tag => tag.id) } : undefined,
            finance: usedVehicleCardDto.finance ? convertFinances() : undefined,
            seller: convertSeller(),
            images: convertImages(),
        }

        if (props.handleUpdateCard) {
            props.handleUpdateCard(cardInput);
        } else if (props.handleCreateCard) {
            props.handleCreateCard(cardInput);
        }

        setChanged(false);
    }

    const convertImages = (): Array<UsedVehicleImageInput> | undefined => {
        return usedVehicleCardDto.images?.map((image) => {
            return {
                id: image.id,
                publicUrl: image.publicUrl,
                mainImage: image.mainImage,
                fileName: image.fileName,
                order: image.order,
            }
        });
    }
    const convertFinances = (): UsedVehicleCardFinanceInput => {
        return {
            bonusIds: usedVehicleCardDto.finance?.bonuses?.map(bonus => bonus.id),
            credit: usedVehicleCardDto.finance?.credit
                ? {
                    creditGroups: usedVehicleCardDto.finance.credit.creditGroups ?
                        usedVehicleCardDto.finance.credit.creditGroups.map(group => {
                            if (!group) {
                                return null;
                            }
                            return {
                                type: group.type,
                                items: group.items?.map(groupItem => {
                                        return {
                                            month: groupItem.month,
                                            monthlyInstalment: groupItem.monthlyInstalment,
                                            advancePayment: groupItem.advancePayment,
                                        }
                                    },
                                ),
                            }
                        })
                        : null,
                    extension: usedVehicleCardDto.finance.credit.extension,
                    serviceId: usedVehicleCardDto.finance.credit.servicePackage.id,
                }
                : undefined,
            deferredCredit: usedVehicleCardDto.finance?.deferredCredit
                ? {
                    deferredCreditGroups: usedVehicleCardDto.finance.deferredCredit.deferredCreditGroups ?
                        usedVehicleCardDto.finance.deferredCredit.deferredCreditGroups.map(group => {
                            if (!group) {
                                return null;
                            }
                            return {
                                type: group.type,
                                items: group.items?.map(groupItem => {
                                        return {
                                            month: groupItem.month,
                                            monthlyInstalment: groupItem.monthlyInstalment,
                                            advancePayment: groupItem.advancePayment,
                                        }
                                    },
                                ),
                            }
                        })
                        : null,
                    extension: usedVehicleCardDto.finance.deferredCredit.extension,
                    serviceId: usedVehicleCardDto.finance.deferredCredit.servicePackage.id,
                }
                : undefined,
            lease: usedVehicleCardDto.finance?.lease
                ? {
                    leasingGroups: usedVehicleCardDto.finance.lease.leasingGroups ?
                        usedVehicleCardDto.finance.lease.leasingGroups.map(group => {
                            if (!group) {
                                return null;
                            }
                            return {
                                type: group.type,
                                items: group.items?.map(groupItem => {
                                        return {
                                            month: groupItem.month,
                                            monthlyInstalment: groupItem.monthlyInstalment,
                                            totalKmPerYear: groupItem.totalKmPerYear,
                                        }
                                    },
                                ),
                            }
                        })
                        : null,
                    contraAccount: Boolean(usedVehicleCardDto.finance.lease.contraAccount),
                    serviceId: usedVehicleCardDto.finance.lease.servicePackage.id,
                }
                : undefined,
            coinsurance: usedVehicleCardDto.finance?.coinsurance,
            autoCmsDiscountPP: usedVehicleCardDto.finance?.autoCmsDiscountPP,
            autoCmsDiscountCRN: usedVehicleCardDto.finance?.autoCmsDiscountCRN,
        }
    }

    const handleFinanceUpdate = (cardFinance: UsedVehicleCardFinance) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            finance: cardFinance,
        }));
        setChanged(true);

    }

    const handlePhotoUpdate = (updatedList: Array<UsedVehicleImage>) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            images: updatedList,
        }));
        setChanged(true);
    }

    const handleCAValueUpdate = (index: number, value: string) => {
        if (usedVehicleCardDto?.vehicleDefinition?.customAttributes) {
            let CAs = sortedCAbyOrder([...usedVehicleCardDto?.vehicleDefinition?.customAttributes]);

            let attribute = {
                ...CAs[index],
                value: value,
            }
            CAs[index] = attribute;
            setUsedVehicleCardDto({ ...usedVehicleCardDto, vehicleDefinition: { ...usedVehicleCardDto.vehicleDefinition, customAttributes: CAs } });
        }

        setChanged(true);

    }

    const handleCAUpdate = (attribute: CustomAttribute) => {

        if (usedVehicleCardDto.vehicleDefinition?.customAttributes) {
            const index = usedVehicleCardDto.vehicleDefinition.customAttributes.findIndex((atr) => attribute.id === atr.id);
            let CAs = [...usedVehicleCardDto.vehicleDefinition.customAttributes];
            index < 0 ? CAs.push(attribute) : CAs[index] = attribute;
            setUsedVehicleCardDto({ ...usedVehicleCardDto, vehicleDefinition: { ...usedVehicleCardDto.vehicleDefinition, customAttributes: CAs } });
        } else {
            setUsedVehicleCardDto({ ...usedVehicleCardDto, vehicleDefinition: { ...usedVehicleCardDto.vehicleDefinition, customAttributes: [attribute] } });
        }
        setChanged(true);

    }

    const handleCARemove = (index: number) => {
        const currentList = Object.assign([], usedVehicleCardDto.vehicleDefinition?.customAttributes);
        currentList.splice(index, 1);
        setUsedVehicleCardDto({ ...usedVehicleCardDto, vehicleDefinition: { ...usedVehicleCardDto.vehicleDefinition, customAttributes: currentList } });
        setChanged(true);

    }

    const handleSellerUpdate = (seller: GeneralSeller) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            seller: seller,
        }))
        setChanged(true);
    }

    const handleEEUpdate = (updatedList: Array<ExtraEquipmentItem>) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            extraEquipment: updatedList,
        }))
        setChanged(true);
    }

    const handleDefinitionUpdate = (vehicleDefinition: GeneralVehicleDefinition) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            vehicleDefinition: vehicleDefinition,
        }))
        setChanged(true);
    }

    const changedInput = (setFunction) => (value: string) => {
        setFunction(value);
        setChanged(true);
    }

    const handlePriceUpdate = (totalPrice: TotalPricesType) => {
        if (totalPrice) {
            setFinalPrices(FinalPrices.assembleFinalPrices(totalPrice, 0));
        }
    }

    const handleUpdatePriceByBonus = (totalBonusesCRN: string, totalBonusesPP: string) => {
        setTotalBonusesCRN(totalBonusesCRN);
        setTotalBonusesPP(totalBonusesPP);
    }

    const handleAddMarketingTag = (newTag: MarketingTag) => {
        const currentTags: Array<MarketingTag> = usedVehicleCardDto.marketing || [];
        currentTags?.push(newTag);
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            marketing: currentTags,
        }));
        setChanged(true);
    }

    const handleRemoveMarketingTag = (updatedList: Array<MarketingTag>) => {
        setUsedVehicleCardDto((prevState) => ({
            ...prevState,
            marketing: updatedList,
        }));
        setChanged(true);
    }

    return (
        <Container className="vehicle-card">
            <Grid>
                <Grid
                    item
                    className="text-left"
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        component="div"
                    >
                        <Grid item>
                            <PageHeader headline={'usedCarsCards'} breadcrumbs={breadcrumbs as unknown as AppPageNameRouteType[]}/>
                        </Grid>
                        {usedVehicleCardDto && props?.handleUpdateUVCS && props?.handleDuplication &&
                            <UsedVehicleCardActionButtons
                                state={usedVehicleCardDto.state}
                                handleUpdateUVCS={props.handleUpdateUVCS}
                                handleDuplicate={props.handleDuplication}
                            />
                        }
                    </Grid>
                </Grid>

                <div className="mt-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        component="div"
                    >
                        <Grid item xs={12} sm={4}>
                            <CardSummary
                                refetch={props.refetch}
                                editMode={Boolean(props.handleUpdateCard)}
                                handleSaveCard={handleSaveCard}
                                finalPrices={finalPrices}
                                brand={usedVehicleCardDto.vehicleDefinition?.brand?.label}
                                vin={usedVehicleCardDto.vehicleDefinition?.vin}
                                images={usedVehicleCardDto.images}
                                model={usedVehicleCardDto.vehicleDefinition?.model}
                                cardState={usedVehicleCardDto.state}
                                changed={changed}
                                isValid={isValid}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Card>
                                <UsedCarDefinition
                                    vehicleDefinition={usedVehicleCardDto.vehicleDefinition}
                                    initiallyExpanded={true}
                                    handleCAValueUpdate={handleCAValueUpdate}
                                    handleCAUpdate={handleCAUpdate}
                                    handleCARemove={handleCARemove}
                                    handleDefinitionUpdate={handleDefinitionUpdate}
                                    handleUserDefinition={changedInput}
                                />
                                <UsedVehicleCardDetailExtraEquipment
                                    cardExtraEquipment={usedVehicleCardDto?.extraEquipment}
                                    initiallyExpanded={false}
                                    handleUpdate={handleEEUpdate}
                                    setTotalPrice={setTotalExtraEquipmentPrice}
                                />
                                <UsedCarFinance
                                    financeData={usedVehicleCardDto.finance}
                                    brandId={usedVehicleCardDto.vehicleDefinition?.brand?.id}
                                    vehicleTypeId={usedVehicleCardDto.vehicleDefinition?.vehicleType}
                                    initiallyExpanded={false}
                                    handleUpdate={handleFinanceUpdate}
                                    modelPrice={usedVehicleCardDto.vehicleDefinition?.price || 0}
                                    totalEquipment={totalExtraEquipmentPrice || 0}
                                    handleUpdatePrice={handleUpdatePriceByBonus}
                                />
                                <UsedCardSeller
                                    handleUpdate={handleSellerUpdate}
                                    seller={usedVehicleCardDto.seller}
                                />
                                <UsedCarPhotos
                                    images={usedVehicleCardDto.images || []}
                                    handleUpdate={handlePhotoUpdate}
                                    initiallyExpanded={false}
                                />
                                <UsedCarMarketing
                                    marketingTags={usedVehicleCardDto?.marketing || []}
                                    initiallyExpanded={false}
                                    handleAddItem={handleAddMarketingTag}
                                    handleRemoveItem={handleRemoveMarketingTag}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Container>
    )
}
