import { AddOutlined } from '@mui/icons-material';
import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    CreateModelColorMutationOptions,
    DeleteModelColorMutationOptions,
    UpdateModelColorMutationOptions,
    useCreateModelColorMutation,
    useDeleteModelColorMutation,
    useGetColorsQuery,
    useGetColorTypesQuery,
    useUpdateModelColorMutation
} from '../../../api/graphql/generated/hooks';
import { Color, ColorType, ModelColor as ModelColorType } from '../../../api/graphql/generated/schema';
import { setLoadingStatus } from '../../../redux/loading';
import { setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import { getApolloErrorMessages, getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { ApolloError, StyledTableCell } from '../../index';
import ModelColorRowEditor, { ModelColorTableRowType, ModelColorTableRowUpdType } from './ModelColorRowEditor';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

function initModelColorRows(modelColors: ModelColorType[]) {
    return modelColors.map(mc => {
        return {
            id: mc.id,
            name: mc.name,
            price: mc.price,
            colorId: mc.color?.id,
            colorTypeId: mc.colorType?.id,
            updatedBy: mc.auditInfo?.updatedBy,
            updated: mc.auditInfo.updated,
        };
    });
}

export default function ModelColorEditor(props: { modelDefinitionId: string, modelColors: ModelColorType[], refreshModelColors }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {loading: loadingColors, data: gqlColors, error: errorColors} = useGetColorsQuery();
    const {loading: loadingColorTypes, data: gqlColorTypes, error: errorColorTypes} = useGetColorTypesQuery();
    const [ createModelColorMutation, {loading: loadingCreateMC, error: errorCreateMC} ] = useCreateModelColorMutation();
    const [ updateModelColorMutation, {loading: loadingUpdateMC, error: errorUpdateMC} ] = useUpdateModelColorMutation();
    const [ deleteModelColorMutation, {loading: loadingDeleteMC, error: errorDeleteMC} ] = useDeleteModelColorMutation();

    const removeButtonTitle = t('actions.remove');
    const saveButtonTitle = t('actions.save');

    const [ userModelColorTableRows, setUserModelColorTableRows, ] = useState<ModelColorTableRowType[]>([]);
    const [ possibleColors, setPossibleColors ] = React.useState<Array<Color>>([]);
    const [ possibleColorTypes, setPossibleColorTypes ] = React.useState<Array<ColorType>>([]);
    const [ changed, setChanged ] = useState<boolean>(false);

    useEffect(() => {
        if (props?.modelColors) {
            setUserModelColorTableRows(initModelColorRows(props.modelColors));
        } else {
            setUserModelColorTableRows([]);
        }
    }, [ props.modelColors ])

    useEffect(() => {
        dispatch(setLoadingStatus(loadingCreateMC));
    }, [ loadingCreateMC, dispatch ]);

    useEffect(() => {
        if (errorCreateMC) {
            dispatch(setSnackbar(true, 'error', getApolloErrorMessages(errorCreateMC)));
        }
    }, [ errorCreateMC, dispatch ]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingUpdateMC));
    }, [ loadingUpdateMC, dispatch ]);

    useEffect(() => {
        if (errorUpdateMC) {
            dispatch(setSnackbar(true, 'error', getApolloErrorMessages(errorUpdateMC)));
        }
    }, [ errorUpdateMC, dispatch ]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingDeleteMC));
    }, [ loadingDeleteMC, dispatch ]);

    useEffect(() => {
        if (errorDeleteMC) {
            dispatch(setSnackbar(true, 'error', getApolloErrorMessages(errorDeleteMC)));
        }
    }, [ errorDeleteMC, dispatch ]);


    useEffect(() => {
        if (!loadingColors && gqlColors) {
            setPossibleColors(gqlColors.getColors);
        }

        dispatch(setLoadingStatus(loadingColors));
    }, [ loadingColors, gqlColors, dispatch ]);

    useEffect(() => {
        if (!loadingColorTypes && gqlColorTypes) {
            setPossibleColorTypes(gqlColorTypes.getColorTypes);
        }

        dispatch(setLoadingStatus(loadingColorTypes));
    }, [ loadingColorTypes, gqlColorTypes, dispatch ]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingCreateMC));
    }, [ loadingCreateMC, dispatch ]);

    const addNewRow = () => {
        let newRow = {
            id: '',
            name: '',
            price: null,
            colorId: '',
            colorTypeId: '',
            updatedBy: '',
            updated: '',
        };
        setUserModelColorTableRows([ ...userModelColorTableRows, newRow ])
    }

    const reset = () => {
        setChanged(false);
        setUserModelColorTableRows(initModelColorRows(props.modelColors));
    }

    const removeModelColor = (row: ModelColorTableRowType) => () => {
        console.log('Remove row', row);
        if (row.id) {
            let inputOptions: DeleteModelColorMutationOptions = {
                variables: {
                    input: {
                        id: row.id
                    }
                },
                onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))),
            };

            deleteModelColorMutation(inputOptions)
                .then(response => {
                    if (response?.data?.deleteModelColor?.success) {
                        dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.modelColorSaved')));
                        props.refreshModelColors();
                    } else if (response?.data?.deleteModelColor?.errors) {
                        response.data.deleteModelColor.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                    } else if (response.errors) {
                        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                    }
                })
                .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
        } else {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('userError.modelColor.missingId')))
        }
    }

    const updateModelColor = (row: ModelColorTableRowUpdType) => {
        if (row.id) {
            let inputOptions: UpdateModelColorMutationOptions = {
                variables: {
                    input: {
                        id: row.id,
                        name: row.name,
                        price: row.price,
                        colorId: row.colorId,
                        colorTypeId: row.colorTypeId
                    }
                },
                onError: (error) => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error)))
            };

            updateModelColorMutation(inputOptions)
                .then(response => {
                    if (response && response.data && response.data.updateModelColor && response.data.updateModelColor.modelColor) {
                        dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.modelColorSaved')));
                        props.refreshModelColors();
                    } else if (response?.data?.updateModelColor?.errors) {
                        response.data.updateModelColor.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                    } else if (response.errors) {
                        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                    }
                })
                .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)));
        } else {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('userError.modelColor.missingId')))
        }
    }

    const createModelColor = (row: ModelColorTableRowUpdType) => {
        let inputOptions: CreateModelColorMutationOptions = {
            variables: {
                input: {
                    name: row.name,
                    price: row.price,
                    colorId: row.colorId,
                    colorTypeId: row.colorTypeId,
                    modelDefinitionId: props.modelDefinitionId
                }
            },
            onError: (error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))),
        };

        createModelColorMutation(inputOptions)
            .then(response => {
                if (response?.data?.createModelColor?.modelColor) {
                    dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('userMessages.modelColorCreated')));
                    props.refreshModelColors();
                } else if (response?.data?.createModelColor?.errors) {
                    response.data.createModelColor.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                } else if (response.errors) {
                    response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch(setSnackbar(true, 'error', error)));
    }

    const saveRow = (row: ModelColorTableRowUpdType) => {
        console.log('Saving row', row);

        if (row.id) {
            updateModelColor(row);
        } else {
            createModelColor(row);
        }
    }

    if (errorColors) return <ApolloError error={[ errorColors ]} />
    if (errorColorTypes) return <ApolloError error={[ errorColorTypes ]} />

  return (
      <div>
        <TableContainer component={Paper }>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('fieldName.name')}</StyledTableCell>
                <StyledTableCell align="center">{t('fieldName.baseColor')}</StyledTableCell>
                <StyledTableCell align="center">{t('fieldName.colorType')}</StyledTableCell>
                <StyledTableCell align="center">{t('fieldName.price')}</StyledTableCell>
                <StyledTableCell align="center">{t('fieldName.updated')}</StyledTableCell>
                <StyledTableCell align="center">{t('fieldName.actions')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userModelColorTableRows?.map((row) =>
                  <ModelColorRowEditor
                      row={row}
                      saveRow={saveRow}
                      saveButtonTitle={saveButtonTitle}
                      removeRow={removeModelColor(row)}
                      removeButtonTitle={removeButtonTitle}
                      possibleColors={possibleColors}
                      possibleColorTypes={possibleColorTypes}
                  />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button onClick={addNewRow} startIcon={<AddOutlined />}>{t("actions.add")}</Button>
          </Grid>
          <Grid item>
            <Button onClick={reset}>{t("actions.discard")}</Button>
          </Grid>
        </Grid>
      </div>
  );
}


