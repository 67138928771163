import React from 'react';
import { CreditGroupDto, CreditGroupItemDto } from '../../../../modelView';
import { useTranslation } from 'react-i18next';
import {
    isValidMonthlyInstalment,
    maxMonthlyInstalment,
    minMonthlyInstalment,
} from '../../../../modelView/credit/CreditUtil';
import { formatPercentageFromDecimal } from '../../../../utils/formatterHelper';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';

export default function CreditGroupTab(props: { creditGroup: CreditGroupDto, handleUpdate: Function }) {

    const { t } = useTranslation();

    const removeRowTooltip = t("actions.remove");
    const addRowTooltip = t("actions.add");


    const handleUpdate = (creditGroup: CreditGroupDto) => {
        props.handleUpdate(creditGroup)
    }

    const removeRow = (index: number) => {
        const newItems = props.creditGroup.items.filter((item, idx) => idx !== index);
        handleUpdate(new CreditGroupDto(props.creditGroup.type, newItems));
    }

    const addRow = () => {
        const newItems = [...props.creditGroup.items, new CreditGroupItemDto(null, null, null)];
        handleUpdate(new CreditGroupDto(props.creditGroup.type, newItems));
    }

    const handleItemUpdate = (newItem: CreditGroupItemDto, index: number) => {
        const newItems = [...props.creditGroup.items];
        newItems[index] = newItem;
        handleUpdate(new CreditGroupDto(props.creditGroup.type, newItems));
    }

    const updateMonth = (item: CreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: CreditGroupItemDto = new CreditGroupItemDto(updatedValue, item.advancedPayment, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const updateAdvancedPayment = (item: CreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) / 100 : null;
        const updatedData: CreditGroupItemDto = new CreditGroupItemDto(item.month, updatedValue, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const updateMonthlyInstalment = (item: CreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: CreditGroupItemDto = new CreditGroupItemDto(item.month, item.advancedPayment, updatedValue);
        handleItemUpdate(updatedData, index);
    }

    return (
        <TabPanel value={props.creditGroup.type}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.credit.creditGroupTable.month')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.credit.creditGroupTable.advancedPayment')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.credit.creditGroupTable.monthlyInstalment')}
                            </TableCell>

                            <TableCell align="center">
                                <Tooltip title={addRowTooltip}>
                                    <IconButton onClick={addRow}>
                                        <AddOutlined/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.creditGroup.items.map((item, index) =>
                            <TableRow key={"credit-group-item-row" + index}>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.month}
                                        value={item.month}
                                        onChange={event => updateMonth(item, index, event.target.value)}
                                        type={"text"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.advancedPayment}
                                        value={formatPercentageFromDecimal(item.advancedPayment)}
                                        onChange={event => updateAdvancedPayment(item, index, event.target.value)}
                                        type={"text"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        className={"w-100"}
                                        error={!isValidMonthlyInstalment(item.monthlyInstalment, true)}
                                        value={item.monthlyInstalment}
                                        onChange={event => updateMonthlyInstalment(item, index, event.target.value)}
                                        type={"number"}
                                        InputProps={{ inputProps: { min: minMonthlyInstalment, max: maxMonthlyInstalment } }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={removeRowTooltip}>
                                        <IconButton onClick={() => removeRow(index)}>
                                            <DeleteOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    );
}
