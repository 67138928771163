import {Button, Popover, Tooltip} from '@mui/material';
import {BonusEditor} from "../../index";
import React from "react";
import {useTranslation} from "react-i18next";
import {Bonus} from "../../../api/graphql/generated/schema";
import {BonusDto} from "../../../modelView";
import { EditOutlined } from '@mui/icons-material';

export default function BonusEditPopover(props: {bonus: BonusDto, handleUpdate: Function }) {
  const {t} = useTranslation();
  const editBonusTooltipTitle = t("actions.edit");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleUpdate = (item: Bonus) => {
    handleClosePopover();
    props.handleUpdate(item);
    return true;
  };

  return (
      <div>
          <Tooltip title={editBonusTooltipTitle}>
            <Button startIcon={ <EditOutlined /> } onClick={handleOpenPopover}/>
          </Tooltip>
        <Popover
          id={"bonus-add-new"}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <BonusEditor bonus={props.bonus} handleBonusSave={handleUpdate}/>
        </Popover>
      </div>
  );
}
