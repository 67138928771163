import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ButtonGroup, IconButton, Popover, Tooltip } from '@mui/material';
import { ArchiveOutlined, EditOutlined, FileCopyOutlined, MoreVert, UnarchiveOutlined } from '@mui/icons-material';
import { VehicleDefinition } from '../../api/graphql/generated/schema';
import CopyVehicleDefinitionToNewModelYear from '../vehicleDefinition/copyVehicleDefinitionToNewModelYear';

// TODO refactor for universal usage
export default function MoreVertPopover(
    props: {
        rowId: string,
        rowState?: any,
        archivedState: any,
        activeState?: any,
        updateArchiveState: Function,
        duplicateVehicleCard?: Function,
        row?: VehicleDefinition,
        editRow?: Function,
    }) {

    const { t } = useTranslation();

    const actionsTitle = t('fieldName.actions');
    const editButtonTitle = t('actions.edit');
    const duplicateButtonTitle = t('actions.duplicate');
    const archiveButtonTitle = t('actions.archive');
    const restoreButtonTitle = t('actions.restore');

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const { rowId, rowState, archivedState, activeState } = props;
    return (
        <>
            <IconButton
                id={rowId}
                onClick={(event) => handleClick(event)}
            >
                <Tooltip title={actionsTitle}>
                    <MoreVert/>
                </Tooltip>
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ButtonGroup>
                    {typeof props.editRow === 'function' &&
                        <IconButton onClick={event => {
                            event.stopPropagation();
                            // @ts-ignore
                            props.editRow(rowId);
                        }}>
                            <Tooltip title={editButtonTitle}>
                                <EditOutlined/>
                            </Tooltip>
                        </IconButton>
                    }
                    {typeof props.duplicateVehicleCard === 'function' &&
                        <IconButton onClick={event => {
                            event.stopPropagation();
                            setAnchorEl(null);
                            // @ts-ignore
                            props.row ? props.duplicateVehicleCard(props.row) : props.duplicateVehicleCard(rowId);
                        }}>
                            <Tooltip title={duplicateButtonTitle}>
                                <FileCopyOutlined/>
                            </Tooltip>
                        </IconButton>
                    }
                    {props.row?.modelDefinition?.id &&
                        props.row?.equipment?.id &&
                        props.row?.vehicleSpec?.id &&
                        <CopyVehicleDefinitionToNewModelYear vehicleDefinitionId={props.row.id}
                                                             sourceModelYear={props.row.modelDefinition?.modelYear}
                                                             sourceOptionCode={props.row.vehicleSpec?.optionCode}/>
                    }
                    {rowState !== archivedState &&
                        <IconButton onClick={event => {
                            event.stopPropagation();
                            setAnchorEl(null);
                            props.row ? props.updateArchiveState(true, props.row) : props.updateArchiveState(rowId, archivedState);
                        }}>
                            <Tooltip title={archiveButtonTitle}>
                                <ArchiveOutlined/>
                            </Tooltip>
                        </IconButton>
                    }
                    {rowState === archivedState &&
                        <IconButton onClick={event => {
                            event.stopPropagation();
                            setAnchorEl(null);
                            props.row ? props.updateArchiveState(false, props.row) : props.updateArchiveState(rowId, activeState);
                        }}>
                            <Tooltip title={restoreButtonTitle}>
                                <UnarchiveOutlined/>
                            </Tooltip>
                        </IconButton>
                    }
                </ButtonGroup>
            </Popover>
        </>
    )
}
