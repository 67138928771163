import { Grid, IconButton, Theme } from '@mui/material';
import React from 'react';
import { CustomAttribute } from '../../../../api/graphql/generated/schema';
import { Delete, EditRounded } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import CustomAttributeInputField from './CustomAttributeInputField';
import { sortedCAbyOrder } from '../../../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            backgroundColor: 'transparent',
            "&:hover" : {
                backgroundColor: 'transparent',
            }
        }
    }),
);

export default function CustomAttributes(props: { customAttributes: Array<CustomAttribute>, handleOpenEditDialog: Function, handleRemove: Function, handleCAUpdate: Function }) {
    const classes = useStyles();

    return (
        <Grid container
              direction="row"
              sx={{width: '100%'}}
        >
            {sortedCAbyOrder(props.customAttributes).map((attribute, index) => (
                <Grid item container xs={12} sm={6}  alignItems="center" sx={{width: '100%'}}>
                    <Grid item xs={12} sm={10}>
                        <CustomAttributeInputField attribute={attribute} handleChange={(value) => props.handleCAUpdate(index, value)} />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={2}>
                        <IconButton color="inherit" size="small" className={classes.iconButton} onClick={() => props.handleOpenEditDialog(attribute)} aria-label="close">
                            <EditRounded/>
                        </IconButton>
                        <IconButton color="inherit" size="small" className={classes.iconButton} onClick={() => props.handleRemove(index)} aria-label="close">
                            <Delete/>
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}
