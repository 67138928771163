import React, {useEffect} from "react";
import {Checkbox, Divider, FormControl, Grid, ListItem, ListItemIcon, TextField, Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import { SaveAltOutlined } from '@mui/icons-material';
import { UserEquipmentItemDto } from './UserEquipmentItemDto';
import {EquipmentCategory as EquipmentCategoryType} from "../../../../api/graphql/generated/schema";
import { CodetableSelect } from '../../../index';


const initUserDataState = {
  "inputModelEquipmentName": null,
  "rowChanged": false,
  "inputIsApplied": false,
  "inputEquipmentName": "",
  "inputEquipmentCategory": "",
  "inputEquipmentDescription": null,
  "inputEquipmentCount": null,
  "modelEquipmentsByCategory": [],
  "possibleEquipmentCategory": [],
  "saveButtonTitle": ""

}

export default function SingularEquipmentItemEditor(props: {userEquipmentItem: UserEquipmentItemDto, possibleEquipmentsCategories: EquipmentCategoryType[], updateRow}) {

  const { t } = useTranslation();

  const [rowChanged, setRowChanged] = React.useState<boolean>(initUserDataState.rowChanged);
  const [userIsApplied, setUserIsApplied] = React.useState<boolean>(initUserDataState.inputIsApplied);
  const [userEquipmentName, setUserEquipmentName] = React.useState<string>(initUserDataState.inputEquipmentName);
  const [userEquipmentCategory, setUserEquipmentCategory] = React.useState<string>(initUserDataState.inputEquipmentCategory);
  const [userEquipmentDescription, setUserEquipmentDescription] = React.useState<string | null>(initUserDataState.inputEquipmentDescription);
  const [userEquipmentCount, setUserEquipmentCount] = React.useState<string | null>(initUserDataState.inputEquipmentCount);
  const [possibleEquipmentsCategories, setPossibleEquipmentsCategories] = React.useState<EquipmentCategoryType[]>(initUserDataState.possibleEquipmentCategory);
  const [modelEquipmentChangedFlagTitle] = React.useState<string>(t("fieldName.rowChanged"));

  useEffect(() => {
    if (props?.possibleEquipmentsCategories) {
      setPossibleEquipmentsCategories(props.possibleEquipmentsCategories);
    } else {
      setPossibleEquipmentsCategories(initUserDataState.possibleEquipmentCategory);
    }
  }, [props.possibleEquipmentsCategories]);

  useEffect(() => {
    setUserIsApplied(props.userEquipmentItem.isApplied);
    setRowChanged(props.userEquipmentItem.rowDataChanged());
  }, [props.userEquipmentItem, props.userEquipmentItem.isApplied]);

  useEffect(() => {
    setUserEquipmentName(props.userEquipmentItem.equipmentName);
    setRowChanged(props.userEquipmentItem.rowDataChanged());
  }, [props.userEquipmentItem, props.userEquipmentItem.equipmentName]);

  useEffect(() => {
    setUserEquipmentCategory(props.userEquipmentItem.equipmentCategoryId);
    setRowChanged(props.userEquipmentItem.rowDataChanged());
  }, [props.userEquipmentItem, props.userEquipmentItem.equipmentCategoryId]);

  useEffect(() => {
    if (props.userEquipmentItem.description) {
      setUserEquipmentDescription(props.userEquipmentItem.description);
    } else {
      setUserEquipmentDescription(initUserDataState.inputEquipmentDescription);
    }
    setRowChanged(props.userEquipmentItem.rowDataChanged());
  }, [props.userEquipmentItem, props.userEquipmentItem.description]);

  useEffect(() => {
    if (props.userEquipmentItem.count !== undefined && props.userEquipmentItem.count !== null) {
      setUserEquipmentCount(props.userEquipmentItem.count.toString());
    } else {
      setUserEquipmentCount(initUserDataState.inputEquipmentCount);
    }
    setRowChanged(props.userEquipmentItem.rowDataChanged());
  }, [props.userEquipmentItem, props.userEquipmentItem.count]);

  const updateRow = (isApplied: boolean, equipmentName: string, equipmentCategory: string, equipmentDescription: string | null, equipmentCount: string | null) => {

    if (userEquipmentName !== "" && userEquipmentCategory !== ""){
      let selectedCategory: EquipmentCategoryType | undefined = props.possibleEquipmentsCategories.find(cat => cat.id === equipmentCategory);
      let equipmentCategoryLabel: string = selectedCategory?.label ? selectedCategory.label : equipmentCategory;
      props.userEquipmentItem.equipmentName = equipmentName;
      props.userEquipmentItem.equipmentCategoryId = equipmentCategory;
      props.userEquipmentItem.equipmentCategoryLabel = equipmentCategoryLabel;
      props.userEquipmentItem.description = equipmentDescription;
      props.userEquipmentItem.count = equipmentCount !== null ? Number(equipmentCount) : null;
      props.userEquipmentItem.isApplied = isApplied;

      return props.updateRow(props.userEquipmentItem);
    }
  };

  function handleUpdateIsApplied(newValue: boolean) {
    if(updateRow(newValue, userEquipmentName, userEquipmentCategory, userEquipmentDescription, userEquipmentCount)){
      setUserIsApplied(newValue);
    }
  }

  function handleUpdateEquipmentName(newValue: string) {
    if (updateRow(userIsApplied, newValue, userEquipmentCategory, userEquipmentDescription, userEquipmentCount)) {
      setUserEquipmentName(newValue);
    }
  }

  function handleUpdateEquipmentCategory(newValue: string) {
    if (updateRow(userIsApplied, userEquipmentName, newValue, userEquipmentDescription, userEquipmentCount)){
      setUserEquipmentCategory(newValue);
    }
  }

  function handleUpdateDescription(newValue: string) {
    if (updateRow(userIsApplied, userEquipmentName, userEquipmentCategory, newValue, userEquipmentCount)) {
      setUserEquipmentDescription(newValue);
    }
  }

  function handleUpdateCount(newValue: string) {
    if (updateRow(userIsApplied, userEquipmentName, userEquipmentCategory, userEquipmentDescription, newValue)) {
      setUserEquipmentCount(newValue);
    }
  }

  return (
      <div>
        <ListItem key={props.userEquipmentItem.equipmentId}>
          <ListItemIcon>
            <span>
              <Checkbox
                  checked={userIsApplied}
                  onChange={() => handleUpdateIsApplied(!userIsApplied)}
                  name="is-applied-chbx"
                  color={"primary"}
              />
              <br/>
              {rowChanged &&
                <Tooltip title={modelEquipmentChangedFlagTitle}>
                  <SaveAltOutlined />
                </Tooltip>
              }
            </span>
          </ListItemIcon>
          <Grid container direction={"column"}>
          <Grid item>
            <Grid container>
              <Grid item>
                <FormControl>
                  <Tooltip title={userEquipmentName}>
                  <TextField
                      variant="outlined"
                      label={t("fieldName.name")}
                      value={userEquipmentName}
                      error={!userEquipmentName}
                      required
                      fullWidth
                      onChange={(event) => handleUpdateEquipmentName(event.target.value)}
                      inputProps={{
                        maxLength: 100
                      }}
                  />
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item>
                <CodetableSelect
                    inputValues={possibleEquipmentsCategories}
                    filteredValues={userEquipmentCategory}
                    setFilteredValues={handleUpdateEquipmentCategory}
                    label={t("fieldName.equipmentCategory")}
                    multiselect={false}
                    required
                />
              </Grid>
            </Grid>
          </Grid>

            {userIsApplied && <Grid item> <Grid container>
              <Grid item>
                <FormControl>
                  <TextField
                    variant="outlined"
                    label={t("fieldName.description")}
                    value={userEquipmentDescription}
                    fullWidth
                    onChange={(event) => handleUpdateDescription(event.target.value)}
                    inputProps={{
                      maxLength: 100
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    variant="outlined"
                    type={"number"}
                    aria-valuemin={0}
                    label={t("fieldName.count")}
                    value={userEquipmentCount}
                    fullWidth
                    onChange={(event) => handleUpdateCount(event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            </Grid>
            }
          </Grid>
        </ListItem>
        <Divider />
      </div>
    );
}
