import { LeasingGroupDto } from "./LeasingGroupDto";
import {
    LeasingGroupInput as LeasingGroupInputType,
    LeasingInput as LeasingInputType
} from "../../api/graphql/generated/schema";
import { LeasingDto } from "./LeasingDto";

const convertToLeasingGroupInput = (leasingGroup: LeasingGroupDto): LeasingGroupInputType => {
    return {
        type: leasingGroup.type,
        items: leasingGroup.items.map(item => {
            return {
                month: item.month,
                monthlyInstalment: item.monthlyInstalment,
                totalKmPerYear: item.totalKmPerYear,
            }})
    }
}

export const convertToLeasingInput = (leasing: LeasingDto): LeasingInputType | undefined => {
    if (leasing.serviceId){
        return {
            serviceId: leasing.serviceId,
            contraAccount: leasing.contraAccount,
            leasingGroups: leasing.leasingGroups.map(lg => convertToLeasingGroupInput(lg)),
        }
    } else {
        return undefined;
    }
}
