import { Box, Button, FormControl, Grid, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { CodetableSelect, EnumSelect } from '../../../../components';
import { VisibilityOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@mui/styles';
import { Brand, UsedVehicleCardFilter, UsedVehicleCardStateEnum } from '../../../../api/graphql/generated/schema';
import { setLoadingStatus } from '../../../../redux/loading';
import { usedVehicleDefinitionStates, usedVehicleInitFilterStates } from '../../../../utils/const';
import { useGetBrandsQuery } from '../../../../api/graphql/generated/hooks';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);


const initFilterState = {
    vin: '',
    brands: [],
    states: usedVehicleInitFilterStates,
}

export default function UsedVehicleCardsFilter(props : {handleSubmitFilter, appliedFilter: UsedVehicleCardFilter}) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const {handleSubmitFilter, appliedFilter} = props;

    const [vin, setFilterVin] = React.useState<string | null>(appliedFilter?.vin || initFilterState.vin);
    const [filterStates, setFilterStates] = React.useState<UsedVehicleCardStateEnum[]>(appliedFilter?.state || initFilterState.states);
    const [inputStates] = React.useState<UsedVehicleCardStateEnum[]>(usedVehicleDefinitionStates);
    const [filterBrandIds, setFilterBrandIds] = React.useState<string[]>(appliedFilter?.brand ||initFilterState.brands);
    const [inputBrands, setInputBrands] = React.useState<Brand[]>([]);

    const {loading: loadingMDBrands, data: gqlModelDefinitionBrands, error: errorMDBrands} = useGetBrandsQuery();
    const handleFilterVin = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
        setFilterVin(event.target.value);
    }

    useEffect(() => {
        if (!loadingMDBrands && gqlModelDefinitionBrands) {
            setInputBrands(gqlModelDefinitionBrands.getBrands);
        }

    }, [loadingMDBrands, gqlModelDefinitionBrands]);

    //Handle loading
    useEffect(() => {
        dispatch(setLoadingStatus(loadingMDBrands));
    }, [loadingMDBrands])
    //Handle errors
    if (errorMDBrands ) {
        return <div>Error! ${errorMDBrands?.message}</div>;
    }

    const resetFilter = () => {
        setFilterVin(initFilterState.vin);
        setFilterBrandIds(initFilterState.brands);
        setFilterStates(initFilterState.states);
    }

    const checkFilterStates = () => {
        if(filterStates.includes("" as UsedVehicleCardStateEnum)) {
            return undefined;
        }
        return filterStates;
    }

    const submitFilter = () => {
        const submittedFilter = {
            vin: vin ? vin : undefined,
            brand: filterBrandIds?.length !== 0 ? filterBrandIds : undefined,
            state: filterStates?.length !== 0 ? checkFilterStates() : undefined,
        };

        handleSubmitFilter(submittedFilter);
    }

    return (
        <Grid className="filter__holder">
            <Box mb={3}>
                <Typography variant="h5" className="text-left">
                    {t("pages.definitionTable.filters.title")}
                </Typography>
            </Box>
            <Grid container direction="row" justifyContent="flex-start" >
                <Grid item xs={12} md={2} className="text-left">
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Tooltip title={t("pages.usedVehicleCards.filter.tooltips.asterisk")}>
                            <TextField
                                id={"card-filter-vin"}
                                label={t("fieldName.vin")}
                                type={"text"}
                                name={"vin"}
                                value={vin}
                                onChange={handleFilterVin}
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className="text-left">
                    <EnumSelect inputValues={inputStates} filteredValues={filterStates} setFilteredValues={setFilterStates} label={t("fieldName.state")} enumLabelsPath="usedVehicleCard.states" multiselect={true} />
                </Grid>
                <Grid item xs={12} sm={2} className="text-left">
                    <CodetableSelect inputValues={inputBrands} filteredValues={filterBrandIds} setFilteredValues={setFilterBrandIds} label={t("fieldName.brand")} multiselect={true}/>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"

                component={'div'}
            >
                <Grid item xs={12} sm={12} className="text-right">
                    <Button className="reset-button" onClick={resetFilter}>{t("pages.definitionTable.filters.resetButton")}</Button>
                    <Button className="icon-button" onClick={submitFilter}>
                        <VisibilityOutlined />
                        {t('pages.definitionTable.filters.searchButton')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
