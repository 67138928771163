import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDefinitionPage as VehicleDefinitionPageType, VehicleDefinitionStateEnum } from '../../../../../api/graphql/generated/schema';
import { StyledTableCell, StyledTableRow, VehicleDefinitionStateColor } from '../../../../../components';
import { ReactComponent as SettingWheel } from '../../../../../components/icon/SettingWheel.svg'
import { formatUpdated } from '../../../../../utils/formatterHelper';
import { rowsPerPage } from '../../../../../utils/const';
import { displayEquipmentNameWithSortNumber } from '../../../../../components/modelEquipment/ModelEquipmentSelect';
import { Card, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import MoreVertPopover from '../../../../../components/common/MoreVertPopover';

export default function VehicleDefinitionTable(props: { dataPage: VehicleDefinitionPageType | undefined, handleChangePage, handleChangeRowsPerPage, updateArchiveState, duplicateVehicleDefinition, redirectToEdit }) {
    const { t } = useTranslation();

    const transmissionColumnTranslation = t('fieldName.transmission');

    // @ts-ignore
    return (
        <Card variant="outlined">
            <TableContainer component={Paper}>
                <Table aria-label="customized table" className="dataTable__holder">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t('fieldName.state')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.optionCodeShort')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.brand')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.line')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelYear')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.model')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelEquipment')}</StyledTableCell>
                            <StyledTableCell align="center">
                                <Tooltip title={transmissionColumnTranslation}>
                                    <SettingWheel/>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.fuel')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.engine')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelPriceBase')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.updated')}</StyledTableCell>
                            <StyledTableCell align="right" style={{paddingRight: '24px'}}>{t('fieldName.actions')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!props.dataPage || !props.dataPage.data || props.dataPage.data.length === 0) &&
                            <Typography variant={'subtitle1'}>
                                {t('datatable.empty')}
                            </Typography>
                        }
                        {props.dataPage?.data.map((row) => (
                            <StyledTableRow key={row?.id} onClick={(event) => {
                                event.stopPropagation();
                                event.nativeEvent.stopPropagation();
                                props.redirectToEdit(row)
                            }}>
                                <StyledTableCell align="center">
                                    <VehicleDefinitionStateColor state={row?.state}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleSpec?.optionCode}</StyledTableCell>
                                <StyledTableCell align="center">{row?.modelDefinition?.brand?.label}</StyledTableCell>
                                <StyledTableCell align="center">{row?.modelDefinition?.line}</StyledTableCell>
                                <StyledTableCell align="center">{row?.modelDefinition?.modelYear}</StyledTableCell>
                                <StyledTableCell align="center">{row?.modelDefinition?.model}</StyledTableCell>
                                <StyledTableCell
                                    align="center">{row?.equipment && displayEquipmentNameWithSortNumber(row.equipment.name, row.equipment.sortNumber)}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleSpec?.transmissionType?.label}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleSpec?.fuelType?.label}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleSpec?.engine}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleSpec?.price}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Tooltip title={row?.auditInfo?.updatedBy}>
                        <span>
                          {formatUpdated(row?.auditInfo)}
                        </span>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{paddingRight: '18px'}}>
                                    <MoreVertPopover
                                        rowId={row.id}
                                        rowState={row.state.toString()}
                                        archivedState={VehicleDefinitionStateEnum.Archived}
                                        updateArchiveState={props.updateArchiveState}
                                        duplicateVehicleCard={props.duplicateVehicleDefinition}
                                        row={row}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {props.dataPage?.pageInfo &&
                <TablePagination
                    component={'div'}
                    rowsPerPageOptions={rowsPerPage}
                    labelRowsPerPage={t('datatable.paging')}
                    count={props.dataPage.pageInfo.totalCount ? props.dataPage.pageInfo.totalCount : 0}
                    rowsPerPage={props.dataPage.pageInfo.limit ? props.dataPage.pageInfo.limit : 0}
                    page={props.dataPage.pageInfo.offset ? props.dataPage.pageInfo.offset : 0}
                    SelectProps={{ native: true }}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            }
        </Card>
    );
}
