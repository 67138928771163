import React from "react";
import {EnumSelect} from "../../components";
import { FormControl, Grid, TextField, Theme } from '@mui/material';
import {useTranslation} from "react-i18next";
import {VehicleDefinitionStateEnum} from "../../api/graphql/generated/schema";
import {vehicleDefinitionStates} from "../../utils/const";
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
    }),
);

export default function VehicleDefinitionDetailHeader(props: {
  vehicleDefinitionState: VehicleDefinitionStateEnum | null,
  model: string | null | undefined,
  equipmentName: string | null | undefined,
  optionCode: string | null | undefined,
}) {

  const { t } = useTranslation();
  const classes = useStyles();
  const {vehicleDefinitionState, model, equipmentName, optionCode} = props;

  const handleShrink = (input: string | null | undefined) => {
    return input ? {shrink: true} : {shrink: false};
  }

  return (
      <Grid container justifyContent={"space-evenly"}>
        <Grid item>
          <EnumSelect
              disabled={true}
              inputValues={vehicleDefinitionStates}
              label={t("fieldName.state")}
              enumLabelsPath="vehicleDefinition.definitionState.values"
              filteredValues={[vehicleDefinitionState]}
              multiselect={false}
              setFilteredValues={null}

          />
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
                variant="outlined"
                disabled={true}
                label={t("fieldName.model")}
                value={model}
                InputLabelProps={handleShrink(model)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
                variant="outlined"
                disabled={true}
                label={t("fieldName.modelEquipment")}
                value={equipmentName}
                InputLabelProps={handleShrink(equipmentName)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
                variant="outlined"
                disabled={true}
                label={t("fieldName.optionCode")}
                value={optionCode}
                InputLabelProps={handleShrink(optionCode)}
            />
          </FormControl>
        </Grid>
      </Grid>
  );

}
