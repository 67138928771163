import {
  Equipment as EquipmentType,
  UsedVehicleEquipmentItem,
} from '../../../../api/graphql/generated/schema';
import { v4 as uuidv4 } from 'uuid';
import { getCategoryLabel } from '../equipment.utils';
import { EquipmentItemShortenedType } from '../EquipmentViewer';

export class UserEquipmentItemDto {

  //Current Values
  private _isApplied: boolean;
  private _equipmentName: string;
  private _equipmentId: string;
  private _equipmentCategoryId: string;
  private _equipmentCategoryLabel: string;
  private _description: string | null | undefined;
  private _count: number | null | undefined;

  //Original values for
  //Model equipment item
  private readonly _idOriginal: string | null | undefined;
  private readonly _descriptionOriginal: string | null | undefined;
  private readonly _countOriginal: number | null | undefined;
  // Equipment Item
  private readonly _equipmentNameOriginal: string;
  private readonly _equipmentIdOriginal: string;
  //Equipment Category
  private readonly _equipmentCategoryIdOriginal: string;
  private readonly _equipmentCategoryLabelOriginal: string | null | undefined;


  constructor(id: string | null, isApplied: boolean, equipmentName: string, equipmentId: string, equipmentCategoryId: string, equipmentCategoryLabel: string, description: string | null | undefined, count: number | null | undefined) {
    this._isApplied = isApplied;
    this._equipmentName = equipmentName;
    this._equipmentId = equipmentId;
    this._equipmentCategoryId = equipmentCategoryId;
    this._equipmentCategoryLabel = equipmentCategoryLabel;
    this._description = description;
    this._count = count;

    this._idOriginal = id;
    this._equipmentNameOriginal = equipmentName;
    this._equipmentIdOriginal = equipmentId;
    this._equipmentCategoryIdOriginal = equipmentCategoryId;
    this._equipmentCategoryLabelOriginal = equipmentCategoryLabel;
    this._descriptionOriginal = description;
    this._countOriginal = count;

  }

  static convertEquipmentToUserEquipmentItemType (equipmentItem: EquipmentType): UserEquipmentItemDto {
    return new UserEquipmentItemDto(
        null,
        false,
        equipmentItem.name,
        equipmentItem.id,
        equipmentItem.category.id,
        getCategoryLabel(equipmentItem.category),
        null,
        null
    );
  }


  static convertUsedToUserEquipmentItemType (modelEquipmentItem: UsedVehicleEquipmentItem ): UserEquipmentItemDto {
    return new UserEquipmentItemDto(
        uuidv4(),
        true,
        modelEquipmentItem.equipment.name,
        modelEquipmentItem.equipment.id,
        modelEquipmentItem.equipment.category.id,
        getCategoryLabel(modelEquipmentItem.equipment.category),
        modelEquipmentItem.description,
        modelEquipmentItem.count,
    );
  }

  static convertToUserEquipmentItemType (modelEquipmentItem: EquipmentItemShortenedType): UserEquipmentItemDto {
    return new UserEquipmentItemDto(
        uuidv4(),
        true,
        modelEquipmentItem.name,
        modelEquipmentItem.id,
        modelEquipmentItem.category.id,
        getCategoryLabel({ id: modelEquipmentItem.category.id, label: modelEquipmentItem.category.label }),
        modelEquipmentItem.description,
        modelEquipmentItem.count,
    );
  }

  static duplicateToUserModelEquipmentItemType = (modelEquipmentItem: EquipmentItemShortenedType): UserEquipmentItemDto => {
    return new UserEquipmentItemDto(
        null,
        true,
        modelEquipmentItem.name,
        modelEquipmentItem.id,
        modelEquipmentItem.category.id,
        getCategoryLabel({ id: modelEquipmentItem.category.id, label: modelEquipmentItem.category.label }),
        modelEquipmentItem.description,
        modelEquipmentItem.count
    );
  }

  get id(){
    return this._idOriginal;
  }

  get equipmentId(){
    return this._equipmentIdOriginal;
  }

  get isApplied(): boolean {
    return this._isApplied;
  }

  set isApplied(value: boolean) {
    this._isApplied = value;
  }

  get equipmentCategoryLabel(): string {
    return this._equipmentCategoryLabel;
  }

  set equipmentCategoryLabel(value: string) {
    this._equipmentCategoryLabel = value;
  }

  get equipmentCategoryId(): string {
    return this._equipmentCategoryId;
  }

  set equipmentCategoryId(value: string) {
    this._equipmentCategoryId = value;
  }

  get equipmentName(): string {
    return this._equipmentName;
  }

  set equipmentName(value: string) {
    this._equipmentName = value;
  }

  get count(): number | null | undefined {
    return this._count;
  }

  set count(value: number | null | undefined) {
    this._count = value;
  }
  get description(): string | null | undefined {
    return this._description;
  }

  set description(value: string | null | undefined) {
    this._description = value;
  }

  private equipmentNameChanged() {
    return this._equipmentName !== this._equipmentNameOriginal;
  }

  private modelEquipmentDescriptionChanged() {
    return !(((this._description === null || this._description === "") && (this._descriptionOriginal === undefined || this._descriptionOriginal === null)) || this._descriptionOriginal === this._description)
  }

  private modelEquipmentCountChanged() {
    return !(((this._count === undefined || this._count === null) && (this._countOriginal === undefined || this._countOriginal === null)) || this._countOriginal === this._count)
  }

  equipmentCategoryChanged() {
    return this._equipmentCategoryId !== this._equipmentCategoryIdOriginal;
  }

  isAppliedChanged() {
    return (this._isApplied && !this._idOriginal) || (this._idOriginal && !this._isApplied) === true;
  }

  equipmentItemDataChanged() {
    return this.equipmentNameChanged() || this.equipmentCategoryChanged();
  }

  modelEquipmentItemDataChanged(){
    return this.modelEquipmentDescriptionChanged() || this.modelEquipmentCountChanged() || this.isAppliedChanged();
  }

  rowDataChanged(){
    return this.equipmentNameChanged() || this.equipmentCategoryChanged() || this.modelEquipmentDescriptionChanged() || this.modelEquipmentCountChanged() || this.isAppliedChanged();
  }

}
