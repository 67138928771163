import { DeferredCreditGroupItem as DeferredCreditGroupItemType } from '../../api/graphql/generated/schema';
import { isValidMonthlyInstalment, isValidPurchasePrice } from './CreditUtil';

export class DeferredCreditGroupItemDto {

  private _month: number | null;
  private _monthlyInstalment: number | null;
  private _advancedPayment: number | null;
  private _deferredPayment: number | null;
  private _purchasePrice: number | null;


  constructor(month: number | null, advancedPayment: number | null, monthlyInstalment: number | null, deferredPayment: number | null, purchasePrice: number | null) {
    this._month = month;
    this._monthlyInstalment = monthlyInstalment;
    this._advancedPayment = advancedPayment;
    this._deferredPayment = deferredPayment;
    this._purchasePrice = purchasePrice;
  }

  static convertFromDeferredCreditGroupItem(creditGroupItem: DeferredCreditGroupItemType) {
    const _month = creditGroupItem.month ? creditGroupItem.month : null;
    const _advancedPayment = creditGroupItem.advancePayment ? creditGroupItem.advancePayment : null;

    return new DeferredCreditGroupItemDto(_month, _advancedPayment,  creditGroupItem.monthlyInstalment, creditGroupItem.deferredPayment, creditGroupItem.purchasePrice);
  }


  get month(): number | null {
    return this._month;
  }

  set month(value: number | null) {
    this._month = value;
  }

  get monthlyInstalment(): number | null{
    return this._monthlyInstalment;
  }

  set monthlyInstalment(value: number | null) {
    this._monthlyInstalment = value;
  }

  get advancedPayment(): number | null {
    return this._advancedPayment;
  }

  set advancedPayment(value: number | null) {
    this._advancedPayment = value;
  }

  get deferredPayment(): number | null {
    return this._deferredPayment;
  }

  set deferredPayment(value: number | null) {
    this._deferredPayment = value;
  }

  get purchasePrice(): number | null {
    return this._purchasePrice;
  }

  set purchasePrice(value: number | null) {
    this._purchasePrice = value;
  }

  hasError(){
    return (!this._month && !this._advancedPayment && !this._monthlyInstalment) || (!isValidMonthlyInstalment(this._monthlyInstalment, false)) || (!isValidPurchasePrice(this._purchasePrice, false));
  }

  isValid(){
    return this._month && this._advancedPayment && this._monthlyInstalment
  }

}
