import { CreditTypeEnum } from '../../api/graphql/generated/schema';
import { DeferredCreditGroupDto } from './DeferredCreditGroupDto';
import { DeferredCreditGroupItemDto } from './DeferredCreditGroupItemDto';
import { CreditGroupDto } from './CreditGroupDto';
import { CreditGroupItemDto } from '..';

const generateCreditGroup = (type: CreditTypeEnum): CreditGroupDto => {
  let _items: Array<CreditGroupItemDto> = new Array<CreditGroupItemDto>();
  _items.push(new CreditGroupItemDto(24, 0.2, null));
  _items.push(new CreditGroupItemDto(24, 0.3, null));
  _items.push(new CreditGroupItemDto(24, 0.4, null));
  _items.push(new CreditGroupItemDto(36, 0.2, null));
  _items.push(new CreditGroupItemDto(36, 0.3, null));
  _items.push(new CreditGroupItemDto(36, 0.4, null));
  _items.push(new CreditGroupItemDto(36, 0.5, null));
  _items.push(new CreditGroupItemDto(48, 0.2, null));
  _items.push(new CreditGroupItemDto(48, 0.3, null));
  _items.push(new CreditGroupItemDto(60, 0.4, null));

  return new CreditGroupDto(type, _items);
}

const generateDeferredCreditGroup = (type: CreditTypeEnum): DeferredCreditGroupDto => {
  let _items: Array<DeferredCreditGroupItemDto> = new Array<DeferredCreditGroupItemDto>();
  _items.push(new DeferredCreditGroupItemDto(24, 0.2, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(24, 0.3, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(24, 0.4, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(36, 0.2, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(36, 0.3, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(36, 0.4, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(36, 0.5, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(48, 0.2, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(48, 0.3, null, null, null));
  _items.push(new DeferredCreditGroupItemDto(60, 0.4, null, null, null));

  return new DeferredCreditGroupDto(type, _items);
}


const generateCRNCreditGroup = (): CreditGroupDto => {
  return generateCreditGroup(CreditTypeEnum.Crn);
}

const generateCRNDeferredCreditGroup = (): DeferredCreditGroupDto => {
  return generateDeferredCreditGroup(CreditTypeEnum.Crn);
}

const generatePhysicalPersonCreditGroup = (): CreditGroupDto => {
  return generateCreditGroup(CreditTypeEnum.PhysicalPerson);
}

const generatePhysicalPersonDeferredCreditGroup = (): DeferredCreditGroupDto => {
  return generateDeferredCreditGroup(CreditTypeEnum.PhysicalPerson);
}

export const generateCreditGroupsEmptyStructure = (): Array<CreditGroupDto> => {

  let _creditGroups: Array<CreditGroupDto> = new Array<CreditGroupDto>();
  _creditGroups.push(generateCRNCreditGroup());
  _creditGroups.push(generatePhysicalPersonCreditGroup());

  return _creditGroups;
}

export const generateDeferredCreditGroupsEmptyStructure = (): Array<DeferredCreditGroupDto> => {

  let _deferredCreditGroups: Array<DeferredCreditGroupDto> = new Array<DeferredCreditGroupDto>();
  _deferredCreditGroups.push(generateCRNDeferredCreditGroup());
  _deferredCreditGroups.push(generatePhysicalPersonDeferredCreditGroup());

  return _deferredCreditGroups;
}

export const isValidMonthlyInstalment = (monthlyInstalment: number | null, mustExists: boolean) => {
  return (!mustExists && !monthlyInstalment) || (monthlyInstalment && (monthlyInstalment >= minMonthlyInstalment && monthlyInstalment <= maxMonthlyInstalment));
}

export const isValidPurchasePrice = (purchasePrice: number | null, mustExists: boolean) => {
  return (!mustExists && !purchasePrice) || (purchasePrice && (purchasePrice >= minPurchasePrice));
}

export const minPurchasePrice: number = 0;

export const minMonthlyInstalment: number = 1000;
export const maxMonthlyInstalment: number = 79999;
