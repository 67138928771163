import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ApolloError, MarketingTagSection } from "../../../components";
import {
  MarketingTagEnum,
  VehicleCardMarketing as VehicleCardMarketingType
} from "../../../api/graphql/generated/schema";
import {
  CreateVehicleCardMarketingMutationOptions,
  UpdateVehicleCardMarketingMutationOptions,
  useCreateVehicleCardMarketingMutation,
  useUpdateVehicleCardMarketingMutation
} from "../../../api/graphql/generated/hooks";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { isEmptyArray } from "../../../utils/functionHelper";
import { setLoadingStatus } from "../../../redux/loading";
import { ExpandMore, SaveOutlined } from '@mui/icons-material';

const initState = {
  marketingTags: [],
}

const initMarketingTags = (existingTags: Array<MarketingTagEnum> | null | undefined) => {
  return existingTags ? existingTags : initState.marketingTags;
}

const marketingTagsChanged = (originalTags: Array<MarketingTagEnum> | null | undefined, userMarketingTags: Array<MarketingTagEnum> | null | undefined) => {
  let changed;
  if (isEmptyArray(originalTags) && isEmptyArray(userMarketingTags)) {
    changed = false;
  } else if (originalTags && userMarketingTags) {
    if (originalTags.length === userMarketingTags.length){
      changed = originalTags.some((ot) => !userMarketingTags.includes(ot));
    } else {
      changed = true;
    }
  } else {
    changed = true;
  }

  return changed;

}

export default function VehicleCardMarketing(props: { cardId: string, inStoreCard: boolean, marketing: VehicleCardMarketingType | null | undefined, initiallyExpanded?: boolean, handleUpdate: Function }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [userMarketingTags, setUserMarketingTags] = React.useState<Array<MarketingTagEnum>>(initMarketingTags(props.marketing?.marketingTags));

  const [createVCMM, {loading: loadingCreateVCM, error: errorCreateVCM}] = useCreateVehicleCardMarketingMutation();
  const [updateVCMM, {loading: loadingUpdateVCM, error: errorUpdateVCM}] = useUpdateVehicleCardMarketingMutation();

  useEffect(() => {
    dispatch(setLoadingStatus(loadingCreateVCM || loadingUpdateVCM));
  }, [dispatch, loadingCreateVCM, loadingUpdateVCM]);

  const handleCreateVCM = () => {
    let inputOptions: CreateVehicleCardMarketingMutationOptions = {
      variables: {
        input: {
          cardId: props.cardId,
          marketingTags: userMarketingTags
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    createVCMM(inputOptions)
        .then(response => {
          if (response.data?.createVehicleCardMarketing?.marketing?.id) {
            props.handleUpdate();
            dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.marketing.created")));
          } else if (response.data?.createVehicleCardMarketing?.errors) {
            response.data.createVehicleCardMarketing.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard.marketing"))));
          } else if (response.errors) {
            response.errors.forEach((error) => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
          }
        })
        .then(() => setChanged(false))
        .catch(error => <ApolloError error={error}/>);
  }

  const handleUpdateVCM = (cardMarketingId: string) => {
    let inputOptions: UpdateVehicleCardMarketingMutationOptions = {
      variables: {
        input: {
          id: cardMarketingId,
          marketingTags: userMarketingTags
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateVCMM(inputOptions)
        .then(response => {
          if (response?.data?.updateVehicleCardMarketing?.marketing?.id) {
            props.handleUpdate();
            dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.marketing.updated")));
          } else if (response.data?.updateVehicleCardMarketing?.errors) {
            response.data.updateVehicleCardMarketing.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard.marketing"))));
          } else if (response.errors) {
            response.errors.forEach((error) => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
          }
        })
        .then(() => setChanged(false))
        .catch(error => <ApolloError error={error}/>);
  }

  const handleSubmit = () => {
    if (props.marketing?.id){
      handleUpdateVCM(props.marketing?.id);
    } else {
      handleCreateVCM();
    }
  }

  const handleReset = () => {
    setUserMarketingTags(initMarketingTags(props.marketing?.marketingTags));
    setChanged(false);
  }

  const handleMarketingTagAdd = (marketingTagId: MarketingTagEnum) => {
    const newUserTags = [...userMarketingTags.filter((mt) => mt !== marketingTagId), marketingTagId]
    setUserMarketingTags(newUserTags);
    setChanged(marketingTagsChanged(props.marketing?.marketingTags, newUserTags));
  };

  const handleMarketingTagRemove = (marketingTagId: MarketingTagEnum) => {
    const newUserTags = userMarketingTags.filter((mt) => mt !== marketingTagId);
    setUserMarketingTags(newUserTags);
    setChanged(marketingTagsChanged(props.marketing?.marketingTags, newUserTags));
  };

  if (errorCreateVCM) return <ApolloError error={[errorCreateVCM]} />;
  if (errorUpdateVCM) return <ApolloError error={[errorUpdateVCM]} />;

  return (
      <form id="extra-equipment-form">
        <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)} >
          <AccordionSummary
              sx={{ flexDirection: 'row-reverse' }}
              expandIcon={<ExpandMore color='secondary' />}>
            <Grid container justifyContent={"space-between"}>
              <Grid item >
                <h2>{t("pages.vehicleCards.detail.marketing.title")}</h2>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <MarketingTagSection inStoreCard={props.inStoreCard} appliedMarketingTags={userMarketingTags} handleAdd={handleMarketingTagAdd} handleRemove={handleMarketingTagRemove} />
          </AccordionDetails>
          <AccordionActions>
            <Grid container justifyContent={"space-between"} direction={"column"}>
              <Grid container xs={12} sm={12} justifyContent={"flex-end"}>
                <Button className="reset-button" onClick={handleReset}>{t('pages.vehicleCards.detail.actions.reset')}</Button>
                <Button className="icon-button" onClick={handleSubmit} disabled={!changed}><SaveOutlined />  {props.marketing?.id ? t('pages.vehicleCards.detail.actions.save') : t('pages.vehicleCards.detail.marketing.actions.create')}</Button>
              </Grid>
            </Grid>
          </AccordionActions>
        </Accordion>
      </form>

  );

}
