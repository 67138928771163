import React from 'react';
import { LeasingGroupDto, LeasingGroupItemDto } from '../../../../modelView';
import { useTranslation } from 'react-i18next';
import {
    isValidMonthlyInstalment,
    maxMonthlyInstalment,
    minMonthlyInstalment,
} from '../../../../modelView/leasing/LeasingUtil';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';

export default function LeasingGroupTab(props: { leasingGroup: LeasingGroupDto, handleUpdate: Function }) {

    const { t } = useTranslation();

    const removeRowTooltip = t("actions.remove");
    const addRowTooltip = t("actions.add");


    const handleUpdate = (leasingGroup: LeasingGroupDto) => {
        props.handleUpdate(leasingGroup)
    }

    const removeRow = (index: number) => {
        const newItems = props.leasingGroup.items.filter((item, idx) => idx !== index);
        handleUpdate(new LeasingGroupDto(props.leasingGroup.type, newItems));
    }

    const addRow = () => {
        const newItems = [...props.leasingGroup.items, new LeasingGroupItemDto(null, null, null)];
        handleUpdate(new LeasingGroupDto(props.leasingGroup.type, newItems));
    }

    const handleItemUpdate = (newItem: LeasingGroupItemDto, index: number) => {
        const newItems = [...props.leasingGroup.items];
        newItems[index] = newItem;
        handleUpdate(new LeasingGroupDto(props.leasingGroup.type, newItems));
    }

    const updateMonth = (item: LeasingGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: LeasingGroupItemDto = new LeasingGroupItemDto(updatedValue, item.totalKmPerYear, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const updateTotalKmPerYear = (item: LeasingGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: LeasingGroupItemDto = new LeasingGroupItemDto(item.month, updatedValue, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const multiplyTotalKmPerYear = (item: LeasingGroupItemDto, index: number) => {
        const updatedValue: number | null = item && item.totalKmPerYear && item.totalKmPerYear * 1000;
        const updatedData: LeasingGroupItemDto = new LeasingGroupItemDto(item.month, updatedValue, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const divideTotalKmPerYear = (item: LeasingGroupItemDto, index: number) => {
        const updatedValue: number | null = item && item.totalKmPerYear && item.totalKmPerYear / 1000;
        const updatedData: LeasingGroupItemDto = new LeasingGroupItemDto(item.month, updatedValue, item.monthlyInstalment);
        handleItemUpdate(updatedData, index);
    }

    const updateMonthlyInstalment = (item: LeasingGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: LeasingGroupItemDto = new LeasingGroupItemDto(item.month, item.totalKmPerYear, updatedValue);
        handleItemUpdate(updatedData, index);
    }

    return (
        <TabPanel value={props.leasingGroup.type}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.leasing.leasingGroupTable.month')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.leasing.leasingGroupTable.totalKmPerYear_km')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.leasing.leasingGroupTable.monthlyInstalment')}
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip title={addRowTooltip}>
                                    <IconButton onClick={addRow}>
                                        <AddOutlined/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.leasingGroup.items.map((item, index) =>
                            <TableRow key={"leasing-group-item-row" + index}>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.month}
                                        value={item.month}
                                        onChange={event => updateMonth(item, index, event.target.value)}
                                        type={"text"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.totalKmPerYear}
                                        value={item.totalKmPerYear}
                                        onBlur={() => multiplyTotalKmPerYear(item, index)}
                                        onFocus={() => divideTotalKmPerYear(item, index)}
                                        onChange={event => updateTotalKmPerYear(item, index, event.target.value)}
                                        type={"number"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        className={"w-100"}
                                        error={!isValidMonthlyInstalment(item.monthlyInstalment, true)}
                                        value={item.monthlyInstalment}
                                        onChange={event => updateMonthlyInstalment(item, index, event.target.value)}
                                        type={"number"}
                                        InputProps={{ inputProps: { min: minMonthlyInstalment, max: maxMonthlyInstalment } }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={removeRowTooltip}>
                                        <IconButton onClick={() => removeRow(index)}>
                                            <DeleteOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    );
}
