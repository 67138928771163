import { FiberManualRecord, LocalTaxiOutlined } from '@mui/icons-material';
import { Card, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UpdateVehicleCardStateMutationOptions, useGetVehicleCardsQuery, useUpdateVehicleCardStateMutation } from '../../../../api/graphql/generated/hooks';
import { GetVehicleCardsQueryVariables } from '../../../../api/graphql/generated/operations';
import { VehicleCardStateEnum, VehicleDefinitionStateEnum } from '../../../../api/graphql/generated/schema';
import { ApolloError, ColorsViewer, StyledTableCell, StyledTableRow, VehicleDeliveryView } from '../../../../components';
import { ReactComponent as SettingWheel } from '../../../../components/icon/SettingWheel.svg';
import { ColorViewerDto } from '../../../../modelView';
import { setLoadingStatus } from '../../../../redux/loading';
import { setSnackbar, SnackbarStates } from '../../../../redux/snackbar';
import { rowsPerPage } from '../../../../utils/const';
import { getApolloErrorMessages, getBusinessErrorMessages, getGQLErrorMessages } from '../../../../utils/graphGL/graphQLHelper';
import { VehicleCardPageFilterType } from '../vehicleCardOverview';
import MoreVertPopover from '../../../../components/common/MoreVertPopover';
import { AppPageNameRoutes } from '../../../../routes/paths';


export default function VehicleCardOverviewTable(props: { pageFilter: VehicleCardPageFilterType, handlePageFilter: Function }) {
    const { t } = useTranslation();
    const { pageFilter, handlePageFilter } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const [vehicleCardsVariables] = useState<GetVehicleCardsQueryVariables>(pageFilter);
    const { data: gqlVehicleCards, error: errorVehicleCards, loading: loadingVehicleCards, fetchMore, refetch: refetchVC } = useGetVehicleCardsQuery({ variables: vehicleCardsVariables });
    const [updateVehicleCardStateMutation, { error: errorUpdateVCS }] = useUpdateVehicleCardStateMutation();
    const [vehicleCards, setVehicleCards] = React.useState<any>(null);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingVehicleCards));
        if (gqlVehicleCards) {
            setVehicleCards(gqlVehicleCards.getVehicleCards);
        }
    }, [gqlVehicleCards, loadingVehicleCards]);

    const transmissionColumnTranslation = t('fieldName.transmission');
    const insStoreCarColumnTranslation = t('fieldName.inStoreCar');

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(setLoadingStatus(true));
        let changedInput = { offset: newPage }
        fetchMore({ variables: changedInput })
            .then(response => {
                setVehicleCards(response.data.getVehicleCards);
            })
            .finally(() => dispatch(setLoadingStatus(false)));

        handlePageFilter(pageFilter.filter, pageFilter.limit, newPage, pageFilter.sort);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoadingStatus(true));
        const limit: number = parseInt(event.target.value);
        let changedInput: GetVehicleCardsQueryVariables = {
            filter: pageFilter.filter,
            sort: pageFilter.sort,
            offset: pageFilter.offset,
            limit: limit,
        }

        fetchMore({ variables: changedInput })
            .then(response => {
                setVehicleCards(response.data.getVehicleCards);
            })
            .finally(() => dispatch(setLoadingStatus(false)));

        handlePageFilter(pageFilter.filter, limit, pageFilter.offset, pageFilter.sort);
    }

    const updateArchiveState = (archive: boolean, row) => {
        if (row) {
            dispatch(setLoadingStatus(true));
            let inputOptions: UpdateVehicleCardStateMutationOptions = {
                variables: {
                    input: {
                        cardId: row.id,
                        state: archive ? VehicleCardStateEnum.Archived : VehicleCardStateEnum.Unarchive,
                    },
                },
                onError: error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))),
            }

            updateVehicleCardStateMutation(inputOptions)
                .then(response => {
                    if (response?.data?.updateVehicleCardState?.vehicleCard) {
                        let successMsg = archive ? 'userMessages.vehicleCard.archiveSuccessful' : 'userMessages.vehicleCard.restoreSuccessful';
                        dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t(successMsg)));
                    } else if (response?.data?.updateVehicleCardState?.errors) {
                        response.data.updateVehicleCardState.errors.forEach(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                    } else if (response.errors) {
                        response.errors.forEach(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                    }
                })
                .then(async () => {
                    await refetchVC()
                })
                .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)))
                .finally(() => dispatch(setLoadingStatus(false)));
        } else {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('userErrors.vehicleCard.missingVehicleCard')))
        }
    };

    if (errorVehicleCards) return <ApolloError error={[errorVehicleCards]}/>;
    if (errorUpdateVCS) return <ApolloError error={[errorUpdateVCS]}/>;

    const stateIconHandler = (state: VehicleCardStateEnum) => {
        let tooltipTitle = t('pages.vehicleCards.states.' + state);
        let className = '';
        switch (state) {
            case VehicleCardStateEnum.InProgress:
            case VehicleCardStateEnum.Approved:
            case VehicleCardStateEnum.AwaitApproval:
                className = 'orange-dot';
                break;
            case VehicleCardStateEnum.Published:
                className = 'green-dot';
                break;
            case VehicleCardStateEnum.Sold:
                className = 'grey-dot';
                break;
            case VehicleCardStateEnum.Archived:
                className = 'red-dot';
                break;
        }
        return (
            <Tooltip title={tooltipTitle}>
                <FiberManualRecord className={className}/>
            </Tooltip>
        );
    }

    const redirectToEdit = (row) => {
        let path = AppPageNameRoutes.VEHICLE_NEW_CARD_DETAIL.pageRoute.replace(':vehicleCardId', row.id);
        history.push(path);
    }

    return (
        <Card variant="outlined" className="card-overview">
            <TableContainer component={Paper}>
                <Table className="dataTable__holder" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t('fieldName.state')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.commission')}</StyledTableCell>
                            <StyledTableCell align="center">
                                <Tooltip title={insStoreCarColumnTranslation}>
                                    <LocalTaxiOutlined />
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.brand')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.line_short')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelYear_short')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.model')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.equipment')}</StyledTableCell>
                            <StyledTableCell align="center">
                                <Tooltip title={transmissionColumnTranslation}>
                                    <SettingWheel/>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.color')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.offerValidTo')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.deliveryDate')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.updated')}</StyledTableCell>
                            <StyledTableCell align="right" style={{paddingRight: '24px'}}>{t('fieldName.actions')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!vehicleCards?.data.length &&
                            <Typography variant={'subtitle1'}>
                                {t('datatable.empty')}
                            </Typography>
                        }
                        {vehicleCards?.data.map((row) => (
                            <StyledTableRow key={row?.id} onClick={() => redirectToEdit(row)}>
                                <StyledTableCell align="center">
                                    {stateIconHandler(row.state)}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row?.commission}</StyledTableCell>
                                <StyledTableCell align="center">{row?.inStoreCard ? t('fieldName.yes') : t('fieldName.no')}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.modelDefinition.brand.label}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.modelDefinition.line}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.modelDefinition.modelYear}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.modelDefinition.model}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.equipment.name}</StyledTableCell>
                                <StyledTableCell align="center">{row?.definition.vehicleSpec.transmission.charAt(0).toUpperCase()}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <ColorsViewer inputValues={row.colors.map((cc) => ColorViewerDto.constructFromVehicleCardColor(cc))} displayMissingInfo={true}
                                                  maxDisplayed={2}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row?.offerValidTo ? new Date(row.offerValidTo).toLocaleDateString('cs-CZ') : t('userMessages.noLimit')}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <VehicleDeliveryView deliveryDate={row?.deliveryDate} textInput={false}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Tooltip title={row?.auditInfo?.updatedBy}>
                      <span>
                        {new Date(row?.auditInfo?.updated).toLocaleDateString('cs-CZ')}
                      </span>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{paddingRight: '18px'}}>
                                    <MoreVertPopover
                                        rowId={row.id}
                                        rowState={row.state.toString()}
                                        archivedState={VehicleDefinitionStateEnum.Archived}
                                        updateArchiveState={updateArchiveState}
                                        row={row}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {vehicleCards?.pageInfo &&
                <TablePagination
                    component={'div'}
                    rowsPerPageOptions={rowsPerPage}
                    labelRowsPerPage={t('datatable.paging')}
                    count={vehicleCards?.pageInfo.totalCount ? vehicleCards.pageInfo.totalCount : 0}
                    rowsPerPage={vehicleCards?.pageInfo.limit ? vehicleCards.pageInfo.limit : 0}
                    page={vehicleCards?.pageInfo.offset ? vehicleCards.pageInfo.offset : 0}
                    SelectProps={{ native: true }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            }
        </Card>
    )
}
