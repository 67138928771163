import { Box, Card, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Theme, Tooltip, Typography } from '@mui/material';
import { CodetableDto, CodetableStateEnum } from '../../../api/graphql/generated/schema';
import { FiberManualRecord } from '@mui/icons-material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCell, StyledTableRow } from '../../common';
import MoreVertPopover from '../../common/MoreVertPopover';
import { createStyles, makeStyles } from '@mui/styles';
import { BodyTable, CodetableTableHead, Order } from '../../../utils/typings';
import { visuallyHidden } from '@mui/utils';
import { getComparator, stableSort } from './codetable.utils';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        redDot: {
            fill: '#B84A45FF'
        },
        greenDot: {
            fill: '#72B562FF'
        },
        sortLabel: {
            '&:active': {
                color: theme.palette.common.white,
            },
            '&:hover': {
                color: theme.palette.common.white,
            },
            color: `${theme.palette.common.white} !important`,
            '& .MuiSvgIcon-root': {
                color: 'inherit !important',
            },
        },
    }),
);

const initState = {
    rowsPerPage: 10,
    page: 0,
    order: 'asc',
    orderBy: 'state'
}


const headCells: Array<CodetableTableHead> = [
    {
        id: 'state',
        sortable: false,
    },
    {
        id: 'title',
        sortable: true,
        codetableProperty: 'label',
    },
    {
        id: 'code',
        sortable: true,
        codetableProperty: 'code',
    },
    {
        id: 'vehicleType',
        sortable: true,
        codetableProperty: 'vehicleTypeLabel',
    },
    {
        id: 'actions',
        sortable: false,
    }
];

export default function CodetableDepenItemsTable (
    props: {
        data: Array<BodyTable>,
        handleUpdateState: Function,
        handleDialogOpen: Function,
    }) {

    const { t } = useTranslation();
    const classes = useStyles();

    const [order, setOrder] = React.useState<Order>(initState.order as Order);
    const [orderBy, setOrderBy] = React.useState<keyof CodetableDto>(initState.orderBy as keyof CodetableDto);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initState.rowsPerPage);

    const stateIconHandler = (state: CodetableStateEnum | null | undefined) => {
        let tooltipTitle = t('codetables.states.' + state);
        let className = '';
        switch (state) {
            case CodetableStateEnum.Active:
                className = classes.greenDot;
                break;
            case CodetableStateEnum.Archived:
                className = classes.redDot;
                break;
        }
        return (
            <Tooltip title={tooltipTitle}>
                <FiberManualRecord className={className}/>
            </Tooltip>
        );
    }

    interface EnhancedTableProps {
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CodetableDto) => void;
        order: Order;
        orderBy: string;
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler =
            (property: keyof CodetableDto) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.id === 'actions' ? 'right' : 'left'}
                            style={{paddingRight: `${headCell.id === 'actions' ? '24px' : null}`, paddingLeft: `${headCell.id === 'state' ? '18px' : null}` }}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sortable ?
                                <TableSortLabel
                                    active={orderBy === headCell.codetableProperty}
                                    direction={orderBy === headCell.codetableProperty ? order : 'asc'}
                                    className={classes.sortLabel}
                                    onClick={createSortHandler(headCell.codetableProperty as keyof CodetableDto)}
                                >
                                    {t(`fieldName.${headCell.id}`)}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                                : t(`fieldName.${headCell.id}`)}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof CodetableDto,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return(
        <Card variant="outlined" className="card-overview" sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" className="dataTable__holder">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {(!props.data || props.data.length === 0) &&
                            <Typography variant={'subtitle1'}>
                                {t('datatable.empty')}
                            </Typography>
                        }
                        {stableSort(props.data, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                            <StyledTableRow key={row?.id}>
                                <StyledTableCell align="left" style={{paddingLeft: '18px'}}>
                                    {stateIconHandler(row.state)}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.label}</StyledTableCell>
                                <StyledTableCell align="left">{row?.code}</StyledTableCell>
                                <StyledTableCell align="left">{row.vehicleTypeLabel}</StyledTableCell>
                                <StyledTableCell align="right" style={{paddingRight: '18px'}}>
                                    <MoreVertPopover
                                        rowId={row.id}
                                        rowState={row.state?.toString()}
                                        archivedState={CodetableStateEnum.Archived}
                                        activeState={CodetableStateEnum.Active}
                                        updateArchiveState={props.handleUpdateState}
                                        editRow={props.handleDialogOpen}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={props.data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    )
}
