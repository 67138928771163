import { LeasingGroup as LeasingGroupType, LeasingTypeEnum } from "../../api/graphql/generated/schema";
import { LeasingGroupItemDto } from "./LeasingGroupItemDto";

export class LeasingGroupDto {

  private readonly _type: LeasingTypeEnum;
  private _items: Array<LeasingGroupItemDto>;


  constructor(type: LeasingTypeEnum, items:  Array<LeasingGroupItemDto>) {
    this._type = type;
    this._items = items;
  }

  static convertFromLeasingGroup(leasingGroup: LeasingGroupType) {
    let _items = leasingGroup.items ? leasingGroup.items.map(item => LeasingGroupItemDto.convertFromLeasingGroupItem(item)) : [];
    return new LeasingGroupDto(leasingGroup.type, _items);
  }

  get type(): LeasingTypeEnum {
    return this._type;
  }

  get items():  Array<LeasingGroupItemDto> {
    return this._items;
  }

  set items(value:  Array<LeasingGroupItemDto>) {
    this._items = value;
  }

  hasAnyInvalidItem(){
    return this._items && this._items.some((item) => !item.isValid());
  }
}
