import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import { ILoadingState } from '../../modelView';

const AutoCmsLoading = () => {
  const showLoading = useSelector((state: ILoadingState) => state.loading.loadingStatus);

  const LoadingElement = () => {
    return(
      <div className="module-loading">
        <CircularProgress />
      </div>
    )
  }

  return (
    ( showLoading ? <LoadingElement /> : <></> )
  );
};

export default AutoCmsLoading;
