import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ExpandMore } from '@mui/icons-material';
import { GeneralSeller } from '../../../utils/typings';

// TODO add option to remove seller
export default function UsedCardSeller(props: { seller: GeneralSeller | null | undefined, initiallyExpanded?: boolean, handleUpdate: Function }) {

    const {t} = useTranslation();

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded);

    const changeName = (value: string) => {
        if (props.seller) {
            props.seller.name = value;
            props.handleUpdate(props.seller);
        } else {
            props.handleUpdate({name : value});
        }
    }

    const changeAddress = (value: string) => {
        if (props.seller) {
            props.seller.address = value;
            props.handleUpdate(props.seller);
        } else {
            props.handleUpdate({address : value});
        }
    }

    const changePhoneNumber = (value: string) => {
        if (props.seller) {
            props.seller.phone = value;
            props.handleUpdate(props.seller);
        } else {
            props.handleUpdate({ phone: value });
        }
    }

    const changeEmail = (value: string) => {
        if (props?.seller) {
            props.seller.email = value;
            props.handleUpdate(props.seller);
        } else {
            props.handleUpdate({ email: value });
        }
    }
    return (
        <form id="extra-equipment-form">
            <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)} >
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandMore color='secondary' />}
                >
                    <Grid container justifyContent={"space-between"}>
                        <Grid item >
                            <h2>{t("pages.usedVehicleCards.detail.seller.title")}</h2>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="mt-3 w-100">
                        <strong className="text-left d-block mb-4">{t('pages.usedVehicleCards.detail.seller.contact')}</strong>
                    </div>
                    <Grid container
                          direction="row"
                          >
                        <Grid item xs={12} sm={4} >
                            <FormControl className="outlined">
                                <TextField
                                    id="fullName"
                                    label={t('fieldName.fullName')}
                                    type="text"
                                    value={props.seller?.name}
                                    onChange={event => changeName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <FormControl className="outlined">
                                <TextField
                                    id="phone"
                                    label={t('fieldName.phone')}
                                    type="number"
                                    value={props.seller?.phone}
                                    onChange={event => changePhoneNumber(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <FormControl className="outlined">
                                <TextField
                                    id="email"
                                    label={t('fieldName.email')}
                                    type="text"
                                    value={props.seller?.email}
                                    onChange={event => changeEmail(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FormControl className="outlined">
                                <TextField
                                    id="address"
                                    label={t('fieldName.address')}
                                    type="text"
                                    value={props.seller?.address}
                                    onChange={event => changeAddress(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </form>

    );

}
