import {Bonus} from "../api/graphql/generated/schema";
import {FinanceGroupDto} from "./finance/FinanceGroupDto";

export class CardFinanceDto {

  private readonly _cardId: string;
  private _id: string | null = null;
  private _modelPrice: number = 0;
  private _totalEquipment: number = 0;
  private _autoCmsDiscountCRN: number = 0;
  private _autoCmsDiscountPP: number = 0;
  private _coinsurance: number | null = 0.1;
  private _bonuses: Array<Bonus> = [];
  private _financeGroups: Array<FinanceGroupDto> = [];

  constructor(cardId: string) {
    this._cardId = cardId;
  }

  get cardId(): string {
    return this._cardId;
  }

  get id(): string | null {
    return this._id;
  }

  set id(value: string | null) {
    this._id = value;
  }

  get modelPrice(): number {
    return this._modelPrice;
  }

  set modelPrice(value: number) {
    this._modelPrice = value;
  }

  get totalEquipment(): number {
    return this._totalEquipment;
  }

  set totalEquipment(value: number) {
    this._totalEquipment = value;
  }

  get autoCmsDiscountCRN(): number {
    return this._autoCmsDiscountCRN;
  }

  set autoCmsDiscountCRN(value: number) {
    this._autoCmsDiscountCRN = value;
  }

  get autoCmsDiscountPP(): number {
    return this._autoCmsDiscountPP;
  }

  set autoCmsDiscountPP(value: number) {
    this._autoCmsDiscountPP = value;
  }

  get coinsurance(): number | null {
    return this._coinsurance;
  }

  set coinsurance(value: number | null) {
    this._coinsurance = value;
  }

  get bonuses(): Array<Bonus> {
    return this._bonuses;
  }

  set bonuses(value: Array<Bonus>) {
    this._bonuses = value;
  }

  get financeGroups(): Array<FinanceGroupDto> {
    return this._financeGroups;
  }

  set financeGroups(value: Array<FinanceGroupDto>) {
    this._financeGroups = value;
  }
}
