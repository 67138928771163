import React, { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { useTranslation } from "react-i18next";
import { VehicleCardColor as VehicleCardColorType } from "../../../api/graphql/generated/schema";
import {
  DeleteImageMutationOptions,
  UpdateVehicleCardColorMutationOptions,
  UploadImageMutationOptions,
  useDeleteImageMutation,
  useUpdateVehicleCardColorMutation,
  useUploadImageMutation
} from "../../../api/graphql/generated/hooks";
import { setLoadingStatus } from "../../../redux/loading";
import { useDispatch } from "react-redux";
import { ApolloError, ColorImage, ColorsViewer, EmptyDataInfo } from '../../../components';
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { ColorViewerDto } from "../../../modelView";
import { Add, ExpandMore } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { DropzoneDialogBase, FileObject } from 'react-mui-dropzone';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default function GeneralCarPhotos(props: { cardColors: Array<VehicleCardColorType>, initiallyExpanded?: boolean, handleUpdate: Function }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [colorValue, setColorValue] = React.useState<string>(props.cardColors.length > 0 ? props.cardColors[0].id : "");
  const [openDropZoneDialog, setOpenDropZoneDialog] = React.useState<boolean>(false);
  const [fileObjects, setFileObjects] = React.useState<FileObject[]>([]);
  const [uploadImageMutation, {loading: loadingUploadImageMutation}] = useUploadImageMutation();
  const [deleteImageMutation, {loading: loadingDeleteImageMutation}] = useDeleteImageMutation();
  const [updateCardColorMutation, {loading: loadingUpdateCardColorMutation}] = useUpdateVehicleCardColorMutation();

  useEffect(() => {
    dispatch(setLoadingStatus(loadingUploadImageMutation || loadingUpdateCardColorMutation));
  }, [dispatch, loadingUploadImageMutation, loadingDeleteImageMutation, loadingUpdateCardColorMutation]);

  const handleChangeMainImage = (cardColorId: string, imageId: string) => {
    let inputOptions: UpdateVehicleCardColorMutationOptions = {
      variables: {
        input: {
          cardColorId: cardColorId,
          mainImageId: imageId
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateCardColorMutation(inputOptions)
    .then(response => {
      if (response?.data?.updateVehicleCardColor?.cardColor?.id) {
        dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.mainImageChanged")));
        props.handleUpdate();
      } else if (response?.data?.updateVehicleCardColor?.errors) {
        response.data.updateVehicleCardColor.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
      } else if(response.errors){
        response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
      }
    })
    .catch(error => <ApolloError error={error}/>);
  }

  const handleUploadImage = (cardColorId: string, base64: string) => {
    let inputOptions: UploadImageMutationOptions = {
      variables: {
        input: {
          cardColorId: cardColorId,
          content: base64
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    uploadImageMutation(inputOptions)
      .then(response => {
        if (response?.data?.uploadImage?.imageId) {
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageUploaded")));
          props.handleUpdate();
        } else if (response?.data?.uploadImage?.errors) {
          response.data.uploadImage.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
        } else if(response.errors){
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .catch(error => <ApolloError error={error}/>);
  }

  const handleRemoveImage = (imageId: string) => {
    let inputOptions: DeleteImageMutationOptions = {
      variables: {
        input: {
          imageId: imageId
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    deleteImageMutation(inputOptions)
      .then(response => {
        if (response?.data?.deleteImage?.success) {
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageDeleted")));
          props.handleUpdate();
        } else if (response?.data?.deleteImage?.errors) {
          response.data.deleteImage.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
        } else if(response.errors){
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .catch(error => <ApolloError error={error}/>);
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, data: string) => {
    setColorValue(data);
  }

  const handleSaveImage = (cardColorId: string, file: FileObject) => {
    toBase64(file.file)
    .then(b64 => b64 as string)
    .then(b64String => handleUploadImage(cardColorId, b64String));
  }

  const handleOnSaveImages = (cardColorId: string) => {
    fileObjects.forEach(fileObject => handleSaveImage(cardColorId, fileObject));
    setFileObjects([]);
    setOpenDropZoneDialog(false);
  }

  return (
    <Accordion className="card-section" variant="outlined" expanded={accordionExpanded}
               onChange={(event, expanded) => setAccordionExpanded(expanded)}>
      <AccordionSummary
          sx={{ flexDirection: 'row-reverse' }}
        expandIcon={<ExpandMore color='secondary' />}
      >
        <h2>{t("pages.vehicleCards.detail.photos.title")}</h2>
      </AccordionSummary>
      <AccordionDetails>
        <TabContext value={colorValue}>
        <Box mb={2}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12}>
              <Tabs
                value={colorValue}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                {!props.cardColors.length && <EmptyDataInfo messageToDisplay={t("userError.vehicleCard.photos.missingColor")}/>}
                {props.cardColors.map((item) => (
                    <Tab value={item.id} label={item.modelColor.name} icon={<ColorsViewer inputValues={[ColorViewerDto.constructFromVehicleCardColor(item)]} displayMissingInfo={false} />} wrapped={true} />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Box>
        {props.cardColors.map((item) => (
            <TabPanel value={item.id}>
              <Box mb={2}>
                <Grid container direction={"row"} justifyContent={"flex-end"} sm={12}>
                  <Button variant="outlined" onClick={() => setOpenDropZoneDialog(true)}>
                    <Add/>{t("actions.add")}
                  </Button>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container direction={"row"} justifyContent={"space-evenly"} sm={12}>
                {item.images?.map(img => (
                    <Grid item>
                      <ColorImage imageId={img.id} imageUrl={img.publicUrl} isMainImage={img.id === item.mainImageId} handleUpdate={(imgId) => handleChangeMainImage(item.id, imgId)} handleRemove={handleRemoveImage}/>
                    </Grid>
                ))}
                </Grid>
                <DropzoneDialogBase
                    dialogTitle={t("pages.vehicleCards.detail.photos.dropzone.title")}
                    acceptedFiles={['image/*']}
                    fileObjects={fileObjects}
                    cancelButtonText={t("actions.discard")}
                    submitButtonText={t("actions.save")}
                    maxFileSize={5000000}
                    filesLimit={10}
                    dropzoneText={t("pages.vehicleCards.detail.photos.dropzone.tooltip")}
                    open={openDropZoneDialog}
                    onAdd={newFileObjs => {
                      setFileObjects([...fileObjects, ...newFileObjs]);
                    }}
                    onDelete={(deleteFileObj, index) => {
                      setFileObjects(fileObjects.filter(fo => fo.file.name !== deleteFileObj.file.name));
                    }}
                    onClose={() => {
                      setFileObjects([]);
                      setOpenDropZoneDialog(false);
                    }}
                    onSave={() => handleOnSaveImages(item.id)}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                />
              </Box>
            </TabPanel>
        ))}
        </TabContext>
      </AccordionDetails>
    </Accordion>
  );
}
