import React, { useEffect } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { DeferredCreditGroupDto } from '../../../../modelView';
import { useTranslation } from 'react-i18next';
import DeferredCreditGroupTab from './DeferredCreditGroupTab';
import { DoneOutlined, WarningOutlined } from '@mui/icons-material';
import { TabContext } from '@mui/lab';

const creditGroupTabOrder = {
  CRN: 0,
  PHYSICAL_PERSON: 1,
}

const creditGroupTabComparator = (o1: DeferredCreditGroupDto, o2: DeferredCreditGroupDto) => {
  return creditGroupTabOrder[o1.type] <= creditGroupTabOrder[o2.type] ? -1 : 1;
}

export default function DeferredCreditGroups(props:{ deferredCreditGroups: Array<DeferredCreditGroupDto>, handleUpdate: Function }) {

  props.deferredCreditGroups.sort(creditGroupTabComparator);

  const {t} = useTranslation();

  const [selectedDeferredCreditGroup, setSelectedDeferredCreditGroup] = React.useState<string>(props.deferredCreditGroups.length > 0 ? props.deferredCreditGroups[0].type : "");

  useEffect(() => {
    if (selectedDeferredCreditGroup && selectedDeferredCreditGroup.length > 0 && props.deferredCreditGroups.length > 0 && !props.deferredCreditGroups.some((lg) => lg.type === selectedDeferredCreditGroup)){
      setSelectedDeferredCreditGroup(props.deferredCreditGroups[0].type);
    }

  }, [props.deferredCreditGroups, selectedDeferredCreditGroup])

  const handleTabChange = (event: React.ChangeEvent<{}>, data: string) => {
    setSelectedDeferredCreditGroup(data);
  }

  const handleGroupUpdate = (dataToUpdate: DeferredCreditGroupDto) => {
    let _creditGroups = [...props.deferredCreditGroups.filter(item => item.type !== dataToUpdate.type), dataToUpdate];
    props.handleUpdate(_creditGroups);
  }

  const renderEndIcon = (hasError) => {
    return hasError ? <WarningOutlined color={"error"}/> : <DoneOutlined color={"primary"}/>;
  }

  return (
      <Box>
        <TabContext value={selectedDeferredCreditGroup}>
          <Box mb={2}>
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                <Tabs
                    value={selectedDeferredCreditGroup}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                  {props.deferredCreditGroups.map((item) => (
                      <Tab value={item.type} label={t("creditGroup.typeEnum.values." + item.type)} wrapped={true}  icon={renderEndIcon(item.hasAnyInvalidItem())}/>
                  ))}
                </Tabs>
                {props.deferredCreditGroups.map((item) => <DeferredCreditGroupTab deferredCreditGroup={item} handleUpdate={handleGroupUpdate}/>)}
              </Grid>
            </Grid>
          </Box>
        </TabContext>
      </Box>
  );
}
