import { AuditInfo } from "../api/graphql/generated/schema";
import Moment from "moment";
import { dateTimeFormat } from "./const";
import { roundToZeroDecimals } from "./functionHelper";

export function formatUpdated(auditInfo: AuditInfo){
  return auditInfo?.updated && formatDateTime(auditInfo.updated);
}

export function formatDateTime(data){
  return data && Moment(data).format(dateTimeFormat)
}

export const formatToZeroDecimalsString = (decimalNumber: number) => {
  let rounded = roundToZeroDecimals(decimalNumber);
  return rounded?.toPrecision() || "0";
}

export const formatPercentageFromDecimal = (decimalNumber: number | null | undefined) => {
  return decimalNumber && decimalNumber * 100;
}
