import React from "react";
import {Typography} from '@mui/material';

export default function EmptyDataListInfo(props: {messageToDisplay: string}) {

  return (
      <Typography variant={"subtitle1"}>
        {props.messageToDisplay}
      </Typography>
      );
}
