import { Role } from './enums';
import { CreditDto, DeferredCreditDto, LeasingDto } from '../modelView';
import { Credit, CreditTypeEnum, CustomAttribute, DeferredCredit, Leasing, LeasingTypeEnum, ServicePackage } from '../api/graphql/generated/schema';

export const checkUserRole = (userRoles: string[], wantedRoles: Role[]) => {
 return wantedRoles.filter(role => userRoles.includes(role.toString())).length > 0;
}

export const convertNumericalIntAttribute = (value: string) => {
 const parsed = parseInt(value, 10);
 if (isNaN(parsed)) { return null; }
 return parsed
}


export const convertNumericalFloatAttribute = (value: string) => {
 const parsed = parseFloat(value.replace(',','.'));
 if (isNaN(parsed)) { return null; }
 return parsed
}

export const convertInputToCredit = (creditData: CreditDto, servicePackage: ServicePackage): Credit => {
 return {
   servicePackage: servicePackage,
   creditGroups: creditData.creditGroups.map(creditGroup => {
    return {
     type: creditGroup.type as CreditTypeEnum,
     items: creditGroup.items.map(item => { return {
      month: item.month,
      monthlyInstalment: item.monthlyInstalment,
      advancePayment: item.advancedPayment }})
    }})
 }
}


export const convertInputToDeferredCredit = (deferredCreditData: DeferredCreditDto, servicePackage: ServicePackage): DeferredCredit => {
 return {
  servicePackage: servicePackage,
  deferredCreditGroups: deferredCreditData.creditGroups.map(creditGroup => {
   return {
    type: creditGroup.type as CreditTypeEnum,
    items: creditGroup.items.map(item => { return {
     month: item.month,
     monthlyInstalment: item.monthlyInstalment,
     advancePayment: item.advancedPayment }})
   }})
 }
}


export const convertInputToLease = (lease: LeasingDto, servicePackage: ServicePackage): Leasing => {
 return {
  servicePackage: servicePackage,
  leasingGroups: lease.leasingGroups.map(group => {
   return {
    type: group.type as LeasingTypeEnum,
    items: group.items.map(item => { return {
     month: item.month,
     monthlyInstalment: item.monthlyInstalment,
     totalKmPerYear: item.totalKmPerYear }})
   }})
 }
}


export const sortedCAbyOrder = (customAttributes: Array<CustomAttribute>) => {
 const max = Math.max(...customAttributes.map(attr => attr?.order || 0)) + 1;
 return [...customAttributes].sort((atr1, atr2) =>
     (atr1?.order || max) - (atr2?.order || max)
 )
}
