import { IconButton, ListItem, ListItemSecondaryAction, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@mui/icons-material';


export default function ServiceInlineEditor(props:{service: string, handleUpdate: Function, handleRemove}) {
    const { t } = useTranslation();

    const [userServiceError, setUserServiceError] = useState<string | null>();

    let helperText: string = '';
    if (userServiceError){
        helperText =  userServiceError;
    } else if (!props.service){
        t("userMessages.codetable.servicePackage.item.helperTextAdd");
    }

    const handleUpdate = (event) => {
        const result = props.handleUpdate(event.target.value);
        setUserServiceError(result);
    }

    return (
        <ListItem>
            <TextField
                id={"sp-item-name"}
                type={"text"}
                name={"newItem"}
                value={props.service}
                error={!!userServiceError}
                helperText={helperText}
                onChange={handleUpdate}
                className={"w-100"}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={props.handleRemove}>
                    <DeleteOutlined />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
