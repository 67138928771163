import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CodetableDto } from '../../../api/graphql/generated/schema';
import { CoreEntityEnum } from '../../../utils/enums';

const initState = {
    id: '',
    label: '',
}

export default function CodetableEditDialog(props: { open: boolean, data: CodetableDto | null | undefined, enumValue: CoreEntityEnum, handleClose: () => void, handleSave: Function}) {

    const { t } = useTranslation();

    const [changed, setChanged] = React.useState<boolean>(false);
    const [id, setId] = React.useState<string | undefined | null>(initState.id);
    const [label, setLabel] = React.useState<string | undefined | null>(initState.label);

    useEffect(() => {
        if (props.data) {
            setId(props.data.id);
            setLabel(props.data.label);
        } else {
            setInitState();
        }
    }, [props.data])
    const setInitState = () => {
        setId(initState.id);
        setLabel(initState.label)
    }

    const changedInput = (setFunction) => (value: string | number) => {
        setFunction(value);
        setChanged(true);
    }

    const isValid = () => {
        return id && label;
    }

    const handleSave = () => {
        props.handleSave(id, label);
        setInitState()
        props.handleClose();
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            id="custom-attributes-dialog"
        >
            <DialogTitle>
                <Grid container direction={"row"} justifyContent={"flex-end"} alignItems={'flex-end'}>
                    <Grid item sx={{flex: 1}}>
                        {t(`codetables.action.${props.data?.state ? 'update' : 'add'}.dialogTitle`) + t(`codetables.addActions.${props.enumValue}`)}
                    </Grid>
                    <Grid item sx={{m: 1}}>
                        {props.data?.state && <Chip label={props.data?.state}/>}
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" onClick={() => {setInitState(); props.handleClose()}} aria-label="close">
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container
                      direction="row"
                      sx={{p: 1}}
                >
                    <Grid item xs={12} sm={6} >
                        <FormControl className="outlined">
                            <TextField
                                id={props.data?.id}
                                label={t('fieldName.code')}
                                type="text"
                                value={id}
                                required
                                error={!id}
                                disabled={!!props.data?.id}
                                onChange={event => changedInput(setId)(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <FormControl className="outlined">
                            <TextField
                                id={`${props.data?.id}-title`}
                                label={t('fieldName.title')}
                                type="text"
                                value={label}
                                required
                                error={!label}
                                onChange={event =>
                                    changedInput(setLabel)(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"flex-end"}>
                    <Grid item>
                        <Button disabled={!changed} onClick={() => {setInitState(); props.handleClose();}}>{t("actions.discard")}</Button>
                        { props.data && <Button disabled={!changed || !isValid()} onClick={handleSave}>{t("actions.save")}</Button> }
                        { !props.data && <Button disabled={!changed || !isValid()} onClick={handleSave}>{t("actions.create")}</Button>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
