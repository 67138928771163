import { CreditGroupItem as CreditGroupItemType } from '../../api/graphql/generated/schema';
import { isValidMonthlyInstalment } from './CreditUtil';

export class CreditGroupItemDto {

  private _month: number | null;
  private _monthlyInstalment: number | null;
  private _advancedPayment: number | null;

  constructor(month: number | null, advancedPayment: number | null, monthlyInstalment: number | null) {
    this._month = month;
    this._monthlyInstalment = monthlyInstalment;
    this._advancedPayment = advancedPayment;
  }

  static convertFromCreditGroupItem(creditGroupItem: CreditGroupItemType) {
    const _month = creditGroupItem.month ? creditGroupItem.month : null;
    const _advancedPayment = creditGroupItem.advancePayment ? creditGroupItem.advancePayment : null;
    const _monthlyInstalment = creditGroupItem.monthlyInstalment;

    return new CreditGroupItemDto(_month, _advancedPayment, _monthlyInstalment);
  }


  get month(): number | null {
    return this._month;
  }

  set month(value: number | null) {
    this._month = value;
  }

  get monthlyInstalment(): number | null{
    return this._monthlyInstalment;
  }

  set monthlyInstalment(value: number | null) {
    this._monthlyInstalment = value;
  }

  get advancedPayment(): number | null {
    return this._advancedPayment;
  }

  set advancedPayment(value: number | null) {
    this._advancedPayment = value;
  }

  hasError(){
    return (!this._month && !this._advancedPayment && !this._monthlyInstalment) || (!isValidMonthlyInstalment(this._monthlyInstalment, false));
  }

  isValid(){
    return this._month && this._advancedPayment && this._monthlyInstalment
  }

}
