import { FinanceGroupDto } from './FinanceGroupDto';
import { FinanceGroupInput as FinanceGroupInputType } from '../../api/graphql/generated/schema';
import { convertToLeasingInput } from '../leasing/leasingConverter';
import { convertToCreditInput, convertToDeferredCreditInput } from '../credit/creditConverter';

export const convertToFinanceGroupsInput = (financeGroups: Array<FinanceGroupDto>) => {
    return financeGroups.map(fg => convertToFinanceGroupInput(fg))
}

export const convertToFinanceGroupInput = (financeGroup: FinanceGroupDto): FinanceGroupInputType => {
    return {
        groupColorPrice: financeGroup.groupPrice,
        lease: financeGroup.lease ? convertToLeasingInput(financeGroup.lease) : undefined,
        credit: financeGroup.credit ? convertToCreditInput(financeGroup.credit) : undefined,
        deferredCredit: financeGroup.deferredCredit ? convertToDeferredCreditInput(financeGroup.deferredCredit) : undefined,
    };
}
