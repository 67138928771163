import {BonusDto} from "./BonusDto";
import {Bonus, BonusUnitEnum, CodetableDto} from "../api/graphql/generated/schema";

export class FinanceBonusDto extends BonusDto {

  private _isApplied: boolean = false;

  private constructor(id: string, name: string, price: number, unit: BonusUnitEnum, brand: CodetableDto | null | undefined, vehicleType: CodetableDto | null | undefined, forCrn: boolean, forPhysicalPerson: boolean) {
    super(id, name, price, unit, brand, vehicleType, forCrn, forPhysicalPerson);
  }

  static convertFromBonusGQL(bonus: Bonus){
    let _forCRN: boolean = !!bonus.forCrn;
    let _forPhysicalPerson: boolean = !!bonus.forPhysicalPerson;

    return new FinanceBonusDto(bonus.id, bonus.name, bonus.value, bonus.unit, bonus.brand, bonus.vehicleType, _forCRN, _forPhysicalPerson)
  }

  static convertFromBonus(bonus: BonusDto){
    return new FinanceBonusDto(bonus.id, bonus.name, bonus.price, bonus.unit, bonus.brand, bonus.vehicleType, bonus.forCrn, bonus.forPhysicalPerson);
  }


  get isApplied() {
    return this._isApplied;
  }

  set isApplied(value) {
    this._isApplied = value;
  }
}
