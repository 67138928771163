

export class EquipmentItemDto {
    private _description: string | null | undefined;
    private _count: number | null | undefined;
    private _modelChanged: boolean;
    private _equipmentId: string;
    private _equipmentName: string;
    private _categoryId: string;
    private _categoryLabel: string;
    private _equipmentChanged: boolean;

    constructor(description: string | null | undefined, count: number | null | undefined, modelChanged: boolean, id: string, name: string, categoryId: string, categoryLabel: string, itemChanged: boolean) {
        this._description  = description;
        this._count = count;
        this._modelChanged = modelChanged;
        this._equipmentId = id;
        this._equipmentName = name;
        this._categoryId = categoryId;
        this._categoryLabel = categoryLabel;
        this._equipmentChanged = itemChanged;
    }

    public get equipmentChanged(): boolean {
        return this._equipmentChanged;
    }
    public get categoryId(): string {
        return this._categoryId;
    }
    public get categoryLabel(): string {
        return this._categoryLabel;
    }
    public get equipmentName(): string {
        return this._equipmentName;
    }
    public get equipmentId(): string {
        return this._equipmentId;
    }
    public get modelChanged(): boolean {
        return this._modelChanged;
    }
    public get count(): number | null | undefined {
        return this._count;
    }
    public get description(): string | null | undefined {
        return this._description;
    }
}
