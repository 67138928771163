import { CustomAttributeDataTypeEnum, CustomAttributeInput } from '../../../../api/graphql/generated/schema';
import { Checkbox, FormControl, Grid, TextField, Theme, Typography } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 160,
            maxWidth: 200,
        },
        iconButton: {
            backgroundColor: 'transparent',
            "&:hover" : {
                backgroundColor: 'transparent',
            }
        }
    }),
);

export default function CustomAttributeInputField(props: { attribute: CustomAttributeInput, handleChange, editMode?: boolean }) {
    const classes = useStyles();
    const {t} = useTranslation();

    const changeValue = (value: string) => {
        props.attribute.value = value;
        props.handleChange(value);
    }

    const renderStringAttribute = (attribute: CustomAttributeInput) => {
        return (
            <Grid item >
                <FormControl className="outlined" size="medium">
                    <TextField
                        label={props.editMode ? t('fieldName.attributeValue') : attribute.label }
                        type="text"
                        value={attribute.value}
                        onChange={(event) => changeValue(event.target.value)}
                    />
                </FormControl>
            </Grid>
        );
    }

    const renderBooleanAttribute = (attribute: CustomAttributeInput) => {

        return (
            <Grid item >
                <Typography variant={"subtitle1"} >
                    <Checkbox
                        checked={attribute.value === "true"}
                        onChange={(event) => changeValue(event.target.checked.toString())}
                        color={"primary"}
                    />
                    {props.editMode ? t('fieldName.attributeValue') : attribute.label }
                </Typography>
            </Grid>
        );
    }

    const renderIntAttribute = (attribute: CustomAttributeInput) => {

        return (
            <Grid item >
                <FormControl className={classes.formControl}>
                    <TextField
                        label={props.editMode ? t('fieldName.attributeValue') : attribute.label }
                        type="number"
                        value={parseInt(attribute.value, 10)}
                        onChange={(event) => changeValue(event.target.value)}
                    />
                </FormControl>
            </Grid>
        );
    }

    const renderFloatAttribute = (attribute: CustomAttributeInput) => {

        return (
            <Grid item >
                <FormControl className={classes.formControl}>
                    <TextField
                        label={props.editMode ? t('fieldName.attributeValue') : attribute.label }
                        type="number"
                        value={parseFloat(attribute.value)}
                        onChange={(event) => changeValue(event.target.value)}
                    />
                </FormControl>
            </Grid>
        );
    }

    const renderDateAttribute = (attribute: CustomAttributeInput) => {

        return (
            <Grid item >
                <FormControl className="outlined" size="medium">
                    <TextField
                        label={props.editMode ? t('fieldName.attributeValue') : attribute.label }
                        type="date"
                        value={attribute.value ? format(new Date(attribute.value),"yyyy-MM-dd") : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => {
                            if ( event.target.value != "" ){
                                changeValue(event.target.value);
                            }
                        }}
                    />
                </FormControl>
            </Grid>
        );
    }

    const renderDateTimeAttribute = (attribute: CustomAttributeInput) => {

        return (
            <Grid item >
                <FormControl className="outlined" size="medium">
                    <TextField
                        label={props.editMode ? t('fieldName.attributeValue') : attribute.label }
                        type="datetime-local"
                        value={attribute.value}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => changeValue(event.target.value)}
                    />
                </FormControl>
            </Grid>
        );
    }

    const renderAttribute = (attribute: CustomAttributeInput) => {
        switch (attribute.dataType) {
            case CustomAttributeDataTypeEnum.DateTime:
                return renderDateTimeAttribute(attribute);
            case CustomAttributeDataTypeEnum.Date:
                return renderDateAttribute(attribute);
            case CustomAttributeDataTypeEnum.String:
                return renderStringAttribute(attribute);
            case CustomAttributeDataTypeEnum.Int:
                return renderIntAttribute(attribute);
            case CustomAttributeDataTypeEnum.Float:
                return renderFloatAttribute(attribute);
            case CustomAttributeDataTypeEnum.Boolean:
                return renderBooleanAttribute(attribute);
            default:
                return (<div></div>);
        }

    }

    return (
        renderAttribute(props.attribute)
    );
}
