import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { setLoadingStatus } from '../../../redux/loading';
import { ApolloError } from '../../../components';
import { openErrorSnackbar, openSuccessSnackbar } from '../../../redux/snackbar';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { createUnexpectedErrorMessage } from '../../../utils/functionHelper';
import { AppPageNameRoutes } from '../../../routes/paths';
import { UsedVehicleCard, UsedVehicleCardInput, UsedVehicleCardStateEnum, UsedVehicleDefinition } from '../../../api/graphql/generated/schema';
import CardDetails from '../../../components/usedVehicleCard/CardDetails';
import {
    UpdateUsedVehicleCardMutationOptions,
    UpdateUsedVehicleCardStateMutationOptions,
    useGetUsedVehicleCardQuery,
    useUpdateUsedVehicleCardMutation, useUpdateUsedVehicleCardStateMutation,
} from '../../../api/graphql/generated/hooks';
import { GeneralVehicleDefinition } from '../../../utils/typings';

const initState = {
    mainImageUrl: '',
    changed: false,
    cardState: '',
    usedVehicleCard: null,
    vin: '',
    brand: '',
    model: '',
    priceWithVat: '',
    vat: '',
    totalPriceCRN: '',
    totalPricePP: '',
    editMode: true,
    createMode: false,
    createUsedVehicleCard: {},
}

export default function UsedVehicleCardDetail(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [usedVehicleCard, setUsedVehicleCard] = React.useState<UsedVehicleCard | null>(initState.usedVehicleCard);

    const [updateUsedVehicleCardStateMutation, { loading: loadingUpdateUVCS }] = useUpdateUsedVehicleCardStateMutation();
    const [updateUsedVehicleCardMutation, { loading: loadingUpdateUVC }] = useUpdateUsedVehicleCardMutation();

    const { loading: loadingUsedVehicleCard, data: gqlUsedVehicleCard, refetch: refetchUsedVehicleCard } = useGetUsedVehicleCardQuery({
        variables: { id: props.match.params.usedVehicleCardId },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        dispatch(setLoadingStatus(loadingUsedVehicleCard || loadingUpdateUVCS || loadingUpdateUVC));
    }, [dispatch, loadingUsedVehicleCard, loadingUpdateUVCS, loadingUpdateUVC]);

    useEffect(() => {
        if (!loadingUsedVehicleCard && gqlUsedVehicleCard && gqlUsedVehicleCard.getUsedVehicleCard) {
            setUsedVehicleCard(gqlUsedVehicleCard.getUsedVehicleCard as UsedVehicleCard);
        } else {
            setUsedVehicleCard(initState.usedVehicleCard);
        }
    }, [loadingUsedVehicleCard, gqlUsedVehicleCard]);

    const duplicateVehicleCard = () => {
        let path = AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute;
        history.push(path, {cardToDuplicate: usedVehicleCard});
    }


    const handleUpdateUVCS = (state: UsedVehicleCardStateEnum) => {
        let inputOptions: UpdateUsedVehicleCardStateMutationOptions = {
            variables: {
                input: {
                    cardId: props.match.params.usedVehicleCardId,
                    state,
                },
            },
            onError: (error) => <ApolloError error={[error]}/>,
        };
        updateUsedVehicleCardStateMutation(inputOptions)
            .then(async response => {
                if (response?.data?.updateUsedVehicleCardState?.usedVehicleCard) {
                    dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.updated')));
                    await handleReset();
                } else if (response?.data?.updateUsedVehicleCardState?.errors) {
                    response.data.updateUsedVehicleCardState.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, 'vehicleCard'))));
                } else if (response.errors) {
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .then(() => refetchUsedVehicleCard())
            .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
    }

    const handleUpdateUVC = (usedVehicleCardInput: UsedVehicleCardInput) => {
        if (!usedVehicleCard) return;
        let inputOptions: UpdateUsedVehicleCardMutationOptions = {
            variables: {
                input: {
                    id: usedVehicleCard.id,
                    card: usedVehicleCardInput,
                },
            },
            onError: (error) => <ApolloError error={[error]}/>,
        };
        updateUsedVehicleCardMutation(inputOptions)
            .then(async response => {
                if (response?.data?.updateUsedVehicleCard?.usedVehicleCard) {
                    dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.updated')));
                    await refetchUsedVehicleCard();
                } else if (response?.data?.updateUsedVehicleCard?.errors) {
                    response.data.updateUsedVehicleCard.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, 'vehicleCard'))));
                } else if (response.errors) {
                    response.errors.map(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
    }


    const handleReset = async () => {
        const apolloQueryResult = await refetchUsedVehicleCard();
        setUsedVehicleCard(apolloQueryResult.data.getUsedVehicleCard as UsedVehicleCard);
    }

    const convertDefinitionToInput = (gqlDefinition: UsedVehicleDefinition): GeneralVehicleDefinition => {
        const vehicleDefinition: GeneralVehicleDefinition = {
            ...gqlDefinition,
            brand: {
                id: gqlDefinition.brand.id,
                label: gqlDefinition.brand.label,
            },
            vehicleType: gqlDefinition.vehicleType.id,
            color: {
                color: gqlDefinition.color?.color.id || '',
                name: gqlDefinition.color?.name || '',
                colorType: gqlDefinition.color?.colorType.id || '',
            },
            equipment: convertEquipment(gqlDefinition),
            body: gqlDefinition.body.id,
            fuelType: gqlDefinition.fuelType.id,
            transmissionType: gqlDefinition.transmissionType.id,
            wheelbase: gqlDefinition.wheelbase?.id,
            countryOfOrigin: gqlDefinition.countryOfOrigin?.id,
            price: Number(gqlDefinition.price),
            stkUntil: gqlDefinition.stkUntil ? new Date(gqlDefinition.stkUntil) : undefined,
            emissionUntil: gqlDefinition.emissionUntil ? new Date(gqlDefinition.emissionUntil) : undefined,
            intoOperation: gqlDefinition.intoOperation ? new Date(gqlDefinition.intoOperation) : undefined,
        }
        return vehicleDefinition;
    }

    const convertEquipment = (gqlDefinition: UsedVehicleDefinition) => {
        return {
            id: gqlDefinition.equipment?.id || '',
            name: gqlDefinition.equipment?.name || '',
            equipments: gqlDefinition.equipment?.equipments?.map((equipment) => ({
                equipment: {
                    id: equipment.equipment.id,
                    name: equipment.equipment.name,
                    category: equipment.equipment.category,
                    auditInfo: equipment.equipment.auditInfo,
                },
                description: equipment.description,
                count: equipment.count,
            }))
        };
    }

    return (
        <>
            {usedVehicleCard &&
                <CardDetails
                    usedVehicleCard={{
                        state: usedVehicleCard?.state,
                        vehicleDefinition: usedVehicleCard?.vehicleDefinition ? convertDefinitionToInput(usedVehicleCard.vehicleDefinition) : null,
                        extraEquipment: usedVehicleCard?.extraEquipment?.items || [],
                        finance: usedVehicleCard.finance,
                        images: usedVehicleCard?.images || [],
                        seller: usedVehicleCard?.seller,
                        marketing: usedVehicleCard?.marketing?.marketingTags || [] }}
                    handleUpdateCard={handleUpdateUVC}
                    handleUpdateUVCS={handleUpdateUVCS}
                    handleDuplication={duplicateVehicleCard}
                    refetch={handleReset}
                />
            }
        </>
    )
}
