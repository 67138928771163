import * as React from 'react';
import { useTranslation } from "react-i18next";
import { VehicleDelivery as VehicleDeliveryType } from "../../api/graphql/generated/schema";
import { TextField } from '@mui/material';
import Moment from "moment";
import { dateFormat } from "../../utils/const";
import { CheckBox } from '@mui/icons-material';

export default function VehicleDeliveryView(props: {deliveryDate: VehicleDeliveryType | null | undefined, textInput: boolean, required?: boolean}) {
    const {t} = useTranslation();

    let displayValue: string = props.required ? "" : t("userMessages.notSpecified");
    if(props.deliveryDate?.exactDate){
        displayValue = Moment(props.deliveryDate.exactDate).format(dateFormat);
    } else if (props.deliveryDate?.plusDays){
        displayValue = Moment().add(props.deliveryDate.plusDays, "d").format(dateFormat) + " (+" + props.deliveryDate.plusDays + ")";
    }

    const renderTextField = () => {
        return (
            <TextField
                variant="outlined"
                label={t("fieldName.deliveryDate")}
                value={displayValue}
                error={props.required && !displayValue}
                InputLabelProps={{shrink: !!displayValue}}
                InputProps={{
                    endAdornment: props?.deliveryDate?.immediatePickUp && (<CheckBox />)
                }}
                required={props.required}
            />
        );
    };

    const renderNonEditableInput = () => {
        return (
            <div>
                {displayValue} {props?.deliveryDate?.immediatePickUp && (<CheckBox/>)}
            </div>
        );
    }

    return (
        <div>
            {props.textInput ? renderTextField() : renderNonEditableInput()}
        </div>
    );
}
