import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TechnicalSpecification as TechnicalSpecificationType,
  VehicleDefinition as VehicleDefinitionType,
  VehicleTypeEnum
} from '../../../api/graphql/generated/schema';
import { AppPageNameRoutes } from '../../../routes/paths';
import { ExpandMore, SaveOutlined } from '@mui/icons-material';


export default function GeneralCarDefinition(props: { vehicleDefinition: VehicleDefinitionType, initiallyExpanded?: boolean }) {

  const { t } = useTranslation();
  const history = useHistory();

  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded);

  const redirectToEditDefinition = () => {
    let path = AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL.pageRoute.replace(':vehicleDefinitionId', props.vehicleDefinition.id);
    history.push(path);
  }

  //Rendering part
  const renderCommercialCarInfo = (vehicleSpec: TechnicalSpecificationType | null | undefined) => {
    return (
      <div>
        <div className="mt-5 w-100">
          <strong className="text-left d-block mb-4">{t('fieldName.commercialCar')}</strong>
        </div>
        <Grid container
              direction="row"
             >
          <Grid item xs={12} sm={3}>
            <FormControl className="outlined">
              <TextField
                id="trunkSpace"
                label={t('fieldName.trunkSpaceShort')}
                type="text"
                value={vehicleSpec?.trunkSpace}
                inputProps={
                  { name: 'trunkSpace' }
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className="outlined">
              <TextField
                id="trunkLength"
                label={t('fieldName.trunkLengthShort')}
                type="text"
                value={vehicleSpec?.trunkLength}
                inputProps={
                  { name: 'trunkLength' }
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className="outlined">
              <TextField
                id="numberOfPallets"
                label={t('fieldName.numberOfPallets')}
                type="text"
                value={vehicleSpec?.numberOfPallets}
                inputProps={
                  { name: 'numberOfPallets' }
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className="outlined">
              <TextField
                id="wheelbase"
                label={t('fieldName.wheelbase')}
                type="text"
                value={vehicleSpec?.wheelbase?.label}
                inputProps={
                  { name: 'wheelbase' }
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <form id="model-definition-form">
      <Accordion variant="outlined" expanded={accordionExpanded}
                 onChange={(event, expanded) => setAccordionExpanded(expanded)}>
        <AccordionSummary
          sx={{ flexDirection: 'row-reverse' }}
          expandIcon={<ExpandMore color='secondary' />}
        >
          <h2>{t('pages.vehicleCards.detail.definition.title')}</h2>
          <Button className="icon-button editDefinition section-header-info"
                  onClick={redirectToEditDefinition}><SaveOutlined/> {t('pages.vehicleCards.detail.definition.buttonEditDefinition')}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3 w-100">
            <strong className="text-left d-block mb-4">{t('pages.vehicleCards.detail.generalInfo')}</strong>
          </div>
          <Grid container
                direction="row"
          >
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="optionCode"
                  label={t('fieldName.optionCode')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.optionCode}
                  inputProps={
                    { name: 'optionCode' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="optionCode"
                  label={t('fieldName.basePrice')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.price}
                  inputProps={
                    { name: 'basePrice' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="brand"
                  label={t('fieldName.brand')}
                  type="text"
                  value={props.vehicleDefinition.modelDefinition?.brand.label}
                  inputProps={
                    { name: 'brand' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="vehicleType"
                  label={t('fieldName.vehicleType')}
                  type="text"
                  value={props.vehicleDefinition.modelDefinition?.vehicleType.label}
                  inputProps={
                    { name: 'vehicleType' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="line"
                  label={t('fieldName.line')}
                  type="text"
                  value={props.vehicleDefinition.modelDefinition?.line}
                  inputProps={
                    { name: 'line' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="model"
                  label={t('fieldName.model')}
                  type="text"
                  value={props.vehicleDefinition.modelDefinition?.model}
                  inputProps={
                    { name: 'model' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="modelYear"
                  label={t('fieldName.modelYear')}
                  type="text"
                  value={props.vehicleDefinition.modelDefinition?.modelYear}
                  inputProps={
                    { name: 'modelYear' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="equipment"
                  label={t('fieldName.equipment')}
                  type="text"
                  value={props.vehicleDefinition.equipment?.name}
                  inputProps={
                    { name: 'equipment' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="body"
                  label={t('fieldName.body')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.body?.label}
                  inputProps={
                    { name: 'body' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="seatsCount"
                  label={t('fieldName.seatsCount')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.seats}
                  inputProps={
                    { name: 'seatsCount' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className="mt-5 w-100">
            <strong className="text-left d-block mb-4">{t('fieldName.engine')}</strong>
          </div>
          <Grid container
                direction="row"
               >
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="engine"
                  label={t('fieldName.motorisation')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.engine}
                  inputProps={
                    { name: 'engine' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="fuel"
                  label={t('fieldName.fuel')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.fuelType?.label}
                  inputProps={
                    { name: 'fuel' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="power"
                  label={t('fieldName.power') + ' (kW)'}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.power}
                  inputProps={
                    { name: 'power' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="emission"
                  label={t('fieldName.emission') + ' (g/km)'}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.emission}
                  inputProps={
                    { name: 'emission' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="cubicCapacity"
                  label={t('fieldName.cubicCapacity') + ' (cm3)'}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.cubicCapacity}
                  inputProps={
                    { name: 'cubicCapacity' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="consumption"
                  label={t('fieldName.consumption') + ' (l/100km)'}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.fuelConsumption}
                  inputProps={
                    { name: 'consumption' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="range"
                  label={t('fieldName.range') + ' (km)'}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.range}
                  inputProps={
                    { name: 'range' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>


          <div className="mt-5 w-100">
            <strong className="text-left d-block mb-4">{t('fieldName.drive')}</strong>
          </div>
          <Grid container
                direction="row"
               >
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="transmissionType"
                  label={t('fieldName.transmissionType')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.transmissionType?.label}
                  inputProps={
                    { name: 'transmissionType' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="transmission"
                  label={t('fieldName.transmission')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.transmission}
                  inputProps={
                    { name: 'transmission' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="drive"
                  label={t('fieldName.drive')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.driveWheel}
                  inputProps={
                    { name: 'drive' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className="outlined">
                <TextField
                  id="4x4"
                  label={t('fieldName.4x4')}
                  type="text"
                  value={props.vehicleDefinition.vehicleSpec?.fourDriveWheel ? t('fieldName.yes') : t('fieldName.no')}
                  inputProps={
                    { name: '4x4' }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
          {props.vehicleDefinition.modelDefinition?.vehicleType.id === VehicleTypeEnum.Commercial && renderCommercialCarInfo(props.vehicleDefinition.vehicleSpec)}
        </AccordionDetails>
        <AccordionActions>
        </AccordionActions>
      </Accordion>
    </form>
  );
}
