import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Grid } from '@mui/material';
import { ArchiveOutlined, ArrowForwardOutlined, FileCopyOutlined, UnarchiveOutlined } from '@mui/icons-material';
import { UsedVehicleCardStateEnum } from '../../api/graphql/generated/schema';

export default function UsedVehicleCardActionButtons(
    props: {
        state: UsedVehicleCardStateEnum,
        handleUpdateUVCS: Function,
        handleDuplicate: Function
    }) {

    const {t} = useTranslation();

    const handleToInProgress = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.InProgress);
    }

    const handleToReview = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.AwaitApproval);
    }

    const renderStateHandlerButton = (actionName: string, onClickHandler) => {
        return <Button className="icon-button" onClick={onClickHandler}><ArrowForwardOutlined/> {t(actionName)}</Button>;
    }

    const handleToApprove = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.Approved);
    }

    const handleSold = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.Sold);
    }

    const handleArchive = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.Archived);
    }

    const handleUnarchive = () => {
        props.handleUpdateUVCS(UsedVehicleCardStateEnum.InProgress);
    }

    return (
        <Grid item >
            {props.state !== UsedVehicleCardStateEnum.InProgress && props.state !== UsedVehicleCardStateEnum.Archived && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.backToInProgress', handleToInProgress) }
            {props.state === UsedVehicleCardStateEnum.InProgress && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.toReview', handleToReview) }
            {props.state === UsedVehicleCardStateEnum.AwaitApproval && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.readyForWeb', handleToApprove) }
            {props.state === UsedVehicleCardStateEnum.Published && renderStateHandlerButton('pages.vehicleCards.detail.actions.process.sold', handleSold) }
            <Button className="icon-button only-icon" onClick={() => props.handleDuplicate()}><FileCopyOutlined/></Button>
            {props.state !== UsedVehicleCardStateEnum.Archived && <Button className="icon-button only-icon" onClick={handleArchive}><ArchiveOutlined/></Button>}
            {props.state === UsedVehicleCardStateEnum.Archived && <Button className="icon-button only-icon" onClick={handleUnarchive}><UnarchiveOutlined/></Button>}
        </Grid>
    );
}
