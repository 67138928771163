import { ExtraEquipmentItem } from '../../../api/graphql/generated/schema';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { ExtraEquipmentItemDto } from '../../../modelView/ExtraEquipmentItemDto';
import { roundToTwoDecimals } from '../../../utils/functionHelper';
import { EmptyDataListInfo, ExtraEquipmentItemEditor } from '../../../components';
import {
    Accordion, AccordionActions,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Grid,
    IconButton, List, ListItem,
    Paper, Popover,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Theme, Tooltip,
} from '@mui/material';
import { Add, DeleteOutline, ExpandMore } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';

const initUserDataState = {
    totalExtraEquipmentPrice: 0,
    userExtraEquipment: []
}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        }
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

export default function UsedVehicleCardDetailExtraEquipment(props: { cardExtraEquipment: Array<ExtraEquipmentItem> | undefined | null, handleUpdate: Function, setTotalPrice: Function, initiallyExpanded?: boolean }) {

    const {t} = useTranslation();

    const [changed, setChanged] = React.useState<boolean>(false);
    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
    const [totalExtraEquipmentPrice, setTotalExtraEquipmentPrice] = React.useState<number | null>(initUserDataState.totalExtraEquipmentPrice);
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        if (props.cardExtraEquipment &&  props.cardExtraEquipment.length > 0) {
            let sumTEE = props.cardExtraEquipment.map(eei => eei.price).reduce((price1: number, price2: number) => Number(price1) + Number(price2), 0);
            setTotalExtraEquipmentPrice(roundToTwoDecimals(sumTEE));
            props.setTotalPrice(roundToTwoDecimals(sumTEE));
        } else {
            setTotalExtraEquipmentPrice(initUserDataState.totalExtraEquipmentPrice);
        }
    }, [props.cardExtraEquipment]);


    const handleOpenExtraEquipmentEditor = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseExtraEquipmentEditor = () => {
        setAnchorEl(null);
    };

    const addNewItem = (item: ExtraEquipmentItemDto) => {
        const newItem = {name: item.name, price: item.price};
        props?.cardExtraEquipment ? props.handleUpdate([newItem, ...props.cardExtraEquipment]) : props.handleUpdate([newItem])
        handleCloseExtraEquipmentEditor();
        setChanged(!changed);
        return true;
    };

    const removeItem = (index: number) => {
        if (props?.cardExtraEquipment ){
            const currentList = [...props.cardExtraEquipment];
            currentList.splice(index, 1);
            props.handleUpdate(currentList);
        }
    };

    const updateItemName = (event, index: number) => {
        if (props.cardExtraEquipment ) {
            const currentList = [...props.cardExtraEquipment];
            currentList[index] = new ExtraEquipmentItemDto(event.target.value, currentList[index].price);
            props.handleUpdate([...currentList]);
        }
    };

    const updateItemPrice = (event, index: number) => {
        if (props.cardExtraEquipment ) {
            const currentList = [...props.cardExtraEquipment];
            currentList[index] = new ExtraEquipmentItemDto(currentList[index]?.name || '', event.target.value);
            props.handleUpdate([...currentList]);
        }

    };

    const renderTableBody = () => {
        if (props.cardExtraEquipment && props.cardExtraEquipment.length > 0){
            return props.cardExtraEquipment.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <TextField
                                key={"name-" + index}
                                type={"text"}
                                required={true}
                                error={!row.name}
                                value={row.name}
                                onChange={event => updateItemName(event, index)}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <TextField
                                key={"price-" + index}
                                required={true}
                                error={!(row.price?.toString())}
                                value={row.price}
                                type="number"
                                onChange={event => updateItemPrice(event, index)}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <IconButton onClick={() => removeItem(index)}>
                                <DeleteOutline/>
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                )
            );
        } else {
            return (<EmptyDataListInfo />);
        }

    }

    return (
        <form id="extra-equipment-form">
            <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandMore color='secondary' />}
                >
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <h2>{t('pages.vehicleCards.detail.equipment.title')}</h2>
                        </Grid>
                        <Grid item>
                            <FormControl className="outlined">
                                <TextField
                                    className="section-header-info"
                                    id="totalExtraEquipmentPrice"
                                    label={t('pages.vehicleCards.detail.equipment.totalExtraEquipmentPrice')}
                                    type="number"
                                    value={totalExtraEquipmentPrice}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table className="dataTable__holder">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">{t('fieldName.name')}</StyledTableCell>
                                    <StyledTableCell align="center">{t('fieldName.price')}</StyledTableCell>
                                    <StyledTableCell align="center">{t('fieldName.actions')}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTableBody()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
                <AccordionActions>
                    <Grid container justifyContent={'flex-start'} direction={'column'}>
                        <Grid item >
                            <div>
                                <IconButton onClick={handleOpenExtraEquipmentEditor} color={"secondary"}>
                                    <Tooltip title={t('pages.vehicleCards.detail.equipment.actions.addItem')}>
                                        <Add />
                                    </Tooltip>
                                </IconButton>
                                <Popover
                                    id={'extra-equipment-add-new'}
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseExtraEquipmentEditor}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            <ExtraEquipmentItemEditor editHandler={addNewItem}/>
                                        </ListItem>
                                    </List>
                                </Popover>
                            </div>
                        </Grid>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>

    );


}
