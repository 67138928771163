import {
    Body as BodyType,
    Brand,
    Color,
    ColorType,
    CurrencyEnum,
    CustomAttribute,
    FuelType,
    TransmissionType,
    UsedVehicleEquipmentItem,
    VehicleType, VehicleTypeEnum, WheelbaseType,
} from '../../../api/graphql/generated/schema';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Checkbox, FormControl, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Add, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError, CodetableSelect } from '../../../components';
import CustomAttributesDialog from '../../../components/usedVehicleCard/definition/customAttributes/CustomAttributesDialog';
import CustomAttributes from '../../../components/usedVehicleCard/definition/customAttributes/CustomAttributes';
import { currencyOptions, vehicleStates } from '../../../utils/const';
import EnumSelect from '../../../components/common/EnumSelect';
import { GeneralVehicleDefinition } from '../../../utils/typings';
import UsedVehicleDefinitionEquipment from '../../../components/usedVehicleCard/equipment/UsedVehicleDefinitionEquipment';
import {
    useGetBodiesQuery,
    useGetBrandsQuery,
    useGetColorsQuery,
    useGetColorTypesQuery,
    useGetCountriesOfOriginQuery,
    useGetFuelTypesQuery,
    useGetTransmissionTypesQuery,
    useGetVehicleTypesQuery, useGetWheelbaseTypesQuery,
} from '../../../api/graphql/generated/hooks';
import { convertNumericalFloatAttribute, convertNumericalIntAttribute, sortedCAbyOrder } from '../../../utils/utils';
import { VehicleStateEnum } from '../../../utils/enums';
import { format } from 'date-fns';

const initState = {
    changed: false,
    openDialog: false,
    inputs: [],
    userInput: '',
    currency: CurrencyEnum.Czk,
    vehicleState: null,
}

export default function UsedCarDefinition(props: {
    vehicleDefinition: GeneralVehicleDefinition | null | undefined,
    handleUserDefinition: Function,
    handleDefinitionUpdate: Function,
    handleCAValueUpdate: Function,
    handleCAUpdate: Function,
    handleCARemove: Function,
    initiallyExpanded?: boolean
}) {

    const { t } = useTranslation();

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded);
    const [changed, setChanged] = React.useState<boolean>(false)
    const [inputTransmissionType, setInputTransmissionType] = React.useState<TransmissionType[]>(initState.inputs);
    const [inputBrands, setInputBrands] = React.useState<Brand[]>(initState.inputs);
    const [inputVehicleTypes, setInputVehicleTypes] = React.useState<VehicleType[]>(initState.inputs);
    const [inputBodyTypes, setInputBodyTypes] = React.useState<BodyType[]>(initState.inputs);
    const [inputFuelTypes, setInputFuelTypes] = React.useState<FuelType[]>(initState.inputs);
    const [inputCountriesOfOrigin, setInputCountriesOfOrigin] = React.useState<any>(initState.inputs);
    const [inputColors, setInputColors] = React.useState<Color[]>(initState.inputs);
    const [inputColorTypes, setInputColorTypes] = React.useState<ColorType[]>(initState.inputs);
    const [inputWheelbaseType, setInputWheelbaseType] = React.useState<WheelbaseType[]>(initState.inputs);

    const [userBrandId, setUserBrandId] = React.useState<string>(initState.userInput);
    const [userVehicleTypeId, setUserVehicleTypeId] = React.useState<string>(initState.userInput);
    const [userTransmissionTypeId, setUserTransmissionTypeId] = React.useState<string>(initState.userInput);
    const [userFuelTypeId, setUserFuelTypeId] = React.useState<string>(initState.userInput);
    const [userCountryId, setUserCountryId] = React.useState<string>(initState.userInput);
    const [userBodyId, setUserBodyId] = React.useState<string>(initState.userInput);
    const [userCurrencyId, setUserCurrencyId] = React.useState<CurrencyEnum>(initState.currency);
    const [userVehicleStateId, setVehicleStateId] = React.useState<VehicleStateEnum | null>(initState.vehicleState);
    const [userColorId, setUserColorId] = React.useState<string>(initState.userInput);
    const [userColorTypeId, setUserColorTypeId] = React.useState<string>(initState.userInput);
    const [userWheelbaseTypeId, setUserWheelTypeId] = React.useState<string>(initState.userInput);

    const [openDialog, setOpenDialog] = useState<boolean>(initState.openDialog);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(initState.openDialog);
    const [editAttribute, setEditAttribute] = useState<CustomAttribute | null>(null);

    const { loading: loadingBrands, data: gqlBrands, error: errorBrands } = useGetBrandsQuery();
    const { loading: loadingTT, data: gqlTransmissionTypes, error: errorTT } = useGetTransmissionTypesQuery();
    const { loading: loadingVehicleTypes, data: gqlVehicleTypes, error: errorVehicleTypes } = useGetVehicleTypesQuery()
    const { loading: loadingFuelTypes, data: gqlFuelTypes, error: errorFuelTypes } = useGetFuelTypesQuery();
    const { loading: loadingCountries, data: gqlCountries, error: errorCountries } = useGetCountriesOfOriginQuery();
    const { loading: loadingBodies, data: gqlBodies, error: errorBodies } = useGetBodiesQuery();
    const { loading: loadingColors, data: gqlColors, error: errorColors } = useGetColorsQuery();
    const { loading: loadingColorTypes, data: gqlColorTypes, error: errorColorTypes } = useGetColorTypesQuery();
    const { loading: loadingWheelbaseTypes, data: gqlWheelbaseTypes, error: errorWheelbaseTypes } = useGetWheelbaseTypesQuery();



    useEffect(() => {
        if (props.vehicleDefinition?.vehicleState) {
            setVehicleStateId(VehicleStateEnum[props.vehicleDefinition.vehicleState])
        }
    }, [props.vehicleDefinition?.vehicleState]);

    useEffect(() => {
        if (props.vehicleDefinition?.currency) {
            setUserCurrencyId(props.vehicleDefinition.currency as CurrencyEnum);
        }
    }, [props.vehicleDefinition?.currency]);

    useEffect(() => {
        if (!loadingBrands && gqlBrands) {
            setInputBrands(gqlBrands.getBrands);
            if (props.vehicleDefinition?.brand) {
                props.vehicleDefinition.brand.id ? setUserBrandId(props.vehicleDefinition.brand.id) : setUserBrandId('');
            }
        }


    }, [loadingBrands, gqlBrands, props.vehicleDefinition]);


    useEffect(() => {
        if (!loadingVehicleTypes && gqlVehicleTypes) {
            setInputVehicleTypes(gqlVehicleTypes.getVehicleTypes);
            if (props.vehicleDefinition?.vehicleType) {
                setUserVehicleTypeId(props.vehicleDefinition.vehicleType);
            }
        }

    }, [loadingVehicleTypes, gqlVehicleTypes, props.vehicleDefinition]);


    useEffect(() => {
        if (!loadingTT && gqlTransmissionTypes) {
            setInputTransmissionType(gqlTransmissionTypes.getTransmissionTypes);
            if (props.vehicleDefinition?.transmissionType) {
                setUserTransmissionTypeId(props.vehicleDefinition.transmissionType);
            }
        }

    }, [loadingTT, gqlTransmissionTypes, props.vehicleDefinition]);


    useEffect(() => {
        if (!loadingFuelTypes && gqlFuelTypes) {
            setInputFuelTypes(gqlFuelTypes.getFuelTypes);
            if (props.vehicleDefinition?.fuelType) {
                setUserFuelTypeId(props.vehicleDefinition.fuelType);
            }
        }

    }, [loadingFuelTypes, gqlFuelTypes, props.vehicleDefinition]);


    useEffect(() => {
        if (!loadingCountries && gqlCountries && gqlCountries.getCountriesOfOrigin) {
            setInputCountriesOfOrigin(gqlCountries.getCountriesOfOrigin);
            if (props.vehicleDefinition) {
                props.vehicleDefinition?.countryOfOrigin ? setUserCountryId(props.vehicleDefinition.countryOfOrigin) : setUserCountryId('');
            }
        }

    }, [loadingCountries, gqlCountries, props.vehicleDefinition]);


    useEffect(() => {
        if (!loadingBodies && gqlBodies) {
            if (props.vehicleDefinition?.vehicleType) {
                const possibleBodies = gqlBodies.getBodies.filter(body => body?.vehicleType?.id === props.vehicleDefinition?.vehicleType)
                setInputBodyTypes(possibleBodies);
                // @ts-ignore
                if (props.vehicleDefinition.body && possibleBodies.filter(body => body.id === props.vehicleDefinition.body).length === 0) {
                    changeBody('');
                } else {
                    setUserBodyId(props.vehicleDefinition.body || '');
                }
            } else {
                setInputBodyTypes(gqlBodies.getBodies);
            }
        }

    }, [loadingBodies, gqlBodies, props.vehicleDefinition?.vehicleType]);


    useEffect(() => {
        if (!loadingColors && gqlColors) {
            setInputColors(gqlColors.getColors);
            if (props.vehicleDefinition) {
                props.vehicleDefinition?.color?.color ? setUserColorId(props.vehicleDefinition.color.color) : setUserColorId('');
            }
        }
    }, [loadingColors, gqlColors, props.vehicleDefinition]);

    useEffect(() => {
        if (!loadingColorTypes && gqlColorTypes) {
            setInputColorTypes(gqlColorTypes.getColorTypes);
            if (props.vehicleDefinition) {
                props.vehicleDefinition?.color?.colorType ? setUserColorTypeId(props.vehicleDefinition.color.colorType) : setUserColorTypeId('');
            }
        }
    }, [loadingColorTypes, gqlColorTypes, props.vehicleDefinition]);



    useEffect(() => {
        if (!loadingWheelbaseTypes && gqlWheelbaseTypes) {
            setInputWheelbaseType(gqlWheelbaseTypes.getWheelbaseTypes);
            if (props.vehicleDefinition) {
                props.vehicleDefinition?.wheelbase ? setUserWheelTypeId(props.vehicleDefinition.wheelbase) : setUserWheelTypeId('');
            }
        }
    }, [loadingWheelbaseTypes, gqlWheelbaseTypes, props.vehicleDefinition]);


    const changeVin = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ vin: value });
            return;
        }
        if (props.vehicleDefinition?.vin) {
            props.vehicleDefinition.vin = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, vin: value });
        }
    }

    const changeBrand = (id: string) => {
        const brand = inputBrands.filter(brand => brand.id === id)[0]
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ brand: { id, label: brand.label } });
            return;
        }
        if (props.vehicleDefinition?.brand) {
            props.vehicleDefinition.brand = { id, label: brand.label };
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, brand: { id, label: brand.label } });
        }

        setUserBrandId(id);
    }

    const changeVehicleType = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ vehicleType: id });
            return;
        }
        if (props.vehicleDefinition?.vehicleType) {
            props.vehicleDefinition.vehicleType = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, vehicleType: id });
        }

        setUserVehicleTypeId(id);
    }

    const changeLine = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ line: value });
            return;
        }
        if (props.vehicleDefinition?.line) {
            props.vehicleDefinition.line = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, line: value });
        }
    }

    const changeModel = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ model: value });
            return;
        }
        if (props.vehicleDefinition?.model) {
            props.vehicleDefinition.model = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, model: value });
        }
    }

    const changeModelYear = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ modelYear: value });
            return;
        }
        if (props.vehicleDefinition?.modelYear) {
            props.vehicleDefinition.modelYear = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, modelYear: value });
        }
    }

    const changeModelKey = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ modelKey: value });
            return;
        }
        if (props.vehicleDefinition?.modelKey) {
            props.vehicleDefinition.modelKey = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, modelKey: value });
        }
    }

    const changeModelKeyExtension = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ modelKeyExtension: value });
            return;
        }
        if (props.vehicleDefinition?.modelKeyExtension) {
            props.vehicleDefinition.modelKeyExtension = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, modelKeyExtension: value });
        }
    }


    const changeBody = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ body: id });
            return;
        }
        if (props.vehicleDefinition?.body) {
            props.vehicleDefinition.body = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, body: id });
        }

        setUserBodyId(id);
    }


    const changeColor = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ color: { color: id } });
            return;
        }
        if (props.vehicleDefinition?.color) {
            props.vehicleDefinition.color.color = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, color: { color: id } });
        }

        setUserColorId(id);
    }

    const changeColorType = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ color: { colorType: id } });
            return;
        }
        if (props.vehicleDefinition?.color) {
            props.vehicleDefinition.color.colorType = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, color: { colorType: id } });
        }

        setUserColorTypeId(id);
    }

    const changeColorName = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ color: { name: value } });
            return;
        }
        if (props.vehicleDefinition?.color) {
            props.vehicleDefinition.color.name = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, color: { name: value } });
        }
    }

    const changeSeats = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ seats: value });
            return;
        }
        if (props.vehicleDefinition?.seats) {
            props.vehicleDefinition.seats = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, seats: value });
        }
    }

    const changeDoors = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ doorsNumber: value });
            return;
        }
        if (props.vehicleDefinition?.doorsNumber) {
            props.vehicleDefinition.doorsNumber = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, doorsNumber: value });
        }
    }

    const changeOwnerCount = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ ownerCount: value });
            return;
        }
        if (props.vehicleDefinition?.ownerCount) {
            props.vehicleDefinition.ownerCount = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, ownerCount: value });
        }
    }

    const changeMileage = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ mileage: value });
            return;
        }
        if (props.vehicleDefinition?.mileage) {
            props.vehicleDefinition.mileage = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, mileage: value });
        }
    }


    const changeNumberOfPallets = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ numberOfPallets: value });
            return;
        }
        if (props.vehicleDefinition?.numberOfPallets) {
            props.vehicleDefinition.numberOfPallets = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, numberOfPallets: value });
        }
    }


    const changeTrunkLength = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ trunkLength: value });
            return;
        }
        if (props.vehicleDefinition?.trunkLength) {
            props.vehicleDefinition.trunkLength = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, trunkLength: value });
        }
    }


    const changeTrunkSpace = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ trunkSpace: value });
            return;
        }
        if (props.vehicleDefinition?.trunkSpace) {
            props.vehicleDefinition.trunkSpace = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, trunkSpace: value });
        }
    }


    const changePrice = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ price: value });
            return;
        }
        if (props.vehicleDefinition?.price) {
            props.vehicleDefinition.price = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, price: value });
        }
    }

    const changeAuctionPrice = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ auctionPrice: value });
            return;
        }
        if (props.vehicleDefinition?.auctionPrice) {
            props.vehicleDefinition.auctionPrice = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, auctionPrice: value });
        }
    }


    const changeNewVehiclePrice = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ newVehiclePrice: value });
            return;
        }
        if (props.vehicleDefinition?.newVehiclePrice) {
            props.vehicleDefinition.newVehiclePrice = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, newVehiclePrice: value });
        }
    }
    const changeCurrency = (value: CurrencyEnum) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ currency: value });
            return;
        }
        if (props.vehicleDefinition?.currency) {
            props.vehicleDefinition.currency = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, currency: value });
        }

        setUserCurrencyId(value);
    }


    const changeVehicleState = (value: VehicleStateEnum) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ vehicleState: value.toString() });
            return;
        }
        if (props.vehicleDefinition?.vehicleState) {
            props.vehicleDefinition.vehicleState = value.toString();
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, vehicleState: value.toString() });
        }

        setVehicleStateId(value);
    }

    const changeCountry = (id: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ countryOfOrigin: id });
            return;
        }
        if (props.vehicleDefinition?.countryOfOrigin) {
            props.vehicleDefinition.countryOfOrigin = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, countryOfOrigin: id });
        }

        setUserCountryId(id);
    }

    const changeEngine = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ engine: value });
            return;
        }
        if (props.vehicleDefinition?.engine) {
            props.vehicleDefinition.engine = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, engine: value });
        }
    }

    const changeNotes = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ notes: value });
            return;
        }
        if (props.vehicleDefinition?.notes) {
            props.vehicleDefinition.notes = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, notes: value });
        }
    }

    const changePower = (value: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ power: value });
            return;
        }
        if (props.vehicleDefinition?.power) {
            props.vehicleDefinition.power = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, power: value });
        }
    }

    const changeFuelType = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ fuelType: id });
            return;
        }
        if (props.vehicleDefinition?.fuelType) {
            props.vehicleDefinition.fuelType = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, fuelType: id });
        }

        setUserFuelTypeId(id);
    }


    const changeWheelbase = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ wheelbase: id });
            return;
        }
        if (props.vehicleDefinition?.wheelbase) {
            props.vehicleDefinition.wheelbase = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, wheelbase: id });
        }

        setUserWheelTypeId(id);
    }

    const changeDriveWheel = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ driveWheel: value });
            return;
        }
        if (props.vehicleDefinition?.driveWheel) {
            props.vehicleDefinition.driveWheel = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, driveWheel: value });
        }
    }

    const changeShifts = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ shifts: value });
            return;
        }
        if (props.vehicleDefinition?.shifts) {
            props.vehicleDefinition.shifts = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, shifts: value });
        }
    }

    const changeEmission = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ emission: value });
            return;
        }
        if (props.vehicleDefinition?.emission) {
            props.vehicleDefinition.emission = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, emission: value });
        }
    }

    const changeEmissionUntil = (value: Date) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ emissionUntil: value });
            return;
        }
        if (props.vehicleDefinition?.emissionUntil) {
            props.vehicleDefinition.emissionUntil = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, emissionUntil: value });
        }
    }

    const changeIntoOperation = (value: Date) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ intoOperation: value });
            return;
        }
        if (props.vehicleDefinition?.intoOperation) {
            props.vehicleDefinition.intoOperation = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, intoOperation: value });
        }
    }

    const changeSTKUntil = (value: Date) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ stkUntil: value });
            return;
        }
        if (props.vehicleDefinition?.stkUntil) {
            props.vehicleDefinition.stkUntil = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, stkUntil: value });
        }
    }

    const changeCubicCapacity = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ cubicCapacity: value });
            return;
        }
        if (props.vehicleDefinition?.cubicCapacity) {
            props.vehicleDefinition.cubicCapacity = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, cubicCapacity: value });
        }
    }

    const changeFuelConsumption = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ fuelConsumption: value });
            return;
        }
        if (props.vehicleDefinition?.fuelConsumption) {
            props.vehicleDefinition.fuelConsumption = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, fuelConsumption: value });
        }
    }

    const changeRange = (value: number) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ range: value });
            return;
        }
        if (props.vehicleDefinition?.range) {
            props.vehicleDefinition.range = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, range: value });
        }
    }

    const changeTransmissionType = (id: string) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ transmissionType: id });
            return;
        }
        if (props.vehicleDefinition?.transmissionType) {
            props.vehicleDefinition.transmissionType = id;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, transmissionType: id });
        }

        setUserTransmissionTypeId(id);
    }

    const changeTransmission = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ transmission: value });
            return;
        }
        if (props.vehicleDefinition?.transmission) {
            props.vehicleDefinition.transmission = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, transmission: value });
        }
    }

    const changeFourDriveWheel = (value: boolean) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ fourDriveWheel: value });
            return;
        }
        if (props.vehicleDefinition?.fourDriveWheel) {
            props.vehicleDefinition.fourDriveWheel = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, fourDriveWheel: value });
        }
    }

    const changeRefVehicle = (value: boolean) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ refVehicle: value });
            return;
        }
        if (props.vehicleDefinition?.refVehicle) {
            props.vehicleDefinition.refVehicle = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, refVehicle: value });
        }
    }

    const changeVatExpelFlag = (value: boolean) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ vatExpelFlag: value });
            return;
        }
        if (props.vehicleDefinition?.vatExpelFlag) {
            props.vehicleDefinition.vatExpelFlag = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, vatExpelFlag: value });
        }
    }

    const changeNoCrashFlag = (value: boolean) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ noCrashFlag: value });
            return;
        }
        if (props.vehicleDefinition?.noCrashFlag) {
            props.vehicleDefinition.noCrashFlag = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, noCrashFlag: value });
        }
    }

    const changeServiceBookFlag = (value: boolean) => {
        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ serviceBookFlag: value });
            return;
        }
        if (props.vehicleDefinition?.serviceBookFlag) {
            props.vehicleDefinition.serviceBookFlag = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, serviceBookFlag: value });
        }
    }

    const changeCCDescription = (value: string) => {

        if (!props?.vehicleDefinition) {
            props.handleDefinitionUpdate({ cubicCapacityDescription: value });
            return;
        }
        if (props.vehicleDefinition?.cubicCapacityDescription) {
            props.vehicleDefinition.cubicCapacityDescription = value;
            props.handleDefinitionUpdate(props.vehicleDefinition);
        } else {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, cubicCapacityDescription: value });
        }
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditAttribute(null);
    }

    const handleCAEdit = (attribute: CustomAttribute) => {
        setEditAttribute(attribute);
        setOpenEditDialog(true);
    }

    const handleCARemove = (index: number) => {
        if (!props.vehicleDefinition?.customAttributes) {
            return;
        }
        const currentList = sortedCAbyOrder([...props.vehicleDefinition?.customAttributes]);
        currentList.splice(index, 1);
        props.vehicleDefinition.customAttributes = currentList;
        props.handleDefinitionUpdate(props.vehicleDefinition);
        setChanged(true);
    }

    const handleEquipmentUpdate = (name: string, equipments?: UsedVehicleEquipmentItem[]) => {
        if (!props.vehicleDefinition?.equipment) {
            props.handleDefinitionUpdate({ ...props.vehicleDefinition, equipment: { name: name, equipments: equipments } });
            setChanged(true);
            return;
        }
        if (!equipments) {
            props.vehicleDefinition.equipment.name = name;
            props.handleDefinitionUpdate(props.vehicleDefinition);
            setChanged(true);
            return;
        }

        props.vehicleDefinition.equipment.name = name;
        props.vehicleDefinition.equipment.equipments = equipments;
        props.handleDefinitionUpdate(props.vehicleDefinition);
        setChanged(true);
    }

    if (errorBrands) return <ApolloError error={[errorBrands]}/>;
    if (errorVehicleTypes) return <ApolloError error={[errorVehicleTypes]}/>;
    if (errorTT) return <ApolloError error={[errorTT]}/>;
    if (errorBodies) return <ApolloError error={[errorBodies]}/>;
    if (errorFuelTypes) return <ApolloError error={[errorFuelTypes]}/>;
    if (errorCountries) return <ApolloError error={[errorCountries]}/>;
    if (errorColors) return <ApolloError error={[errorColors]}/>;
    if (errorColorTypes) return <ApolloError error={[errorColorTypes]}/>;
    if (errorWheelbaseTypes) return <ApolloError error={[errorWheelbaseTypes]}/>;

    return (
        <form id="model-definition-form">
            <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandMore color="secondary"/>}
                >
                    <h2>{t('pages.vehicleCards.detail.definition.title')}</h2>
                </AccordionSummary>
                <AccordionDetails>

                    <div className="mt-3 w-100">
                        <strong className="text-left d-block mb-4">{t('pages.vehicleCards.detail.generalInfo')}</strong>
                    </div>
                    <Grid container
                          direction="row"
                    >
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined" size="medium">
                                <TextField
                                    id="brand"
                                    label={t('fieldName.vin')}
                                    type="text"
                                    value={props.vehicleDefinition?.vin || ''}
                                    error={!props.vehicleDefinition?.vin || props.vehicleDefinition?.vin.length < 17}
                                    required
                                    onChange={event => {
                                        props.handleUserDefinition(changeVin)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputBrands}
                                filteredValues={userBrandId}
                                setFilteredValues={(id) => changeBrand(id)}
                                label={t('fieldName.brand')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputVehicleTypes}
                                filteredValues={userVehicleTypeId}
                                setFilteredValues={(id) => changeVehicleType(id)}
                                label={t('fieldName.vehicleType')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="line"
                                    label={t('fieldName.line')}
                                    type="text"
                                    required
                                    value={props.vehicleDefinition?.line}
                                    error={!props.vehicleDefinition?.line}
                                    onChange={event => {
                                        props.handleUserDefinition(changeLine)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="model"
                                    label={t('fieldName.model')}
                                    type="text"
                                    value={props.vehicleDefinition?.model}
                                    required
                                    error={!props.vehicleDefinition?.model}
                                    onChange={event => {
                                        props.handleUserDefinition(changeModel)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="modelYear"
                                    label={t('fieldName.modelYear')}
                                    type="number"
                                    value={props.vehicleDefinition?.modelYear}
                                    required
                                    error={!props.vehicleDefinition?.modelYear}
                                    onChange={event => {
                                        props.handleUserDefinition(changeModelYear)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="modelKey"
                                    label={t('fieldName.modelKey')}
                                    type="text"
                                    value={props.vehicleDefinition?.modelKey}
                                    required
                                    error={!props.vehicleDefinition?.modelKey}
                                    onChange={event => {
                                        props.handleUserDefinition(changeModelKey)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="modelKeyExtension"
                                    label={t('fieldName.modelKeyExtension')}
                                    type="text"
                                    value={props.vehicleDefinition?.modelKeyExtension}
                                    onChange={event => {
                                        props.handleUserDefinition(changeModelKeyExtension)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputBodyTypes}
                                filteredValues={userBodyId}
                                setFilteredValues={(id) => changeBody(id)}
                                label={t('fieldName.body')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="seatsCount"
                                    label={t('fieldName.color')}
                                    type="text"
                                    required
                                    error={!props.vehicleDefinition?.color?.name}
                                    value={props.vehicleDefinition?.color?.name}
                                    onChange={event => {
                                        props.handleUserDefinition(changeColorName)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputColors}
                                filteredValues={userColorId}
                                setFilteredValues={(id) => changeColor(id)}
                                label={t('fieldName.baseColor')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputColorTypes}
                                filteredValues={userColorTypeId}
                                setFilteredValues={(id) => changeColorType(id)}
                                label={t('fieldName.colorType')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="seatsCount"
                                    label={t('fieldName.seatsCount')}
                                    type="number"
                                    value={props.vehicleDefinition?.seats}
                                    required
                                    error={!props.vehicleDefinition?.seats}
                                    onChange={event => {
                                        props.handleUserDefinition(changeSeats)(convertNumericalIntAttribute(event.target.value) || null);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="doorsNumber"
                                    label={t('fieldName.doorsNumber')}
                                    type="number"
                                    value={props.vehicleDefinition?.doorsNumber}
                                    onChange={event => {
                                        props.handleUserDefinition(changeDoors)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="ownerCount"
                                    label={t('fieldName.ownerCount')}
                                    type="number"
                                    value={props.vehicleDefinition?.ownerCount}
                                    required
                                    error={!props.vehicleDefinition?.ownerCount}
                                    onChange={event => {
                                        props.handleUserDefinition(changeOwnerCount)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="mileage"
                                    label={t('fieldName.mileage')}
                                    type="number"
                                    value={props.vehicleDefinition?.mileage}
                                    required
                                    error={!props.vehicleDefinition?.mileage && props.vehicleDefinition?.mileage !== 0}
                                    onChange={event => {
                                        props.handleUserDefinition(changeMileage)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputCountriesOfOrigin}
                                filteredValues={userCountryId}
                                setFilteredValues={(id) => changeCountry(id)}
                                label={t('fieldName.country')}
                                multiselect={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <EnumSelect
                                inputValues={vehicleStates}
                                label={t('fieldName.vehicleState')}
                                enumLabelsPath="usedVehicleCard.vehicleState"
                                filteredValues={userVehicleStateId}
                                setFilteredValues={(value) => changeVehicleState(value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="stkUntil"
                                    label={t('fieldName.stkUntil')}
                                    type="date"
                                    value={props.vehicleDefinition?.stkUntil ? format(props.vehicleDefinition.stkUntil,"yyyy-MM-dd") : ''}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={event => {
                                        props.handleUserDefinition(changeSTKUntil)(new Date(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="emissionUntil"
                                    label={t('fieldName.emissionUntil')}
                                    type="date"
                                    value={props.vehicleDefinition?.emissionUntil ? format(props.vehicleDefinition.emissionUntil,"yyyy-MM-dd") : ''}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={event => {
                                        props.handleUserDefinition(changeEmissionUntil)(new Date(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="intoOperation"
                                    label={t('fieldName.intoOperation')}
                                    type="date"
                                    value={props.vehicleDefinition?.intoOperation ? format(props.vehicleDefinition.intoOperation,"yyyy-MM-dd") : ''}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={event => {
                                        props.handleUserDefinition(changeIntoOperation)(new Date(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant={'body2'}>
                                <Checkbox
                                    checked={props.vehicleDefinition?.serviceBookFlag || false}
                                    onChange={(event) => props.handleUserDefinition(changeServiceBookFlag)(event.target.checked)}
                                    color={'primary'}
                                    id="serviceBookFlag"
                                />
                                {t('fieldName.serviceBookFlag')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant={'body2'}>
                                <Checkbox
                                    checked={props.vehicleDefinition?.noCrashFlag || false}
                                    onChange={(event) => props.handleUserDefinition(changeNoCrashFlag)(event.target.checked)}
                                    color={'primary'}
                                    id="noCrashFlag"
                                />
                                {t('fieldName.noCrashFlag')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant={'body2'}>
                                <Checkbox
                                    checked={props.vehicleDefinition?.refVehicle || false}
                                    onChange={(event) => props.handleUserDefinition(changeRefVehicle)(event.target.checked)}
                                    color={'primary'}
                                    id="refVehicle"
                                />
                                {t('fieldName.refVehicle')}
                            </Typography>
                        </Grid>

                    </Grid>

                    {userVehicleTypeId === VehicleTypeEnum.Commercial &&
                        <>
                            <div className="mt-5 w-100">
                                <strong className="text-left d-block mb-4">{t('pages.definitionEditor.technicalSpecification.section.infoForCommercial')}</strong>
                            </div>
                            <Grid container>
                                <Grid item xs={12} sm={4}>
                                    <FormControl className="outlined">
                                        <TextField
                                            id="trunkLength"
                                            label={t('fieldName.trunkLength')}
                                            type="number"
                                            value={props.vehicleDefinition?.trunkLength}
                                            required
                                            error={!props.vehicleDefinition?.trunkLength && props.vehicleDefinition?.trunkLength !== 0}
                                            onChange={event => {
                                                props.handleUserDefinition(changeTrunkLength)(convertNumericalFloatAttribute(event.target.value));
                                                setChanged(!changed)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl className="outlined">
                                        <TextField
                                            id="trunkSpace"
                                            label={t('fieldName.trunkSpace')}
                                            type="number"
                                            value={props.vehicleDefinition?.trunkSpace}
                                            required
                                            error={!props.vehicleDefinition?.trunkSpace && props.vehicleDefinition?.trunkSpace !== 0}
                                            onChange={event => {
                                                props.handleUserDefinition(changeTrunkSpace)(convertNumericalFloatAttribute(event.target.value));
                                                setChanged(!changed)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl className="outlined">
                                        <TextField
                                            id="mileage"
                                            label={t('fieldName.numberOfPallets')}
                                            type="number"
                                            value={props.vehicleDefinition?.numberOfPallets}
                                            onChange={event => {
                                                props.handleUserDefinition(changeNumberOfPallets)(convertNumericalIntAttribute(event.target.value));
                                                setChanged(!changed)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CodetableSelect
                                        inputValues={inputWheelbaseType}
                                        filteredValues={userWheelbaseTypeId}
                                        setFilteredValues={(id) => changeWheelbase(id)}
                                        label={t('fieldName.wheelbase')}
                                        required={true}
                                        multiselect={false}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }

                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.engine')}</strong>
                    </div>
                    <Grid container
                          direction="row"
                    >
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="engine"
                                    label={t('fieldName.motorisation')}
                                    type="text"
                                    value={props.vehicleDefinition?.engine}
                                    required
                                    error={!props.vehicleDefinition?.engine}
                                    onChange={event => {
                                        props.handleUserDefinition(changeEngine)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputFuelTypes}
                                filteredValues={userFuelTypeId}
                                setFilteredValues={(id) => changeFuelType(id)}
                                label={t('fieldName.fuelType')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="power"
                                    label={t('fieldName.power') + ' (kW)'}
                                    type="text"
                                    value={props.vehicleDefinition?.power}
                                    required
                                    error={!props.vehicleDefinition?.power}
                                    onChange={event => {
                                        props.handleUserDefinition(changePower)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="emission"
                                    label={t('fieldName.emission') + ' (g/km)'}
                                    type="number"
                                    value={props.vehicleDefinition?.emission}
                                    required
                                    error={!props.vehicleDefinition?.emission && props.vehicleDefinition?.emission !== 0}
                                    onChange={event => {
                                        props.handleUserDefinition(changeEmission)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="cubicCapacity"
                                    label={t('fieldName.cubicCapacity') + ' (cm3)'}
                                    type="number"
                                    value={props.vehicleDefinition?.cubicCapacity}
                                    required
                                    error={!props.vehicleDefinition?.cubicCapacity && props.vehicleDefinition?.cubicCapacity !== 0}
                                    onChange={event => {
                                        props.handleUserDefinition(changeCubicCapacity)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="cubicCapacityDescription"
                                    label={t('fieldName.cubicCapacityDescription')}
                                    type="text"
                                    value={props.vehicleDefinition?.cubicCapacityDescription}
                                    onChange={event => {
                                        props.handleUserDefinition(changeCCDescription)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="consumption"
                                    label={t('fieldName.consumption') + ' (l/100km)'}
                                    type="number"
                                    error={!props.vehicleDefinition?.fuelConsumption && props.vehicleDefinition?.fuelConsumption !== 0}
                                    required
                                    value={props.vehicleDefinition?.fuelConsumption}
                                    onChange={event => {
                                        props.handleUserDefinition(changeFuelConsumption)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="range"
                                    label={t('fieldName.range') + ' (km)'}
                                    type="number"
                                    value={props.vehicleDefinition?.range}
                                    onChange={event => {
                                        props.handleUserDefinition(changeRange)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.equipment')}</strong>
                    </div>
                    <>
                        <UsedVehicleDefinitionEquipment equipment={props.vehicleDefinition?.equipment} handleUpdate={handleEquipmentUpdate}/>
                    </>

                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.drive')}</strong>
                    </div>
                    <Grid container
                          direction="row"
                    >
                        <Grid item xs={12} sm={4}>
                            <CodetableSelect
                                inputValues={inputTransmissionType}
                                filteredValues={userTransmissionTypeId}
                                setFilteredValues={(id) => changeTransmissionType(id)}
                                label={t('fieldName.transmissionType')}
                                required={true}
                                multiselect={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="transmission"
                                    label={t('fieldName.transmission')}
                                    type="text"
                                    value={props.vehicleDefinition?.transmission}
                                    required
                                    error={!props.vehicleDefinition?.transmission}
                                    onChange={event => {
                                        props.handleUserDefinition(changeTransmission)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="driveWheel"
                                    label={t('fieldName.driveWheel')}
                                    type="text"
                                    required
                                    error={!props.vehicleDefinition?.driveWheel}
                                    value={props.vehicleDefinition?.driveWheel}
                                    onChange={event => {
                                        props.handleUserDefinition(changeDriveWheel)(event.target.value);
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant={'body2'}>
                                <Checkbox
                                    checked={props.vehicleDefinition?.fourDriveWheel || false}
                                    onChange={(event) => props.handleUserDefinition(changeFourDriveWheel)(event.target.checked)}
                                    color={'primary'}
                                    id="4x4"
                                />
                                {t('fieldName.4x4')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="shifts"
                                    label={t('fieldName.shifts')}
                                    type="number"
                                    value={props.vehicleDefinition?.shifts}
                                    onChange={event => {
                                        props.handleUserDefinition(changeShifts)(convertNumericalIntAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.prices')}</strong>
                    </div>
                    <Grid container
                          direction="row"
                    >
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="price"
                                    label={t('fieldName.price')}
                                    type="number"
                                    value={props.vehicleDefinition?.price}
                                    required
                                    error={!props.vehicleDefinition?.price && props.vehicleDefinition?.price !== 0}
                                    onChange={event => {
                                        props.handleUserDefinition(changePrice)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <EnumSelect
                                inputValues={currencyOptions}
                                label={t('fieldName.currency')}
                                enumLabelsPath="usedVehicleCard.currency"
                                filteredValues={userCurrencyId}
                                setFilteredValues={(value) => changeCurrency(value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="auctionPrice"
                                    label={t('fieldName.auctionPrice')}
                                    type="number"
                                    value={props.vehicleDefinition?.auctionPrice}
                                    onChange={event => {
                                        props.handleUserDefinition(changeAuctionPrice)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="newVehiclePrice"
                                    label={t('fieldName.newVehiclePrice')}
                                    type="number"
                                    value={props.vehicleDefinition?.newVehiclePrice}
                                    onChange={event => {
                                        props.handleUserDefinition(changeNewVehiclePrice)(convertNumericalFloatAttribute(event.target.value));
                                        setChanged(!changed)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant={'body2'}>
                                <Checkbox
                                    checked={props.vehicleDefinition?.vatExpelFlag || false}
                                    onChange={(event) => props.handleUserDefinition(changeVatExpelFlag)(event.target.checked)}
                                    color={'primary'}
                                    id="vatExpelFlag"
                                />
                                {t('fieldName.vatExpelFlag')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.notes')}</strong>
                    </div>
                    <Grid item xs={12} sm={12}>
                        <FormControl className="outlined">
                            <TextField
                                id="engine"
                                label={t('fieldName.notes')}
                                type="text"
                                multiline
                                value={props.vehicleDefinition?.notes}
                                onChange={event => {
                                    props.handleUserDefinition(changeNotes)(event.target.value);
                                    setChanged(!changed)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('fieldName.customAttributes')}</strong>
                    </div>
                    {props.vehicleDefinition?.customAttributes &&
                        <CustomAttributes
                            customAttributes={props.vehicleDefinition.customAttributes}
                            handleOpenEditDialog={handleCAEdit}
                            handleRemove={handleCARemove}
                            handleCAUpdate={props.handleCAValueUpdate}
                        />
                    }
                    {editAttribute && <CustomAttributesDialog customAttribute={editAttribute} open={openEditDialog} handleClose={handleCloseEditDialog}
                                                              handleUpdate={props.handleCAUpdate}/>
                    }
                </AccordionDetails>
                <AccordionActions>
                    <Grid container justifyContent={'flex-start'} direction={'column'}>
                        <Grid item>
                            <div>
                                <IconButton onClick={handleOpenDialog} color={'secondary'}>
                                    <Tooltip title={t('pages.usedVehicleCards.detail.definition.action.addAttribute')}>
                                        <Add/>
                                    </Tooltip>
                                </IconButton>
                                <CustomAttributesDialog customAttribute={null} open={openDialog} handleClose={handleCloseDialog}
                                                        handleUpdate={props.handleCAUpdate}/>
                            </div>
                        </Grid>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>
    );
}
