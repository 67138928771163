import React from "react";
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { VehicleCardColor as VehicleCardColorType } from "../../../api/graphql/generated/schema";
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 200,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

export default function CardColorSelect (
    props:{
        inputValues: VehicleCardColorType[],
        filteredValues: string | string[],
        setFilteredValues,
        label?: string,
        disabled?: boolean,
        multiselect?: boolean,
        required?: boolean
    }) {

    const classes = useStyles();

    const handleChange = (event: SelectChangeEvent<string | string[]>) => {
        props.setFilteredValues(event.target.value as string | string[]);
    };

    return (
        <FormControl className={classes.formControl} required={props.required} error={props.required && !props.filteredValues}>
            <InputLabel id="card-color-select-label">{props.label}</InputLabel>
            <Select
                label={props.label}
                labelId="card-color-select-label"
                id="card-color-select"
                multiple={props.multiselect}
                value={props.filteredValues}
                onChange={handleChange}
                MenuProps={MenuProps}
                disabled={props.disabled}
            >
                {props?.inputValues?.map((color) => (
                    <MenuItem key={color.id} value={color.id}>
                        {color.modelColor.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

}
