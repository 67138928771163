import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, CardHeader, Container, Grid, TextField } from '@mui/material';
import { Add, ViewList } from '@mui/icons-material';
import { useGetUsedVehicleCardsQuery, useGetVehicleCardsQuery } from '../../api/graphql/generated/hooks';
import { UsedVehicleCard, UsedVehicleCardStateEnum, UsedVehicleImage, VehicleCard, VehicleCardStateEnum } from '../../api/graphql/generated/schema';
import { setLoadingStatus } from '../../redux/loading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApolloError, VehicleCardCreate } from '../../components';
import Carousel from 'react-material-ui-carousel'
import { AppPageNameRoutes } from '../../routes/paths';
import { UserDto } from '../../api';
import { getUserFromStorage } from '../../utils/functionHelper';
import { checkUserRole } from '../../utils/utils';
import { Role } from '../../utils/enums';
import noImage from '../../assets/media/images/no_image.svg';

export function Home() {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [vehicleCards, setVehicleCards] = React.useState<Array<Record<string, any>>>([]);
  const [usedVehicleCards, setUsedVehicleCards] = React.useState<Array<Record<string, any>>>([]);
  const [user] = React.useState<UserDto | null>(getUserFromStorage());
  const [openCreateCardDialogDialog, setOpenCreateCardDialogDialog] = useState<boolean>(false);


  const {
    data: gqlUsedVehicleCards,
    error: errorUsedVehicleCards,
    loading: loadingUsedVehicleCards,
  } = useGetUsedVehicleCardsQuery({ variables: { filter: { state: [UsedVehicleCardStateEnum.Approved, UsedVehicleCardStateEnum.Published] }, limit: 5 } });
  const {
    data: gqlVehicleCards,
    error: errorVehicleCards,
    loading: loadingVehicleCards,
  } = useGetVehicleCardsQuery({ variables: { filter: { state: [VehicleCardStateEnum.Approved, VehicleCardStateEnum.Published] }, limit: 5 } });

  useEffect(() => {
    dispatch(setLoadingStatus(loadingUsedVehicleCards));
    if (gqlUsedVehicleCards && gqlUsedVehicleCards.getUsedVehicleCards) {
      setUsedVehicleCards(gqlUsedVehicleCards.getUsedVehicleCards.data);
    }
  }, [gqlUsedVehicleCards, loadingUsedVehicleCards]);

  useEffect(() => {
    dispatch(setLoadingStatus(loadingVehicleCards));
    if (gqlVehicleCards && gqlVehicleCards.getVehicleCards) {
      setVehicleCards(gqlVehicleCards.getVehicleCards.data);
    }
  }, [gqlVehicleCards, loadingVehicleCards]);


  const handleOpenCreateCardDialog = () => {
    setOpenCreateCardDialogDialog(true);
  };

  const handleCloseCreateCardDialog = () => {
    setOpenCreateCardDialogDialog(false);
  };

  if (errorVehicleCards) return <ApolloError error={[errorVehicleCards]}/>;
  if (errorUsedVehicleCards) return <ApolloError error={[errorUsedVehicleCards]}/>;

  const usedVehicleCardCarouselItemLayout = (usedVehicleCard: Partial<UsedVehicleCard>) => {
    if (!usedVehicleCard.vehicleDefinition || !usedVehicleCard.state) {
      return null;
    }
    let publicUrl = '';
    if (usedVehicleCard.images && usedVehicleCard.images.length > 0) {
      const firstMainImage: UsedVehicleImage | undefined = usedVehicleCard.images
        .find((image) => image.mainImage);
      if (firstMainImage) {
        publicUrl = firstMainImage.publicUrl;
      }
    }

    return carouselItemLayout(usedVehicleCard.vehicleDefinition.brand.label || '', usedVehicleCard.state.toString(), publicUrl, usedVehicleCard.vehicleDefinition.line, usedVehicleCard.vehicleDefinition.model)
  }

  const newVehicleCardCarouselItemLayout = (vehicleCard: Partial<VehicleCard>) => {
    if (!(vehicleCard.definition?.modelDefinition) || !vehicleCard.state) {
      return null;
    }

    let publicUrl;
    if (vehicleCard.colors && vehicleCard.colors.length > 0) {
      const cardColor = vehicleCard.colors[0];
      if (cardColor) {
        publicUrl = cardColor.images?.find((image) => image.id === cardColor.mainImageId)?.publicUrl
      }
    }

    return carouselItemLayout(vehicleCard.definition.modelDefinition.brand.label || '', vehicleCard.state.toString(), publicUrl, vehicleCard.definition.modelDefinition.line, vehicleCard.definition.modelDefinition.model)
  }

  const carouselItemLayout = (brand: string, cardState: string, imageUrl: string, line: string, model: string) => {

    return (
      <Grid container direction="column">
        <Grid item container justifyContent="center" alignItems="center" sx={{ pl: 0 }}>
          <Grid item>
            {imageUrl
              ? <img src={imageUrl} alt="car-heading" width={'100%'} height={'250px'}/>
              : <img src={noImage} alt="car-heading" width={'100%'} height={'250px'}/>
            }
          </Grid>
        </Grid>
        <Grid container justifyContent="space-evenly">
          <Grid item>
            <TextField
              id="commission"
              label={t('fieldName.brand')}
              type="text"
              value={brand}
              disabled
            />
          </Grid>
          <Grid item>
            <TextField
              id="state"
              label={t('fieldName.state')}
              type="text"
              disabled
              value={t('pages.vehicleCards.states.' + cardState)}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-evenly">
          <Grid item>
            <TextField
              id="model"
              label={t('fieldName.model')}
              type="text"
              disabled
              value={model}
            />
          </Grid>
          <Grid item>
            <TextField
              id="model"
              label={t('fieldName.line')}
              type="text"
              disabled
              value={line}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container className="page" sx={{ marginTop: 4 }}>
      <Grid
        container
        justifyContent="center"
        spacing={6}
        sx={{ maxWidth: '1800px' }}
      >
        <Grid item xs={5}>
          {checkUserRole(user?.roles || [], [Role.NV_CARD_ADMIN]) &&
            <Card id="new-vehicles-paper" sx={{ background: 'transparent', boxShadow: 'none' }}>
              <CardHeader
                sx={{ background: '#000000', padding: 1 }}
                title={t('pageNames.newCars')}
                titleTypographyProps={{ variant: 'h5', color: '#fdfdfd', margin: '0 10px 0 10px' }}
                action={
                  <>
                    <Button onClick={() => history.push(AppPageNameRoutes.VEHICLE_NEW_CARDS.pageRoute)}>
                      <ViewList color="secondary"/>
                    </Button>
                    <Button onClick={handleOpenCreateCardDialog}>
                      <Add color="secondary"/>
                    </Button>
                  </>
                }
              />
              <CardContent sx={{ background: '#ffffff' }}>
                <Grid container justifyContent="center" sx={{ width: '100%', height: '500px' }}>
                  <Grid item sx={{ width: '100%', height: '100%' }}>
                    {vehicleCards &&
                      <Carousel>
                        {vehicleCards.map(item => newVehicleCardCarouselItemLayout(item))}
                      </Carousel>
                    }
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          }
        </Grid>
        <Grid item xs={5}>
          {checkUserRole(user?.roles || [], [Role.UV_CARD_READ, Role.UV_CARD_EDIT]) &&
            <Card id="used-vehicles-paper" sx={{ background: 'transparent', boxShadow: 'none' }}>
              <CardHeader
                sx={{ background: '#000000', padding: 1 }}
                title={t('pageNames.usedCars')}
                titleTypographyProps={{ variant: 'h5', color: '#fdfdfd', margin: '0 10px 0 10px' }}
                action={
                  <>
                    {checkUserRole(user?.roles || [], [Role.UV_CARD_EDIT]) &&
                      <Button
                        onClick={() => history.push(AppPageNameRoutes.VEHICLE_USED_CARDS.pageRoute)}
                      >
                        <ViewList color="secondary"/>
                      </Button>
                    }
                    {checkUserRole(user?.roles || [], [Role.UV_CARD_EDIT]) &&
                      <Button
                        onClick={() => history.push(AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute)}
                      >
                        <Add color="secondary"/>
                      </Button>
                    }
                  </>
                }
              />

              <CardContent sx={{ background: '#ffffff' }}>
                <Grid container justifyContent="center" sx={{ width: '100%', height: '500px' }}>
                  <Grid item sx={{ width: '100%', height: '100%' }}>
                    {usedVehicleCards &&
                      <Carousel>
                        {usedVehicleCards.map(item => usedVehicleCardCarouselItemLayout(item))}
                      </Carousel>
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>

      <VehicleCardCreate open={openCreateCardDialogDialog} handleClose={handleCloseCreateCardDialog}/>
    </Container>
  );
}

export default Home
