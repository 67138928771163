import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GeneralCarDefinition from './vehicleCardCreateEditGeneralCarDefinition';
import VehicleCardExtraEquipment from './vehicleCardExtraEquipment';

import { ApolloError, VehicleCardColorEditor } from '../../../components';
import PageHeader from '../../../components/pageHeader/PageHeader';
import {
  ExtraEquipmentItem,
  VehicleCardColor,
  VehicleCardFinance,
  VehicleCardStateEnum,
  VehicleDelivery as VehicleDeliveryType,
  VehicleDeliveryInput,
} from '../../../api/graphql/generated/schema';

import CardFinance from './CardFinance';
import GeneralCarPhotos from './vehicleCardCreateEditPhotos';
import {
  UpdateVehicleCardMutationOptions,
  UpdateVehicleCardStateMutationOptions,
  useGetVehicleCardQuery,
  useUpdateVehicleCardMutation,
  useUpdateVehicleCardStateMutation,
} from '../../../api/graphql/generated/hooks';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../../routes/paths';
import { openErrorSnackbar, openSuccessSnackbar } from '../../../redux/snackbar';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../../redux/loading';
import { CardFinanceDto, ColorViewerDto, FinanceGroupDto } from '../../../modelView';
import VehicleCardMarketing from './vehicleCardMarketing';
import { convertDeliveryTypeToDeliveryInput } from '../../../utils/graphGL/graphQLConverter';
import { createUnexpectedErrorMessage } from '../../../utils/functionHelper';
import VehicleDeliveryFakeCommission from '../../../components/vehicleCard/VehicleDeliveryFakeCommission';
import VehicleDeliveryCommission from '../../../components/vehicleCard/VehicleDeliveryCommission';
import VehicleCardActionButtons from '../../../components/vehicleCard/VehicleCardActionButtons';
import { Button, Card, Container, FormControl, Grid, TextField } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';

const breadcrumbs = [
  AppPageNameRoutes.VEHICLE_NEW_CARDS,
  AppPageNameRoutes.VEHICLE_NEW_CARD_DETAIL
];

const initState = {
  mainImageUrl: '',
  offeredValidTo: '',
  commission: '',
  deliveryDate: null,
  optionCode: '',
  changed: false,
  cardState: '',
  inStoreCardPopAnchorEl: null,
  cardFinance: (cardId) => {
    return new CardFinanceDto(cardId)
  },
  vehicleCard: null,
}

export default function VehicleCardCreateEdit(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [commission, setCommission] = React.useState<string>(initState.commission);
  const [deliveryDate, setDeliveryDate] = React.useState<VehicleDeliveryType | null>(initState.deliveryDate);
  const [offerValidTo, setOfferValidTo] = React.useState<string | null>(initState.offeredValidTo);
  const [optionCode, setOptionCode] = React.useState<string>(initState.optionCode);
  const [changed, setChanged] = React.useState(initState.changed);
  const [cardState, setCardState] = React.useState(initState.cardState);
  const [vehicleCard, setVehicleCard] = React.useState<any>(initState.vehicleCard);
  const [cardFinance, setCardFinance] = React.useState<CardFinanceDto>(initState.cardFinance(props.match.params.vehicleCardId));
  const [mainImageUrl, setMainImageUrl] = useState<string>(initState.mainImageUrl);

  const { loading: loadingVehicleCard, data: gqlVehicleCard, refetch: refetchVehicleCard } = useGetVehicleCardQuery({
    variables: { id: props.match.params.vehicleCardId },
    fetchPolicy: 'network-only'
  });

  const [updateVehicleCardStateMutation, { loading: loadingUpdateVCS, error: errorUpdateVCS }] = useUpdateVehicleCardStateMutation();
  const [updateVehicleCardMutation, { loading: loadingUpdateVC, error: errorUpdateVC }] = useUpdateVehicleCardMutation();

  useEffect(() => {
    dispatch(setLoadingStatus(loadingVehicleCard || loadingUpdateVCS || loadingUpdateVC));
  }, [dispatch, loadingVehicleCard, loadingUpdateVCS, loadingUpdateVC]);


  useEffect(() => {
    if (!loadingVehicleCard && gqlVehicleCard && gqlVehicleCard.getVehicleCard) {
      setVehicleCard(gqlVehicleCard.getVehicleCard);
      setCommission(gqlVehicleCard.getVehicleCard?.commission);
      setOfferValidTo(gqlVehicleCard.getVehicleCard?.offerValidTo ? gqlVehicleCard.getVehicleCard?.offerValidTo.toString() : initState.offeredValidTo);
      setCardState(gqlVehicleCard.getVehicleCard?.state as string);
      setOptionCode(gqlVehicleCard.getVehicleCard?.definition?.vehicleSpec?.optionCode as string);
      // @ts-ignore
      setDeliveryDate(gqlVehicleCard.getVehicleCard?.deliveryDate);

      setCardFinance(assembleCardFinance(cardFinance, gqlVehicleCard.getVehicleCard.finance, gqlVehicleCard.getVehicleCard.colors, gqlVehicleCard.getVehicleCard.definition?.vehicleSpec?.price, gqlVehicleCard.getVehicleCard.extraEquipment?.items));
      setMainImageUrl(initState.mainImageUrl);
      if (gqlVehicleCard.getVehicleCard.colors && gqlVehicleCard.getVehicleCard.colors.length > 0) {
        const possibleMainImages: Array<VehicleCardColor> = gqlVehicleCard.getVehicleCard.colors
          .filter((color) => color.mainImageId)
          .filter((color) => color.images && color.images.length > 0);
        if (possibleMainImages && possibleMainImages.length > 0 && possibleMainImages[0] && possibleMainImages[0].images && possibleMainImages[0].images.length > 0) {
          const mainImgIndex: number = possibleMainImages[0].images.findIndex((img) => img.id === possibleMainImages[0].mainImageId)
          const publicUrl = possibleMainImages[0].images[mainImgIndex].publicUrl;
          setMainImageUrl(publicUrl ? publicUrl : initState.mainImageUrl);
        }
      }
    } else {
      initComponentState();
    }
  }, [loadingVehicleCard, gqlVehicleCard]);

  const initComponentState = () => {
    setCommission(initState.commission);
    setDeliveryDate(initState.deliveryDate);
    setOfferValidTo(initState.offeredValidTo);
    setOptionCode(initState.optionCode);
    setChanged(initState.changed);
    setCardState(initState.cardState);
    setVehicleCard(initState.vehicleCard);
    setCardFinance(initState.cardFinance(props.match.params.vehicleCardId));
    setMainImageUrl(initState.mainImageUrl);
  }

  const changedInput = (setFunction) => (value: any) => {
    setFunction(value);
    setChanged(true);
  }

  const changeCommission = (value: string) => {
    changedInput(setCommission)(value);
  }

  const changeOfferValidTo = (value: string) => {
    changedInput(setOfferValidTo)(value);
  }

  const handleUpdateVCS = (newCardState: VehicleCardStateEnum) => {
    let inputOptions: UpdateVehicleCardStateMutationOptions = {
      variables: {
        input: {
          cardId: props.match.params.vehicleCardId,
          state: newCardState
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateVehicleCardStateMutation(inputOptions)
      .then(response => {
        if (response?.data?.updateVehicleCardState?.vehicleCard) {
          dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.updated')));
        } else if (response?.data?.updateVehicleCardState?.errors) {
          response.data.updateVehicleCardState.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, 'vehicleCard'))));
        } else if (response.errors) {
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .then(() => refetchVehicleCard())
      .then(() => setChanged(false))
      .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
  }

  const handleUpdateVC = () => {
    let deliveryDateInput: VehicleDeliveryInput | null = null;
    if (deliveryDate) {
      deliveryDateInput = convertDeliveryTypeToDeliveryInput(deliveryDate);
    }
    let inputOptions: UpdateVehicleCardMutationOptions = {
      variables: {
        input: {
          id: props.match.params.vehicleCardId,
          commission: commission,
          offerValidTo: offerValidTo ? offerValidTo : null,
          deliveryDate: deliveryDateInput,
          definitionId: vehicleCard.definition.id
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateVehicleCardMutation(inputOptions)
      .then(response => {
        if (response?.data?.updateVehicleCard?.vehicleCard) {
          dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.created')));
        } else if (response?.data?.updateVehicleCard?.errors) {
          response.data.updateVehicleCard.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, 'vehicleCard'))));
        } else if (response.errors) {
          response.errors.map(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .then(() => setChanged(false))
      .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
  }

  const handleUpdateData = () => {
    handleUpdateVC();
  }

  if (errorUpdateVCS) return <ApolloError error={[errorUpdateVCS]}/>;
  if (errorUpdateVC) return <ApolloError error={[errorUpdateVC]}/>;

  const renderVehicleDelivery = () => {
    if (!!vehicleCard?.inStoreCard) {
      return (
        <VehicleDeliveryCommission deliveryDate={deliveryDate}
                                   setVehicleDelivery={changedInput(setDeliveryDate)}
                                   required={true}
        />
      )
    } else {
      return (
        <VehicleDeliveryFakeCommission deliveryDate={deliveryDate} setVehicleDelivery={changedInput(setDeliveryDate)}/>
      )
    }
  }

  return (
    <Container className="vehicle-card">
      <Grid>
        <Grid
          item
          className="text-left"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            direction="row"
            component="div"
          >
            <Grid item>
              <PageHeader headline={'cards'} breadcrumbs={breadcrumbs as unknown as AppPageNameRouteType[]}/>
            </Grid>
            {vehicleCard && <VehicleCardActionButtons
              vehicleCardId={vehicleCard.id}
              state={vehicleCard.state}
              inStoreCard={vehicleCard.inStoreCard}
              vehicleCardColors={vehicleCard.colors}
              handleUpdateVCS={handleUpdateVCS}
            />}
          </Grid>
        </Grid>

        <div className="mt-5">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

            component="div"
          >
            <Grid item xs={12} sm={4}>
              <Card
                className="carOverview"
              >
                {mainImageUrl
                  ? <img src={mainImageUrl} alt="car-heading" className="car-heading" height={'auto'}/>
                  : t('userMessages.vehicleCard.photos.missingCardMainImage')
                }

                <h2>{vehicleCard?.definition.modelDefinition.model}</h2>
                <span className="brand">{vehicleCard?.definition.modelDefinition.brand.label}</span>

                <div className="mt-3">
                  <Grid container
                        direction="row"
                       >
                    <Grid item xs={12} sm={12}>
                      <FormControl className="outlined">
                        <TextField
                          id="card-info-option-code"
                          label={t('fieldName.optionCode')}
                          type="text"
                          value={optionCode}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className="mt-4">
                  <Grid container
                        direction="row"
                       >
                    <Grid item xs={12} sm={6}>
                      <FormControl className="outlined">
                        <TextField
                          id="state"
                          label={t('fieldName.state')}
                          type="text"
                          disabled
                          value={t('pages.vehicleCards.states.' + cardState)}
                          inputProps={
                            { name: 'card-state' }
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className="outlined">
                        <TextField
                          id="commission"
                          label={t('fieldName.commission')}
                          type="text"
                          value={commission}
                          required
                          error={!commission}
                          onChange={event => changeCommission(event.target.value)}
                          inputProps={
                            { name: 'commission' }
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className="mt-3">
                  <Grid container
                        direction="row"
                       >
                    <Grid xs={12} sm={6} item direction={'row'}>
                      {renderVehicleDelivery()}
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <FormControl className="outlined">
                        <TextField
                          id="card-info-offered-valid-to"
                          label={t('fieldName.offerValidTo')}
                          type="date"
                          value={offerValidTo}
                          onChange={event => changeOfferValidTo(event.target.value)}
                          inputProps={
                            { name: 'offerValidTo' }
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>

                <div className="mt-4">
                  <Grid container
                        direction="row"
                        >
                    <Grid item xs={12} sm={12}>
                      <VehicleCardColorEditor
                        modelColors={vehicleCard?.definition?.modelDefinition?.colors ? vehicleCard?.definition?.modelDefinition?.colors : []}
                        cardColors={vehicleCard?.colors ? vehicleCard?.colors : []}
                        cardId={props.match.params.vehicleCardId}
                        refreshParentData={refetchVehicleCard}
                      />
                      <div className="mt-4">
                        <Grid item xs={12} sm={12} className="text-right">
                          <Button disabled={!changed} className="reset-button">{t('pages.vehicleCards.detail.actions.reset')}</Button>
                          <Button disabled={!changed} className="icon-button"
                                  onClick={handleUpdateData}><SaveOutlined/> {t('pages.vehicleCards.detail.actions.save')}
                          </Button>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>

              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card>
                {vehicleCard &&
                  <GeneralCarDefinition
                    vehicleDefinition={vehicleCard?.definition}
                    initiallyExpanded={false}
                  />
                }

                <VehicleCardExtraEquipment
                  cardId={props.match.params.vehicleCardId}
                  cardExtraEquipment={vehicleCard?.extraEquipment ? vehicleCard.extraEquipment : null}
                  initiallyExpanded={false}
                  handleUpdate={refetchVehicleCard}
                />
                {vehicleCard &&
                  <CardFinance
                    financeData={cardFinance}
                    brandId={vehicleCard.definition.modelDefinition.brand.id}
                    vehicleTypeId={vehicleCard.definition.modelDefinition.vehicleType.id}
                    initiallyExpanded={true}
                    handleUpdate={refetchVehicleCard}
                  />
                }
                {vehicleCard &&
                  <GeneralCarPhotos
                    cardColors={vehicleCard.colors}
                    initiallyExpanded={false}
                    handleUpdate={refetchVehicleCard}
                  />
                }
                {vehicleCard &&
                  <VehicleCardMarketing
                    cardId={props.match.params.vehicleCardId}
                    inStoreCard={vehicleCard.inStoreCard}
                    marketing={vehicleCard.marketing}
                    initiallyExpanded={false}
                    handleUpdate={refetchVehicleCard}
                  />
                }
              </Card>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Container>
  )
}

const assembleCardFinance = (originalData: CardFinanceDto, vehicleCardFinance: VehicleCardFinance | null | undefined, vcc: Array<VehicleCardColor> | null | undefined, modelPrice: any, extraEquipments: Array<ExtraEquipmentItem> | null | undefined) => {
  const cardFinanceData: CardFinanceDto = Object.assign(originalData);
  let _fianceGroups: Array<FinanceGroupDto> = [];
  let groupedColors: Map<number, Array<VehicleCardColor>> = new Map<number, Array<VehicleCardColor>>();
  if (vcc && vcc.length > 0) {
    vcc.forEach(color => groupedColors.set(color.modelColor.price, [...groupedColors.get(color.modelColor.price) || [], color]));
  }

  if (vehicleCardFinance) {
    if (vehicleCardFinance.bonuses) {
      cardFinanceData.bonuses = Object.assign(vehicleCardFinance.bonuses);
    } else {
      cardFinanceData.bonuses = [];
    }

    cardFinanceData.autoCmsDiscountCRN = vehicleCardFinance.autoCmsDiscountCRN;
    cardFinanceData.autoCmsDiscountPP = vehicleCardFinance.autoCmsDiscountPP;
    cardFinanceData.coinsurance = vehicleCardFinance.coinsurance ? vehicleCardFinance.coinsurance : null;
    if (groupedColors.size > 0) {
      if (vehicleCardFinance.financeGroups && vehicleCardFinance.financeGroups.length > 0) {
        vehicleCardFinance.financeGroups.map(fg => FinanceGroupDto.convertFromFinanceGroup(fg, groupedColors.get(fg.groupPrice))).forEach(fgDto => _fianceGroups.push(fgDto));
      }

    } else if (vehicleCardFinance.financeGroups && vehicleCardFinance.financeGroups.length > 0) {
      vehicleCardFinance.financeGroups.map(fg => FinanceGroupDto.convertFromFinanceGroup(fg, null)).forEach(fgDto => _fianceGroups.push(fgDto));
    }

    cardFinanceData.id = vehicleCardFinance.id;
  }
  cardFinanceData.modelPrice = modelPrice;

  if (extraEquipments && extraEquipments.length > 0) {
    cardFinanceData.totalEquipment = extraEquipments.map(item => item.price).reduce(((previousValue, currentValue) => previousValue + currentValue), 0);
  } else {
    cardFinanceData.totalEquipment = 0;
  }


  if (vcc && vcc.length > 0) {
    groupedColors.forEach((value, key) => {
      if (!_fianceGroups.find(fg => fg.groupPrice === key)) {
        _fianceGroups.push(new FinanceGroupDto(key, value.map(color => ColorViewerDto.constructFromVehicleCardColor(color)), null, null, null));
      }
    });
  }
  cardFinanceData.financeGroups = _fianceGroups;
  cardFinanceData.financeGroups.sort((fg1, fg2) => fg1.groupPrice - fg2.groupPrice);

  return cardFinanceData;
}
