import {
  AuditInfo,
  BusinessError,
  Equipment,
  EquipmentCategory,
  EquipmentItem,
  LeasingTypeEnum,
  Maybe,
  ModelEquipment,
  VehicleCardStateEnum,
} from '../../api/graphql/generated/schema';
import { getUnique } from '../functionHelper';
import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';

const LIKE_CHARACTER = "*";

export const getModelEquipments = (modelEquipments: Array<{ __typename?: "ModelEquipment" } & Pick<ModelEquipment, "id" | "modelDefinitionId" | "name" | "sortNumber"> & { auditInfo: { __typename?: "AuditInfo" } & Pick<AuditInfo, "createdBy" | "created" | "updatedBy" | "updated">; equipments?: Maybe<Array<{ __typename?: "EquipmentItem" } & Pick<EquipmentItem, "id" | "count" | "description"> & { equipment: { __typename?: "Equipment" } & Pick<Equipment, "id" | "name"> & { auditInfo: { __typename?: "AuditInfo" } & Pick<AuditInfo, "createdBy" | "created" | "updatedBy" | "updated">; category: { __typename?: "EquipmentCategory" } & Pick<EquipmentCategory, "id" | "label"> } }>> }>) => {
  const equipments = modelEquipments?.filter(getUnique);

  return equipments ? equipments : [];
}

export const getApolloErrorMessages = (error: ApolloError) => {
  return error?.message;
}

export const getGQLErrorMessages = (error: GraphQLFormattedError) => {
  return error.message;
}

export const getBusinessErrorMessages = (error: BusinessError | undefined, translator, msgPrefix?: string) => {
  let prefix = msgPrefix ? msgPrefix + "." : "";
  return error ? translator("userError.businessError." + prefix + error?.code) : "No error";
}

export const searchLikeValue = (data: string, left: boolean, right: boolean) => {
  let prefix = left ? LIKE_CHARACTER : "";
  let suffix = right ? LIKE_CHARACTER : "";
  return prefix + data + suffix;
}

export const leasingContraAccountEnums: LeasingTypeEnum[]  = [ LeasingTypeEnum.ContraAccountCrn, LeasingTypeEnum.ContraAccountPhysicalPerson ];

export const CARD_STATE_ALL_ACTIVE = [
  VehicleCardStateEnum.InProgress,
  VehicleCardStateEnum.Approved,
  VehicleCardStateEnum.AwaitApproval,
  VehicleCardStateEnum.Published,
]
