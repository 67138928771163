import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import {useTranslation} from "react-i18next";
import ModelColorEditor from "./ModelColorEditor";
import {ModelColor as ModelColorType} from "../../../api/graphql/generated/schema";
import { CloseRounded } from '@mui/icons-material';

export default function ModelColorDialog(props: {modelDefinitionId: string, modelColors: ModelColorType[], refreshModelColors, open: boolean, handleClose}) {

  const {t} = useTranslation();

  return (
      <Dialog
          open={props.open}
          onClose={props.handleClose}
          fullWidth={true}
          maxWidth="lg"
      >
        <DialogTitle>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
              {t("pages.definitionEditor.modelDefinition.colorDialog.title")}
            </Grid>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <ModelColorEditor modelDefinitionId={props.modelDefinitionId} modelColors={props.modelColors}  refreshModelColors={props.refreshModelColors}/>
        </DialogContent>
      </Dialog>
  );

}
