import { ColorViewerDto } from '../ColorViewerDto';
import { LeasingDto } from '../leasing/LeasingDto';
import { FinanceGroup as FinanceGroupType, VehicleCardColor } from '../../api/graphql/generated/schema';
import { DeferredCreditDto } from '../credit/DeferredCreditDto';
import { CreditDto } from '../credit/CreditDto';

export class FinanceGroupDto {

  private readonly _id: string;
  private readonly _groupPrice: number;
  private _colors: Array<ColorViewerDto>;
  private _lease: LeasingDto | null;
  private _credit: CreditDto | null;
  private _deferredCredit: DeferredCreditDto | null;


  constructor(groupPrice: number, colors: Array<ColorViewerDto>, lease: LeasingDto | null, credit: CreditDto | null, deferredCredit: DeferredCreditDto | null) {
    this._id = groupPrice.toString();
    this._groupPrice = groupPrice;
    this._colors = colors;
    this._lease = lease;
    this._credit = credit;
    this._deferredCredit = deferredCredit;
  }

  static convertFromFinanceGroup(financeGroup: FinanceGroupType, colors: Array<VehicleCardColor> | null | undefined){
    let _colors: Array<ColorViewerDto> = colors ? colors.map(color => ColorViewerDto.constructFromVehicleCardColor(color)) : [];
    let _leasing: LeasingDto | null = financeGroup.lease ? LeasingDto.convertFromLease(financeGroup.lease) : null;
    let _credit: CreditDto | null = financeGroup.credit ? CreditDto.convertFromCredit(financeGroup.credit) : null;
    let _deferredCredit: DeferredCreditDto | null = financeGroup.deferredCredit ? DeferredCreditDto.convertFromCredit(financeGroup.deferredCredit) : null;
    return new FinanceGroupDto(financeGroup.groupPrice, _colors, _leasing, _credit, _deferredCredit);
  }

  get id(): string {
    return this._id;
  }

  get groupPrice(): number {
    return this._groupPrice;
  }

  get colors(): Array<ColorViewerDto> {
    return this._colors;
  }

  set colors(value: Array<ColorViewerDto>) {
    this._colors = value;
  }

  get lease(): LeasingDto | null {
    return this._lease;
  }

  set lease(value: LeasingDto | null) {
    this._lease = value;
  }

  get credit(): CreditDto | null {
    return this._credit;
  }

  set credit(value: CreditDto | null) {
    this._credit = value;
  }

  get deferredCredit(): DeferredCreditDto | null {
    return this._deferredCredit;
  }

  set deferredCredit(value: DeferredCreditDto | null) {
    this._deferredCredit = value;
  }
}
