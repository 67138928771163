import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {setContext} from "@apollo/client/link/context";
import {environment} from "../../environments/environment";

const httpLink = createHttpLink({
  uri: environment.graphQLEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('token') !== undefined ? JSON.parse(sessionStorage.getItem('token') || '{}') : ''
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const graphQLClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

onError(({ networkError }) => {
  // @ts-ignore
  if (networkError !== undefined && networkError.statusCode === 401) {
    window.location.reload();
  }
})

export default graphQLClient
