import {
  ModelColor as ModelColorType,
  VehicleCardColor as VehicleCardColorType
} from "../../../api/graphql/generated/schema";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useEffect, useState } from "react";
import { CardColorDto, ColorViewerDto } from "../../../modelView";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  List,
  ListItem,
  Popover
} from '@mui/material';
import {
  CreateVehicleCardColorMutationOptions,
  DeleteVehicleCardColorMutationOptions,
  useCreateVehicleCardColorMutation,
  useDeleteVehicleCardColorMutation
} from "../../../api/graphql/generated/hooks";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { ApolloError, ColorsViewer } from "../../index";
import { setLoadingStatus } from "../../../redux/loading";
import { Add, Remove } from '@mui/icons-material';

const initState = {
  "colors": [],
  "openRemoveDialog": false,
  "cardColorIdToDelete": null,

}

function assembleColors(modelColors: Array<ModelColorType>, cardColors: Array<VehicleCardColorType> | null | undefined) {
  let colors: Array<CardColorDto>;

  if (cardColors) {
    let existingMCs: Array<string> = []
    colors = cardColors.map(cc => {
      existingMCs.push(cc.modelColor.id);
      return CardColorDto.constructFromVehicleCardColor(cc)
    });

    colors.push(...modelColors.filter(mc => !existingMCs.includes(mc.id)).map(mc => CardColorDto.constructFromModelColor(mc)));

  } else {
    colors = modelColors.map(mc => CardColorDto.constructFromModelColor(mc))
  }

  return colors;
}

export default function CardColorsEditor(props: {modelColors: Array<ModelColorType>, cardColors: Array<VehicleCardColorType>, cardId: string, refreshParentData: Function}) {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [colors, setColors] = useState<Array<CardColorDto>>(initState.colors);
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(initState.openRemoveDialog);
  const [cardColorToDelete, setCardColorToDelete] = useState<CardColorDto | null>(initState.cardColorIdToDelete);

  const [createVehicleColorMut, {loading: gqlCreateVCCLoading}] = useCreateVehicleCardColorMutation();
  const [deleteVehicleColorMut, {loading: gqlDeleteVCCLoading}] = useDeleteVehicleCardColorMutation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setColors(assembleColors(props.modelColors, props.cardColors))
  }, [props.modelColors, props.cardColors]);

  useEffect(() => {
    dispatch(setLoadingStatus(gqlCreateVCCLoading || gqlDeleteVCCLoading))
  }, [gqlCreateVCCLoading, gqlDeleteVCCLoading, dispatch])


  const addCardColor = (cardId: string, modelColorId: string) => {
    let mutationOptions: CreateVehicleCardColorMutationOptions = {
      variables: {
        input: {
          cardId: cardId,
          modelColorId: modelColorId
        }
      }
    }

    createVehicleColorMut(mutationOptions)
    .then(response => {
      if (response.data?.createVehicleCardColor?.cardColor?.id){
        dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.color.created")));
        props.refreshParentData();
      } else if (response?.data?.createVehicleCardColor?.errors) {
        response.data.createVehicleCardColor.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCardColor"))));
      } else if(response.errors){
        response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
      }
    })
    .catch(error => <ApolloError error={error}/>);
  }

  const removeCardColor = (cardColor: CardColorDto | null ) => {
    if (cardColor?.colorCardId) {
      let mutationOptions: DeleteVehicleCardColorMutationOptions = {
        variables: {
          input: {
            cardColorId: cardColor.colorCardId,
          }
        }
      }

      deleteVehicleColorMut(mutationOptions)
      .then(response => {
        if (response.data?.deleteVehicleCardColor?.success){
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.color.deleted")));
          props.refreshParentData();
        } else if (response?.data?.deleteVehicleCardColor?.errors) {
          response.data.deleteVehicleCardColor.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCardColor"))));
        } else if(response.errors){
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      }).then(() => discardRemoveDialogState())
      .catch(error => <ApolloError error={error}/>)
    } else {
      dispatch(openErrorSnackbar(t("userError.vehicleCard.color.deleted")));
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const discardRemoveDialogState = () => {
    setOpenRemoveDialog(false);
    setCardColorToDelete(null);
  }

  const displayCardColorRemoveDialog = (cardColor: CardColorDto | null ) => {
    setCardColorToDelete(cardColor);
    setOpenRemoveDialog(true);
  }

  const renderVehicleColorItem = (color: CardColorDto) => {
    return (
        <ListItem button={false}>
          {color.colorCardId ? <Button startIcon={<Remove/>} onClick={() => displayCardColorRemoveDialog(color)} /> : <Button startIcon={<Add />} onClick={() => addCardColor(props.cardId, color.modelColorId)}/>}
          {color.name}, {color.basicName}, {color.type}, {color.price}
        </ListItem>
    )
  }

  const hasCardColors = () => {
    return props.cardColors?.length > 0;
  }

  const renderColorViewer = () => {
    if(hasCardColors()) {
      return (
          <Button className="addColorButton" onClick={handleClick}>
            <ColorsViewer inputValues={props.cardColors.map((cc) => ColorViewerDto.constructFromVehicleCardColor(cc))} displayMissingInfo={false} />
          </Button>
      )
    } else {
      return (
          <Button className="addColorButton" onClick={handleClick} >
            <Add/> {t("pages.vehicleCards.detail.actions.colorsAdd")}
          </Button>
      );
    }
  }

  const renderColorEditor = () => {
    return (<Popover
            id={"extra-equipment-add-new"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
        >
          <List>
            {colors.map(color => renderVehicleColorItem(color))}
          </List>
          <Dialog
              open={openRemoveDialog}
              onClose={discardRemoveDialogState}
              maxWidth={"sm"}
              fullWidth={true}
          >
            <DialogTitle title={'Delete'} id={'color-edit-remove-confirm-dialog'}>
              {t("pages.vehicleCards.detail.cardColor.removeDialogTitle")}
            </DialogTitle>
            <DialogContent>
              {t("pages.vehicleCards.detail.cardColor.removeDialogText", {"colorName": cardColorToDelete?.name})}
            </DialogContent>
            <DialogActions>
              <Button onClick={discardRemoveDialogState}
                      value={'Cancel'}
              >
                {t('actions.discard')}
              </Button>
              <Button
                      onClick={() => removeCardColor(cardColorToDelete)}
                      title={'Approve'}
                      className="icon-button">
                {t('actions.approve')}
              </Button>
            </DialogActions>
          </Dialog>
        </Popover>
    );
  }

  return (
      <div>
        <InputLabel id={'state-label'}>{ t('fieldName.color') }</InputLabel>
        {renderColorViewer()}
        {renderColorEditor()}
      </div>
  );

}
