import React from "react";
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect(props) {

  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    props.setFilteredValues(event.target.value as string[]);
  };

  return (
      <FormControl className={classes.formControl} required={props.required} error={props.required && !props.filteredValues} >
        <InputLabel id="multi-select-label">{props.label}</InputLabel>
        <Select
            label={props.label}
            labelId="multi-select-label"
            id="multi-select"
            multiple
            value={props.filteredValues}
            onChange={handleChange}
            MenuProps={MenuProps}
        >
          {props?.inputValues?.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );

}
