import React, { useEffect } from "react";
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { CreditGroupDto } from "../../../../modelView";
import { useTranslation } from "react-i18next";
import CreditGroupTab from "./CreditGroupTab";
import { DoneOutlined, WarningOutlined } from '@mui/icons-material';
import { TabContext } from '@mui/lab';

const creditGroupTabOrder = {
  CRN: 0,
  PHYSICAL_PERSON: 1,
}

const creditGroupTabComparator = (o1: CreditGroupDto, o2: CreditGroupDto) => {
  return creditGroupTabOrder[o1.type] <= creditGroupTabOrder[o2.type] ? -1 : 1;
}

export default function CreditGroups(props:{ creditGroups: Array<CreditGroupDto>, handleUpdate: Function }) {

  props.creditGroups.sort(creditGroupTabComparator);

  const {t} = useTranslation();

  const [selectedCreditGroup, setSelectedCreditGroup] = React.useState<string>(props.creditGroups.length > 0 ? props.creditGroups[0].type : "");

  useEffect(() => {
    if (selectedCreditGroup && selectedCreditGroup.length > 0 && props.creditGroups.length > 0 && !props.creditGroups.some((lg) => lg.type === selectedCreditGroup)){
      setSelectedCreditGroup(props.creditGroups[0].type);
    }

  }, [props.creditGroups, selectedCreditGroup])

  const handleTabChange = (event: React.ChangeEvent<{}>, data: string) => {
    setSelectedCreditGroup(data);
  }

  const handleGroupUpdate = (dataToUpdate: CreditGroupDto) => {
    let _creditGroups = [...props.creditGroups.filter(item => item.type !== dataToUpdate.type), dataToUpdate];
    props.handleUpdate(_creditGroups);
  }

  const renderEndIcon = (hasError) => {
    return hasError ? <WarningOutlined color={"error"}/> : <DoneOutlined color={"primary"}/>;
  }

  return (
      <Box>
        <TabContext value={selectedCreditGroup}>
          <Box mb={2}>
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                <Tabs
                    value={selectedCreditGroup}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                  {props.creditGroups.map((item) => (
                      <Tab value={item.type} label={t("creditGroup.typeEnum.values." + item.type)} wrapped={true}  icon={renderEndIcon(item.hasAnyInvalidItem())}/>
                  ))}
                </Tabs>
                {props.creditGroups.map((item) => <CreditGroupTab creditGroup={item} handleUpdate={handleGroupUpdate}/>)}
              </Grid>
            </Grid>
          </Box>
        </TabContext>
      </Box>
  );
}
