import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl, Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Brand,
  ModelColor as ModelColorType,
  ModelDefinition as ModelDefinitionType,
  VehicleType,
} from '../../../api/graphql/generated/schema';
import { ApolloError, CodetableSelect, GraphQLError } from '../../index';
import {
  CreateModelDefinitionMutationOptions,
  UpdateModelDefinitionMutationOptions,
  useCreateModelDefinitionMutation,
  useGetBrandsQuery,
  useGetVehicleTypesQuery,
  useUpdateModelDefinitionMutation,
} from '../../../api/graphql/generated/hooks';
import ModelColorDialog from '../modelColor/ModelColorDialog';
import { setLoadingStatus } from '../../../redux/loading';
import { openErrorSnackbar, setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import ColorsViewer from '../../common/ColorsViewer';
import { ColorViewerDto } from '../../../modelView';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { ExpandMore } from '@mui/icons-material';
import { MAX_MODEL_YEAR, MIN_MODEL_YEAR } from '../../../utils/const';

const initUserDataState = {
  "inputBrands": [],
  "inputVehicleTypes": [],
  "modelDefinitionId": '',
  "brandId": '',
  "vehicleTypeId": '',
  "modelLine": '',
  "model": '',
  "modelYear": '',
  "modelColors": []
}

export const validateModelYear = (userModelYear: string) => {
  let currentModelYear = parseInt(userModelYear);
  return currentModelYear >= MIN_MODEL_YEAR && currentModelYear <= MAX_MODEL_YEAR;
}
//FIXME: Form validation
export default function ModelDefinition(props: { modelDefinition: ModelDefinitionType | null, initiallyExpanded?: boolean, handleUpdateVehicleDefinition, refetch? }) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: loadingBrands, data: gqlBrands, error: errorBrands } = useGetBrandsQuery();
  const { loading: loadingVehicleTypes, data: gqlVehicleTypes, error: errorVehicleTypes } = useGetVehicleTypesQuery();
  const [updateModelDefinitionMutation, { loading: loadingUpdateModelDef, error: errorUpdateModelDef }] = useUpdateModelDefinitionMutation();
  const [createModelDefinitionMutation, { loading: loadingCreateModelDef, error: errorCreateModelDef }] = useCreateModelDefinitionMutation();
  const [changed, setChanged] = useState(false);
  const [inputBrands, setInputBrands] = React.useState<Brand[]>(initUserDataState.inputBrands);
  const [inputVehicleTypes, setInputVehicleTypes] = React.useState<VehicleType[]>(initUserDataState.inputVehicleTypes);
  const [modelDefinitionId, setModelDefinitionId] = React.useState<string>(initUserDataState.modelDefinitionId);
  const [userBrandId, setUserBrandId] = React.useState<string>(initUserDataState.brandId);
  const [userVehicleTypeId, setUserVehicleTypeId] = React.useState<string>(initUserDataState.vehicleTypeId);
  const [userModelLine, setUserModelLine] = React.useState<string>(initUserDataState.modelLine);
  const [userModel, setUserModel] = React.useState<string>(initUserDataState.model);
  const [userModelYear, setUserModelYear] = React.useState<string>(initUserDataState.modelYear);
  const [userModelColors, setUserModelColors] = React.useState<ModelColorType[]>(initUserDataState.modelColors);
  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [openModelColorDialog, setOpenModelColorDialog] = React.useState<boolean>(false)


  useEffect(() => {
    if (!loadingBrands && gqlBrands) {
      setInputBrands(gqlBrands.getBrands);
      if (props.modelDefinition) {
        setUserBrandId(props.modelDefinition.brand?.id);
      }
    }

  }, [loadingBrands, gqlBrands, props.modelDefinition]);

  useEffect(() => {
    if (!loadingVehicleTypes && gqlVehicleTypes) {
      setInputVehicleTypes(gqlVehicleTypes.getVehicleTypes);
      if (props.modelDefinition) {
        setUserVehicleTypeId(props.modelDefinition.vehicleType?.id);
      }
    }

  }, [loadingVehicleTypes, gqlVehicleTypes, props.modelDefinition]);

  useEffect(() => {
    if (props.modelDefinition) {
      setModelDefinitionId(props.modelDefinition.id)
      setUserModelLine(props.modelDefinition.line);
      setUserModel(props.modelDefinition.model);
      setUserModelYear(props.modelDefinition.modelYear.toString());
      setUserModelColors(props.modelDefinition.colors ? props.modelDefinition.colors : initUserDataState.modelColors)
    }
  }, [props.modelDefinition]);

  const reset = () => {
    setChanged(false);
    if (props.modelDefinition) {
      setModelDefinitionId(props.modelDefinition.id)
      setUserBrandId(props.modelDefinition.brand?.id);
      setUserVehicleTypeId(props.modelDefinition.vehicleType?.id);
      setUserModelLine(props.modelDefinition.line);
      setUserModel(props.modelDefinition.model);
      setUserModelYear(props.modelDefinition.modelYear.toString());
      setUserModelColors(props.modelDefinition.colors ? props.modelDefinition.colors : initUserDataState.modelColors)
    } else {
      setModelDefinitionId(initUserDataState.modelDefinitionId)
      setUserBrandId(initUserDataState.brandId);
      setUserVehicleTypeId(initUserDataState.vehicleTypeId);
      setUserModelLine(initUserDataState.modelLine);
      setUserModel(initUserDataState.model);
      setUserModelYear(initUserDataState.modelYear);
      setUserModelColors(initUserDataState.modelColors);
    }
  }

  const changedInput = (setFunction) => (value: string) => {
    setFunction(value);
    setChanged(true);
  }

  const updateModelDefinition = () => {
    if (validateModelYear(userModelYear)) {
      updateValidModelDefinition();
    } else {
      dispatch(setSnackbar(true, SnackbarStates.ERROR, t("pages.definitionEditor.modelDefinition.invalid")))
    }
  }

  const updateValidModelDefinition = () => {
    let inputOptions: UpdateModelDefinitionMutationOptions = {
      variables: {
        input: {
          id: modelDefinitionId,
          brandId: userBrandId,
          vehicleTypeCode: userVehicleTypeId,
          line: userModelLine.trim(),
          model: userModel.trim(),
          modelYear: parseInt(userModelYear)
        }
      },
      onError: (error) => <ApolloError error={[error]}/>,
    };
    updateModelDefinitionMutation(inputOptions)
      .then(response => {
        if (response?.data?.updateModelDefinition?.modelDefinition) {
          dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t("userMessages.modelDefinitionSaved")));
          console.log("Model definition updated", response);
          setChanged(false);
        } else if (response?.data?.updateModelDefinition?.errors) {
          response.data.updateModelDefinition.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "modelDefinition"))));
        } else if (response.errors) {
          response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
        }
      })
      .catch(error => <ApolloError error={error}/>);
  };

  const createModelDefinition = () => {
    let inputOptions: CreateModelDefinitionMutationOptions = {
      variables: {
        input: {
          brandId: userBrandId,
          vehicleTypeCode: userVehicleTypeId,
          line: userModelLine.trim(),
          model: userModel.trim(),
          modelYear: parseInt(userModelYear)
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    createModelDefinitionMutation(inputOptions)
      .then(response => {
        if (response?.data?.createModelDefinition?.modelDefinition) {
          props.handleUpdateVehicleDefinition(response.data.createModelDefinition.modelDefinition);
          dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t("userMessages.modelDefinitionCreated")));
        } else if (response.errors) {
          return <GraphQLError error={response.errors}/>
        }
      }).then(() => setChanged(false))
      .catch(error => <ApolloError error={error}/>);
  };

  const handleOpenModelColorDialog = () => {
    setOpenModelColorDialog(true);
  };

  const handleCloseModelColorDialog = () => {
    setOpenModelColorDialog(false);
  };

  const isValid = (): boolean => {
    return !!userVehicleTypeId && !!userBrandId && !!userModelLine && !!userModel && !!userVehicleTypeId && !!userModelYear;
  }

  //Handle loading
  dispatch(setLoadingStatus(loadingBrands || loadingVehicleTypes || loadingUpdateModelDef || loadingCreateModelDef));
  //Handel errors
  if (errorBrands) return <ApolloError error={[errorBrands]}/>;
  if (errorVehicleTypes) return <ApolloError error={[errorVehicleTypes]}/>;
  if (errorUpdateModelDef) return <ApolloError error={[errorUpdateModelDef]}/>;
  if (errorCreateModelDef) return <ApolloError error={[errorCreateModelDef]}/>;
  //Handle rendering
  return (
    <form id="model-definition-form">
      <Accordion variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
        >
          {t("pages.definitionEditor.modelDefinition.title")}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item>
              <CodetableSelect
                inputValues={inputBrands}
                filteredValues={userBrandId}
                setFilteredValues={changedInput(setUserBrandId)}
                label={t("fieldName.brand")}
                required={true}
                multiselect={false}
              />
            </Grid>
            <Grid item>
              <CodetableSelect
                inputValues={inputVehicleTypes}
                filteredValues={userVehicleTypeId}
                setFilteredValues={changedInput(setUserVehicleTypeId)}
                label={t("fieldName.vehicleType")}
                required={true}
                multiselect={false}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  variant="outlined"
                  label={t("fieldName.line")}
                  value={userModelLine}
                  required={true}
                  error={!userModelLine}
                  onChange={event => changedInput(setUserModelLine)(event.target.value)}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  variant="outlined"
                  type="text"
                  label={t("fieldName.model")}
                  value={userModel}
                  required={true}
                  error={!userModel}
                  onChange={event => changedInput(setUserModel)(event.target.value)}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  variant="outlined"
                  type="number"
                  label={t("fieldName.modelYear")}
                  value={userModelYear}
                  required={true}
                  disabled={!!props.modelDefinition?.id}
                  error={!userModelYear}
                  onChange={event => changedInput(setUserModelYear)(event.target.value)}
                  inputProps={{
                    min: MIN_MODEL_YEAR,
                    max: MAX_MODEL_YEAR,
                    step: "1"
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        {modelDefinitionId
          && <AccordionDetails>
            <ColorsViewer inputValues={userModelColors.map((mc) => ColorViewerDto.constructFromModelColor(mc))} displayMissingInfo={false}/>
            <Button onClick={handleOpenModelColorDialog}>
              {userModelColors?.length !== 0 ? t("pages.definitionEditor.modelDefinition.editColorButton") : t("pages.definitionEditor.modelDefinition.addColorButton")}
            </Button>
            <ModelColorDialog modelDefinitionId={modelDefinitionId} modelColors={userModelColors} refreshModelColors={props.refetch} open={openModelColorDialog}
                              handleClose={handleCloseModelColorDialog}/>
          </AccordionDetails>
        }
        <AccordionActions>
          <Button onClick={reset} disabled={!changed}>{t("actions.discard")}</Button>
          {modelDefinitionId && <Button onClick={updateModelDefinition} disabled={!changed || !isValid()}>{t("pages.definitionEditor.modelDefinition.actions.save")}</Button>}
          {!modelDefinitionId && <Button onClick={createModelDefinition} disabled={!changed || !isValid()}>{t("pages.definitionEditor.modelDefinition.actions.create")}</Button>}
        </AccordionActions>
      </Accordion>
    </form>

  );

}
