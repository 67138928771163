import { CreditTypeEnum, DeferredCreditGroup as DeferredCreditGroupType } from '../../api/graphql/generated/schema';
import { DeferredCreditGroupItemDto } from './DeferredCreditGroupItemDto';

export class DeferredCreditGroupDto {

  private readonly _type: CreditTypeEnum;
  private _items: Array<DeferredCreditGroupItemDto>;


  constructor(type: CreditTypeEnum, items:  Array<DeferredCreditGroupItemDto>) {
    this._type = type;
    this._items = items;
  }

  static convertFromDeferredCreditGroup(creditGroup: DeferredCreditGroupType) {
    let _items = creditGroup.items ? creditGroup.items.map(item => DeferredCreditGroupItemDto.convertFromDeferredCreditGroupItem(item)) : [];
    return new DeferredCreditGroupDto(creditGroup.type, _items);
  }

  get type(): CreditTypeEnum {
    return this._type;
  }

  get items():  Array<DeferredCreditGroupItemDto> {
    return this._items;
  }

  set items(value:  Array<DeferredCreditGroupItemDto>) {
    this._items = value;
  }

  hasAnyInvalidItem(){
    return this._items && this._items.some((item) => !item.isValid());
  }
}
