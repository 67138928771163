import { LeasingGroupItem as LeasingGroupItemType } from "../../api/graphql/generated/schema";
import { isValidMonthlyInstalment } from "./LeasingUtil";

export class LeasingGroupItemDto {

  private _month: number | null;
  private _monthlyInstalment: number | null;
  private _totalKmPerYear: number | null;


  constructor(month: number | null, totalKmPerYear: number | null, monthlyInstalment: number | null) {
    this._month = month;
    this._monthlyInstalment = monthlyInstalment;
    this._totalKmPerYear = totalKmPerYear;
  }

  static convertFromLeasingGroupItem(leasingGroupItem: LeasingGroupItemType) {
    let _month = leasingGroupItem.month ? leasingGroupItem.month : -1;
    let _totalKms = leasingGroupItem.totalKmPerYear ? leasingGroupItem.totalKmPerYear : -1;
    let _monthlyInstalment = leasingGroupItem.monthlyInstalment;

    return new LeasingGroupItemDto(_month, _totalKms, _monthlyInstalment);
  }


  get month(): number | null {
    return this._month;
  }

  set month(value: number | null) {
    this._month = value;
  }

  get monthlyInstalment(): number | null{
    return this._monthlyInstalment;
  }

  set monthlyInstalment(value: number | null) {
    this._monthlyInstalment = value;
  }

  get totalKmPerYear(): number | null {
    return this._totalKmPerYear;
  }

  set totalKmPerYear(value: number | null) {
    this._totalKmPerYear = value;
  }

  hasError(){
    return (!this._month && !this._totalKmPerYear && !this._monthlyInstalment) || (!isValidMonthlyInstalment(this._monthlyInstalment, false));
  }

  isValid(){
    return this._month && this._totalKmPerYear && this._monthlyInstalment
  }

}
