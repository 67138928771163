import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuditInfo, ModelEquipment as ModelEquipmentType, ModelEquipmentItemInput } from '../../../api/graphql/generated/schema';
import {
  CreateModelEquipmentMutationOptions,
  UpdateModelEquipmentMutationOptions,
  useCreateModelEquipmentMutation,
  useGetModelEquipmentsQuery, useUpdateModelEquipmentMutation,
} from '../../../api/graphql/generated/hooks';
import { getApolloErrorMessages, getGQLErrorMessages, getModelEquipments } from '../../../utils/graphGL/graphQLHelper';
import { ApolloError, ModelEquipmentSelect } from '../../index';
import { setLoadingStatus } from '../../../redux/loading';
import { AddOutlined, EditOutlined, ExpandMore, FileCopyOutlined } from '@mui/icons-material';
import Equipment from '../../common/equipment/Equipment';
import { EquipmentDto } from '../../../modelView';
import { logger } from '../../../utils/functionHelper';
import { setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import { EquipmentItemDto } from '../../../modelView'
import { EquipmentItemShortenedType } from '../../common/equipment/EquipmentViewer';

const initUserDataState = {
  "changed": false,
  "userModelEquipment": null,
  "selectedModelEquipment": null,
  "selectedEquipmentItems": []
}

export type CurrentModelEquipmentType = {
  id: string;
  auditInfo: AuditInfo;
  modelDefinitionId: string;
  name: string;
  sortNumber: number;
  equipments?: Array<EquipmentItemShortenedType>;
}

export default function ModelEquipment(props: {
  modelDefinitionId: string,
  modelEquipment: ModelEquipmentType | null,
  initiallyExpanded?: boolean,
  handleUpdateVehicleDefinition,
}) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [editButtonTitle] = React.useState<string>(t("actions.edit"))
  const [duplicateButtonTitle] = React.useState<string>(t("actions.duplicate"))
  const [addButtonTitle] = React.useState<string>(t("actions.add"))
  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [changed, setChanged] = React.useState<boolean>(false)
  const [currentModelEquipment, setCurrentModelEquipment] = React.useState<CurrentModelEquipmentType | null>(initUserDataState.userModelEquipment)
  const [userModelEquipment, setUserModelEquipment] = React.useState<EquipmentDto | null>(initUserDataState.userModelEquipment)
  const [inputModelEquipments, setInputModelEquipments] = React.useState<ModelEquipmentType[]>([]);
  const [openEquipmentEditorDialog, setOpenEquipmentEditorDialog] = React.useState<boolean>(false);

  const {loading: loadingModelEquipments, data: gqlModelEquipments, error: errorModelEquipments, refetch: refetchModelEquipments} = useGetModelEquipmentsQuery({variables: { modelDefinitionId: props.modelDefinitionId}});

  const [createModelEquipmentMutation, { loading: loadingCreateModelEquip, error: errorCreateModelEquip }] = useCreateModelEquipmentMutation();
  const [updateModelEquipmentMutation, { loading: loadingUpdateModelEquip, error: errorUpdateModelEquip }] = useUpdateModelEquipmentMutation();

  useEffect(() => {
    dispatch(setLoadingStatus(loadingModelEquipments));
  }, [loadingModelEquipments, dispatch])

  useEffect(() => {
    if (gqlModelEquipments) {
      let possibleModelEquipments = getModelEquipments(gqlModelEquipments.getModelEquipments);
      setInputModelEquipments(possibleModelEquipments);
      if(!currentModelEquipment){
        selectUserModelEquipment(possibleModelEquipments);
      }
    }

  }, [gqlModelEquipments, props.modelEquipment]);

  const convertModelEquipment = (model: ModelEquipmentType): CurrentModelEquipmentType => {
    return {
      ...model,
      equipments: model.equipments?.map(item => {
        return {
          id: item.equipment.id,
          relationId: item.id,
          description: item.description,
          count: item.count,
          name: item.equipment.name,
          category: {
            id: item.equipment.category.id,
            label: item.equipment.category.label,
          }
        }
      })
    }
  }

  const selectUserModelEquipment = (possibleModelEquipments: Array<ModelEquipmentType>) => {
    if (props.modelEquipment?.id) {
      let vehicleDefinitionModelEquipmentId = props.modelEquipment.id;
      let vdhModelEquipment = possibleModelEquipments.find(meq => meq.id === vehicleDefinitionModelEquipmentId);
      if (vdhModelEquipment) {
        setCurrentModelEquipment(convertModelEquipment(vdhModelEquipment));
      }
    }
  };

  const reset = () => {
    setChanged(false);
    selectUserModelEquipment(inputModelEquipments);
  }

  const resetModelEquipmentData = () => {
    if (currentModelEquipment) {
      setUserModelEquipment(EquipmentDto.convertFromModelEquipment(currentModelEquipment));
    } else {
      setUserModelEquipment(initUserDataState.userModelEquipment);
    }
  }

  const handleOpenEquipmentEditorDialog = () => {
    setOpenEquipmentEditorDialog(true);
  };

  const handleCloseEquipmentEditorDialog = () => {
    setOpenEquipmentEditorDialog(false);
  };

  const handleEditModelEquipments = () => {
    if (currentModelEquipment){
      setUserModelEquipment(EquipmentDto.convertFromModelEquipment(currentModelEquipment));
      handleOpenEquipmentEditorDialog();
    }
  }

  const handleAddModelEquipments = () => {
    setCurrentModelEquipment(null);
    setUserModelEquipment(EquipmentDto.convertFromModelEquipment(null));
    handleOpenEquipmentEditorDialog();
  }

  const handleDuplicateModelEquipments = () => {
    if (currentModelEquipment){
      setUserModelEquipment(EquipmentDto.duplicateFromModelEquipment(currentModelEquipment));
      setCurrentModelEquipment(null);
      handleOpenEquipmentEditorDialog();
    }
  }

  const handleUpdateVehicleDefinition = () => {
    props.handleUpdateVehicleDefinition(currentModelEquipment);
    setChanged(false);
  };

  const handleModelEquipmentChange = (modelEquipment: ModelEquipmentType) => {
    if (modelEquipment.id !== props.modelEquipment?.id) {
      setChanged(true);
    } else {
      setChanged(false);
    }
    setCurrentModelEquipment(convertModelEquipment(modelEquipment));
  };

  if (errorModelEquipments) return <ApolloError error={[errorModelEquipments]} />;
  if (errorCreateModelEquip) return <ApolloError error={[errorCreateModelEquip]} />;
  if (errorUpdateModelEquip) return <ApolloError error={[errorUpdateModelEquip]} />;

  const createModelEquipment = (modelEquipmentName: string, items: ModelEquipmentItemInput[], sortNumber: number) => {
    let inputOptions: CreateModelEquipmentMutationOptions = {
      variables: {
        input: {
          name: modelEquipmentName,
          modelDefinitionId: props.modelDefinitionId,
          modelEquipmentItems: items,
          sortNumber: sortNumber,
        }
      },
      onError: (error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error)))
    };
    createModelEquipmentMutation(inputOptions)
        .then( async (response) => {
          if (response?.data?.createModelEquipment?.modelEquipment) {
            let modelEquipment: ModelEquipmentType = response.data.createModelEquipment.modelEquipment;
            dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t("userMessages.modelEquipment.created")));
            setCurrentModelEquipment(convertModelEquipment(modelEquipment))
            await refetchModelEquipments();
          } else if (response.errors) {
            response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
          }
        })
        .then(() => setChanged(true))
        .catch((error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))));
  }

  const updateModelEquipment = (modelEquipmentId: string, modelEquipmentName: string, items: ModelEquipmentItemInput[], sortNumber: number) => {
    let inputOptions: UpdateModelEquipmentMutationOptions = {
      variables: {
        input: {
          id: modelEquipmentId,
          name: modelEquipmentName,
          modelEquipmentItems: items,
          sortNumber: sortNumber,
        }
      },
      onError: (error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error)))
    };
    updateModelEquipmentMutation(inputOptions)
        .then(response => {
          if (response?.data?.updateModelEquipment?.modelEquipment) {
            dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t("userMessages.modelEquipment.updated")));
          } else if (response.errors) {
            response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
          }
        })
        .then(() => setChanged(true))
        .catch((error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))));
  };

  const handleSubmit = (userEquipmentName: string, userEquipmentItems: Array<EquipmentItemDto>, userModelEquipmentSortNumber: number | null ) => {
    if (!userEquipmentName || userModelEquipmentSortNumber === null) {
      logger("Model Equipment missing name");
      return;
    }
    const convertedModel = convertModelEquipmentInput(userEquipmentItems);
    const convertedUserInput = convertToModelEquipmentItemInput(userEquipmentItems);

    if (currentModelEquipment && props.modelEquipment) {
      // @ts-ignore
      setCurrentModelEquipment((prevState) => ({
        ...prevState,
        equipments: convertedModel
      }));
      updateModelEquipment(props.modelEquipment.id, userEquipmentName, convertedUserInput, userModelEquipmentSortNumber);
    } else if (userEquipmentName && (userModelEquipmentSortNumber != null)) {
      createModelEquipment(userEquipmentName, convertedUserInput, userModelEquipmentSortNumber);
    } else {
      logger("Model Equipment not updated. No change.");
    }
    handleCloseEquipmentEditorDialog();
  };


  const convertModelEquipmentInput = (userEquipmentItems: Array<EquipmentItemDto>): Array<EquipmentItemShortenedType>  => {
    return userEquipmentItems.map(item => {
      return {
        id: item.equipmentId,
        count: item.count,
        description: item.description,
        changed: item.modelChanged,
        name: item.equipmentName,
        category: {
          id: item.categoryId,
          label: item.categoryLabel,
        },
      }
    })
  }

  const findRelationId = (equipmentId: string) => {
    const equipment = currentModelEquipment?.equipments?.find(item => item.id === equipmentId);
    return equipment?.relationId;
  }

  const convertToModelEquipmentItemInput = (userEquipmentItems: Array<EquipmentItemDto>): ModelEquipmentItemInput[]  => {
    return userEquipmentItems.map(item => {
      return {
        id: findRelationId(item.equipmentId) || undefined,
        count: item.count,
        description: item.description,
        changed: item.modelChanged,
        equipmentId: item.equipmentId,
        equipmentName: item.equipmentName,
        categoryId: item.categoryId,
        equipmentChanged: item.equipmentChanged,
      }
    })
  }

  //Handle loading
  dispatch(setLoadingStatus(loadingModelEquipments));
  //Handle errors
  if (errorModelEquipments) return <ApolloError error={[errorModelEquipments]} />;
  //Handle rendering
  return (
      <Accordion variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
        <AccordionSummary
            expandIcon={<ExpandMore />}
        >
          {t("pages.definitionEditor.modelEquipment.title")}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ModelEquipmentSelect
                inputValues={inputModelEquipments}
                filteredValues={currentModelEquipment as ModelEquipmentType}
                setFilteredValues={handleModelEquipmentChange}
                label={t("fieldName.name")}
                multiselect={false}
            />

            {currentModelEquipment &&
              <IconButton>
                <Tooltip title={editButtonTitle}>
                  <EditOutlined onClick={handleEditModelEquipments}/>
                </Tooltip>
              </IconButton>
            }
            {currentModelEquipment &&
              <IconButton>
                <Tooltip title={duplicateButtonTitle}>
                  <FileCopyOutlined onClick={handleDuplicateModelEquipments}/>
                </Tooltip>
              </IconButton>
            }
            <IconButton>
              <Tooltip title={addButtonTitle}>
                <AddOutlined onClick={handleAddModelEquipments}/>
              </Tooltip>
            </IconButton>
            <Equipment
                open={openEquipmentEditorDialog}
                userEquipment={userModelEquipment}
                userEquipmentItems={currentModelEquipment?.equipments}
                handleClose={handleCloseEquipmentEditorDialog}
                handleReset={resetModelEquipmentData}
                handleSubmit={handleSubmit}
            />
          </div>
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={reset} disabled={!changed}>{t("actions.discard")}</Button>
          <Button onClick={handleUpdateVehicleDefinition} disabled={!changed}>{t("actions.save")}</Button>
        </AccordionActions>
      </Accordion>
  );

}
