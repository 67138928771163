import * as React from 'react';
import { VehicleDelivery as VehicleDeliveryType } from "../../api/graphql/generated/schema";
import VehicleDelivery, { DeliveryInputTypeEnum } from "./VehicleDelivery";

export default function VehicleDeliveryCommission(props: { deliveryDate: VehicleDeliveryType | null | undefined, setVehicleDelivery: Function, required?: boolean}) {
    return (
        <VehicleDelivery deliveryDate={props.deliveryDate}
                         setVehicleDelivery={props.setVehicleDelivery}
                         required={props.required}
                         hideInputs={[DeliveryInputTypeEnum.PLUS_DAYS]}
        />
    );
}
