import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid } from '@mui/material';
import PageHeader from '../../../components/pageHeader/PageHeader';
import VehicleCardOverviewFilter from './filter/vehicleCardOverviewFilter';
import VehicleCardOverviewTable from './table/vehicleCardOverviewTable';
import { VehicleCardFilter } from '../../../api/graphql/generated/schema';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../../routes/paths';
import { VehicleCardCreate } from '../../../components';
import { CARD_STATE_ALL_ACTIVE } from '../../../utils/graphGL/graphQLHelper';
import { GLOBAL_SESSION_STATE_NAMES } from '../../../App';
import { defaultRowsPerPage } from '../../../utils/const';
import { Add } from '@mui/icons-material';

const breadcrumbs: AppPageNameRouteType[] = [
    AppPageNameRoutes.VEHICLE_NEW_CARDS,
];

const initVehicleDefinitionFilter: VehicleCardFilter = {
    state: CARD_STATE_ALL_ACTIVE,
}

export type VehicleCardPageFilterType = {
    filter: VehicleCardFilter;
    sort: any;
    offset: number;
    limit: number;
}

const handlePageFilterInit = (): VehicleCardPageFilterType => {
    const sessionFilter: string | null = sessionStorage.getItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_CARDS_PAGE_FILTER_ID);
    return sessionFilter ? JSON.parse(sessionFilter) : { filter: initVehicleDefinitionFilter, sort: null, offset: 0, limit: defaultRowsPerPage };
}

export default function VehicleCardOverview() {
    const { t } = useTranslation();
    const [openCreateCardDialogDialog, setOpenCreateCardDialogDialog] = useState<boolean>(false);

    const [pageFilter, setPageFilter] = useState<VehicleCardPageFilterType>(handlePageFilterInit());
    const [key, setKey] = useState<string>('');

    const updatePageFilter = (filter: VehicleCardFilter, limit: number, offset: number, sort: any) => {
        let currentPageFilter: VehicleCardPageFilterType = {
            filter,
            limit,
            offset,
            sort,
        };

        setPageFilter(currentPageFilter);
        sessionStorage.setItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_CARDS_PAGE_FILTER_ID, JSON.stringify(currentPageFilter));
    }

    const onSubmitFilter = (submittedFilter: VehicleCardFilter) => {
        setKey(Math.random().toString(36).substring(7))
        updatePageFilter(submittedFilter, defaultRowsPerPage, 0, null);
    }

    const handleOpenCreateCardDialog = () => {
        setOpenCreateCardDialogDialog(true);
    };

    const handleCloseCreateCardDialog = () => {
        setOpenCreateCardDialogDialog(false);
    };

    return (
        <Container className="page vehicle-card">
            <Grid justifyContent={'space-between'}>
                <Grid
                    item
                    className="text-left"
                >
                    <Button startIcon={<Add/>} className="button new-card" onClick={handleOpenCreateCardDialog}>
                        {t('pages.vehicleCards.addNewCard')}
                    </Button>
                    <PageHeader headline={'cards'} breadcrumbs={breadcrumbs}/>
                </Grid>

            </Grid>

            <VehicleCardCreate open={openCreateCardDialogDialog} handleClose={handleCloseCreateCardDialog}/>
            <VehicleCardOverviewFilter handleSubmitFilter={onSubmitFilter} appliedFilter={pageFilter.filter}/>
            <VehicleCardOverviewTable pageFilter={pageFilter} key={key} handlePageFilter={updatePageFilter}/>
        </Container>
    )

}
