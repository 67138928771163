import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Theme, SelectChangeEvent } from '@mui/material';
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 195,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function EnumSelect(
    props: {
        inputValues,
        filteredValues,
        setFilteredValues,
        label: string,
        enumLabelsPath: string,
        disabled?: boolean,
        multiselect?: boolean,
        required?: boolean,
    }) {

    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        props.setFilteredValues(event.target.value as string[]);
    };

    return (
        <FormControl className={classes.formControl} required={props.required} error={props.required && !props.filteredValues}>
            <InputLabel htmlFor="enum-multi-select-label">{props.label}</InputLabel>
            <Select
                label={props.label}
                labelId="enum-multi-select-label"
                id="enum-multi-select"
                multiple={props.multiselect}
                value={props.filteredValues ? props.filteredValues : ''}
                onChange={handleChange}
                MenuProps={MenuProps}
                disabled={props.disabled}
            >
                {props.multiselect &&
                    <MenuItem key={"all"} value={''}>
                        {t("all")}
                    </MenuItem>
                }
                {props?.inputValues?.map((value) => (
                    <MenuItem key={value} value={value}>
                        {t(props.enumLabelsPath + "." + value)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

}
