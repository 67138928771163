import { EquipmentCategory } from '../../../api/graphql/generated/schema';
import { EquipmentItemShortenedType, EquipmentsInCategoryType } from './EquipmentViewer';
import { UserEquipmentsCategoryType } from './equipmentItems/EquipmentItemsEditor';
import { UserEquipmentItemDto } from './equipmentItems/UserEquipmentItemDto';

export const assembleEquipmentsByCode = (equipmentItems: EquipmentItemShortenedType[] | undefined | null) => {
  let categories: Map<string, EquipmentsInCategoryType> = new Map();
  equipmentItems?.forEach(equipmentItem => {
    if(equipmentItem.category?.id){
      if(categories.has(equipmentItem.category.id)){
        categories.get(equipmentItem.category.id)?.equipmentItems.push(equipmentItem);
      } else {
        let newCategoryEquipment: EquipmentsInCategoryType = {
          category: {id: equipmentItem.category.id, label: equipmentItem.category.label},
          equipmentItems: [equipmentItem],
        }
        categories.set(equipmentItem.category.id, newCategoryEquipment);
      }
    }
  })

  return Array.from(categories.values()).sort(comparatorModelEquipmentsInCategory);
}

function comparatorModelEquipmentsInCategory(a: EquipmentsInCategoryType, b: EquipmentsInCategoryType) {
  if(!a.category.label){
    return -1;
  } else if (!b.category.label){
    return 1
  } else if ( a.category.label < b.category.label ){
    return -1;
  } else if ( a.category.label > b.category.label ){
    return 1;
  } else {
    return 0;
  }
}

function comparator(a: UserEquipmentsCategoryType, b: UserEquipmentsCategoryType) {
  if ( a.categoryLabel < b.categoryLabel ){
    return -1;
  }
  if ( a.categoryLabel > b.categoryLabel ){
    return 1;
  }
  return 0;
}
function sortAlphabetically(equipmentA: UserEquipmentItemDto, equipmentB:UserEquipmentItemDto) {
  const nameA = equipmentA.equipmentName.toUpperCase()
  const nameB = equipmentB.equipmentName.toUpperCase()
  return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
}

export const assembleModelEquipmentsByCode = (modelEquipments: UserEquipmentItemDto[] | undefined | null) => {
  let categories: Map<string, UserEquipmentsCategoryType> = new Map();
  modelEquipments?.forEach(equipmentItem => {
    if(equipmentItem.equipmentCategoryId){
      if(categories.has(equipmentItem.equipmentCategoryId)){
        categories.get(equipmentItem.equipmentCategoryId)?.equipmentItems.push(equipmentItem);
      } else {
        let newCategoryEquipment: UserEquipmentsCategoryType = {
          categoryId: equipmentItem.equipmentCategoryId,
          categoryLabel: equipmentItem.equipmentCategoryLabel,
          equipmentItems: [equipmentItem],
        }
        categories.set(equipmentItem.equipmentCategoryId, newCategoryEquipment);
      }
    }
  })

  const sorted = Array.from(categories.values()).sort(comparator);
  sorted.forEach(userEquipmentItem => userEquipmentItem.equipmentItems.sort(sortAlphabetically));
  return sorted;
}


export const getCategoryLabel = (category: EquipmentCategory) => {
  return category.label ? category.label : 'Missing Category Label for category ID: ' + category.id
}

