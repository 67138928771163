import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Card,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { rowsPerPage } from '../../../../utils/const';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import * as React from 'react';
import { ReactComponent as SettingWheel } from '../../../../components/icon/SettingWheel.svg';
import { formatDateTime } from '../../../../utils/formatterHelper';
import { AppPageNameRoutes } from '../../../../routes/paths';
import MoreVertPopover from '../../../../components/common/MoreVertPopover';
import { FiberManualRecord } from '@mui/icons-material';
import { ColorsViewer, StyledTableCell, StyledTableRow } from '../../../../components';
import { ColorViewerDto } from '../../../../modelView';
import { UsedVehicleCardPage, UsedVehicleCardStateEnum } from '../../../../api/graphql/generated/schema';


export default function UsedVehicleCardsTable(props: { dataPage: UsedVehicleCardPage | null |undefined, handleChangePage, handleChangeRowsPerPage, updateArchiveState, duplicateVehicleCard }) {

    const { t } = useTranslation();
    const history = useHistory();

    const transmissionColumnTranslation = t('fieldName.transmission');

    const redirectToEdit = (row) => {
        let path = AppPageNameRoutes.VEHICLE_USED_CARD_DETAIL.pageRoute.replace(':usedVehicleCardId', row.id);
        history.push(path);
    }

    const stateIconHandler = (state: UsedVehicleCardStateEnum) => {
        let tooltipTitle = t('pages.vehicleCards.states.' + state);
        let className = '';
        switch (state) {
            case UsedVehicleCardStateEnum.InProgress:
            case UsedVehicleCardStateEnum.Approved:
            case UsedVehicleCardStateEnum.AwaitApproval:
                className = 'orange-dot';
                break;
            case UsedVehicleCardStateEnum.Published:
                className = 'green-dot';
                break;
            case UsedVehicleCardStateEnum.Sold:
                className = 'grey-dot';
                break;
            case UsedVehicleCardStateEnum.Archived:
                className = 'red-dot';
                break;
        }
        return (
            <Tooltip title={tooltipTitle}>
                <FiberManualRecord className={className}/>
            </Tooltip>
        );
    }

    return (
        <Card variant="outlined" className="card-overview">
            <TableContainer component={Paper}>
                <Table aria-label="customized table" className="dataTable__holder">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t('fieldName.state')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.vin')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.brand')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.line')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelYear')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.model')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.modelEquipment')}</StyledTableCell>
                            <StyledTableCell align="center">
                                <Tooltip title={transmissionColumnTranslation}>
                                    <SettingWheel/>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.color')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.updated')}</StyledTableCell>
                            <StyledTableCell align="center">{t('fieldName.actions')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!props.dataPage || !props.dataPage.data || props.dataPage.data.length === 0) &&
                            <Typography variant={'subtitle1'}>
                                {t('datatable.empty')}
                            </Typography>
                        }
                        {props.dataPage?.data.map((row) => (
                            <StyledTableRow key={row?.id} onClick={() => redirectToEdit(row)}>

                                <StyledTableCell align="center">
                                    {stateIconHandler(row.state)}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition.vin}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition?.brand?.label}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition?.line}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition?.modelYear}</StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition?.model}</StyledTableCell>
                                <StyledTableCell
                                    align="center">{row?.vehicleDefinition?.equipment?.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row?.vehicleDefinition?.transmissionType?.label}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {row?.vehicleDefinition?.color &&
                                        <ColorsViewer inputValues={[ColorViewerDto.constructFromUsedVehicleCardColor(row.vehicleDefinition.color)]} displayMissingInfo={true}
                                                      maxDisplayed={2}/>
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Tooltip title={row?.updatedBy || row?.createdBy}>
                                        <span>
                                          {formatDateTime(row?.updated || row?.created)}
                                        </span>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <MoreVertPopover
                                        rowId={row.id}
                                        rowState={row.state.toString()}
                                        archivedState={UsedVehicleCardStateEnum.Archived}
                                        activeState={UsedVehicleCardStateEnum.InProgress}
                                        updateArchiveState={props.updateArchiveState}
                                        duplicateVehicleCard={props.duplicateVehicleCard}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.dataPage?.pageInfo &&
                <TablePagination
                    component={'div'}
                    rowsPerPageOptions={rowsPerPage}
                    labelRowsPerPage={t('datatable.paging')}
                    count={props.dataPage.pageInfo.totalCount ? props.dataPage.pageInfo.totalCount : 0}
                    rowsPerPage={props.dataPage.pageInfo.limit ? props.dataPage.pageInfo.limit : 0}
                    page={props.dataPage.pageInfo.offset ? props.dataPage.pageInfo.offset : 0}
                    SelectProps={{ native: true }}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            }
        </Card>
    );
}
