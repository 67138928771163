import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormControl, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { EquipmentCategory as EquipmentCategoryType } from "../../../../api/graphql/generated/schema";
import { CreateEquipmentMutationOptions, useCreateEquipmentMutation } from "../../../../api/graphql/generated/hooks";
import {
  getApolloErrorMessages,
  getBusinessErrorMessages,
  getGQLErrorMessages
} from "../../../../utils/graphGL/graphQLHelper";
import { AddOutlined } from '@mui/icons-material';
import { setLoadingStatus } from '../../../../redux/loading';
import { setSnackbar, SnackbarStates } from '../../../../redux/snackbar';
import { CodetableSelect } from '../../../index';

export type UserModelEquipmentItemType = {
  id: string | null,
  equipmentId: string | null,
  equipmentName: string,
  equipmentCategoryId: string,
  equipmentCategoryLabel: string,
  equipmentChanged: boolean,
  description: string | null | undefined,
  count: number | null | undefined
  changed: boolean
  isApplied: boolean
}

const initUserDataState = {
  "inputModelEquipmentName": null,
  "inputIsApplied": false,
  "inputEquipmentName": "",
  "inputEquipmentCategory": "",
  "inputEquipmentDescription": null,
  "inputEquipmentCount": null,
  "modelEquipmentsByCategory": [],
  "possibleEquipmentCategory": [],
  "saveButtonTitle": ""

}

export default function EquipmentItemInput(props: {possibleEquipmentsCategories: EquipmentCategoryType[], addRow}) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [createEquipmentMutation, {loading: loadingCreateEquipmentMutation}] = useCreateEquipmentMutation();
  const [userEquipmentName, setUserEquipmentName] = React.useState<string>(initUserDataState.inputEquipmentName);
  const [userEquipmentCategory, setUserEquipmentCategory] = React.useState<string>(initUserDataState.inputEquipmentCategory);
  const [possibleEquipmentsCategories, setPossibleEquipmentsCategories] = React.useState<EquipmentCategoryType[]>(initUserDataState.possibleEquipmentCategory);
  const [saveButtonTitle] = React.useState<string>(t("actions.add"));

  useEffect(() => {
    if (props?.possibleEquipmentsCategories) {
      setPossibleEquipmentsCategories(props.possibleEquipmentsCategories);
    } else {
      setPossibleEquipmentsCategories(initUserDataState.possibleEquipmentCategory);
    }
  }, [props.possibleEquipmentsCategories]);

  useEffect(() => {
    dispatch(setLoadingStatus(loadingCreateEquipmentMutation));

  }, [loadingCreateEquipmentMutation, dispatch]);

  const createEquipmentItem = (row: UserModelEquipmentItemType) => {
    dispatch( setLoadingStatus( true));
    let inputOptions: CreateEquipmentMutationOptions = {
      variables: {
        input: {
          name: row.equipmentName,
          categoryId: row.equipmentCategoryId
        }
      },
      onError: (error) =>  dispatch( setSnackbar( true, SnackbarStates.ERROR, getApolloErrorMessages(error))),
    }

    createEquipmentMutation(inputOptions)
    .then(response => {
      if (response?.data?.createEquipment?.equipment) {
        dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t("userMessages.modelEquipment.equipment.created")));
        props.addRow(response.data.createEquipment.equipment);
        setUserEquipmentName(initUserDataState.inputEquipmentName);
        setUserEquipmentCategory(initUserDataState.inputEquipmentCategory);
      } else if (response?.data?.createEquipment?.errors) {
        response.data.createEquipment.errors.forEach(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t, "equipment"))));
      } else if (response.errors) {
        response.errors.forEach(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
      }
    })
    .catch(error => dispatch( setSnackbar( true, "error", error)))
    .finally(() => dispatch( setLoadingStatus( false)));
  }

  const saveRow = () => {
    if (userEquipmentName !== "" && userEquipmentCategory !== ""){
      let selectedCategory: EquipmentCategoryType | undefined = props.possibleEquipmentsCategories.find(cat => cat.id === userEquipmentCategory);
      let equipmentCategoryLabel: string = selectedCategory?.label ? selectedCategory.label : userEquipmentCategory;
      let newData: UserModelEquipmentItemType = {
        id: null,
        equipmentId: null,
        equipmentName: userEquipmentName,
        equipmentCategoryId: userEquipmentCategory,
        equipmentCategoryLabel: equipmentCategoryLabel,
        description: null,
        count: null,
        changed: true,
        equipmentChanged: true,
        isApplied: true,
      };

      createEquipmentItem(newData)
    } else {
      dispatch(setSnackbar(true, SnackbarStates.ERROR, t("userMessages.fillRequiredFields")));
    }
  };

  const handleChangeEquipment = (updateFunction) => (value) => {
    updateFunction(value);
  }

  return (
      <Grid container>
        <Grid item>
          <FormControl>
            <TextField
                variant="outlined"
                label={t("fieldName.name")}
                value={userEquipmentName}
                error={!userEquipmentName}
                required
                fullWidth
                onChange={(event) => handleChangeEquipment(setUserEquipmentName)(event.target.value)}
                inputProps={{
                  maxLength: 100
                }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <CodetableSelect
              inputValues={possibleEquipmentsCategories}
              filteredValues={userEquipmentCategory}
              setFilteredValues={handleChangeEquipment(setUserEquipmentCategory)}
              label={t("fieldName.equipmentCategory")}
              multiselect={false}
              required
          />
          <IconButton>
            <Tooltip title={saveButtonTitle}>
              <AddOutlined onClick={saveRow}/>
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    );
}
