import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppPageNameRoutes } from './paths';

import {
	Home,
	NotFound,
	Profile,
	ServicePackageOverview,
	VehicleDefinitionDetailCreate,
	VehicleDefinitionDetailEditor,
	VehicleDefinitions, UsedVehicleCards,
	CodetableEditor
} from '../pages';

import VehicleCardOverview from '../pages/vehicleCard/overview/vehicleCardOverview';
import VehicleCardCreateEdit from '../pages/vehicleCard/newEdit/vehicleCardCreateEdit';
import { default as UsedVehicleCardDetail }  from '../pages/vehicleUsed/edit/usedVehicleCardDetail';
import UsedVehicleCardDetailCreate from '../pages/vehicleUsed/create/UsedVehicleCardCreate';
/**
 * Create the routes.
 *
 * @returns {Switch} created routes
 */
function AppRoutes() {
	return (
		<Switch key='cms-router-switch'>
			<Route component={Home} exact path={AppPageNameRoutes.HOME.pageRoute} />
			<Route component={VehicleDefinitions} exact path={AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS.pageRoute} />
			<Route component={VehicleDefinitionDetailCreate} exact path={AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL_CREATE.pageRoute} />
			<Route component={VehicleDefinitionDetailEditor} exact path={AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL.pageRoute} />
			<Route component={UsedVehicleCards} exact path={AppPageNameRoutes.VEHICLE_USED_CARDS.pageRoute} />
			<Route component={UsedVehicleCardDetailCreate} exact path={AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute} />
			<Route component={UsedVehicleCardDetail} exat path={AppPageNameRoutes.VEHICLE_USED_CARD_DETAIL.pageRoute} />
			<Route component={Home} exact path={AppPageNameRoutes.ABOUT.pageRoute} />
			<Route component={Profile} exact path={AppPageNameRoutes.PROFILE.pageRoute} />
			<Route component={VehicleCardOverview} exact path={AppPageNameRoutes.VEHICLE_NEW_CARDS.pageRoute} />
			<Route component={VehicleCardCreateEdit} exact path={AppPageNameRoutes.VEHICLE_NEW_CARD_DETAIL_CREATE.pageRoute} />
			<Route component={VehicleCardCreateEdit} exact path={AppPageNameRoutes.VEHICLE_NEW_CARD_DETAIL.pageRoute} />
			<Route component={ServicePackageOverview} exact path={AppPageNameRoutes.CODETABLES_SERVICE_PACKAGES.pageRoute} />
			<Route component={CodetableEditor} exact path={AppPageNameRoutes.CODETABLES.pageRoute} />
			<Route component={NotFound} />
		</Switch>
	);
}

export default AppRoutes;
