import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, Grid, IconButton, TextField, Theme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  UsedVehicleEquipment,
  Equipment as GQLEquipment, UsedVehicleEquipmentItem,
} from '../../../api/graphql/generated/schema';
import { EditOutlined} from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import Equipment from '../../common/equipment/Equipment';
import { EquipmentDto } from '../../../modelView';
import { EquipmentItemDto } from '../../../modelView';
import { useGetEquipmentsQuery } from '../../../api/graphql/generated/hooks';
import { setLoadingStatus } from '../../../redux/loading';
import { ApolloError } from '../../index';
import { EquipmentItemShortenedType } from '../../common/equipment/EquipmentViewer';
import { setSnackbar } from '../../../redux/snackbar';
import { getApolloErrorMessages } from '../../../utils/graphGL/graphQLHelper';
const initUserDataState = {
  "changed": false,
  "userModelEquipment": null,
  "selectedModelEquipment": null,
  "selectedEquipmentItems": []
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        minWidth: 160,
        maxWidth: 200,
      },
      iconButton: {
        backgroundColor: 'transparent',
        "&:hover" : {
          backgroundColor: 'transparent',
        }
      }
    }),
);

export default function UsedVehicleDefinitionEquipment(props: { equipment: UsedVehicleEquipment | null | undefined, handleUpdate}) {

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editButtonTitle] = React.useState<string>(t("actions.edit"));
  const [changed, setChanged] = React.useState<boolean>(false);
  const [userEquipment, setUserEquipment] = React.useState<EquipmentDto | null>(initUserDataState.userModelEquipment);
  const [userEquipmentName, setUserEquipmentName] = React.useState<string | null>(null)
  const [openEquipmentEditorDialog, setOpenEquipmentEditorDialog] = React.useState<boolean>(false);
  const [possibleEquipments, setPossibleEquipments] = React.useState<GQLEquipment[]>([]);

  const { loading: loadEquipments, data: gqlEquipments, error: errorEquipments, refetch: getEquipment } = useGetEquipmentsQuery();

  useEffect(() => {
    dispatch(setLoadingStatus( loadEquipments));
  }, [loadEquipments])

  useEffect(() => {
    if (!loadEquipments && gqlEquipments) {
      setPossibleEquipments(gqlEquipments.getEquipments);
    }

  }, [loadEquipments, gqlEquipments]);

  useEffect(() => {
    if (props.equipment?.name) {
      setUserEquipmentName(props.equipment.name);
    } else {
      setUserEquipmentName(null);
    }
  }, [props.equipment?.name])

  const convertEquipment = (equipments: Array<UsedVehicleEquipmentItem> | null | undefined): Array<EquipmentItemShortenedType> | undefined => {
    return equipments?.map(item => {
      return {
        id: item.equipment.id,
        description: item.description,
        count: item.count,
        name: item.equipment.name,
        category: {
          id: item.equipment.category?.id,
          label: item.equipment.category?.label,
        }
      }
    })
  }

  const reset = () => {
    setChanged(false);
  }

  const resetModelEquipmentData = () => {
    if (props.equipment){
      setUserEquipment(EquipmentDto.convertFromUsedVehicleEquipment(props.equipment));
    } else {
      setUserEquipment(initUserDataState.userModelEquipment);
    }
  }

  const handleOpenEquipmentEditorDialog = () => {
    setOpenEquipmentEditorDialog(true);
  };

  const handleCloseEquipmentEditorDialog = () => {
    reset()
    setOpenEquipmentEditorDialog(false);
  };

  const handleEditEquipments = () => {
    if (props.equipment){
      setUserEquipment(EquipmentDto.convertFromUsedVehicleEquipment(props.equipment));
    }
    handleOpenEquipmentEditorDialog();
  }

  const handleUpdateVehicleEquipment = (equipmentName: string, items: Array<EquipmentItemDto>) => {
    props.handleUpdate(equipmentName, items.map(item => {
      return {
        equipment: possibleEquipments.filter(equipment => equipment.id === item.equipmentId)[0],
        count: item.count,
        description: item.description,
      }
    }));
  }

  const handleAddNewEquipmentItem = () => {
    getEquipment().catch(error => dispatch(setSnackbar(true, 'error', getApolloErrorMessages(error))));
  }

  if (errorEquipments) return <ApolloError error={[errorEquipments]} />;
  return (
      <div>
        <Grid container
              direction="row"
        >
          <Grid item xs={12} sm={4} >
            <FormControl className="outlined">
              <TextField
                  id="equipment"
                  label={t('fieldName.name')}
                  type="text"
                  value={props.equipment?.name || ''}
                  required
                  error={!userEquipmentName}
                  onChange={event => {props.handleUpdate(event.target.value); setChanged(!changed)}}
              />
            </FormControl>
          </Grid>
          <IconButton disableRipple size="small" className={classes.iconButton} >
            <Tooltip title={editButtonTitle}>
              <EditOutlined onClick={() => {
                handleEditEquipments();
              }}/>
            </Tooltip>
          </IconButton>
        </Grid>
        <Equipment
            open={openEquipmentEditorDialog}
            userEquipment={userEquipment}
            userEquipmentItems={convertEquipment(props.equipment?.equipments)}
            handleClose={handleCloseEquipmentEditorDialog}
            handleReset={resetModelEquipmentData}
            handleSubmit={handleUpdateVehicleEquipment}
            handleEquipmentUpdate={handleAddNewEquipmentItem}
        />
      </div>
  );

}
