import { Theme } from '@mui/material';
import React, { useState } from 'react';
import './assets/styles/main.scss';

import { ApolloProvider } from '@apollo/client'
import graphQLClient from './api/graphql/GraphQLClient';
import { Loader, NavBar, Snackbar } from './components';

import { Provider } from 'react-redux';
import store from './redux/configureStore';

import { BrowserRouter } from 'react-router-dom';
import LoginPage from './pages/login/index';
import AppRoutes from './routes/routes';

import { environment } from './environments/environment';
import { logger } from './utils/functionHelper';
import { UserDto } from './api';
import jwtDecode from 'jwt-decode';

import { createTheme, ThemeProvider } from '@mui/material/styles';


export const GLOBAL_SESSION_STATE_NAMES = {
    VEHICLE_DEFINITIONS_PAGE_FILTER_ID: 'vehicleDefinitionsPageFilter',
    VEHICLE_CARDS_PAGE_FILTER_ID: 'vehicleCardsPageFilter',
}

type Credentials = {
    username: string,
    password: string
}

function App(authKey) {

    const token = getToken();
    const [auth, setAuth] = useState(token);

    async function loginUser(credentials) {

        let loginUrl = environment.loginEndpoint ? environment.loginEndpoint : '';
        let authBody: Credentials = { username: credentials.userName, password: credentials.password };

        if (environment.nodeEnv === 'development' && environment.mockedJWT) {
            setToken(environment.mockedJWT);
            setAuth(true);

            return Promise.resolve(true);

        } else {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');

            let requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(authBody),
                redirect: 'follow',
            };

            return fetch(loginUrl, requestOptions)
                .then(response => {
                    if (response.status !== 200) {
                        logger('Authentication failed', response);
                        throw new Error('Authentication failed');
                    } else {
                        return response;
                    }
                })
                .then(response => response.json())
                .then(data => {
                    setToken(data.jwt);
                    setAuth(true);
                    return true;
                })
                .catch(reason => {
                    logger(reason);
                    setAuth(false);
                    return false;
                })
                .finally(() => window.location.reload());
        }
    }

    function setToken(userToken) {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        const claims: any = jwtDecode(userToken);
        console.log(claims.roles?.split(',').map(role => role.trim()));
        const userDto: UserDto = new UserDto(claims.sub, claims.name, claims.surname, claims.roles?.split(',').map(role => role.trim()));
        sessionStorage.setItem('user', JSON.stringify(userDto));
    }

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        if (tokenString !== null) {
            return JSON.parse(tokenString || '{}');
        } else {
            return false
        }
    }

    function userLogout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        Object.keys(GLOBAL_SESSION_STATE_NAMES)
            .forEach((key) => sessionStorage.removeItem(GLOBAL_SESSION_STATE_NAMES[key]))
        setAuth(false);
    }

    const theme: Theme = createTheme({
        palette: {
            primary: {
                main: '#000000'
            },
            secondary: {
                main: '#f2971e'
            }
        },
        components: {
            MuiGrid: {
                defaultProps: {
                    spacing: 1
                }
            }
        },
        typography: {
            fontSize: 14
        }
    });
    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={graphQLClient} key={auth}>
                <Provider store={store}>
                    <BrowserRouter>
                        <NavBar userLogout={userLogout}/>
                        <div className="content">
                            <Snackbar/>
                            <Loader/>
                            {!auth ? <LoginPage loginUser={loginUser}/> : <AppRoutes/>}
                        </div>
                    </BrowserRouter>
                </Provider>
            </ApolloProvider>
        </ThemeProvider>
    );
}

export default App;
