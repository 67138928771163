export const SET_SNACKBAR = "teamly/settings/SET_SNACKBAR";

export const SnackbarStates = {
  SUCCESS: "success",
  ERROR: "error",
}

const initialState = {
  snackbarOpen: false,
  snackbarType: SnackbarStates.SUCCESS,
  snackbarMessage: ""
};

export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case SET_SNACKBAR:
      const { snackbarOpen, snackbarMessage, snackbarType } = action;
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage
      };
    default:
      return state;
  }
};

export const setSnackbar = (
  snackbarOpen,
  snackbarType = SnackbarStates.SUCCESS,
  snackbarMessage = ""
) => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage
});

export const openSuccessSnackbar = (
    snackbarMessage = ""
) => ({
  type: SET_SNACKBAR,
  snackbarOpen: true,
  snackbarType: SnackbarStates.SUCCESS,
  snackbarMessage
});

export const openErrorSnackbar = (
    snackbarMessage = ""
) => ({
  type: SET_SNACKBAR,
  snackbarOpen: true,
  snackbarType: SnackbarStates.ERROR,
  snackbarMessage
});
