import {
    CodetableStateEnum,
    CurrencyEnum,
    CustomAttributeDataTypeEnum,
    ServicePackageStateEnum,
    UsedVehicleCardStateEnum,
    VehicleDefinitionStateEnum,
} from '../api/graphql/generated/schema';
import { VehicleStateEnum } from './enums';

export const vehicleDefinitionStates = [
    VehicleDefinitionStateEnum.WaitModel,
    VehicleDefinitionStateEnum.WaitEquipment,
    VehicleDefinitionStateEnum.WaitSpecification,
    VehicleDefinitionStateEnum.Active,
    VehicleDefinitionStateEnum.Archived,
]

export const vehicleDefinitionInitFilterStates = [
    VehicleDefinitionStateEnum.WaitModel,
    VehicleDefinitionStateEnum.WaitEquipment,
    VehicleDefinitionStateEnum.WaitSpecification,
    VehicleDefinitionStateEnum.Active,
]

export const usedVehicleDefinitionStates = [
    UsedVehicleCardStateEnum.InProgress,
    UsedVehicleCardStateEnum.AwaitApproval,
    UsedVehicleCardStateEnum.Archived,
    UsedVehicleCardStateEnum.Sold,
    UsedVehicleCardStateEnum.Approved,
    UsedVehicleCardStateEnum.Published,
]

export const usedVehicleInitFilterStates = [
    UsedVehicleCardStateEnum.InProgress,
    UsedVehicleCardStateEnum.AwaitApproval,
]

export const customAttributeDataTypes = [
    CustomAttributeDataTypeEnum.Boolean,
    CustomAttributeDataTypeEnum.Int,
    CustomAttributeDataTypeEnum.String,
    CustomAttributeDataTypeEnum.Date,
    CustomAttributeDataTypeEnum.DateTime,
    CustomAttributeDataTypeEnum.Float,
]

export const servicePackageStates = [
    ServicePackageStateEnum.Active,
    ServicePackageStateEnum.Archived,
]

export const codetableStates = [
    CodetableStateEnum.Active,
    CodetableStateEnum.Archived,
]

export const currencyOptions = [
    CurrencyEnum.Czk,
    CurrencyEnum.Eur,
]


export const vehicleStates = [
    VehicleStateEnum.perfect,
    VehicleStateEnum.veryGood,
    VehicleStateEnum.good,
    VehicleStateEnum.showCar
]

export enum YesNoEnum {
    YES = 'YES',
    NO = 'NO'
}

export const dateFormat = 'DD.MM.yyyy'
export const dateTimeFormat = 'HH:mm:ss DD.MM.YYYY'

export const timeStartOfTheDay = 'T00:00:00';
export const timeEndOfTheDay = 'T23:59:59';

export const rowsPerPage = [10, 25, 50, 100];
export const defaultRowsPerPage = 10;

export const defaultVat = 0.21

export const coinsuranceValues = {
    FIVE: '0.05',
    TEN: '0.1',
}

export const MIN_MODEL_YEAR = 1990;
export const MAX_MODEL_YEAR = new Date().getFullYear() + 2;
