import React from "react";
import {ApolloError as ApolloErrorType} from "@apollo/client";

export default function ApolloError(
    props: {
      error: ApolloErrorType[]
    }) {


  return <div>Error! ${props.error?.map(err => err.message)}</div>;

}
