import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import VehicleDefinitionsFilter from './filter/VehicleDefinitionsFilter';
import VehicleDefinitionsTable from './table/VehicleDefinitionsTable';
import {
    VehicleDefinitionFilter,
    VehicleDefinitionFilter as VehicleDefinitionFilterType,
    VehicleDefinitionPage as VehicleDefinitionPageType,
} from '../../../../api/graphql/generated/schema';
import { ApolloError, GraphQLError, PageHeader } from '../../../../components';
import { AppPageNameRoutes } from '../../../../routes/paths';
import { useTranslation } from 'react-i18next';
import { setLoadingStatus } from '../../../../redux/loading';
import { GetVehicleDefinitionsQueryVariables } from '../../../../api/graphql/generated/operations';
import {
    UpdateArchivedStateVehicleDefinitionMutationOptions,
    UpdateVehicleDefinitionMutationOptions,
    useCreateVehicleDefinitionMutation,
    useGetVehicleDefinitionsQuery,
    useUpdateArchivedStateVehicleDefinitionMutation,
    useUpdateVehicleDefinitionMutation,
} from '../../../../api/graphql/generated/hooks';
import { setSnackbar, SnackbarStates } from '../../../../redux/snackbar';
import { getApolloErrorMessages, getBusinessErrorMessages, getGQLErrorMessages } from '../../../../utils/graphGL/graphQLHelper';
import { defaultRowsPerPage, vehicleDefinitionInitFilterStates } from '../../../../utils/const';
import { GLOBAL_SESSION_STATE_NAMES } from '../../../../App';
import { Button, Container, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';

const breadcrumbs = [
    AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS,
];

const initVehicleDefinitionFilter: VehicleDefinitionFilterType = {
    brand: undefined,
    line: undefined,
    model: undefined,
    modelYear: undefined,
    state: vehicleDefinitionInitFilterStates,
    equipment: undefined,
    engine: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    updatedFrom: undefined,
    updatedTo: undefined,
}

type PageFilterType = {
    filter: VehicleDefinitionFilterType;
    sort: any;
    offset: number;
    limit: number;
}

const handlePageFilterInit = (): PageFilterType => {
    const sessionFilter: string | null = sessionStorage.getItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_DEFINITIONS_PAGE_FILTER_ID);
    return sessionFilter ? JSON.parse(sessionFilter) : { filter: initVehicleDefinitionFilter, sort: null, offset: 0, limit: defaultRowsPerPage };
}

export default function VehicleDefinitions() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [pageFilter, setPageFilter] = useState<PageFilterType>(handlePageFilterInit())

    const [vehicleDefinitionPage, setVehicleDefinitionPage] = useState<VehicleDefinitionPageType | undefined>(undefined);

    const [vehicleDefinitionsVariables] = useState<GetVehicleDefinitionsQueryVariables>(pageFilter);
    const { data, error, refetch: refetchVDs, fetchMore } = useGetVehicleDefinitionsQuery({ variables: vehicleDefinitionsVariables })
    const [updateArchiveStateVD] = useUpdateArchivedStateVehicleDefinitionMutation();
    const [createVehicleDefinitionMutation] = useCreateVehicleDefinitionMutation();
    const [updateVehicleDefinitionMutation] = useUpdateVehicleDefinitionMutation();

    useEffect(() => {
        setVehicleDefinitionPage(data?.getVehicleDefinitions);
    }, [data]);

    useEffect(() => {
       dispatch(setLoadingStatus(true));
        let currentInput = { filter: pageFilter.filter, sort: null, offset: pageFilter.offset, limit: pageFilter.limit };
        refetchVDs(currentInput)
            .then(response => {
                setVehicleDefinitionPage(response.data.getVehicleDefinitions);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    }, [pageFilter, dispatch, refetchVDs])

    //Handle errors
    if (error) return <div>Error! ${error?.message}</div>

    const updatePageFilter = (filter: VehicleDefinitionFilter, limit: number, offset: number, sort: any) => {
        let currentPageFilter: PageFilterType = {
            filter,
            limit,
            offset,
            sort
        };

        setPageFilter(currentPageFilter);
        sessionStorage.setItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_DEFINITIONS_PAGE_FILTER_ID, JSON.stringify(currentPageFilter));
    }

    const onSubmitFilter = (submittedFilter: VehicleDefinitionFilterType) => {
        updatePageFilter(submittedFilter, defaultRowsPerPage, 0, null);
    }

    const handleNewDefinition = () => {
        let path = AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL_CREATE.pageRoute;
        history.push(path);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(setLoadingStatus(true));
        let changedInput = { offset: newPage }
        fetchMore({ variables: changedInput })
            .then(response => {
                setVehicleDefinitionPage(response.data.getVehicleDefinitions);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
        updatePageFilter(pageFilter.filter, pageFilter.limit, newPage, pageFilter.sort);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoadingStatus(true));
        const limit: number = parseInt(event.target.value);
        let changedInput = { offset: pageFilter.offset, limit }
        fetchMore({ variables: changedInput })
            .then(response => {
                setVehicleDefinitionPage(response.data.getVehicleDefinitions);
            })
            .finally(() => dispatch(setLoadingStatus(false)));

        updatePageFilter(pageFilter.filter, limit, pageFilter.offset, pageFilter.sort);

    }

    const updateArchiveState = (archive: boolean, row) => {
        if (row) {
            dispatch(setLoadingStatus(true));
            let inputOptions: UpdateArchivedStateVehicleDefinitionMutationOptions = {
                variables: {
                    input: {
                        id: row.id,
                        archived: archive,
                    },
                },
                onError: error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))),
            }

            updateArchiveStateVD(inputOptions)
                .then(response => {
                    if (response?.data?.updateArchivedStateVehicleDefinition?.vehicleDefinition) {
                        let successMsg = archive ? 'userMessages.vehicleDefinition.archiveSuccessful' : 'userMessages.vehicleDefinition.restoreSuccessful';
                        dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t(successMsg)));
                    } else if (response?.data?.updateArchivedStateVehicleDefinition?.errors) {
                        response.data.updateArchivedStateVehicleDefinition.errors
                            .forEach(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
                    } else if (response.errors) {
                        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
                    }
                })
                .then(() => refetchVDs())
                .catch(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, error)))
                .finally(() => dispatch(setLoadingStatus(false)));
        } else {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('userErrors.vehicleDefinition.missingVehicleDefinition')))
        }
    };

    const duplicateVehicleDefinition = (row) => {
        if (row && row.modelDefinition?.id) {
            dispatch(setLoadingStatus(true));
            let modelDefinitionId = row.modelDefinition?.id;
            createVehicleDefinitionMutation()
                .then(response => {
                    if (response?.data?.createVehicleDefinition?.id) {
                        updateVD(response.data.createVehicleDefinition.id, modelDefinitionId, row.equipment?.id);
                    } else if (response.errors) {
                        return <GraphQLError error={response.errors}/>
                    }
                })
                .catch(error => <ApolloError error={error}/>)
                .finally(() => dispatch(setLoadingStatus(false)));
        }
    };

    const updateVD = (vehicleDefinitionId: string, modelDefinitionId: string, modelEquipmentId: string | null | undefined) => {
        let inputOptions: UpdateVehicleDefinitionMutationOptions = {
            variables: {
                input: {
                    id: vehicleDefinitionId,
                    modelDefinitionId: modelDefinitionId,
                    equipmentId: modelEquipmentId,
                },
            },
            onError: (error) => <ApolloError error={[error]}/>,
        };

        console.log('Handling Vehicle Definition Updated', inputOptions)

        updateVehicleDefinitionMutation(inputOptions)
            .then(response => {
                if (response?.data?.updateVehicleDefinition?.vehicleDefinition?.id) {
                    redirectToEdit(response?.data?.updateVehicleDefinition?.vehicleDefinition);
                } else if (response.errors) {
                    return <GraphQLError error={response.errors}/>
                }
            })
            .catch(error => <ApolloError error={error}/>);
    }

    let redirectToEdit = (row) => {
        if (row) {
            let path = AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS.pageRoute + '/' + row.id;
            history.push(path);
        }
    }

    return (
        <Container className="page vehicle-definitions">
            <Grid justifyContent={'space-between'}>
                <Grid item className="text-left">
                    <Button startIcon={<Add/>} onClick={handleNewDefinition} className="button new-definition">
                        {t('pages.definitionTable.addNewDefinition')}
                    </Button>
                    <PageHeader headline={'definitions'} breadcrumbs={breadcrumbs}/>
                </Grid>

            </Grid>
            <VehicleDefinitionsFilter handleSubmitFilter={onSubmitFilter} appliedFilter={pageFilter.filter}/>
            {data &&
                <VehicleDefinitionsTable
                    dataPage={vehicleDefinitionPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    updateArchiveState={updateArchiveState}
                    duplicateVehicleDefinition={duplicateVehicleDefinition}
                    redirectToEdit={redirectToEdit}
                />
            }
        </Container>
    );

}
