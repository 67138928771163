import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Body, VehicleType } from '../../../api/graphql/generated/schema';
import { CodetableSelect } from '../../index';

const initState = {
    code: '',
    label: '',
    vehicleType: '',
}

export default function CodetableDepenEditDialog(
    props: {
        open: boolean,
        data: Body | null | undefined,
        handleClose: () => void,
        possibleDependencies: Array<VehicleType>,
        handleSave: Function
    }) {

    const { t } = useTranslation();

    const [changed, setChanged] = React.useState<boolean>(false);
    const [code, setCode] = React.useState<string | undefined | null>(initState.code);
    const [label, setLabel] = React.useState<string | undefined | null>(initState.label);
    const [possibleVehicleType, setPossibleVehicleType] = useState<Array<VehicleType>>([]);
    const [userVehicleType, setUserVehicleType] = useState<string>(initState.vehicleType);

    useEffect(() => {
        if (props.data) {
            setCode(props.data.code);
            setLabel(props.data.label);
            setUserVehicleType(props.data.vehicleType.id);
            setPossibleVehicleType(props.possibleDependencies);
        } else {
            setInitState();
        }
    }, [props.data])
    const setInitState = () => {
        setCode(initState.code);
        setLabel(initState.label);
        setUserVehicleType(initState.vehicleType);
        setPossibleVehicleType(props.possibleDependencies);
    }

    const changedInput = (setFunction) => (value: string | number) => {
        setFunction(value);
        setChanged(true);
    }

    const handleBrandChange = (selectedVehicleTypeId: string) => {
        setUserVehicleType(selectedVehicleTypeId);
        setChanged(true);
    }

    const isValid = () => {
        return code && label && userVehicleType;
    }

    const handleSave = () => {
        if (props.data?.id){
            props.handleSave(props.data?.id, label, userVehicleType);
        } else {
            props.handleSave(code, label, userVehicleType);
        }
        setInitState()
        props.handleClose();
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            id="custom-attributes-dialog"
        >
            <DialogTitle>
                <Grid container direction={"row"} justifyContent={"flex-end"} alignItems={'flex-end'}>
                    <Grid item sx={{flex: 1}}>
                        {t(`codetables.action.${props.data?.state ? 'update' : 'add'}.dialogTitle`) + t('codetables.addActions.body')}
                    </Grid>
                    <Grid item sx={{mx: 1}}>
                        {props.data?.state && <Chip label={props.data?.state}/>}
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" onClick={() => {setInitState(); props.handleClose()}} aria-label="close">
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container
                      direction="row"
                      sx={{p: 1}}
                      spacing={3}
                >
                    <Grid item xs={12} sm={6} >
                        <FormControl className="outlined">
                            <TextField
                                id={`${props.data?.id}-code`}
                                label={t('fieldName.code')}
                                type="text"
                                value={code}
                                required
                                error={!code}
                                disabled={!!props.data?.code}
                                onChange={event => changedInput(setCode)(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <FormControl className="outlined">
                            <TextField
                                id={`${props.data?.id}-title`}
                                label={t('fieldName.title')}
                                type="text"
                                value={label}
                                required
                                error={!label}
                                onChange={event =>
                                    changedInput(setLabel)(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CodetableSelect
                            label={t("fieldName.vehicleType")}
                            required
                            filteredValues={userVehicleType}
                            multiselect={false}
                            inputValues={possibleVehicleType}
                            setFilteredValues={handleBrandChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"flex-end"}>
                    <Grid item>
                        <Button disabled={!changed} onClick={() => {setInitState(); props.handleClose();}}>{t("actions.discard")}</Button>
                        { props.data && <Button disabled={!changed || !isValid()} onClick={handleSave}>{t("actions.save")}</Button> }
                        { !props.data && <Button disabled={!changed || !isValid()} onClick={handleSave}>{t("actions.create")}</Button>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
