import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Container, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import { getUserFromStorage } from '../../utils/functionHelper';
import PageHeader from '../../components/pageHeader/PageHeader';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../routes/paths';
import { UserDto } from '../../api';
import { UserAvatar } from "../../components/common";

const breadcrumbs: AppPageNameRouteType[] = [
    AppPageNameRoutes.PROFILE
];

export default function Profile() {

    const { t } = useTranslation();

    const user: UserDto | null = getUserFromStorage();

    if (!user){
        throw Error('Enable to locate user in storage');
    }


    return (
        <Container className="page" >
            <Grid container direction={"row"} justifyContent={"space-between"} alignItems="center">
                <Grid item className="text-left" >
                    <PageHeader headline={"profile"} breadcrumbs={breadcrumbs} headlineParams={{userName: user.fullName}}/>
                </Grid>
                <Grid item>
                    <UserAvatar user={user} />
                </Grid>
            </Grid>
            <Grid className="filter__holder">
                <Box >
                    <Typography variant={'h6'}>
                        {t('pages.profile.basicInfo')}
                    </Typography>
                <Grid container>
                    <Grid item>
                        <TextField
                            id="total-bonuses-crn"
                            label={t('pages.profile.name')}
                            type="text"
                            value={user.name}
                            disabled
                            variant={"outlined"}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="total-bonuses-crn"
                            label={t('pages.profile.surname')}
                            type="text"
                            value={user.surname}
                            disabled
                            variant={"outlined"}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="total-bonuses-crn"
                            label={t('pages.profile.userName')}
                            type="text"
                            value={user.userName}
                            disabled
                            variant={"outlined"}
                        />
                    </Grid>
                </Grid>
                </Box>
                <Box >
                    <Typography variant={'h6'}>
                        {t('pages.profile.assignedRoles')}
                    </Typography>
                    <List>
                        {user.roles.map((role) => <ListItem>{t('roles.values.' + role)}</ListItem>)}
                    </List>
                </Box>
            </Grid>
        </Container>
    );
}
