import { MarketingTagDto } from "../../../modelView/marketing/MarketingTagDto";
import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, Checkbox, Grid, Typography } from '@mui/material';
import { MarketingTagEnum } from "../../../api/graphql/generated/schema";
import { useTranslation } from "react-i18next";

const initState = {
    //FIXME: Temporary solution. After BE is prepared for whole complexity change this to data load from BE
    possibleMarketingTags: [
        MarketingTagDto.initPossible(MarketingTagEnum.Partner),
        MarketingTagDto.initPossible(MarketingTagEnum.TopOffer),
        MarketingTagDto.initPossible(MarketingTagEnum.MarketLowestPrice),
        MarketingTagDto.initPossible(MarketingTagEnum.Reserved),
    ],
    userMarketingTags: [],
}

export default function MarketingTagSection(
    props:{inStoreCard: boolean, appliedMarketingTags: Array<MarketingTagEnum>, handleAdd: Function, handleRemove: Function }) {

    const {t} = useTranslation();

    const [possibleMarketingTags] = React.useState<Array<MarketingTagDto>>(initState.possibleMarketingTags.filter((mt) => mt.id !== MarketingTagEnum.Reserved || props.inStoreCard));
    const [userMarketingTags, setUserMarketingTags] = React.useState<Array<MarketingTagDto>>(initState.userMarketingTags);


    useEffect(() => {
        const mmTags: Array<MarketingTagDto> = [];
        possibleMarketingTags.forEach((pmt) => {
            if (props.appliedMarketingTags && props.appliedMarketingTags.includes(pmt.id as MarketingTagEnum)) {
                mmTags.push(MarketingTagDto.initAppliedFromMarketingTag(pmt));
            } else {
                mmTags.push(pmt);
            }
        });
        mmTags.sort((a, b) => a.id <= b.id ? -1 : 1);
        setUserMarketingTags(mmTags);
    }, [possibleMarketingTags, props.appliedMarketingTags]);

    const handleIsApplied = (marketingTag: MarketingTagDto) => {
        if (marketingTag.isApplied){
            props.handleRemove(marketingTag.id);
        } else {
            props.handleAdd(marketingTag.id);
        }
    }

    const renderMarketingTag = (marketingTag: MarketingTagDto) => {
        return (
            <Grid item justifyContent={"space-between"} sm={12} md={3} id={"marketing-tag-grid-" + marketingTag.id}>
                <Typography variant={"body2"} id={"marketing-tag-typography-" + marketingTag.id}>
                    <Checkbox
                        checked={marketingTag.isApplied}
                        onChange={() => handleIsApplied(marketingTag)}
                        color={"primary"}
                        id={"marketing-tag-checkbox-" + marketingTag.id}
                    />
                    {t("marketingTag.enum.values." + marketingTag.id)}
                </Typography>
            </Grid>
        );
    }

    return (
        <Card variant="outlined">
            <CardHeader title={"Marketingové tagy"} />
            <CardContent>
                <Grid container direction={"row"} sm={12} md={12}>
                    {userMarketingTags.map((mt, index) => renderMarketingTag(mt))}
                </Grid>
            </CardContent>
        </Card>
    );
}
