import {
    VehicleCardColor as VehicleCardColorType,
    VehicleDelivery as VehicleDeliveryType
} from "../../api/graphql/generated/schema";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Card, CardActions, CardContent, FormControl, Grid, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import CardColorSelect from "./color/CardColorSelect";
import {
    CreateInStoreVehicleCardMutationOptions,
    useCreateInStoreVehicleCardMutation
} from "../../api/graphql/generated/hooks";
import { ApolloError } from "../index";
import { openErrorSnackbar, openSuccessSnackbar } from "../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../utils/graphGL/graphQLHelper";
import { setLoadingStatus } from "../../redux/loading";
import { createUnexpectedErrorMessage } from "../../utils/functionHelper";
import { convertDeliveryTypeToDeliveryInput } from "../../utils/graphGL/graphQLConverter";
import { AppPageNameRoutes } from "../../routes/paths";
import VehicleDeliveryCommission from "./VehicleDeliveryCommission";

const initState = {
    selectedColorId: '',
    commission: '',
    deliveryDate: null,
    offerValidTo: '',
    validToSubmit: false,
}

export default function InStoreVehicleCreate(props: {cardId: string, colors: Array<VehicleCardColorType>, onSuccess?: Function}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedColorId, setSelectedColorId] = useState<string>(initState.selectedColorId);
    const [userCommission, setUserCommission] = useState<string | null>(initState.commission);
    const [userDeliveryDate, setUserDeliveryDate] = React.useState<VehicleDeliveryType | null>(initState.deliveryDate);
    const [userOfferValidTo, setUserOfferValidTo] = React.useState<string | null>(initState.offerValidTo);
    const [validToSubmit, setValidToSubmit] = useState<boolean>(initState.validToSubmit);

    const [createInStoreCard, {loading: loadingCreateISC}] = useCreateInStoreVehicleCardMutation();


    useEffect(() => {
        setValidToSubmit(!!(selectedColorId && userCommission && userDeliveryDate));
    }, [userCommission, selectedColorId, userDeliveryDate]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingCreateISC));
    }, [dispatch, loadingCreateISC]);

    const changeCommission = (value: string) => {
        setUserCommission(value);
    }

    const handleDiscard = () => {
        setUserCommission(initState.commission);
        setSelectedColorId(initState.selectedColorId);
    }

    const handleSubmit = () => {
        if (!!(selectedColorId && userCommission && userDeliveryDate)){
            handleCreateInStoreCard(selectedColorId, userCommission, userDeliveryDate, userOfferValidTo);
        } else {
            dispatch(openErrorSnackbar(t("userError.missingRequiredField")));
        }
    }

    const handleCreateInStoreCard = (colorId: string, commission: string, deliveryDate: VehicleDeliveryType, offerValidTo: string | null) => {
        let inputOptions: CreateInStoreVehicleCardMutationOptions = {
            variables: {
                input: {
                    cardId: props.cardId,
                    cardColorId: colorId,
                    commission: commission,
                    deliveryDate: convertDeliveryTypeToDeliveryInput(deliveryDate),
                    offerValidTo: offerValidTo ? offerValidTo : null
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        createInStoreCard(inputOptions)
            .then(response => {
                if (response?.data?.createInStoreVehicleCard?.vehicleCard) {
                    dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.inStoreCar.created")));
                    if (props?.onSuccess) {
                        props.onSuccess();
                    }
                    let path = AppPageNameRoutes.VEHICLE_NEW_CARD_DETAIL.pageRoute.replace(':vehicleCardId', response.data.createInStoreVehicleCard.vehicleCard.id);
                    history.push(path);
                } else if (response?.data?.createInStoreVehicleCard?.errors) {
                    response.data.createInStoreVehicleCard.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard.inStoreCar"))));
                } else if (response.errors) {
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
    }


    return (
        <Card>
            <CardContent>
                    <Grid container direction="row">
                        <Grid item xs={12} sm={6} >
                            <FormControl className="outlined">
                                <TextField
                                    id="in-store-commission"
                                    label={t('fieldName.commission')}
                                    type="text"
                                    value={userCommission}
                                    required
                                    error={!userCommission}
                                    onChange={event => changeCommission(event.target.value)}
                                    inputProps={
                                        {name: 'commission'}
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CardColorSelect inputValues={props.colors} filteredValues={selectedColorId} setFilteredValues={setSelectedColorId} required={true} multiselect={false} label={t('fieldName.color') } />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <VehicleDeliveryCommission deliveryDate={userDeliveryDate} setVehicleDelivery={setUserDeliveryDate} required={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className="outlined">
                                <TextField
                                    id="in-store-offered-valid-to"
                                    label={t('fieldName.offerValidTo')}
                                    type="date"
                                    value={userOfferValidTo}
                                    onChange={event => setUserOfferValidTo(event.target.value)}
                                    inputProps={
                                        {name: 'offerValidTo'}
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={handleDiscard}>{t("actions.discard")}</Button>
                <Button disabled={!validToSubmit} onClick={handleSubmit}>{t("actions.create")}</Button>
            </CardActions>
        </Card>
    );

}
