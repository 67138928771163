import { UserDto } from '../api';

export function getUnique(value, index, self) {
  return self.indexOf(value) === index;
}

//TODO: add API log after BE supports api for logs
export function logger(message?: any, ...optionalParams: any[]){
  console.log(message, optionalParams);
}

export const roundToDecimals = (decimalNumber: number, multiplayer: number) => {
  return decimalNumber ? Math.round(decimalNumber * multiplayer) / multiplayer : null;
}

export const roundToZeroDecimals = (decimalNumber: number) => {
  const multiplayer = Math.pow(10, 0);
  return decimalNumber ? Math.round(decimalNumber * multiplayer) / multiplayer : null;
}

export const roundToTwoDecimals = (decimalNumber: number) => {
  const multiplayer = Math.pow(10, 2);
  return roundToDecimals(decimalNumber, multiplayer);
}

export const isEmptyArray = (arr: Array<any> | undefined | null) => {
  return !arr || arr.length === 0;
}

export const createUnexpectedErrorMessage = (translator, error: any) => {

  const additionalInfo = error ? JSON.stringify(error) : translator("userError.noAdditionInfo");

  return translator("userError.unexpectedError") + ":\n" + additionalInfo;
}

export const getUserFromStorage = (): UserDto | null => {
  const userString: string | null = sessionStorage.getItem('user');
  let user: UserDto | null = null;
  if (userString){
    const claims: any = JSON.parse(userString);
    user = new UserDto(claims._username, claims._name, claims._surname, claims._roles);
  }

  return user;
}

export const hasRole = (expectedRole: string): boolean => {
  const user: UserDto | null = getUserFromStorage();
  if (!user){
    throw new Error('User cannot be null');
  }
  const roles: Array<string> = user.roles
  return roles.includes(expectedRole);
}

export const hasAnyRoles = (expectedRoles: Array<string>): boolean => {
  return expectedRoles.some(expectedRole => hasRole(expectedRole));
}


