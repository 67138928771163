import { UserEquipmentItemDto } from '../components/common/equipment/equipmentItems/UserEquipmentItemDto';
import { UsedVehicleEquipment } from '../api/graphql/generated/schema';
import { CurrentModelEquipmentType } from '../components/vehicleDefinition/modelEquipment/ModelEquipment';

export class EquipmentDto {
    private _id: string | null;
    private _name: string | null;
    private _sortNumber: number | null;
    private _model: boolean;
    private _equipments: Array<UserEquipmentItemDto>;

    constructor (id: string | null, name: string | null, sortNumber: number | null, equipments: Array<UserEquipmentItemDto>, model: boolean) {
        this._id = id;
        this._name = name;
        this._sortNumber = sortNumber;
        this._equipments = equipments;
        this._model = model;
    }

    static convertFromUsedVehicleEquipment (equipment: UsedVehicleEquipment): EquipmentDto {
        return new EquipmentDto(
            equipment.id,
            equipment.name,
            null,
            equipment.equipments ? equipment.equipments.map(item => UserEquipmentItemDto.convertUsedToUserEquipmentItemType(item)) : [],
            false
        );
    }

    static convertFromModelEquipment (equipment: CurrentModelEquipmentType | null): EquipmentDto {
        if (!equipment) {
            return new EquipmentDto(null, null, null, [], true);
        }
        return new EquipmentDto(
            equipment.id,
            equipment.name,
            equipment.sortNumber,
            equipment.equipments ? equipment.equipments.map(item => UserEquipmentItemDto.convertToUserEquipmentItemType(item)) : [],
            true
        );
    }

    static duplicateFromModelEquipment (equipment: CurrentModelEquipmentType | null): EquipmentDto {
        if (!equipment) {
            return new EquipmentDto(null, null, null, [], true);
        }
        return new EquipmentDto(
            equipment.id,
            equipment.name,
            equipment.sortNumber,
            equipment.equipments ? equipment.equipments.map(item => UserEquipmentItemDto.duplicateToUserModelEquipmentItemType(item)) : [],
            true
        );
    }

    public get sortNumber(): number | null {
        return this._sortNumber;
    }

    public get name(): string | null {
        return this._name;
    }

    public get equipments(): Array<UserEquipmentItemDto> {
        return this._equipments;
    }

    public set equipments(value: Array<UserEquipmentItemDto>) {
        this._equipments = value;
    }

    public get model(): boolean {
        return this._model;
    }

}
