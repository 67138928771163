import React from "react";
import {Typography} from '@mui/material';
import {useTranslation} from "react-i18next";

export default function EmptyDataListInfo() {

  const {t} = useTranslation();

  return (
      <Typography variant={"subtitle1"}>
        {t("datatable.empty")}
      </Typography>
      );
}
