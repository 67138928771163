import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Theme } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EnumSelect from '../../../common/EnumSelect';
import { setSnackbar, SnackbarStates } from '../../../../redux/snackbar';
import { v4 as uuidv4 } from 'uuid';
import { createStyles, makeStyles } from '@mui/styles';
import CustomAttributeInputField from './CustomAttributeInputField';
import { CustomAttribute, CustomAttributeDataTypeEnum } from '../../../../api/graphql/generated/schema';
import { customAttributeDataTypes } from '../../../../utils/const';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        grid: {
            padding: theme.spacing(1),
            justifyContent: 'center',
        },
    }),
);

const initState = {
    value: '',
    dataType: CustomAttributeDataTypeEnum.String,
    order: null,
    label: '',
}

export default function CustomAttributesDialog(props: { customAttribute: CustomAttribute | null | undefined, open: boolean, handleClose, handleUpdate: Function }) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [changed, setChanged] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>(initState.value);
    const [type, setType] = useState<CustomAttributeDataTypeEnum>(initState.dataType);
    const [order, setOrder] = React.useState<number | undefined | null>(initState.order);
    const [label, setLabel] = React.useState<string | undefined | null>(initState.label);

    useEffect(() => {
        if (props.customAttribute) {
            setValue(props.customAttribute.value);
            setType(props.customAttribute.dataType);
            setOrder(props.customAttribute?.order);
            setLabel(props.customAttribute?.label)
        }
    }, [props.customAttribute])

    const setInitState = () => {
        setValue(initState.value);
        setOrder(initState.order);
        setLabel(initState.label)
    }

    const changeDataType = (value: any) => {
        setType(value);
        setInitState();
        setChanged(true);
    }

    const validateAttribute = (): boolean => {
        return Boolean(value) && Boolean(type);
    }

    const handleSave = () => {
        if (!validateAttribute()) {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('pages.usedVehicleCards.detail.definition.messages.missingInputFields')));
        }
        if (props.customAttribute?.id) {
            props.handleUpdate({ id: props.customAttribute.id, value, order, dataType: type, label });
        } else {
            const newAttribute: CustomAttribute = {
                id: uuidv4(),
                value: value,
                dataType: type,
                order: order,
                label: label,
            }
            props.handleUpdate(newAttribute);

        }
        setInitState()
        props.handleClose();
    }

    const changedInput = (setFunction) => (value: string | number) => {
        setFunction(value);
        setChanged(true);
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            id="custom-attributes-dialog"
        >
            <DialogTitle>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item>
                        {t('pages.usedVehicleCards.detail.definition.custom.dialogTitle')}
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setInitState();
                            props.handleClose()
                        }} aria-label="close">
                            <CloseRounded/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container
                      direction="row"

                      className={classes.grid}
                >

                    <Grid item xs={12} sm={6}>
                        <EnumSelect
                            inputValues={customAttributeDataTypes}
                            label={t('fieldName.attributeDataType')}
                            enumLabelsPath="usedVehicleCard.customAttributes.dataTypes"
                            multiselect={false}
                            filteredValues={type}
                            setFilteredValues={(value) => changeDataType(value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id={props.customAttribute?.id}
                                label={t('fieldName.attributeLabelCZ')}
                                type="text"
                                value={label}
                                onChange={event => changedInput(setLabel)(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <TextField
                                id={props.customAttribute?.id}
                                label={t('fieldName.attributeOrder')}
                                type="number"
                                value={order}
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                onChange={event => changedInput(setOrder)(parseInt(event.target.value))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className="outlined">
                            <CustomAttributeInputField
                                attribute={{ id: props.customAttribute?.id || '', dataType: type, value: value, order: order, label: label }}
                                handleChange={changedInput(setValue)} editMode
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button disabled={!changed} onClick={() => {
                            setInitState();
                            props.handleClose();
                        }}>{t('actions.discard')}</Button>
                        <Button disabled={!changed} onClick={handleSave}>{t('pages.vehicleCards.detail.finance.actions.confirmBonusMapping')}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
