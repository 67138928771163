import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { useGetBrandsQuery, useGetVehicleTypesQuery } from '../../../../api/graphql/generated/hooks';
import { ApolloError, CodetableSelect, EnumSelect, FilterDate } from '../../../../components';
import { DateFilter, VehicleCardFilter, VehicleCardStateEnum } from '../../../../api/graphql/generated/schema';
import { FilterDateType } from '../../../../components/common/FilterDate';
import { timeEndOfTheDay, timeStartOfTheDay, YesNoEnum } from '../../../../utils/const';
import { convertCardState } from '../../../../utils/graphGL/graphQLConverter';
import { CARD_STATE_ALL_ACTIVE } from '../../../../utils/graphGL/graphQLHelper';
import { VisibilityOutlined } from '@mui/icons-material';

const VC_STATE_ALL_ACTIVE: string = "ALL_ACTIVE";

const initState = {
  commission: null,
  optionCode: null,
  model: null,
  modelEquipment: null,
  brandIds: [],
  vehicleTypeIds: [],
  cardStates: [VC_STATE_ALL_ACTIVE],
  offeredValidity: { from: null, to: null },
  deliveryDate: { from: null, to: null },
  lastUpdated: { from: null, to: null },
  isInStoreCar: null,
  possibleStates: [
    VC_STATE_ALL_ACTIVE,
    VehicleCardStateEnum.InProgress,
    VehicleCardStateEnum.Approved,
    VehicleCardStateEnum.AwaitApproval,
    VehicleCardStateEnum.Published,
    VehicleCardStateEnum.Sold,
    VehicleCardStateEnum.Archived,
  ],
}

const convertToDateFilter = (filterDate: FilterDateType, withTime?: boolean) => {
  return {
    from: resolveDate(filterDate.from, !!withTime ? timeStartOfTheDay : undefined),
    to: resolveDate(filterDate.to, !!withTime ? timeEndOfTheDay : undefined),
  };
}

const convertToFilterDateType = (filterDate: DateFilter, withTime?: boolean): FilterDateType => {
  return {
    from: resolveDate(filterDate.from, !!withTime ? timeStartOfTheDay : undefined),
    to: resolveDate(filterDate.to, !!withTime ? timeEndOfTheDay : undefined),
  };
}

const resolveDate = (inputDate: string | null | undefined, time: string | undefined) => {
  let resultDate: string | undefined  = undefined;
  if (inputDate){
    resultDate = inputDate;
    if (time){
      resultDate = resultDate + time;
    }
  }

  return resultDate ? resultDate : null;
}


const convertToStateFiler = (cardStates: Array<string>): Array<VehicleCardStateEnum> | undefined => {
  let filterStates: Array<VehicleCardStateEnum> = [];
  if (cardStates.length > 0){
    cardStates.forEach((state) => {
      if (state === VC_STATE_ALL_ACTIVE) {
        filterStates.push(...CARD_STATE_ALL_ACTIVE);
      } else {
        filterStates.push(convertCardState(state));
      }
    })
  }

  return filterStates.length > 0 ? filterStates : undefined;
}

const handleIsInStore = (appliedFilter: VehicleCardFilter, defaultValue: YesNoEnum | null) => {
  if (appliedFilter) {
    if (appliedFilter.isInStoreCar === false) return YesNoEnum.NO;
    if (appliedFilter.isInStoreCar === true) return YesNoEnum.YES;
  }

  return defaultValue;
}

export default function VehicleCardOverviewFilter (props : { handleSubmitFilter, appliedFilter: VehicleCardFilter }) {

  const {t} = useTranslation();
  const {  appliedFilter } = props;

  const asteriskFilterTooltip = t("pages.vehicleCards.filters.tooltips.asterisk");

  const { loading: loadingBrands, data: gqlBrands, error: errorBrands } = useGetBrandsQuery();
  const { loading: loadingVehicleType, data: gqlVehicleType, error: errorVehicleType } = useGetVehicleTypesQuery();
  const [possibleStates] = React.useState<any>(initState.possibleStates);

  const [brands, setBrands] = React.useState<any>([]);
  const [vehicleType, setVehicleType] = React.useState<any>([]);
  const [commission, setCommission] = React.useState<string | null>(appliedFilter?.commission || initState.commission);
  const [optionCode, setOptionCode] = React.useState<string | null>(appliedFilter?.optionCode || initState.optionCode);
  const [brandIds, setBrandIds] = React.useState<Array<string>>(appliedFilter?.brand || initState.brandIds);
  const [vehicleTypeIds, setVehicleTypeIds] = React.useState<Array<string>>(appliedFilter?.vehicleType || initState.vehicleTypeIds);
  const [cardStates, setCardStates] = React.useState<Array<string>>(appliedFilter?.state || initState.cardStates);
  const [model, setModel] = React.useState<string | null>(appliedFilter?.model || initState.model);
  const [modelEquipment, setModelEquipment] = React.useState<string | null>(appliedFilter?.equipment || initState.modelEquipment);
  const [offeredValidity, setOfferedValidity] = useState<FilterDateType>((appliedFilter?.offerValidity && convertToFilterDateType(appliedFilter.offerValidity)) || initState.offeredValidity);
  const [deliveryDate, setDeliveryDate] = useState<FilterDateType>((appliedFilter?.deliveryDate && convertToFilterDateType(appliedFilter.deliveryDate)) || initState.deliveryDate);
  const [lastUpdated, setLastUpdated] = useState<FilterDateType>((appliedFilter?.updated && convertToFilterDateType(appliedFilter.updated)) || initState.lastUpdated);
  const [isInStoreCarPossibilities] = useState<Array<YesNoEnum>>([YesNoEnum.YES, YesNoEnum.NO]);
  const [isInStoreCar, setIsInStoreCar] = useState<YesNoEnum | null>(handleIsInStore(appliedFilter, initState.isInStoreCar));

  useEffect(() => {
    if (!loadingBrands && gqlBrands) {
      setBrands(gqlBrands.getBrands);
    }
  }, [loadingBrands, gqlBrands]);

  useEffect(() => {
    if (!loadingVehicleType && gqlVehicleType) {
      setVehicleType(gqlVehicleType.getVehicleTypes);
    }
  }, [loadingVehicleType, gqlVehicleType]);

  const resetFilter = () => {
    setCommission(initState.commission);
    setOptionCode(initState.optionCode);
    setBrandIds(initState.brandIds);
    setVehicleTypeIds(initState.vehicleTypeIds);
    setCardStates(initState.cardStates);
    setOfferedValidity(initState.offeredValidity);
    setDeliveryDate(initState.deliveryDate);
    setLastUpdated(initState.lastUpdated);
  }

  const handleFilterCommission = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    setCommission(event.target.value);
  }

  const handleFilterOptionCode = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    setOptionCode(event.target.value);
  }
  const handleFilterModel = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    setModel(event.target.value);
  }
  const handleFilterModelEquipment = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    setModelEquipment(event.target.value);
  }

  const handleFilterOfferedValidity = (filterDate: FilterDateType) => {
    handleFilterDateUpdate(filterDate, setOfferedValidity);
  }

  const handleFilterDeliveryDate = (filterDate: FilterDateType) => {
    handleFilterDateUpdate(filterDate, setDeliveryDate);
  }

  const handleLastUpdated = (filterDate: FilterDateType) => {
    handleFilterDateUpdate(filterDate, setLastUpdated);
  }

  const handleFilterDateUpdate = (filterDate: FilterDateType, updateFunction: React.Dispatch<React.SetStateAction<FilterDateType>>) => {
    const newOV = {
      from: filterDate.from,
      to: filterDate.to,
    }
    updateFunction(newOV);
  }

  const handleSubmitFilter = () => {

    let isISC: boolean | undefined  = undefined;
    if (isInStoreCar === YesNoEnum.YES){
      isISC = true;
    } else if (isInStoreCar === YesNoEnum.NO){
      isISC = false;
    }

    const filterToSubmit: VehicleCardFilter = {
      commission: commission ? commission : null,
      optionCode: optionCode ? optionCode : null,
      brand: brandIds,
      vehicleType: vehicleTypeIds,
      state: convertToStateFiler(cardStates),
      offerValidity: convertToDateFilter(offeredValidity),
      deliveryDate: convertToDateFilter(deliveryDate),
      updated: convertToDateFilter(lastUpdated, true),
      isInStoreCar: isISC,
      model: model ? model : null,
      equipment: modelEquipment ? modelEquipment : null,
    }
    props.handleSubmitFilter(filterToSubmit);
  }

  if (errorBrands) return <ApolloError error={[errorBrands]} />;
  if (errorVehicleType) return <ApolloError error={[errorVehicleType]} />;

  return (
    <Grid
      className="filter__holder"
      >
      <Box mb={3}>
        <Typography variant="h5" className="text-left">
          {t("pages.vehicleCards.filters.title")}
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={2} className="text-left">
            <FormControl variant="outlined">
              <Tooltip title={asteriskFilterTooltip}>
                <TextField
                    id={"card-filter-commission"}
                    label={t("fieldName.commission")}
                    type={"text"}
                    name={"commission"}
                    value={commission}
                    onChange={handleFilterCommission}
                />
              </Tooltip>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
            <FormControl variant="outlined">
              <Tooltip title={asteriskFilterTooltip}>
                <TextField
                    id={"card-filter-option-code"}
                    label={t("fieldName.optionCode")}
                    type={"text"}
                    name={"optionCode"}
                    value={optionCode}
                    onChange={handleFilterOptionCode}
                />
              </Tooltip>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
          <EnumSelect inputValues={possibleStates} filteredValues={cardStates} setFilteredValues={setCardStates} label={t("fieldName.state")} enumLabelsPath="pages.vehicleCards.states" multiselect={true} />
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
          <EnumSelect
              inputValues={isInStoreCarPossibilities}
              filteredValues={isInStoreCar}
              setFilteredValues={setIsInStoreCar}
              label={t("fieldName.inStoreCar")}
              multiselect={false}
              enumLabelsPath={"yesNoEnum"}
          />
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
            <CodetableSelect inputValues={brands} filteredValues={brandIds} setFilteredValues={setBrandIds} label={t("fieldName.brand")} multiselect={true}/>
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
            <CodetableSelect inputValues={vehicleType} filteredValues={vehicleTypeIds} setFilteredValues={setVehicleTypeIds} label={t("fieldName.vehicleType")} multiselect={true}/>
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
          <FormControl variant="outlined">
            <Tooltip title={asteriskFilterTooltip}>
              <TextField
                  id={"card-filter-model"}
                  label={t("fieldName.model")}
                  type={"text"}
                  name={"model"}
                  value={model}
                  onChange={handleFilterModel}
              />
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className="text-left">
          <FormControl variant="outlined">
            <Tooltip title={asteriskFilterTooltip}>
              <TextField
                  id={"card-filter-model-equipment"}
                  label={t("fieldName.equipment")}
                  type={"text"}
                  name={"modelEquipment"}
                  value={modelEquipment}
                  onChange={handleFilterModelEquipment}
              />
            </Tooltip>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
      >
        <Grid item xs={12} md={4} className="text-left">
            <FilterDate inputDate={offeredValidity} labelCode={'fieldName.offerValidity'} handleUpdate={handleFilterOfferedValidity} />
        </Grid>
        <Grid item xs={12} md={4} className="text-left">
          <FilterDate inputDate={deliveryDate} labelCode={'fieldName.deliveryDate'} handleUpdate={handleFilterDeliveryDate} />
        </Grid>
        <Grid item xs={12} md={4} className="text-left">
          <FilterDate inputDate={lastUpdated} labelCode={'fieldName.lastUpdated'} handleUpdate={handleLastUpdated} />
        </Grid>
      </Grid>
      <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
      >
        <Grid item xs={12} md={12} className="text-right">
          <Button className="reset-button" onClick={resetFilter}>{t('pages.vehicleCards.filters.resetButton')}</Button>
          <Button className="icon-button" onClick={handleSubmitFilter}>
              <VisibilityOutlined />
              {t('pages.vehicleCards.filters.searchButton')}
          </Button>
        </Grid>
      </Grid>

    </Grid>
  )

}
