import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CurrencyEnum, UsedVehicleCard, UsedVehicleCardInput, UsedVehicleCardStateEnum, UsedVehicleDefinition, UsedVehicleImage } from '../../../api/graphql/generated/schema';
import { setLoadingStatus } from '../../../redux/loading';
import CardDetails from '../../../components/usedVehicleCard/CardDetails';
import { openErrorSnackbar, openSuccessSnackbar, setSnackbar } from '../../../redux/snackbar';
import { useTranslation } from 'react-i18next';
import { GeneralVehicleCard, GeneralVehicleDefinition } from '../../../utils/typings';
import {
    CreateUsedVehicleCardMutationOptions, DuplicateUsedVehicleImageMutationOptions,
    useCreateUsedVehicleCardMutation, useDuplicateUsedVehicleImageMutation,
} from '../../../api/graphql/generated/hooks';
import { AppPageNameRoutes } from '../../../routes/paths';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { ApolloError } from '../../../components';
import { GraphQLError } from 'graphql/error/GraphQLError';


const initState = {
    usedVehicleCard: null,
    usedVehicleCardNew: {
        state: UsedVehicleCardStateEnum.InProgress,
        vehicleDefinition: {
            currency: CurrencyEnum.Czk
        }
    }
}

export default function UsedVehicleCardDetailCreate() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const data = history?.location?.state?.cardToDuplicate;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [createUsedVehicleCardMutation, { loading: loadingCreateUsedVehicleCard }] = useCreateUsedVehicleCardMutation();
    const [duplicateImageMutation, {loading: loadingUploadImageMutation}] = useDuplicateUsedVehicleImageMutation();
    const [usedVehicleCardGeneral, setUsedVehicleCardGeneral] = React.useState<GeneralVehicleCard>(initState.usedVehicleCardNew);


    useEffect( () => {
        setLoading(true);
        convertCardToInput(data)
            .then(() => setLoading(false));
    }, [data]);

    useEffect(() => {
        dispatch(setLoadingStatus(loading || loadingUploadImageMutation || loadingCreateUsedVehicleCard));
    }, [dispatch, loading, loadingUploadImageMutation, loadingCreateUsedVehicleCard]);


    const handleCreateCard = (usedVehicleCardInput: UsedVehicleCardInput) => {
        let inputOptions: CreateUsedVehicleCardMutationOptions = {
            variables: {
                input: usedVehicleCardInput
            }
        };

        console.log("Handling Vehicle Definition Create", inputOptions)
        createUsedVehicleCardMutation(inputOptions)
            .then(response => {
                if (response?.data?.createUsedVehicleCard?.id) {
                    dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.created')));
                    let path = AppPageNameRoutes.VEHICLE_USED_CARD_DETAIL.pageRoute.replace(':usedVehicleCardId', response?.data?.createUsedVehicleCard?.id);
                    history.push(path);
                } else if(response.errors){
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch( setSnackbar( true, "error", error.message)));
    }

    const convertDefinitionToInput = (gqlDefinition: UsedVehicleDefinition): GeneralVehicleDefinition => {
        const vehicleDefinition: GeneralVehicleDefinition = {
            ...gqlDefinition,
            brand: {
                id: gqlDefinition.brand.id,
                label: gqlDefinition.brand.label
            },
            vehicleType: gqlDefinition.vehicleType.id,
            color: {
                color: gqlDefinition.color?.color.id || '',
                name: gqlDefinition.color?.name || '',
                colorType: gqlDefinition.color?.colorType.id || '',
            },
            equipment: convertEquipment(gqlDefinition),
            body: gqlDefinition.body.id,
            fuelType: gqlDefinition.fuelType.id,
            transmissionType: gqlDefinition.transmissionType.id,
            wheelbase: gqlDefinition.wheelbase?.id,
            countryOfOrigin: gqlDefinition.countryOfOrigin?.id,
            currency: gqlDefinition.currency,
            stkUntil: gqlDefinition.stkUntil ? new Date(gqlDefinition.stkUntil) : undefined,
            emissionUntil: gqlDefinition.emissionUntil ? new Date(gqlDefinition.emissionUntil) : undefined,
            intoOperation: gqlDefinition.intoOperation ? new Date(gqlDefinition.intoOperation) : undefined,
        }
        return vehicleDefinition;
    }

    const convertEquipment = (gqlDefinition: UsedVehicleDefinition) => {
        return {
            id: gqlDefinition.equipment?.id || '',
            name: gqlDefinition.equipment?.name || '',
            equipments: gqlDefinition.equipment?.equipments?.map((equipment) => ({
                equipment: {
                    id: equipment.equipment.id,
                    name: equipment.equipment.name,
                    category: equipment.equipment.category,
                    auditInfo: equipment.equipment.auditInfo,
                },
                description: equipment.description,
                count: equipment.count,
            }))
        };
    }

    const convertCardToInput =  async (cardToDuplicate: UsedVehicleCard | null) => {
        if (!cardToDuplicate) {
            return;
        }
        setUsedVehicleCardGeneral ({
            state: cardToDuplicate?.state || UsedVehicleCardStateEnum.InProgress,
            vehicleDefinition: cardToDuplicate?.vehicleDefinition ? convertDefinitionToInput(cardToDuplicate.vehicleDefinition) : undefined,
            extraEquipment: cardToDuplicate?.extraEquipment?.items || [],
            images: cardToDuplicate?.images ? await handleDuplicateImages(cardToDuplicate.images) : [],
            seller: cardToDuplicate?.seller,
            marketing: cardToDuplicate?.marketing?.marketingTags || [],
            finance: cardToDuplicate?.finance,
        });
    }

    const handleDuplicateImages = async (images: Array<UsedVehicleImage>) => {
        let duplicateImages: Array<UsedVehicleImage> = [];
        for (const image of images) {
            const duplicate = await duplicateImage(image);
            if(duplicate){
                duplicateImages.push(duplicate);
            }
        }
        return duplicateImages;
    }

    const duplicateImage = async (image: UsedVehicleImage): Promise<UsedVehicleImage | undefined> => {
        let duplicateImage: UsedVehicleImage;
        let inputOptions: DuplicateUsedVehicleImageMutationOptions = {
            variables: {
                input: {
                    fileName: image.fileName,
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        try {
            const response = await duplicateImageMutation(inputOptions);
            if (response?.data?.duplicateUsedVehicleImage?.imageId) {
                duplicateImage = {
                    id: response.data.duplicateUsedVehicleImage.imageId,
                    fileName: response.data.duplicateUsedVehicleImage.fileName,
                    order: 1,
                    mainImage: false,
                    publicUrl: response.data.duplicateUsedVehicleImage.publicUrl
                };
                return duplicateImage;
            } else if (response?.data?.duplicateUsedVehicleImage?.errors) {
                response.data.duplicateUsedVehicleImage.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard"))));
            } else if (response.errors) {
                response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
            }
        } catch(error) {
            dispatch(openErrorSnackbar(getGQLErrorMessages(error as GraphQLError)))
        }

    }

    return (
        <div>
            {!loading &&
                <CardDetails
                    usedVehicleCard={usedVehicleCardGeneral}
                    handleCreateCard={handleCreateCard}
                    duplicated={Boolean(data)}
                />
            }
        </div>
    )
}
