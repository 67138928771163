import { ModelColor, UsedVehicleColor, VehicleCardColor } from '../api/graphql/generated/schema';
import { CardColorDto } from "./CardColorDto";

export class ColorViewerDto {

  private readonly _name: string;
  private readonly _type: string;
  private readonly _additionalInfo: string | null | undefined;

  constructor(name: string, type: string, additionalInfo?: string | null) {
    this._name = name;
    this._type = type;
    this._additionalInfo = additionalInfo;
  }

  static constructFromVehicleCardColor(cardColor: VehicleCardColor) {
    return new ColorViewerDto(cardColor.modelColor.name, cardColor.modelColor.color.id, cardColor.commissionColorId);
  }

  static constructFromUsedVehicleCardColor(cardColor: UsedVehicleColor) {
    return new ColorViewerDto(cardColor.name, cardColor.color.id);
  }

  static constructFromCardColor(cardColor: CardColorDto) {
    return new ColorViewerDto(cardColor.name, cardColor.type, cardColor.commissionColorId);
  }

  static constructFromModelColor(modelColor: ModelColor) {
    return new ColorViewerDto(modelColor.name, modelColor.color.id);
  }


  get name(): string {
    return this._name;
  }

  get type(): string {
    return this._type;
  }

  get additionalInfo(): string | null | undefined {
    return this._additionalInfo;
  }
}
