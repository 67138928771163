import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    BonusDialog,
    LeasingDialog,
} from '../../../components';
import { BonusDto, CreditDto, DeferredCreditDto, LeasingDto } from '../../../modelView';
import { formatToZeroDecimalsString } from '../../../utils/formatterHelper';
import { TotalPricesType } from '../../../components/vehicleCard/finance/FinanceGroup';
import { coinsuranceValues, defaultVat } from '../../../utils/const';
import { Bonus, ServicePackage, ServicePackageStateEnum, UsedVehicleCardFinance } from '../../../api/graphql/generated/schema';
import { AccountBalance, AttachMoneyOutlined, DoneOutlined, EventAvailableOutlined, ExpandMore, WarningOutlined } from '@mui/icons-material';
import CreditDialog from '../../../components/vehicleCard/finance/credit/CreditDialog';
import { ReactComponent as AirBalloon } from '../../../components/icon/airballoon-outline.svg';
import DeferredCreditDialog from '../../../components/vehicleCard/finance/credit/DeferredCreditDialog';
import { useGetBonusesLazyQuery, useGetServicePackagesQuery } from '../../../api/graphql/generated/hooks';
import { setLoadingStatus } from '../../../redux/loading';
import { useDispatch } from 'react-redux';
import { convertInputToCredit, convertInputToDeferredCredit, convertInputToLease } from '../../../utils/utils';
import { setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import { getApolloErrorMessages } from '../../../utils/graphGL/graphQLHelper';

const initState = {
    autoCmsDiscountPP: '0',
    autoCmsDiscountCRN: '0',
    userBonuses: [],
    openBonusDialog: false,
}

export default function UsedCarFinance(props: {
    financeData: UsedVehicleCardFinance | null | undefined,
    modelPrice: number,
    totalEquipment: number,
    brandId: string | null | undefined,
    vehicleTypeId: string | null | undefined,
    handleUpdatePrice: Function,
    handleUpdate: Function,
    initiallyExpanded?: boolean,
}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const bonusButtonDisabled = props.brandId && props.vehicleTypeId ? '' : t('usedVehicleCard.bonus.disabled')

    const [totalBonusesCRN, setTotalBonusesCRN] = React.useState<string>(initState.autoCmsDiscountCRN);
    const [totalBonusesPP, setTotalBonusesPP] = React.useState<string>(initState.autoCmsDiscountPP);
    const [openBonusDialog, setOpenBonusDialog] = useState<boolean>(initState.openBonusDialog);
    const [lease, setLease] = useState<LeasingDto | null>(null)
    const [credit, setCredit] = useState<CreditDto | null>(null)
    const [deferredCredit, setDeferredCredit] = useState<DeferredCreditDto | null>(null)
    const [possibleServices, setPossibleServices] = useState<Array<ServicePackage>>([]);

    const [openLeasingDialog, setOpenLeasingDialog] = useState<boolean>(false);
    const [openCreditDialog, setOpenCreditDialog] = useState<boolean>(false);
    const [openDeferredCreditDialog, setOpenDeferredCreditDialog] = useState<boolean>(false);

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)

    const [totalPrices, setTotalPrices] = useState<TotalPricesType | null>(null);

    const { loading: loadingServicePackages, data: gqlServicePackages } = useGetServicePackagesQuery({ variables: { filter: { states: [ServicePackageStateEnum.Active] } } });
    const [gqlGetBonuses, { loading: loadingBonuses }] = useGetBonusesLazyQuery();

    useEffect(() => {
        setTotalPrices({
            model: props.modelPrice,
            extraEquipment: props.totalEquipment,
            totalBonusesCRN: Number(totalBonusesCRN),
            totalBonusesPP: Number(totalBonusesPP),
            discountOcCrn: Number(props.financeData?.autoCmsDiscountCRN),
            discountOcPP: Number(props.financeData?.autoCmsDiscountPP),
        });
    }, [totalBonusesCRN, totalBonusesPP, props.financeData, props.modelPrice, props.totalEquipment])

    useEffect(() => {
        if (props.financeData?.bonuses) {
            initBonuses(props.financeData.bonuses);
        }
    }, [props.financeData?.bonuses]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingServicePackages));
    }, [loadingServicePackages, dispatch])

    useEffect(() => {
        dispatch(setLoadingStatus(loadingBonuses));
    }, [loadingBonuses, dispatch])

    useEffect(() => {
        if (!loadingServicePackages && gqlServicePackages) {
            setPossibleServices(gqlServicePackages.getServicePackages);
        }
    }, [gqlServicePackages, loadingServicePackages]);

    const initBonuses = (_bonuses: Array<Bonus>) => {
        if (_bonuses) {
            setUpdateBonuses(_bonuses.map(inBonus => BonusDto.convertFromBonusGQL(inBonus)))
        } else {
            setUpdateBonuses(initState.userBonuses);
        }
    }

    useEffect(() => {
        if (props.financeData?.lease) {
            setLease(LeasingDto.convertFromLease(props.financeData.lease))
        }
        if (props.financeData?.credit) {
            setCredit(CreditDto.convertFromCredit(props.financeData.credit))
        }
        if (props.financeData?.deferredCredit) {
            setDeferredCredit(DeferredCreditDto.convertFromCredit(props.financeData.deferredCredit))
        }
    }, [props.financeData?.lease, props.financeData?.credit, props.financeData?.deferredCredit]);

    const changeAutoCmsDiscountCRN = (value: number) => {
        if (!props?.financeData) {
            props.handleUpdate({ autoCmsDiscountCRN: value });
            return;
        }
        if (props.financeData?.autoCmsDiscountCRN) {
            props.financeData.autoCmsDiscountCRN = value;
            props.handleUpdate(props.financeData);
        } else {
            props.handleUpdate({ ...props.financeData, autoCmsDiscountCRN: value });
        }
    }

    const changeAutoCmsDiscountPP = (value: number) => {
        if (!props?.financeData) {
            props.handleUpdate({ autoCmsDiscountPP: value });
            return;
        }
        if (props.financeData?.autoCmsDiscountPP) {
            props.financeData.autoCmsDiscountPP = value;
            props.handleUpdate(props.financeData);
        } else {
            props.handleUpdate({ ...props.financeData, autoCmsDiscountPP: value });
        }

    }

    const changeCoinsurance = (value: number) => {
        if (!props?.financeData) {
            props.handleUpdate({ coinsurance: value });
            return;
        }
        if (props.financeData?.coinsurance) {
            props.financeData.coinsurance = value;
            props.handleUpdate(props.financeData);
        } else {
            props.handleUpdate({ ...props.financeData, coinsurance: value });
        }
    }

    const handleOpenBonusDialog = () => {
        setOpenBonusDialog(true);
    }

    const handleCloseBonusDialog = () => {
        setOpenBonusDialog(false);
    }

    const setUpdateBonuses = (_bonuses: Array<BonusDto>) => {
        let _totalBonusesCRN = _bonuses.filter(bonus => bonus.forCrn).map(bonus => bonus.price).reduce((b1, b2) => b1 + b2, 0);
        const bonusesCNRFormatted = formatToZeroDecimalsString(_totalBonusesCRN);
        setTotalBonusesCRN(bonusesCNRFormatted);
        let _totalBonusesPP = _bonuses.filter(bonus => bonus.forPhysicalPerson).map(bonus => bonus.price).reduce((b1, b2) => b1 + b2, 0);
        const bonusesPPFormatted = formatToZeroDecimalsString(_totalBonusesPP);
        setTotalBonusesPP(bonusesPPFormatted);
        props.handleUpdatePrice (bonusesCNRFormatted, bonusesPPFormatted);
    }

    const handleUpdateBonuses = async (_bonuses: Array<BonusDto>) => {
        setUpdateBonuses(_bonuses);
        let gqlBonuses;
        await gqlGetBonuses({ variables: { filter: { brandId: props.brandId, vehicleTypeId: props.vehicleTypeId } } })
            .then((bonuses) => {
                gqlBonuses = bonuses.data?.getBonuses;
            })
            .catch((error) => dispatch(setSnackbar(true, SnackbarStates.ERROR, getApolloErrorMessages(error))));
        const bonuses = gqlBonuses.filter(bonus => {
            return _bonuses.some(bonusDto => bonusDto.id === bonus.id);
        });

        props.handleUpdate({
            ...props.financeData,
            bonuses: bonuses,
        });
    }

    const renderFinanceGroups = (_totalPrices: TotalPricesType) => {
        let _priceWithVat: number = _totalPrices.model + _totalPrices.extraEquipment;
        let _vat: number = _priceWithVat * defaultVat;
        return (
            <Grid container direction="row">
                <Grid item xs={12} sm={4}>
                    <FormControl className="outlined">
                        <TextField
                            id="priceTAX"
                            label={t('fieldName.priceVAT')}
                            type="text"
                            disabled
                            value={_priceWithVat}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl className="outlined">
                        <TextField
                            id="price"
                            label={t('fieldName.vat')}
                            type="text"
                            disabled
                            value={_vat}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    const renderEndIcon = (object) => {
        return object ? <DoneOutlined color={'primary'}/> : <WarningOutlined color={'error'}/>;
    }

    const handleOpenLeasingDialog = () => {
        setOpenLeasingDialog(true);
    };

    const handleCloseLeasingDialog = () => {
        setOpenLeasingDialog(false);
    };

    const handleUpdateLeasingData = (leaseData: LeasingDto) => {
        const servicePackage = possibleServices.filter(service => service.id === leaseData.serviceId)[0];
        props.handleUpdate({
            ...props.financeData,
            lease: convertInputToLease(leaseData, servicePackage),
        })
        handleCloseLeasingDialog();
    };

    const handleOpenCreditDialog = () => {
        setOpenCreditDialog(true);
    };

    const handleCloseCreditDialog = () => {
        setOpenCreditDialog(false);
    };

    const handleOpenDeferredCreditDialog = () => {
        setOpenDeferredCreditDialog(true);
    };

    const handleCloseDeferredCreditDialog = () => {
        setOpenDeferredCreditDialog(false);
    };

    const handleUpdateCreditData = (creditData: CreditDto) => {
        const servicePackage = possibleServices.filter(service => service.id === creditData.serviceId)[0];
        props.handleUpdate({
            ...props.financeData,
            credit: convertInputToCredit(creditData, servicePackage),
        })
        handleCloseLeasingDialog();
    };

    const handleUpdateDeferredCreditData = (deferredCreditData: DeferredCreditDto) => {
        const servicePackage = possibleServices.filter(service => service.id === deferredCreditData.serviceId)[0];
        props.handleUpdate({
            ...props.financeData,
            deferredCredit: convertInputToDeferredCredit(deferredCreditData, servicePackage),
        })
        handleCloseLeasingDialog();
    };

    return (
        <form id="model-finance-form">
            <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandMore color="secondary"/>}
                >
                    <h2>{t('pages.vehicleCards.detail.finance.title')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="mt-3 w-100">
                        <strong className="text-left d-block mb-4">{t('pages.vehicleCards.detail.finance.discounts')}</strong>
                    </div>
                    <Box mb={2}>
                        <Grid container direction="row">
                            <Grid item xs={12} sm={4}>
                                <FormControl className="outlined">
                                    <TextField
                                        id="total-bonuses-crn"
                                        label={t('fieldName.totalBonusesCRN')}
                                        type="text"
                                        value={totalBonusesCRN}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className="outlined">
                                    <TextField
                                        id="total-bonuses-pp"
                                        label={t('fieldName.totalBonusesPP')}
                                        type="text"
                                        value={totalBonusesPP}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Tooltip title={bonusButtonDisabled}>
                                    <FormControl>
                                        <Button disabled={!props.brandId || !props.vehicleTypeId} size={'large'} onClick={handleOpenBonusDialog}>
                                            <AttachMoneyOutlined/>
                                            {t('pages.vehicleCards.detail.finance.bonuses')}
                                        </Button>
                                    </FormControl>
                                </Tooltip>
                                {props.brandId && props.vehicleTypeId &&
                                    < BonusDialog bonuses={props.financeData?.bonuses?.map(bonus => BonusDto.convertFromBonusGQL(bonus)) || []}
                                                  brandId={props.brandId} vehicleTypeId={props.vehicleTypeId} open={openBonusDialog}
                                                  handleClose={handleCloseBonusDialog} handleUpdate={async (inputBonuses) => await handleUpdateBonuses(inputBonuses)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container direction="row">
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="olfin-car-discount-crn"
                                    label={t('fieldName.autoCmsDiscountCRN')}
                                    type="number"
                                    value={props.financeData?.autoCmsDiscountCRN ? parseFloat(props.financeData.autoCmsDiscountCRN) : 0}
                                    onChange={event => changeAutoCmsDiscountCRN(parseFloat(event.target.value))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className="outlined">
                                <TextField
                                    id="olfin-car-discount-pp"
                                    label={t('fieldName.autoCmsDiscountPP')}
                                    type="number"
                                    value={props.financeData?.autoCmsDiscountPP ? parseFloat(props.financeData?.autoCmsDiscountPP) : 0}
                                    onChange={event => changeAutoCmsDiscountPP(parseFloat(event.target.value))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth component="fieldset">
                                <FormLabel component="legend">{t('fieldName.coinsurance')}</FormLabel>
                                <RadioGroup row name="coinsurance"
                                            value={props.financeData?.coinsurance ? props.financeData.coinsurance.toString() : ''}
                                            onChange={event => changeCoinsurance(parseFloat(event.target.value))}>
                                    <FormControlLabel value={coinsuranceValues.FIVE} control={<Radio/>} label="5%"/>
                                    <FormControlLabel value={coinsuranceValues.TEN} control={<Radio/>} label="10%"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {totalPrices && renderFinanceGroups(totalPrices)}

                    <div className="mt-5 w-100">
                        <strong className="text-left d-block mb-4">{t('pages.vehicleCards.detail.finance.leasingCreditTitle')}</strong>
                    </div>
                    {totalPrices &&
                        <Box mb={2}>
                            <Grid container direction="row">
                                <Grid item xs={12} sm={12}>

                                    <Grid container spacing={5} justifyContent={'space-evenly'}>
                                        <Grid item>
                                            <FormControl>
                                                <Button startIcon={<EventAvailableOutlined/>} endIcon={renderEndIcon(lease?.hasAllValidGroups())}
                                                        onClick={handleOpenLeasingDialog}>
                                                    {t('pages.vehicleCards.detail.finance.buttonLeasingTitle')}
                                                </Button>
                                                <LeasingDialog leaseData={lease} open={openLeasingDialog}
                                                               handleClose={handleCloseLeasingDialog} handleUpdate={handleUpdateLeasingData}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Button startIcon={<AccountBalance/>} endIcon={renderEndIcon(credit?.hasAllValidGroups())}
                                                        onClick={handleOpenCreditDialog}>
                                                    {t('pages.vehicleCards.detail.finance.buttonCreditTitle')}
                                                </Button>
                                                <CreditDialog creditData={credit} open={openCreditDialog}
                                                              handleClose={handleCloseCreditDialog} handleUpdate={handleUpdateCreditData}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Button startIcon={<AirBalloon/>} endIcon={renderEndIcon(deferredCredit?.hasAllValidGroups())}
                                                        onClick={handleOpenDeferredCreditDialog}>
                                                    {t('pages.vehicleCards.detail.finance.buttonDeferredCreditTitle')}
                                                </Button>
                                                <DeferredCreditDialog deferredCreditData={deferredCredit} open={openDeferredCreditDialog}
                                                                      handleClose={handleCloseDeferredCreditDialog} handleUpdate={handleUpdateDeferredCreditData}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </AccordionDetails>
            </Accordion>
        </form>

    );

}
