import React from 'react';
import Login from '../../components/login/Login'
import { useTranslation } from 'react-i18next';

export function LoginPage({ loginUser }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="content">
                <div className="App text-center">
                    <h1>{t('pages.loginPage.welcomeMessage')}</h1>

                    <Login loginUser={loginUser}/>
                </div>
            </div>
        </>
    );
}

export default LoginPage
