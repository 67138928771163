import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Container, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ApolloError, PageHeader } from '../../../components';
import { useTranslation } from 'react-i18next';
import { AppPageNameRoutes } from '../../../routes/paths';
import { defaultRowsPerPage, usedVehicleInitFilterStates } from '../../../utils/const';
import { GLOBAL_SESSION_STATE_NAMES } from '../../../App';
import UsedVehicleCardsFilter from './filter/UsedVehicleCardsFilter';
import UsedVehicleCardsTable from './table/UsedVehicleCardsTable';
import { setLoadingStatus } from '../../../redux/loading';
import {
    UsedVehicleCard,
    UsedVehicleCardFilter,
    UsedVehicleCardFilter as UsedVehicleCardFilterType,
    UsedVehicleCardPage as UsedVehicleCardPageType, UsedVehicleCardStateEnum,
} from '../../../api/graphql/generated/schema';
import { openErrorSnackbar, openSuccessSnackbar} from '../../../redux/snackbar';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { createUnexpectedErrorMessage } from '../../../utils/functionHelper';
import { GetUsedVehicleCardsQueryVariables } from '../../../api/graphql/generated/operations';
import { UpdateUsedVehicleCardStateMutationOptions, useGetUsedVehicleCardsQuery, useUpdateUsedVehicleCardStateMutation } from '../../../api/graphql/generated/hooks';

const breadcrumbs = [
    AppPageNameRoutes.VEHICLE_USED_CARDS
];

const initUsedVehicleDefinitionFilter = {
    vin: undefined,
    brand: undefined,
    state: usedVehicleInitFilterStates
}

type PageFilterType = {
    filter: UsedVehicleCardFilterType;
    offset: number;
    limit: number;
}

const handlePageFilterInit = (): PageFilterType => {
    const sessionFilter: string | null = sessionStorage.getItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_DEFINITIONS_PAGE_FILTER_ID);
    return sessionFilter ? JSON.parse(sessionFilter) : { filter: initUsedVehicleDefinitionFilter, offset: 0, limit: defaultRowsPerPage };
}

export default function UsedVehicleCards() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [pageFilter, setPageFilter] = useState<PageFilterType>(handlePageFilterInit());
    const [usedVehicleCardPage, setUsedVehicleCardPage] = useState<UsedVehicleCardPageType | undefined | null>(undefined);

    const [usedVehicleCardVariables] = useState<GetUsedVehicleCardsQueryVariables>(pageFilter);
    const { data, error, refetch: refetchVehicleCards, fetchMore } = useGetUsedVehicleCardsQuery({ variables: usedVehicleCardVariables});
    const [updateUsedVehicleCardStateMutation, { loading: loadingUpdateUVCS, error: errorUpdateUVCS }] = useUpdateUsedVehicleCardStateMutation();

    useEffect(() => {
        dispatch(setLoadingStatus( loadingUpdateUVCS));
    }, [dispatch, loadingUpdateUVCS]);

    useEffect(() => {
        setUsedVehicleCardPage(data?.getUsedVehicleCards as UsedVehicleCardPageType);
    }, [data]);

    useEffect(() => {
        dispatch(setLoadingStatus(true));
        let currentInput = { filter: pageFilter.filter, offset: pageFilter.offset, limit: pageFilter.limit };
        refetchVehicleCards(currentInput)
            .then(response => {
                setUsedVehicleCardPage(response.data.getUsedVehicleCards as UsedVehicleCardPageType);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    }, [pageFilter, dispatch, refetchVehicleCards])

    if (error) return <div>Error! ${error?.message}</div>

    const updatePageFilter = (filter: UsedVehicleCardFilter,limit: number, offset: number) => {
        let currentPageFilter: PageFilterType = {
            filter,
            limit,
            offset,
        };

        setPageFilter(currentPageFilter);
        sessionStorage.setItem(GLOBAL_SESSION_STATE_NAMES.VEHICLE_DEFINITIONS_PAGE_FILTER_ID, JSON.stringify(currentPageFilter));
    }

    const onSubmitFilter = (submittedFilter: UsedVehicleCardFilter) => {
        updatePageFilter(submittedFilter, defaultRowsPerPage, 0);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(setLoadingStatus(true));
        let changedInput = { offset: newPage }
        fetchMore({ variables: changedInput })
            .then(response => {
                setUsedVehicleCardPage(response.data.getUsedVehicleCards as UsedVehicleCardPageType);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
        updatePageFilter(pageFilter.filter, pageFilter.limit, newPage);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoadingStatus(true));
        const limit: number = parseInt(event.target.value);
        let changedInput = { offset: pageFilter.offset, limit }
        fetchMore({ variables: changedInput })
            .then(response => {
                setUsedVehicleCardPage(response.data.getUsedVehicleCards as UsedVehicleCardPageType);
            })
            .finally(() => dispatch(setLoadingStatus(false)));

        updatePageFilter(pageFilter.filter, limit, pageFilter.offset);

    }

    const handleUpdateUVCS = (cardId: string, state: UsedVehicleCardStateEnum) => {
        let inputOptions: UpdateUsedVehicleCardStateMutationOptions = {
            variables: {
                input: {
                    cardId: cardId,
                    state,
                },
            },
            onError: (error) => <ApolloError error={[error]}/>,
        };
        updateUsedVehicleCardStateMutation(inputOptions)
            .then(response => {
                if (response?.data?.updateUsedVehicleCardState?.usedVehicleCard) {
                    dispatch(openSuccessSnackbar(t('userMessages.vehicleCard.updated')));
                } else if (response?.data?.updateUsedVehicleCardState?.errors) {
                    response.data.updateUsedVehicleCardState.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, 'vehicleCard'))));
                } else if (response.errors) {
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .then(() => refetchVehicleCards())
            .catch(error => dispatch(openErrorSnackbar(createUnexpectedErrorMessage(t, error))));
    }

    const duplicateVehicleCard = (cardId: string) => {
        const card = data?.getUsedVehicleCards.data.filter(card => card.id === cardId)[0];
        let path = AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute;
        history.push(path, {cardToDuplicate: card});
    };

    const handleNewCard = () => {
        let path = AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute;
        history.push(path);
    }

    if (errorUpdateUVCS) return <ApolloError error={[errorUpdateUVCS]}/>;
    return (
        <Container className="page vehicle-card">
            <Grid justifyContent={'space-between'}>
                <Grid item justifyContent={'flex-end'} className="text-left"  >
                    <Button startIcon={<Add/>} onClick={handleNewCard} className=" button new-card">
                        {t('pages.usedVehicleCards.createUsedVehicleCard')}
                    </Button>
                    <PageHeader headline={'usedCars'} breadcrumbs={breadcrumbs}/>
                </Grid>
            </Grid>
            <UsedVehicleCardsFilter handleSubmitFilter={onSubmitFilter} appliedFilter={pageFilter.filter}/>
            <UsedVehicleCardsTable
                dataPage={usedVehicleCardPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                updateArchiveState={handleUpdateUVCS}
                duplicateVehicleCard={duplicateVehicleCard}
            />
        </Container>
    );

}
