import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Card, CardActions, CardContent, CardHeader, FormControl, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setSnackbar, SnackbarStates } from '../../redux/snackbar';
import { setLoadingStatus } from '../../redux/loading';

export default function Login({loginUser}) {
    const dispatch = useDispatch();

    const [ userName, setUserName ] = useState('');
    const [ password, setPassword ] = useState('');
    const {t} = useTranslation();

    const loginHandler = async e => {

        e.preventDefault();

        dispatch(setLoadingStatus(true));

        if (userName === undefined || userName.length === 0) {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('pages.loginPage.notFilledUsername')))
            return false;
        }
        if (password === undefined || password.length === 0) {
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('pages.loginPage.notFilledPassword')))
            return false;
        }

        var resultLogin = await loginUser({
            userName,
            password
        });
        console.log(resultLogin);
        if (resultLogin) {
            dispatch(setLoadingStatus(false));
            dispatch(setSnackbar(true, SnackbarStates.SUCCESS, t('pages.loginPage.successLogin')))
        } else {
            dispatch(setLoadingStatus(false));
            dispatch(setSnackbar(true, SnackbarStates.ERROR, t('pages.loginPage.failedLogin')))
        }


    };

    return (
        <Card className="auth">
            <CardHeader title={t('pages.loginPage.login')} />
            <CardContent>
                <Grid container direction="row" justifyContent={'center'}>
                    <Grid item>
                        <FormControl>
                            <TextField
                                label={t('fieldName.userName')}
                                required={true}
                                value={userName}
                                onChange={event => setUserName(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <TextField
                                type="password"
                                required={true}
                                label={t('fieldName.password')}
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button className="btn btn-login" onClick={loginHandler}>{t('actions.login')}</Button>
            </CardActions>
        </Card>
    );
};
