import { MarketingTag, MarketingTagEnum } from '../../api/graphql/generated/schema';
import i18n from 'i18next';

const enumTranslationPrefix = "marketingTag.enum.values.";
export class MarketingTagDto {
    private readonly _id: MarketingTagEnum | string;
    private _isApplied: boolean
    private _label: string | undefined | null

    private constructor(id: MarketingTagEnum | string, isApplied: boolean, label: string | undefined | null) {
        this._id = id;
        this._isApplied = isApplied;
        this._label = label;
    }

    public static initPossible(id: MarketingTagEnum){
        return new MarketingTagDto(id, false, i18n.t(enumTranslationPrefix + id));
    }

    public static initApplied(id: MarketingTagEnum){
        return new MarketingTagDto(id, true, i18n.t(enumTranslationPrefix + id));
    }

    public static initPossibleTag(tag: MarketingTag){
        return new MarketingTagDto(tag.id, false, tag.label);
    }

    public static initAppliedTag(tag: MarketingTag){
        return new MarketingTagDto(tag.id, true, tag.label);
    }

    public static initAppliedFromMarketingTag(mt: MarketingTagDto){
        return new MarketingTagDto(mt.id, true, mt.label);
    }

    get id(): MarketingTagEnum | string {
        return this._id;
    }

    get label(): string | undefined | null {
        return this._label;
    }

    get isApplied(): boolean {
        return this._isApplied;
    }

    set isApplied(value: boolean) {
        this._isApplied = value;
    }
}
