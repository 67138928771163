import { MarketingTag } from '../../../api/graphql/generated/schema';
import React, { useEffect } from 'react';
import { MarketingTagDto } from '../../../modelView/marketing/MarketingTagDto';
import { Card, CardContent, CardHeader, Checkbox, Grid, Typography } from '@mui/material';
import { ApolloError } from '../../index';
import { useGetMarketingTagsQuery } from '../../../api/graphql/generated/hooks';


const initState = {
    possibleMarketingTags: [],
    userMarketingTags: [],
}

export default function MarketingTagList(props:{ appliedMarketingTags: Array<MarketingTag>, handleAdd: Function, handleRemove: Function }) {

    const [userMarketingTags, setUserMarketingTags] = React.useState<Array<MarketingTagDto>>(initState.userMarketingTags);

    const { loading: loadingTags, data: gqlMarketingTags, error: error } = useGetMarketingTagsQuery();


    useEffect(() => {
        const mmTags: Array<MarketingTagDto> = [];
        if( !loadingTags && gqlMarketingTags && gqlMarketingTags.getMarketingTags){
            gqlMarketingTags.getMarketingTags.forEach((tag) => {
                if (props.appliedMarketingTags && props.appliedMarketingTags.filter(existingTag => existingTag.id === tag.id).length > 0 ) {
                    mmTags.push(MarketingTagDto.initAppliedTag(tag));
                } else {
                    mmTags.push(MarketingTagDto.initPossibleTag(tag));
                }
            });
            mmTags.sort((a, b) => a.id <= b.id ? -1 : 1);
            setUserMarketingTags(mmTags);
        }

    }, [loadingTags, gqlMarketingTags]);

    const handleIsApplied = (marketingTag: MarketingTagDto) => {
        if (marketingTag.isApplied){
            props.handleRemove(marketingTag);
        } else {
            props.handleAdd(marketingTag);
        }
    }

    const renderMarketingTag = (marketingTag: MarketingTagDto) => {
        return (
            <Grid item justifyContent={"space-between"} sm={12} md={3} key={"marketing-tag-checkbox-" + marketingTag.id}>
                <Typography variant={"body2"}>
                    <Checkbox
                        checked={marketingTag.isApplied}
                        onChange={() => handleIsApplied(marketingTag)}
                        color={"primary"}
                        id={"marketing-tag-checkbox-" + marketingTag.id}
                    />
                    {marketingTag.label}
                </Typography>
            </Grid>
        );
    }

    if (error) return <ApolloError error={[error]} />;

    return (
        <Card variant="outlined">
            <CardHeader title={"Marketingové tagy"} />
            <CardContent>
                <Grid container direction={"row"}>
                    {userMarketingTags.map((mt, index) => renderMarketingTag(mt))}
                </Grid>
            </CardContent>
        </Card>
    );
}
