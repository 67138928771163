import {
  ModelColor as ModelColorType,
  VehicleCardColor as VehicleCardColorType
} from "../api/graphql/generated/schema";

export class CardColorDto {

  private readonly _colorCardId: string | null;
  private readonly _commissionColorId: string | null | undefined;
  private readonly _modelColorId: string;
  private readonly _price: number;
  private readonly _name: string;
  private readonly _basicName: string;
  private readonly _type: string;

  private constructor(colorCardId: string | null, modelColorId: string, price: number, name: string, basicName: string, type: string, commissionColorId?: string) {
    this._colorCardId = colorCardId;
    this._modelColorId = modelColorId;
    this._price = price;
    this._name = name;
    this._basicName = basicName;
    this._type = type;
    this._commissionColorId = commissionColorId;
  }

  static constructFromVehicleCardColor(cardColor: VehicleCardColorType){
    let type = cardColor.modelColor.colorType.label ? cardColor.modelColor.colorType.label : cardColor.modelColor.colorType.id;
    let basicName = cardColor.modelColor.color.label ? cardColor.modelColor.color.label : cardColor.modelColor.color.id;
    return new CardColorDto(cardColor.id, cardColor.modelColor.id, cardColor.modelColor.price, cardColor.modelColor.name,  basicName, type, cardColor.commissionColorId)
  }

  static constructFromModelColor(modelColor: ModelColorType){
    let type = modelColor.colorType.label ? modelColor.colorType.label : modelColor.colorType.id;
    let basicName = modelColor.color.label ? modelColor.color.label : modelColor.color.id;
    return new CardColorDto(null, modelColor.id, modelColor.price, modelColor.name, basicName, type)
  }

  get colorCardId(): string | null {
    return this._colorCardId;
  }

  get modelColorId(): string {
    return this._modelColorId;
  }

  get price(): number {
    return this._price;
  }

  get name(): string {
    return this._name;
  }

  get basicName(): string {
    return this._basicName;
  }

  get type(): string {
    return this._type;
  }

  get commissionColorId(): string | null | undefined {
    return this._commissionColorId;
  }
}
