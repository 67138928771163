import React from "react";
import { Card, CardContent, FormControl, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

export type FilterDateType = {
    from: string | null,
    to: string | null
}

export default function FilterDate(props: {inputDate: FilterDateType, labelCode: string, handleUpdate: Function}) {
    const {t} = useTranslation();

    const handleUpdateFrom = (event) => {
        handleUpdate(event.target.value, props.inputDate.to);
    }

    const handleUpdateTo = (event) => {
        handleUpdate(props.inputDate.from, event.target.value);
    };

    const handleUpdate = (from, to) => {
        const newFilterDate: FilterDateType = {
            from: from,
            to: to
        }
        props.handleUpdate(newFilterDate)
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="caption" color={"textSecondary"}>{t(props.labelCode)}</Typography>
                <Grid container>
                    <Grid item xs={6} className="text-left">
                      <FormControl>
                          <TextField
                              id="delivery-date"
                              label={t('fieldName.filterDate.from')}
                              type={"date"}
                              value={props.inputDate.from}
                              onChange={handleUpdateFrom}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} className="text-left">
                        <FormControl>
                            <TextField
                                id="delivery-date"
                                label={t('fieldName.filterDate.to')}
                                type={"date"}
                                value={props.inputDate.to}
                                onChange={handleUpdateTo}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
  );

}
