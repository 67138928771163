import {Brand} from "../../../../api/graphql/generated/schema";

export class OptionCodeAutocomplete {

  private readonly _vehicleDefinitionId: string;
  private readonly _brand: string;
  private readonly _model: string;
  private readonly _modelLine: string;
  private readonly _modelYear: string ;
  private readonly _optionCode: string;


  constructor(vehicleDefinitionId: string, brand: Brand | undefined, model: string | undefined, modelLine: string | undefined,  modelYear: number | undefined, optionCode: string | undefined) {
    this._vehicleDefinitionId = vehicleDefinitionId;
    this._brand = brand?.label ? brand.label : "";
    this._model = model ? model : "";
    this._modelLine = modelLine ? modelLine : "";
    this._modelYear = modelYear ? modelYear.toString() : "";
    this._optionCode = optionCode ? optionCode : "";

  }

  get vehicleDefinitionId(): string {
    return this._vehicleDefinitionId;
  }

  get optionCode(): string {
    return this._optionCode;
  }

  get model(): string {
    return this._model;
  }

  displayValue(){
    return this._optionCode + " (" + this._brand + ", " + this._modelLine  + ", " + this._model + ", " + this._modelYear +")";
  }
}
