import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, Grid, List, TextField} from '@mui/material';
import {useTranslation} from "react-i18next";
import {Body as BodyType, FuelType, FuelTypeEnum, TechnicalSpecification as TechnicalSpecificationType, TransmissionType, VehicleTypeEnum, WheelbaseType} from "../../../api/graphql/generated/schema";
import {
  CreateTechnicalSpecMutationOptions,
  UpdateTechnicalSpecMutationOptions,
  useCreateTechnicalSpecMutation,
  useGetBodiesQuery,
  useGetFuelTypesQuery,
  useGetTransmissionTypesQuery,
  useGetWheelbaseTypesQuery,
  useUpdateTechnicalSpecMutation
} from "../../../api/graphql/generated/hooks";
import {ApolloError, CodetableSelect} from "../../index";
import {setLoadingStatus} from "../../../redux/loading";
import {setSnackbar, SnackbarStates} from "../../../redux/snackbar";
import {getBusinessErrorMessages, getGQLErrorMessages} from "../../../utils/graphGL/graphQLHelper";
import { ExpandMore } from '@mui/icons-material';

const initState = {
  modelKey: "",
  modelKeyExtension: null,
  optionCode: "",
  price: "",
  bodyId: "",
  seats: "",
  engine: "",
  fuelTypeId: "",
  power: "",
  cubicCapacity: "",
  fuelConsumption: "",
  emission: "",
  range: null,
  transmissionTypeId: "",
  transmission: "",
  driveWheel: "",
  fourDriveWheel: false,
  trunkSpace: null,
  trunkLength: null,
  numberOfPallets: null,
  wheelbase: "",
  changed: false
}

const assembleOptionCode = (modelKey: string, modelYear: number | undefined) => {
  let optionCode;
  if (modelYear && modelKey) {
    optionCode = modelKey + "-" + modelYear;
  } else {
    optionCode = initState.optionCode;
  }
  return optionCode;
}

const parseFloatNumber = (value: string | null | undefined) => {
  return value ? parseFloat(value) : undefined;
}

export default function TechnicalSpecification (
    props: {
      modelEquipmentId: string,
      technicalSpecification?: TechnicalSpecificationType | null,
      vehicleType?: string,
      modelYear: number | undefined,
      initiallyExpanded?: boolean,
      handleUpdateVehicleDefinition
      hasModelKeyExtension?: boolean
    })
{

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: loadingBodies, data: gqlBodies, error: errorBodies } = useGetBodiesQuery();
  const { loading: loadingFuelTypes, data: gqlFuelTypes, error: errorFuelTypes } = useGetFuelTypesQuery();
  const { loading: loadingTransmissionTypes, data: gqlTransmissionTypes, error: errorTransmissionTypes } = useGetTransmissionTypesQuery();
  const { loading: loadingWheelbaseTypes, data: gqlWheelbaseTypes, error: errorWheelbaseTypes } = useGetWheelbaseTypesQuery();
  const [createTechnicalSpecMutation, {loading: loadingCreateTS, error: errorCreateTS}] = useCreateTechnicalSpecMutation();
  const [updateTechnicalSpecMutation, {loading: loadingUpdateTS, error: errorUpdateTS}] = useUpdateTechnicalSpecMutation();


  const [changed, setChanged] = useState(initState.changed);
  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded);

  const [userModelKey, setUserModelKey] = React.useState<string>(initState.modelKey);
  const [userModelKeyExtension, setUserModelKeyExtension] = React.useState<string | null | undefined>(initState.modelKeyExtension);
  const [userOptionCode, setUserOptionCode] = React.useState<string>(initState.optionCode);
  const [userPrice, setUserPrice] = React.useState<string>(initState.price);
  const [userBodyId, setUserBodyId] = React.useState<string>(initState.bodyId);
  const [userSeats, setUserSeats] = React.useState<string>(initState.seats);
  const [userEngine, setUserEngine] = React.useState<string>(initState.engine);
  const [userFuelTypeId, setUserFuelTypeId] = React.useState<string>(initState.fuelTypeId);
  const [userPower, setUserPower] = React.useState<string>(initState.power);
  const [userCubicCapacity, setUserCubicCapacity] = React.useState<string>(initState.cubicCapacity);
  const [userFuelConsumption, setUserFuelConsumption] = React.useState<string>(initState.fuelConsumption);
  const [userEmission, setUserEmission] = React.useState<string>(initState.emission);
  const [userRange, setUserRange] = React.useState<string | null | undefined>(initState.range);
  const [userTransmissionTypeId, setUserTransmissionTypeId] = React.useState<string>(initState.transmissionTypeId);
  const [userTransmission, setUserTransmission] = React.useState<string>(initState.transmission);
  const [userDriveWheel, setUserDriveWheel] = React.useState<string>(initState.driveWheel);
  const [userForDriveWheel, setUserForDriveWheel] = useState<boolean>(initState.fourDriveWheel);
  const [userTrunkSpace, setUserTrunkSpace] = React.useState<string | null | undefined>(initState.trunkSpace);
  const [userTrunkLength, setUserTrunkLength] = React.useState<string | null | undefined>(initState.trunkLength);
  const [userNumberOfPallets, setUserNumberOfPallets] = React.useState<string | null | undefined>(initState.numberOfPallets);
  const [userWheelbaseId, setUserWheelbaseId] = React.useState<string>(initState.wheelbase);

  const [possibleBodies, setPossibleBodies] = React.useState<Array<BodyType>>([]);
  const [possibleFuelTypes, setPossibleFuelTypes] = React.useState<Array<FuelType>>([]);
  const [possibleTransmissionTypes, setPossibleTransmissionTypes] = React.useState<Array<TransmissionType>>([]);
  const [possibleWheelbaseTypes, setPossibleWheelbaseTypes] = React.useState<Array<WheelbaseType>>([]);

  const isCommercial = props.vehicleType === VehicleTypeEnum.Commercial;

  useEffect(() => {
    if (!loadingBodies && gqlBodies) {
      setPossibleBodies(gqlBodies.getBodies.filter(body => body.vehicleType?.id === props.vehicleType));
    }
  }, [loadingBodies, gqlBodies, props.vehicleType]);

  useEffect(() => {
    if (!loadingFuelTypes && gqlFuelTypes) {
      setPossibleFuelTypes(gqlFuelTypes.getFuelTypes);
    }
  }, [loadingFuelTypes, gqlFuelTypes]);

  useEffect(() => {
    if (!loadingTransmissionTypes && gqlTransmissionTypes) {
      setPossibleTransmissionTypes(gqlTransmissionTypes.getTransmissionTypes);
    }
  }, [loadingTransmissionTypes, gqlTransmissionTypes]);


  useEffect(() => {
    if (!loadingWheelbaseTypes && gqlWheelbaseTypes) {
      setPossibleWheelbaseTypes(gqlWheelbaseTypes.getWheelbaseTypes);
    }
  }, [loadingWheelbaseTypes, gqlWheelbaseTypes]);


  const init = () => {
    setChanged(initState.changed);
    if(props?.technicalSpecification){
      setUserModelKey(props.technicalSpecification.modelKey);
      setUserModelKeyExtension(props.technicalSpecification.modelKeyExtension);
      setUserOptionCode(props.technicalSpecification.optionCode);
      setUserPrice(props.technicalSpecification.price);
      setUserBodyId(props.technicalSpecification.body.id);
      setUserSeats(props.technicalSpecification.seats?.toString());
      setUserEngine(props.technicalSpecification.engine);
      setUserFuelTypeId(props.technicalSpecification.fuelType.id);
      setUserPower(props.technicalSpecification.power);
      setUserCubicCapacity(props.technicalSpecification.cubicCapacity?.toString());
      setUserFuelConsumption(props.technicalSpecification.fuelConsumption?.toString());
      setUserEmission(props.technicalSpecification.emission?.toString());
      setUserRange(props.technicalSpecification.range?.toString());
      setUserTransmissionTypeId(props.technicalSpecification.transmissionType.id);
      setUserTransmission(props.technicalSpecification.transmission);
      setUserDriveWheel(props.technicalSpecification.driveWheel);
      setUserForDriveWheel(props.technicalSpecification.fourDriveWheel);
      if (isCommercial) {
        setUserTrunkSpace(props.technicalSpecification.trunkSpace?.toString());
        setUserTrunkLength(props.technicalSpecification.trunkLength?.toString());
        setUserNumberOfPallets(props.technicalSpecification.numberOfPallets?.toString());
        if (props.technicalSpecification.wheelbase) {
          setUserWheelbaseId(props.technicalSpecification.wheelbase?.id);
        } else {
          setUserWheelbaseId(initState.wheelbase);
        }
      }
    } else {
      setUserModelKey(initState.modelKey);
      setUserModelKeyExtension(initState.modelKeyExtension);
      setUserOptionCode(initState.optionCode);
      setUserPrice(initState.price);
      setUserBodyId(initState.bodyId);
      setUserSeats(initState.seats);
      setUserEngine(initState.engine);
      setUserFuelTypeId(initState.fuelTypeId);
      setUserPower(initState.power);
      setUserCubicCapacity(initState.cubicCapacity);
      setUserFuelConsumption(initState.fuelConsumption);
      setUserEmission(initState.emission);
      setUserRange(initState.range);
      setUserTransmissionTypeId(initState.transmissionTypeId);
      setUserTransmission(initState.transmission);
      setUserDriveWheel(initState.driveWheel);
      setUserForDriveWheel(initState.fourDriveWheel);
      setUserTrunkSpace(initState.trunkSpace);
      setUserTrunkLength(initState.trunkLength);
      setUserNumberOfPallets(initState.numberOfPallets);
      setUserWheelbaseId(initState.wheelbase);
    }
  }

  useEffect(() => {
    init();
  }, [props.technicalSpecification])

  const changedInput = (setFunction) => (value: any) => {
    setFunction(value);
    setChanged(true);
  }

  const changeModelKey = (value: string) => {
    changedInput(setUserModelKey)(value);
    setUserOptionCode(assembleOptionCode(value, props.modelYear));
  }

  const handleUpdateTS = (technicalSpecificationId: string) => {
    let inputOptions: UpdateTechnicalSpecMutationOptions = {
      variables: {
        input: {
          id: technicalSpecificationId,
          modelKey: userModelKey,
          modelKeyExtension: userModelKeyExtension,
          optionCode: userOptionCode,
          price: userPrice,
          body: userBodyId,
          seats: parseFloat(userSeats),
          engine: userEngine,
          fuelType: userFuelTypeId,
          power: userPower,
          cubicCapacity: parseFloat(userCubicCapacity),
          fuelConsumption: parseFloat(userFuelConsumption),
          emission: parseFloat(userEmission),
          range: parseFloatNumber(userRange),
          transmissionType: userTransmissionTypeId,
          transmission: userTransmission,
          driveWheel: userDriveWheel,
          fourDriveWheel: userForDriveWheel,
          trunkSpace: parseFloatNumber(userTrunkSpace),
          trunkLength: parseFloatNumber(userTrunkLength),
          numberOfPallets: parseFloatNumber(userNumberOfPallets),
          wheelbase: userWheelbaseId,
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateTechnicalSpecMutation(inputOptions)
    .then(response => {
      if (response?.data?.updateTechnicalSpec?.technicalSpecification) {
        props.handleUpdateVehicleDefinition(response?.data?.updateTechnicalSpec?.technicalSpecification);
        dispatch( setSnackbar( true, SnackbarStates.SUCCESS, t("userMessages.technicalSpecificationSaved")));
      } else if (response?.data?.updateTechnicalSpec?.errors) {
        response.data.updateTechnicalSpec.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
      } else if(response.errors){
        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
      }
    })
    .then(() => setChanged(false))
    .catch(error => <ApolloError error={error}/>);
  }

  const handleCreateTS = () => {
    let inputOptions: CreateTechnicalSpecMutationOptions = {
      variables: {
        input: {
          modelEquipmentId: props.modelEquipmentId,
          modelKey: userModelKey,
          modelKeyExtension: userModelKeyExtension,
          optionCode: userOptionCode,
          price: userPrice,
          body: userBodyId,
          seats: parseFloat(userSeats),
          engine: userEngine,
          fuelType: userFuelTypeId,
          power: userPower,
          cubicCapacity: parseFloat(userCubicCapacity),
          fuelConsumption: parseFloat(userFuelConsumption),
          emission: parseFloat(userEmission),
          range: parseFloatNumber(userRange),
          transmissionType: userTransmissionTypeId,
          transmission: userTransmission,
          driveWheel: userDriveWheel,
          fourDriveWheel: userForDriveWheel,
          trunkSpace: parseFloatNumber(userTrunkSpace),
          trunkLength: parseFloatNumber(userTrunkLength),
          numberOfPallets: parseFloatNumber(userNumberOfPallets),
          wheelbase: userWheelbaseId,
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    createTechnicalSpecMutation(inputOptions)
    .then(response => {
      if (response?.data?.createTechnicalSpec?.technicalSpecification) {
        props.handleUpdateVehicleDefinition(response?.data?.createTechnicalSpec?.technicalSpecification);
        dispatch( setSnackbar( true, SnackbarStates.SUCCESS, t("userMessages.technicalSpecificationCreated")));
      } else if (response?.data?.createTechnicalSpec?.errors) {
        response.data.createTechnicalSpec.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getBusinessErrorMessages(error, t))));
      } else if(response.errors){
        response.errors.map(error => dispatch(setSnackbar(true, SnackbarStates.ERROR, getGQLErrorMessages(error))));
      }
    })
    .then(() => setChanged(false))
    .catch(error => <ApolloError error={error}/>);
  }

  const handleSubmit = () => {
    if (props?.technicalSpecification?.id){
      handleUpdateTS(props.technicalSpecification.id);
    } else {
      handleCreateTS();
    }
  }
  //Handle loading
  dispatch(setLoadingStatus(loadingBodies || loadingFuelTypes || loadingTransmissionTypes || loadingWheelbaseTypes || loadingCreateTS || loadingUpdateTS));
  //Handle errors
  if (errorBodies) return <ApolloError error={[errorBodies]} />;
  if (errorFuelTypes) return <ApolloError error={[errorFuelTypes]} />;
  if (errorTransmissionTypes) return <ApolloError error={[errorTransmissionTypes]} />;
  if (errorWheelbaseTypes) return <ApolloError error={[errorWheelbaseTypes]} />;
  if (errorCreateTS) return <ApolloError error={[errorCreateTS]} />;
  if (errorUpdateTS) return <ApolloError error={[errorUpdateTS]} />;

  return (
      <form onSubmit={handleSubmit} onReset={init}>
        <Accordion variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
          <AccordionSummary
              expandIcon={<ExpandMore />}
          >
            {t("pages.definitionEditor.technicalSpecification.title")}
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <section>
                <h4>{t("pages.definitionEditor.technicalSpecification.section.general")} </h4>
                <Grid container>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.modelKey")}
                        required
                        error={!userModelKey}
                        value={userModelKey}
                        onChange={event => changeModelKey(event.target.value)}
                        inputProps={{
                          maxLength: 15
                        }}
                    />
                  </FormControl>
                  </Grid>
                  {props.hasModelKeyExtension &&
                    <Grid item><FormControl>
                      <TextField
                        variant="outlined"
                        label={t("fieldName.modelKeyExtension")}
                        value={userModelKeyExtension}
                        onChange={event => changedInput(setUserModelKeyExtension)(event.target.value)}
                        inputProps={{
                          maxLength: 15
                        }}

                      />
                    </FormControl>
                    </Grid>
                  }
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.optionCode")}
                        InputLabelProps={userOptionCode ? {shrink: true} : {shrink: false}}
                        required
                        error={!userOptionCode}
                        value={userOptionCode}
                        disabled={true}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.modelPriceBase")}
                        required
                        error={!userPrice}
                        value={userPrice}
                        onChange={event => changedInput(setUserPrice)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <CodetableSelect
                        inputValues={possibleBodies}
                        filteredValues={userBodyId}
                        setFilteredValues={changedInput(setUserBodyId)}
                        label={t("fieldName.body")}
                        multiselect={false}
                        required
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.seatsCount")}
                        required
                        error={!userSeats}
                        value={userSeats}
                        onChange={event => changedInput(setUserSeats)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                  </Grid>
              </section>
              <section>
                <h4>{t("pages.definitionEditor.technicalSpecification.section.engine")} </h4>
                <Grid container>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.engine")}
                        required
                        error={!userEngine}
                        value={userEngine}
                        onChange={event => changedInput(setUserEngine)(event.target.value)}
                        inputProps={{
                          maxLength: 20
                        }}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <CodetableSelect
                        inputValues={possibleFuelTypes}
                        filteredValues={userFuelTypeId}
                        setFilteredValues={changedInput(setUserFuelTypeId)}
                        label={t("fieldName.fuel")}
                        multiselect={false}
                        required
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.power")}
                        required
                        error={!userPower}
                        value={userPower}
                        onChange={event => changedInput(setUserPower)(event.target.value)}
                        inputProps={{
                          maxLength: 10
                        }}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.cubicCapacity")}
                        required
                        error={!userCubicCapacity}
                        value={userCubicCapacity}
                        onChange={event => changedInput(setUserCubicCapacity)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.consumption")}
                        required
                        error={!userFuelConsumption}
                        value={userFuelConsumption}
                        onChange={event => changedInput(setUserFuelConsumption)(event.target.value)}
                        type={"number"}

                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.emission")}
                        required
                        error={!userEmission}
                        value={userEmission}
                        onChange={event => changedInput(setUserEmission)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.range")}
                        required={(userFuelTypeId === FuelTypeEnum.Electric)}
                        error={(userFuelTypeId === FuelTypeEnum.Electric) && !userRange}
                        value={userRange}
                        onChange={event => changedInput(setUserRange)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                  </Grid>
              </section>
              <section>
                <h4>{t("pages.definitionEditor.technicalSpecification.section.drive")} </h4>
                <Grid container>
                  <Grid item>
                  <FormControl>
                    <CodetableSelect
                        inputValues={possibleTransmissionTypes}
                        filteredValues={userTransmissionTypeId}
                        setFilteredValues={changedInput(setUserTransmissionTypeId)}
                        label={t("fieldName.transmissionType")}
                        multiselect={false}
                        required
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.transmission")}
                        required
                        error={!userTransmission}
                        value={userTransmission}
                        onChange={event => changedInput(setUserTransmission)(event.target.value)}
                        inputProps={{
                          maxLength: 50
                        }}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.drive")}
                        required
                        error={!userDriveWheel}
                        value={userDriveWheel}
                        onChange={event => changedInput(setUserDriveWheel)(event.target.value)}
                        inputProps={{
                          maxLength: 8
                        }}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item>
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={userForDriveWheel}
                            onChange={() => changedInput(setUserForDriveWheel)(!userForDriveWheel)}
                            name="is-for-drive-chbx"
                            color={"primary"}
                        />
                      }
                      label={t("fieldName.4x4")}
                  />
                  </Grid>
                  </Grid>
              </section>
              { isCommercial
              && <section>
                <h4>{t("pages.definitionEditor.technicalSpecification.section.infoForCommercial")} </h4>
                  <Grid container>
                  <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.trunkSpace")}
                        required
                        error={!userTrunkSpace}
                        value={userTrunkSpace}
                        onChange={event => changedInput(setUserTrunkSpace)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                  </Grid>
                    <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.trunkLength")}
                        required
                        error={!userTrunkLength}
                        value={userTrunkLength}
                        onChange={event => changedInput(setUserTrunkLength)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                    </Grid>
                    <Grid item>
                  <FormControl>
                    <TextField
                        variant="outlined"
                        label={t("fieldName.numberOfPallets")}
                        value={userNumberOfPallets}
                        onChange={event => changedInput(setUserNumberOfPallets)(event.target.value)}
                        type={"number"}
                    />
                  </FormControl>
                    </Grid>
                    <Grid item>
                  <FormControl>
                    <CodetableSelect
                      inputValues={possibleWheelbaseTypes}
                      filteredValues={userWheelbaseId}
                      setFilteredValues={changedInput(setUserWheelbaseId)}
                      label={t("fieldName.wheelbase")}
                      multiselect={false}
                      required
                    />
                  </FormControl>
                    </Grid>
                    </Grid>
              </section>
              }
            </List>
          </AccordionDetails>
          <AccordionActions>
            <Button disabled={!changed} type="reset">{t("actions.discard")}</Button>
            <Button disabled={!changed} onClick={handleSubmit}>{t("actions.save")}</Button>
          </AccordionActions>
        </Accordion>
      </form>
  );

}
