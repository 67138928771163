import React from 'react';
import { DeferredCreditGroupDto, DeferredCreditGroupItemDto } from '../../../../modelView';
import { useTranslation } from 'react-i18next';
import { isValidMonthlyInstalment, isValidPurchasePrice, maxMonthlyInstalment, minMonthlyInstalment, minPurchasePrice } from '../../../../modelView/credit/CreditUtil';
import { formatPercentageFromDecimal } from '../../../../utils/formatterHelper';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';

export default function DeferredCreditGroupTab(props: { deferredCreditGroup: DeferredCreditGroupDto, handleUpdate: Function }) {

    const { t } = useTranslation();

    const removeRowTooltip = t("actions.remove");
    const addRowTooltip = t("actions.add");


    const handleUpdate = (creditGroup: DeferredCreditGroupDto) => {
        props.handleUpdate(creditGroup)
    }

    const removeRow = (index: number) => {
        const newItems = props.deferredCreditGroup.items.filter((item, idx) => idx !== index);
        handleUpdate(new DeferredCreditGroupDto(props.deferredCreditGroup.type, newItems));
    }

    const addRow = () => {
        const newItems = [...props.deferredCreditGroup.items, new DeferredCreditGroupItemDto(null, null, null, null, null)];
        handleUpdate(new DeferredCreditGroupDto(props.deferredCreditGroup.type, newItems));
    }

    const handleItemUpdate = (newItem: DeferredCreditGroupItemDto, index: number) => {
        const newItems = [...props.deferredCreditGroup.items];
        newItems[index] = newItem;
        handleUpdate(new DeferredCreditGroupDto(props.deferredCreditGroup.type, newItems));
    }

    const updateMonth = (item: DeferredCreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: DeferredCreditGroupItemDto = new DeferredCreditGroupItemDto(updatedValue, item.advancedPayment, item.monthlyInstalment, item.deferredPayment, item.purchasePrice);
        handleItemUpdate(updatedData, index);
    }

    const updateAdvancedPayment = (item: DeferredCreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) / 100 : null;
        const updatedData: DeferredCreditGroupItemDto = new DeferredCreditGroupItemDto(item.month, updatedValue, item.monthlyInstalment, item.deferredPayment, item.purchasePrice);
        handleItemUpdate(updatedData, index);
    }

    const updateDeferredPayment = (item: DeferredCreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: DeferredCreditGroupItemDto = new DeferredCreditGroupItemDto(item.month, item.advancedPayment, item.monthlyInstalment, updatedValue, item.purchasePrice);
        handleItemUpdate(updatedData, index);
    }

    const updateMonthlyInstalment = (item: DeferredCreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: DeferredCreditGroupItemDto = new DeferredCreditGroupItemDto(item.month, item.advancedPayment, updatedValue, item.deferredPayment, item.purchasePrice);
        handleItemUpdate(updatedData, index);
    }

    const updatePurchasePrice = (item: DeferredCreditGroupItemDto, index: number, newValue: string) => {
        const updatedValue: number | null = newValue ? parseInt(newValue) : null;
        const updatedData: DeferredCreditGroupItemDto = new DeferredCreditGroupItemDto(item.month, item.advancedPayment, item.monthlyInstalment, item.deferredPayment, updatedValue);
        handleItemUpdate(updatedData, index);
    }

    return (
        <TabPanel value={props.deferredCreditGroup.type}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.deferredCredit.creditGroupTable.month')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.deferredCredit.creditGroupTable.advancedPayment')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.deferredCredit.creditGroupTable.deferredPayment')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.deferredCredit.creditGroupTable.monthlyInstalment')}
                            </TableCell>
                            <TableCell align="center">
                                {t('pages.vehicleCards.detail.finance.deferredCredit.creditGroupTable.purchasePrice')}
                            </TableCell>

                            <TableCell align="center">
                                <Tooltip title={addRowTooltip}>
                                    <IconButton onClick={addRow}>
                                        <AddOutlined/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.deferredCreditGroup.items.map((item, index) =>
                            <TableRow key={"deferred-credit-group-item-row" + index}>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.month}
                                        value={item.month}
                                        onChange={event => updateMonth(item, index, event.target.value)}
                                        type={"text"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.advancedPayment}
                                        value={formatPercentageFromDecimal(item.advancedPayment)}
                                        onChange={event => updateAdvancedPayment(item, index, event.target.value)}
                                        type={"text"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        error={!item.deferredPayment}
                                        value={item.deferredPayment}
                                        onChange={event => updateDeferredPayment(item, index, event.target.value)}
                                        type={"number"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        className={"w-100"}
                                        error={!isValidMonthlyInstalment(item.monthlyInstalment, true)}
                                        value={item.monthlyInstalment}
                                        onChange={event => updateMonthlyInstalment(item, index, event.target.value)}
                                        type={"number"}
                                        InputProps={{ inputProps: { min: minMonthlyInstalment, max: maxMonthlyInstalment } }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        error={!isValidPurchasePrice(item.purchasePrice, false)}
                                        value={item.purchasePrice}
                                        onChange={event => updatePurchasePrice(item, index, event.target.value)}
                                        type={"number"}
                                        InputProps={{ inputProps: { min: minPurchasePrice } }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={removeRowTooltip}>
                                        <IconButton onClick={() => removeRow(index)}>
                                            <DeleteOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    );
}
