
export enum Role {
    UV_CARD_READ = 'UV_CARD_READ',
    UV_CARD_EDIT = 'UV_CARD_EDIT',
    NV_DEFINITION_ADMIN = 'NV_DEFINITION_ADMIN',
    NV_CARD_ADMIN = 'NV_CARD_ADMIN',
    CODETABLE_ADMIN = 'CODETABLE_ADMIN',
}


export enum CoreEntityEnum {
    body = "body",
    brand = "brand",
    color = "color",
    colorType = "colorType",
    countryOfOrigin = "countryOfOrigin",
    equipmentCategory = "equipmentCategory",
    fuelType = "fuelType",
    marketingTag = "marketingTag",
    transmissionType = "transmissionType",
    vehicleType = "vehicleType",
    wheelbaseType = "wheelbaseType",
}


export enum VehicleStateEnum {
    perfect = "perfect",
    good = "good",
    veryGood = "veryGood",
    showCar = "showCar"
}
