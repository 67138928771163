import { ServicePackage as ServicePackageType } from "../../../api/graphql/generated/schema";


export class ServicePackageSelectDto {
  private readonly _id: string;
  private readonly _name: string;

  constructor(id: string, name: string | null | undefined){
    this._id = id;
    this._name = name ? name : id;
}

static createFromAPI(servicePackageAPIDto: ServicePackageType){
  return new ServicePackageSelectDto(servicePackageAPIDto.id, servicePackageAPIDto.name);
}

  public get id() : string {
      return this._id;
  }

  public get name() : string {
      return this._name;
  }


}
