export const START_PATH = {
	CODETABLES: '/codetables',
	VEHICLE_NEW: '/vehicle-new',
	VEHICLE_USED: '/vehicle-used',
	USER: '/user'
};

export const AppPageNameRoutes = {
	HOME:  {
		pageName: 'home', pageRoute: '/',
	},
	PROFILE:  {
		pageName: 'profile', pageRoute: '/profile',
	},
	CODETABLES: {
		pageName: 'codetables', pageRoute: START_PATH.CODETABLES + "/edit",
	},
	CODETABLES_SERVICE_PACKAGES: {
		pageName: 'servicePackages', pageRoute: START_PATH.CODETABLES + "/service-packages",
	},
	VEHICLE_NEW_DEFINITIONS: {
		pageName: 'definitions', pageRoute: START_PATH.VEHICLE_NEW + "/definitions",
	},
	VEHICLE_NEW_DEFINITION_DETAIL: {
		pageName: 'editDefinition', pageRoute: START_PATH.VEHICLE_NEW + "/definitions/:vehicleDefinitionId",
	},
	VEHICLE_NEW_DEFINITION_DETAIL_CREATE: {
		pageName: 'createDefinition', pageRoute: START_PATH.VEHICLE_NEW + "/definitions/create-definition",
	},
	VEHICLE_NEW_CARDS: {
		pageName: 'cards', pageRoute: START_PATH.VEHICLE_NEW + "/cards",
	},
	VEHICLE_NEW_CARD_DETAIL: {
		pageName: 'editCard', pageRoute: START_PATH.VEHICLE_NEW + "/cards/:vehicleCardId",
	},
	VEHICLE_NEW_CARD_DETAIL_CREATE: {
		pageName: 'createCard', pageRoute: START_PATH.VEHICLE_NEW + "/cards/create-card",
	},
	VEHICLE_USED_CARDS: {
		pageName: 'usedVehicleCards', pageRoute: START_PATH.VEHICLE_USED + "/cards",
	},
	VEHICLE_USED_CARD_CREATE: {
		pageName: 'usedVehicleCardsCreate', pageRoute: START_PATH.VEHICLE_USED + "/cards/create-card",
	},
	VEHICLE_USED_CARD_DETAIL: {
		pageName: 'usedVehicleCardsEdit', pageRoute: START_PATH.VEHICLE_USED + "/cards/:usedVehicleCardId",
	},
	ABOUT: {
		pageName: 'about', pageRoute: "/about",
	},
	NOT_FOUND: {
		pageName: 'notFound', pageRoute: "/not-found",
	}
}

export type AppPageNameRouteType = {
	pageName: string,
	pageRoute: string
}

export function assembleRedirectPath(...optionalParams: any[]){
	return optionalParams.join("/");
}
