import {ExtraEquipmentItem as ExtraEquipmentItemType} from "../api/graphql/generated/schema";

export class ExtraEquipmentItemDto {

  private _name: string;
  private _price: number;

  constructor(name: string, price: number) {
    this._name = name;
    this._price = price;
  }

  static convertFromExtraEquipment(item: ExtraEquipmentItemType){
    let _name = item.name ? item.name : "";
    let _price = item.price ? item.price : 0;

    return new ExtraEquipmentItemDto(_name, _price)
  }

  get price(): number {
    return this._price;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  set price(value: number) {
    this._price = value;
  }
}
