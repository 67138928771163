import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApolloError, GraphQLError, ModelDefinition, PageHeader } from "../../../../components";
import { AppPageNameRoutes, assembleRedirectPath } from "../../../../routes/paths";
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ModelDefinition as ModelDefinitionType } from "../../../../api/graphql/generated/schema";
import {
  CreateVehicleDefinitionMutationOptions,
  UpdateVehicleDefinitionMutationOptions,
  useCreateVehicleDefinitionMutation,
  useUpdateVehicleDefinitionMutation
} from "../../../../api/graphql/generated/hooks";
import { setLoadingStatus } from "../../../../redux/loading";


const breadcrumbs = [
  AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS,
  AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL_CREATE
];

export default function VehicleDefinitionDetailCreate() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [modelDefinition, setModelDefinition] = React.useState<ModelDefinitionType |  undefined>(undefined);
  const [createVehicleDefinitionMutation, { loading: loadingCreateVehicleDef, error: errorCreateVehicleDef }] = useCreateVehicleDefinitionMutation();
  const [updateVehicleDefinitionMutation, { loading: loadingUpdateVehicleDef, error: errorUpdateVehicleDef }] = useUpdateVehicleDefinitionMutation();


  const redirectToEditor = (vehicleDefinitionId: string) => {
    let path = assembleRedirectPath(AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS.pageRoute, vehicleDefinitionId);
    history.push(path);
  }

  const createVD = (modelDefinitionId: string) => {
    let inputOptions: CreateVehicleDefinitionMutationOptions = {
      onError: (error) => <ApolloError error={[error]} />,
    };

    console.log("Handling Vehicle Definition Create", inputOptions)

    createVehicleDefinitionMutation(inputOptions)
        .then(response => {
          if (response?.data?.createVehicleDefinition?.id) {
            updateVD(response?.data?.createVehicleDefinition?.id, modelDefinitionId);
          } else if(response.errors){
            return <GraphQLError error={response.errors} />
          }
        })
        .catch(error => <ApolloError error={error} />);
  }

  const updateVD = (vehicleDefinitionId: string, modelDefinitionId: string) => {
    let inputOptions: UpdateVehicleDefinitionMutationOptions = {
      variables: {
        input: {
          id: vehicleDefinitionId,
          modelDefinitionId: modelDefinitionId
        }
      },
      onError: (error) => <ApolloError error={[error]} />,
    };

    console.log("Handling Vehicle Definition Updated", inputOptions)

    updateVehicleDefinitionMutation(inputOptions)
    .then(response => {
      if (response?.data?.updateVehicleDefinition?.vehicleDefinition?.id) {
        redirectToEditor(response?.data?.updateVehicleDefinition?.vehicleDefinition?.id);
      } else if(response.errors){
        return <GraphQLError error={response.errors} />
      }
    })
    .catch(error => <ApolloError error={error} />);
  }

  const handleModelDefinitionUpdate = useCallback((modelDefinition: ModelDefinitionType) => {
    setModelDefinition(modelDefinition);
    createVD(modelDefinition.id);
  }, []);


  //Handle loading
  dispatch(setLoadingStatus(loadingCreateVehicleDef || loadingUpdateVehicleDef));
  //Handel errors
  if (errorCreateVehicleDef) return <ApolloError error={[errorCreateVehicleDef]} />;
  if (errorUpdateVehicleDef) return <ApolloError error={[errorUpdateVehicleDef]} />;


  return (
      <div className="page">
        <PageHeader headline="createDefinition" breadcrumbs={breadcrumbs}/>
        <Card variant="outlined">
          <CardHeader title={t("pages.definitionEditor.info.createNewDefinitionTitle")} />
          <CardContent>
            <ModelDefinition
                modelDefinition={modelDefinition ? modelDefinition : null}
                initiallyExpanded={true}
                handleUpdateVehicleDefinition={handleModelDefinitionUpdate}
            />
          </CardContent>
        </Card>
      </div>
  );

}
