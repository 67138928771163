import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';
import { BonusDto } from "../../../modelView";
import { BonusUnitEnum, Brand, VehicleType } from "../../../api/graphql/generated/schema";
import { ApolloError, CodetableSelect, EnumSelect } from "../../index";
import {
    CreateBonusMutationOptions,
    UpdateBonusMutationOptions,
    useCreateBonusMutation,
    useGetBrandsQuery,
    useGetVehicleTypesQuery,
    useUpdateBonusMutation
} from "../../../api/graphql/generated/hooks";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { formatToZeroDecimalsString } from "../../../utils/formatterHelper";
import { setLoadingStatus } from "../../../redux/loading";
import { ClearOutlined, SaveAltOutlined } from '@mui/icons-material';

const initState = {
  bonus: {
    name: "",
    price: "",
    unit: BonusUnitEnum.Czk,
    brand: "",
    vehicleType: "",
    forCrn: false,
    forPhysicalPerson: false
  },
  changed: false
}

export default function BonusEditor(props: { bonus?: BonusDto, handleBonusSave: Function }) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const saveBonusItemTooltipTitle = props.bonus?.id ? t("actions.save") : t("actions.create");
  const discardBonusItemTooltipTitle = t("actions.discard");

  const [userName, setUserName] = useState<string>(initState.bonus.name);
  const [userPrice, setUserPrice] = useState<string>(initState.bonus.price);
  const [userBonusUnit, setUserBonusUnit] = useState<BonusUnitEnum>(initState.bonus.unit);
  const [possibleBonusUnit] = useState<Array<BonusUnitEnum>>([initState.bonus.unit]);
  const [userBrand, setUserBrand] = useState<string>(initState.bonus.brand);
  const [possibleBrands, setPossibleBrands] = useState<Array<Brand>>([]);
  const [userVehicleType, setUserVehicleType] = useState<string>(initState.bonus.vehicleType);
  const [possibleVehicleTypes, setPossibleVehicleTypes] = useState<Array<VehicleType>>([]);
  const [userForCRN, setUserForCRN] = useState<boolean>(initState.bonus.forCrn);
  const [userForPP, setUserForPP] = useState<boolean>(initState.bonus.forPhysicalPerson);

  //TODO: remove this an replace this with change function using specific functions
  const [changed, setChanged] = useState<boolean>(initState.changed);

  //GQLs
  const {loading: loadingBrands, data: gqlBrands, error: errorBrands} = useGetBrandsQuery();
  const {loading: loadingVehicleTypes, data: gqlVehicleTypes, error: errorVehicleTypes} = useGetVehicleTypesQuery();
  const [gqlCreateBonusMut, {loading: gqlCreateBonusLoading}] = useCreateBonusMutation();
  const [gqlUpdateBonusMut, {loading: gqlUpdateBonusLoading}] = useUpdateBonusMutation();

  const resetInitState = useCallback(() => {
    setUserName(initState.bonus.name);
    setUserPrice(initState.bonus.name);
    setUserBonusUnit(initState.bonus.unit);
    setUserBrand(initState.bonus.brand);
    setUserVehicleType(initState.bonus.vehicleType);
    setUserForCRN(initState.bonus.forCrn);
    setUserForPP(initState.bonus.forPhysicalPerson);
    setChanged(initState.changed);
  }, []);

  const resetState = useCallback(() => {
    if (props.bonus) {
      setUserName(props.bonus.name);
      setUserPrice(formatToZeroDecimalsString(props.bonus.price));
      setUserBonusUnit(props.bonus.unit);
      setUserBrand(props.bonus.brand ? props.bonus.brand.id : initState.bonus.brand);
      setUserVehicleType(props.bonus.vehicleType ? props.bonus.vehicleType.id : initState.bonus.vehicleType);
      setUserForCRN(props.bonus.forCrn);
      setUserForPP(props.bonus.forPhysicalPerson);
      setChanged(initState.changed);
    } else {
      resetInitState();
    }
  }, [props.bonus, resetInitState]);

  useEffect(() => {
    dispatch(setLoadingStatus(gqlCreateBonusLoading || gqlUpdateBonusLoading));
  }, [gqlCreateBonusLoading, gqlUpdateBonusLoading, dispatch]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    if (!loadingBrands && gqlBrands) {
      setPossibleBrands(gqlBrands.getBrands);
    }
  }, [loadingBrands, gqlBrands]);

  useEffect(() => {
    if (!loadingVehicleTypes && gqlVehicleTypes) {
      setPossibleVehicleTypes(gqlVehicleTypes.getVehicleTypes);
    }
  }, [loadingVehicleTypes, gqlVehicleTypes]);

  const handleChange = (origValue: any, newValue: any) => {
    if (origValue === newValue) {
      setChanged(false)
    } else {
      setChanged(true);
    }
  }

  const handleNameChange = (event) => {
    const newValue = event.target.value;
    setUserName(newValue);
    handleChange(props.bonus?.name, newValue)
  }

  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    setUserPrice(newValue);
    handleChange(props.bonus?.price, newValue)
  }

  const handleBrandChange = (selectedBrandId) => {
    setUserBrand(selectedBrandId);
    handleChange(props.bonus?.brand?.id, selectedBrandId)
  }

  const handleVehicleTypeChange = (selectedVehicleTypeId) => {
    setUserVehicleType(selectedVehicleTypeId);
    handleChange(props.bonus?.vehicleType?.id, selectedVehicleTypeId)
  }

  const handleForCrnChange = () => {
    let newValue = !userForCRN;
    setUserForCRN(newValue);
    handleChange(props.bonus?.forCrn, newValue)
  }

  const handleForPPChange = () => {
    let newValue = !userForPP;
    setUserForPP(newValue);
    handleChange(props.bonus?.forPhysicalPerson, newValue)
  }

  const disabledForSave = () => {
    return !changed || !userName || !userPrice || !userBonusUnit || !userBrand || !userVehicleType || (!userForCRN && !userForPP);
  }

  const updateBonus = (bonusId: string) => {
    const updateOptions: UpdateBonusMutationOptions = {
      variables: {
        input: {
          id: bonusId,
          name: userName,
          value: Number(userPrice),
          unit: userBonusUnit,
          forCrn: userForCRN,
          forPhysicalPerson: userForPP,
          brandId: userBrand,
          vehicleTypeId: userVehicleType
        }
      }
    }

    gqlUpdateBonusMut(updateOptions)
    .then(response => {
      if (response.data?.updateBonus) {
        dispatch(openSuccessSnackbar(t("userMessages.codetable.bonus.updated")));
        props.handleBonusSave(response.data.updateBonus.bonus);
      } else if (response?.data?.updateBonus?.errors) {
        response.data.updateBonus.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "codetable.bonus"))));
      } else if (response.errors) {
        response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
      }
    })
    .catch(error => <ApolloError error={error}/>)
  }

  const createBonus = () => {
    const createOptions: CreateBonusMutationOptions = {
      variables: {
        input: {
          name: userName,
          value: Number(userPrice),
          unit: userBonusUnit,
          forCrn: userForCRN,
          forPhysicalPerson: userForPP,
          brandId: userBrand,
          vehicleTypeId: userVehicleType
        }
      }
    }

    gqlCreateBonusMut(createOptions)
    .then(response => {
      if (response.data?.createBonus) {
        dispatch(openSuccessSnackbar(t("userMessages.codetable.bonus.created")));
        props.handleBonusSave(response.data.createBonus.bonus);
      } else if (response?.data?.createBonus?.errors) {
        response.data.createBonus.errors.map(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "codetable.bonus"))));
      } else if (response.errors) {
        response.errors.map(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
      }
    })
    .catch(error => <ApolloError error={error}/>);
  }

  const handleSave = () => {
    if (props.bonus?.id) {
      updateBonus(props.bonus.id);
    } else {
      createBonus();
    }
  }

  if (errorBrands) return <ApolloError error={[errorBrands]}/>;
  if (errorVehicleTypes) return <ApolloError error={[errorVehicleTypes]}/>;

  return (
      <Card>
        <CardContent>
          <Grid container direction="row"  justifyContent={"space-between"}>
            <Grid item>
              <TextField
                  autoFocus={true}
                  id={"bonus-editor-name"}
                  variant="outlined"
                  label={t("fieldName.name")}
                  type="text"
                  required
                  error={!userName}
                  InputLabelProps={{
                    shrink: !!userName,
                  }}
                  value={userName}
                  onChange={handleNameChange}
              />
            </Grid>
            <Grid item>
              <TextField
                  id={"bonus-editor-price"}
                  variant="outlined"
                  label={t("fieldName.price")}
                  type="number"
                  required
                  error={!userPrice}
                  InputLabelProps={{
                    shrink: !!userPrice,
                  }}
                  value={userPrice}
                  onChange={handlePriceChange}
              />
            </Grid>
            <Grid item>
              <EnumSelect
                  label={t("fieldName.bonus.unit")}
                  filteredValues={userBonusUnit}
                  multiselect={false}
                  inputValues={possibleBonusUnit}
                  disabled
                  setFilteredValues={() => {
                  }}
                  enumLabelsPath="bonus.unitEnum.values"
              />
            </Grid>
          </Grid>
          <Grid container direction="row"  justifyContent={"space-between"}>
            <Grid item>
              <CodetableSelect
                  label={t("fieldName.brand")}
                  required
                  filteredValues={userBrand}
                  multiselect={false}
                  inputValues={possibleBrands}
                  setFilteredValues={handleBrandChange}
              />
            </Grid>
            <Grid item>
              <CodetableSelect
                  label={t("fieldName.vehicleType")}
                  required
                  filteredValues={userVehicleType}
                  multiselect={false}
                  inputValues={possibleVehicleTypes}
                  setFilteredValues={handleVehicleTypeChange}
              />
            </Grid>
            <Grid item>
              <FormControlLabel id="for-crn-chbx"
                                control={
                                  <Checkbox
                                      checked={userForCRN}
                                      onChange={handleForCrnChange}
                                      name="is-for-crn-chbx"
                                      color={"primary"}
                                  />
                                }
                                label={t("fieldName.bonus.crn")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel id="for-pp-chbx"
                                control={
                                  <Checkbox
                                      checked={userForPP}
                                      onChange={handleForPPChange}
                                      name="is-for-pp-chbx"
                                      color={"primary"}
                                  />
                                }
                                label={t("fieldName.bonus.physicalPerson")}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid item justifyContent={"flex-end"}>
            <IconButton
                disabled={disabledForSave()}
                onClick={handleSave}
            >
              <Tooltip title={saveBonusItemTooltipTitle}>
                <SaveAltOutlined/>
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item justifyContent={"flex-end"}>
            <IconButton
                disabled={!changed}
                onClick={resetState}
            >
              <Tooltip title={discardBonusItemTooltipTitle}>
                <ClearOutlined/>
              </Tooltip>
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
  );
}
