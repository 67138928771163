import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/media/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { AppPageNameRoutes } from '../../routes/paths';
import { VehicleCardCreate } from '../index';
import { UserDto } from '../../api';
import { getUserFromStorage } from '../../utils/functionHelper';
import { AppBar, Box, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import { KeyboardArrowDown, Person } from '@mui/icons-material';
import { Role } from '../../utils/enums';
import { checkUserRole } from '../../utils/utils';

export default function MenuAppBar(props: { userLogout: Function }) {
    const { t } = useTranslation();
    const history = useHistory();

    const [anchorPersonMenu, setAnchorPersonMenu] = React.useState<null | HTMLElement>(null);
    const [anchorNewVehicleMenu, setAnchorNewVehicleMenu] = React.useState<null | HTMLElement>(null);
    const [anchorUsedVehicleMenu, setAnchorUsedVehicleMenu] = React.useState<null | HTMLElement>(null);
    const [anchorAdministration, setAnchorAdministration] = React.useState<null | HTMLElement>(null);
    const [user] = React.useState<UserDto | null>(getUserFromStorage());

    const [openCreateCardDialogDialog, setOpenCreateCardDialogDialog] = useState<boolean>(false);

    const handlePersonMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorPersonMenu(event.currentTarget);
    };

    const handleNewVehicleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorNewVehicleMenu(event.currentTarget);
    };

    const handleAdministrationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAdministration(event.currentTarget);
    };

    const handleUsedVehicleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorUsedVehicleMenu(event.currentTarget);
    };

    const handleClose = (closeAnchor) => () => {
        closeAnchor(null);
    };

    const handleUserLoggedOut = () => {
        props.userLogout();
        setAnchorPersonMenu(null);
    }

    const redirectToNewVehicleDefinitions = () => {
        history.push(AppPageNameRoutes.VEHICLE_NEW_DEFINITIONS.pageRoute);
        setAnchorNewVehicleMenu(null);
    }

    const redirectToUsedVehicleCards = () => {
        history.push(AppPageNameRoutes.VEHICLE_USED_CARDS.pageRoute);
        setAnchorUsedVehicleMenu(null);
    }

    const redirectToNewUsedVehicleCard = () => {
        history.push(AppPageNameRoutes.VEHICLE_USED_CARD_CREATE.pageRoute);
        setAnchorUsedVehicleMenu(null);
    }

    const redirectToNewVehicleDefinitionCreation = () => {
        history.push(AppPageNameRoutes.VEHICLE_NEW_DEFINITION_DETAIL_CREATE.pageRoute);
        setAnchorNewVehicleMenu(null);
    }

    const redirectToNewVehicleCards = () => {
        history.push(AppPageNameRoutes.VEHICLE_NEW_CARDS.pageRoute);
        setAnchorNewVehicleMenu(null);
    }

    const redirectToServicePackages = () => {
        history.push(AppPageNameRoutes.CODETABLES_SERVICE_PACKAGES.pageRoute);
        setAnchorAdministration(null);
    }
    const redirectToCodetablesEditor = () => {
        history.push(AppPageNameRoutes.CODETABLES.pageRoute);
        setAnchorAdministration(null);
    }
    const handleOpenCreateCardDialog = () => {
        setOpenCreateCardDialogDialog(true);
        setAnchorNewVehicleMenu(null);
    };

    const handleCloseCreateCardDialog = () => {
        setOpenCreateCardDialogDialog(false);
    };

    const redirectToProfile = () => {
        history.push(AppPageNameRoutes.PROFILE.pageRoute);
        handleClose(setAnchorPersonMenu)
    }

    return (
        <AppBar position="static" className="naviBar">
            <Toolbar>
                <Box component="img" src={logo} className="logo" alt="logo" onClick={() => history.push(AppPageNameRoutes.HOME.pageRoute)} sx={{":hover": { "cursor": "pointer"}}} />
                <div className="grow"/>
                {!user && (
                    <Button color="inherit">Login</Button>
                )}
                {user && (
                    <div>
                        {checkUserRole(user.roles, [Role.NV_DEFINITION_ADMIN, Role.NV_CARD_ADMIN]) &&
                            <Button aria-controls="new-vehicle-menu" onClick={handleNewVehicleMenuClick}>
                                {t('pageNames.newCars')} <KeyboardArrowDown className="chevronDown"/>
                            </Button>
                        }
                        {checkUserRole(user.roles, [Role.UV_CARD_READ, Role.UV_CARD_EDIT]) &&
                            <Button aria-controls="used-vehicle-menu" onClick={handleUsedVehicleMenuClick}>
                                {t('pageNames.usedCars')} <KeyboardArrowDown className="chevronDown"/>
                            </Button>
                        }

                        <Button aria-controls="administration-menu" onClick={handleAdministrationMenuClick}>
                            {t('pageNames.administration')} <KeyboardArrowDown className="chevronDown"/>
                        </Button>

                        <Button aria-controls="person-menu" onClick={handlePersonMenuClick} className="ml-3">
                            <Person/> {user.fullName} <KeyboardArrowDown className="chevronDown"/>
                        </Button>

                        <Menu
                            id="new-vehicle-menu"
                            anchorEl={anchorNewVehicleMenu}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(anchorNewVehicleMenu)}
                            onClose={handleClose(setAnchorNewVehicleMenu)}
                            elevation={0}
                        >
                            {checkUserRole(user.roles, [Role.NV_DEFINITION_ADMIN]) &&
                                <MenuItem onClick={redirectToNewVehicleDefinitionCreation}>{t('pageNames.createDefinition')}</MenuItem>
                            }
                            {checkUserRole(user.roles, [Role.NV_DEFINITION_ADMIN]) &&
                                <MenuItem onClick={redirectToNewVehicleDefinitions}>{t('pageNames.definitions')}</MenuItem>
                            }
                            {checkUserRole(user.roles, [Role.NV_CARD_ADMIN]) &&
                                <MenuItem onClick={redirectToNewVehicleCards}>{t('pageNames.cards')}</MenuItem>
                            }
                            {checkUserRole(user.roles, [Role.NV_CARD_ADMIN]) &&
                                <MenuItem onClick={handleOpenCreateCardDialog}>{t('pageNames.createCard')}</MenuItem>
                            }
                        </Menu>

                        <Menu
                            id="new-vehicle-menu"
                            anchorEl={anchorUsedVehicleMenu}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(anchorUsedVehicleMenu)}
                            onClose={handleClose(setAnchorUsedVehicleMenu)}
                            elevation={0}
                        >
                            <MenuItem onClick={redirectToUsedVehicleCards}>{t('pageNames.usedCars')}</MenuItem>
                            {checkUserRole(user.roles, [Role.UV_CARD_EDIT]) &&
                                <MenuItem onClick={redirectToNewUsedVehicleCard}>{t('pageNames.addUsedVehicleCard')}</MenuItem>
                            }
                        </Menu>
                        <Menu
                            id="administration-menu"
                            anchorEl={anchorAdministration}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(anchorAdministration)}
                            onClose={handleClose(setAnchorAdministration)}
                            elevation={0}
                        >
                            <MenuItem onClick={redirectToServicePackages}>{t('pageNames.servicePackages')}</MenuItem>
                            {checkUserRole(user.roles, [Role.CODETABLE_ADMIN]) &&
                                <MenuItem onClick={redirectToCodetablesEditor}>{t("pageNames.codetables")}</MenuItem>
                            }
                        </Menu>

                        <Menu
                            id="person-menu"
                            anchorEl={anchorPersonMenu}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(anchorPersonMenu)}
                            onClose={handleClose(setAnchorPersonMenu)}
                            elevation={0}
                        >
                            <MenuItem onClick={redirectToProfile}>{t('layout.appNavBar.menu.account.profile')}</MenuItem>
                            <MenuItem onClick={handleUserLoggedOut}>{t('layout.appNavBar.menu.account.logout')}</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
            <VehicleCardCreate open={openCreateCardDialogDialog} handleClose={handleCloseCreateCardDialog}/>

        </AppBar>
    );
}
