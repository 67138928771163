import { DeleteOutlined, SaveAltOutlined } from '@mui/icons-material';
import { IconButton, Input, TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { Color, ColorType } from '../../../api/graphql/generated/schema';
import { formatDateTime } from '../../../utils/formatterHelper';
import { CodetableSelect, StyledTableCell, StyledTableRow } from '../../index';

export interface ModelColorTableRowType{
  id: string,
  name: string,
  price: number | null | undefined,
  colorId: string,
  colorTypeId: string,
  updatedBy: string,
  updated: string
}

export interface ModelColorTableRowUpdType{
  id: string | null | undefined,
  name: string,
  price: number,
  colorId: string,
  colorTypeId: string
}


export default function ModelColorRowEditor(
    props: {
      row: ModelColorTableRowType,
      saveRow,
      saveButtonTitle: string,
      removeRow,
      removeButtonTitle: string,
      possibleColors: Array<Color>,
      possibleColorTypes: Array<ColorType>
    }){

  const [userName, setUserName] = React.useState<string | null | undefined>("");
  const [userPrice, setUserPrice] = React.useState<string | null | undefined>("");
  const [userColorId, setUserColorId] = React.useState<string>("");
  const [userColorTypeId, setUserColorTypeId] = React.useState<string>("");

  useEffect(() => {
    if (props.row) {
      setUserName(props.row.name);
      setUserPrice(props.row.price?.toString());
      setUserColorId(props.row.colorId);
      setUserColorTypeId(props.row.colorTypeId);
    }
  }, [props.row])


  const updateColorId = (filteredValue) => {
    setUserColorId(filteredValue);
  }

  const updateColorTypeId = (filteredValue) => {
   setUserColorTypeId(filteredValue);
  }

  const updateName = (filteredValue) => {
    setUserName(filteredValue);
  }

  const updatePrice = (filteredValue) => {
    setUserPrice(filteredValue);
  }

  const saveRow = () => {
    if (userName && userColorId && userColorTypeId && userPrice){
      let row = {
        id: props.row.id,
        name: userName,
        price: userPrice,
        colorId: userColorId,
        colorTypeId: userColorTypeId
      }

      props.saveRow(row);
    }
  };

  const rowDataChanged = () => {
    return !(props.row.name === userName
        && props.row.price?.toString() === userPrice
        && props.row.colorId === userColorId
        && props.row.colorTypeId === userColorTypeId
    );
  }

  return (
      <StyledTableRow>
        <StyledTableCell align="center">
          <TextField
              required={true}
              error={!userName}
              value={userName}
              onChange={event => updateName(event.target.value)}
              inputProps={{
                maxLength: 100
              }}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <CodetableSelect
              inputValues={props.possibleColors}
              filteredValues={userColorId}
              setFilteredValues={updateColorId}
              multiselect={false}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <CodetableSelect
              inputValues={props.possibleColorTypes}
              filteredValues={userColorTypeId}
              setFilteredValues={updateColorTypeId}
              multiselect={false}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <TextField
              error={!userPrice}
              value={userPrice}
              onChange={event => updatePrice(event.target.value)}
              type={"number"}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <Tooltip title={props.row.updatedBy}>
                      <span>
                        <Input
                            disabled={true}
                            id="updated-to-picker-inline"
                            value={formatDateTime(props?.row?.updated)}
                            onChange={() => true}
                        />
                      </span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell align="right">
          {rowDataChanged() && <IconButton>
            <Tooltip title={props.saveButtonTitle}>
              <SaveAltOutlined onClick={saveRow}/>
            </Tooltip>
          </IconButton>
          }
          <IconButton>
            <Tooltip title={props.removeButtonTitle}>
              <DeleteOutlined onClick={props.removeRow}/>
            </Tooltip>
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
  );
}



