import React, { useEffect } from "react";
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { LeasingGroupDto } from "../../../../modelView";
import { useTranslation } from "react-i18next";
import LeasingGroupTab from "./LeasingGroupTab";
import { DoneOutlined, WarningOutlined } from '@mui/icons-material';
import { TabContext } from '@mui/lab';

const leasingGroupTabOrder = {
  CRN: 0,
  PHYSICAL_PERSON: 1,
  CONTRA_ACCOUNT_CRN: 2,
  CONTRA_ACCOUNT_PHYSICAL_PERSON: 3
}

const leasingGroupTabComparator = (o1: LeasingGroupDto, o2: LeasingGroupDto) => {
  return leasingGroupTabOrder[o1.type] <= leasingGroupTabOrder[o2.type] ? -1 : 1;
}

export default function LeasingGroups(props:{ leasingGroups: Array<LeasingGroupDto>, handleUpdate: Function }) {

  props.leasingGroups.sort(leasingGroupTabComparator);

  const {t} = useTranslation();

  const [selectedLeasingGroup, setSelectedLeasingGroup] = React.useState<string>(props.leasingGroups.length > 0 ? props.leasingGroups[0].type : "");

  useEffect(() => {
    if (selectedLeasingGroup && selectedLeasingGroup.length > 0 && props.leasingGroups.length > 0 && !props.leasingGroups.some((lg) => lg.type === selectedLeasingGroup)){
      setSelectedLeasingGroup(props.leasingGroups[0].type);
    }

  }, [props.leasingGroups, selectedLeasingGroup])

  const handleTabChange = (event: React.ChangeEvent<{}>, data: string) => {
    setSelectedLeasingGroup(data);
  }

  const handleGroupUpdate = (dataToUpdate: LeasingGroupDto) => {
    let _leasingGroups = [...props.leasingGroups.filter(item => item.type !== dataToUpdate.type), dataToUpdate];
    props.handleUpdate(_leasingGroups);
  }

  const renderEndIcon = (hasError) => {
    return hasError ? <WarningOutlined color={"error"}/> : <DoneOutlined color={"primary"}/>;
  }

  return (
      <Box>
        <TabContext value={selectedLeasingGroup}>
          <Box mb={2}>
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                <Tabs
                    value={selectedLeasingGroup}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                  {props.leasingGroups.map((item) => (
                      <Tab value={item.type} label={t("leasingGroup.typeEnum.values." + item.type)} wrapped={true}  icon={renderEndIcon(item.hasAnyInvalidItem())}/>
                  ))}
                </Tabs>
                {props.leasingGroups.map((item) => <LeasingGroupTab leasingGroup={item} handleUpdate={handleGroupUpdate}/>)}
              </Grid>
            </Grid>
          </Box>
        </TabContext>
      </Box>
  );
}
