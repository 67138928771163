import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    List,
    TextField,
} from '@mui/material';
import { openErrorSnackbar, openSuccessSnackbar } from '../../../redux/snackbar';
import ServiceInlineEditor from './ServiceInlineEditor';
import { Add, CloseRounded } from '@mui/icons-material';

export type ServicePackageDto = {
    id: string | null,
    name: string,
    items: Array<string>,
}
export default function ServicePackageDialog(props:{inputServicePackage: ServicePackageDto, open: boolean, handleClose, handleUpdate: Function }) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [servicePackageName, setServicePackageName] = useState<string>('');
    const [services, setServices] = useState<Array<string>>([]);
    const [newItem, setNewItem] = useState<string>();
    const [newItemError, setNewItemError] = useState<boolean>(false);

    const initServicePackage = () => {
        if (props.inputServicePackage){
            setServicePackageName(props.inputServicePackage.name);
            setServices(props.inputServicePackage.items);
        }
    }

    useEffect(() => {
        initServicePackage();
    }, [props.inputServicePackage])

    const handleSave = () => {
        if (!servicePackageName){
            dispatch(openErrorSnackbar(t('userMessages.codetable.servicePackage.packageNameMissing')));
            return;
        }
        if (!services || services.length < 1) {
            dispatch(openErrorSnackbar(t('userMessages.codetable.servicePackage.itemsMissing')));
            return;
        }
        if (services.filter((service) => !service).length > 0){
            dispatch(openErrorSnackbar(t('userMessages.codetable.servicePackage.item.nameMissing')));
            return;
        }
        // If no error provide update
        props.handleUpdate(
            {
                id: props.inputServicePackage?.id,
                name: servicePackageName,
                items: services,
            });
    }

    const reset = () => {
        initServicePackage();
    }

    const updateNewItem = (event) => {
        if (event.target.value) {
            setNewItemError(services.includes(event.target.value));
            setNewItem(event.target.value);
        } else {
            setNewItem('');
        }
    }

    const addNewItem = () => {
        if (newItem) {
            setServices([...services, newItem]);
            setNewItemError(false);
            setNewItem('');
            dispatch(openSuccessSnackbar(t('userMessages.codetable.servicePackage.item.added')))
        } else {
            setNewItemError(true);
        }
    }

    const handleRemove = (removeIndex: number) => {
        setServices(services.filter((sp, index) => index !== removeIndex));
    }

    const handleUpdate = (index, updatedService) => {
        const updServices: Array<string> = [...services];
        updServices[index] = updatedService;
        setServices(updServices);

        if (!updatedService) return t('userMessages.codetable.servicePackage.item.nameMissing');
        if (services.filter((service, currIndex) => service === updatedService && currIndex !== index).length > 0) return t("userMessages.codetable.servicePackage.item.alreadyExists");

        return null;
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item>
                        {t('pages.codetables.servicePackage.detail.dialogTitle', { servicePackageId: props.inputServicePackage?.id ? props.inputServicePackage?.id : '' })}
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseRounded/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item>
                        <FormControl variant="outlined">
                            <TextField
                                id={'sp-name'}
                                label={t('fieldName.name')}
                                type={'text'}
                                name={'servicePackageName'}
                                value={servicePackageName}
                                error={!servicePackageName}
                                helperText={!servicePackageName && t('userMessages.codetable.servicePackage.packageNameMissing')}
                                sx={{ margin: 8}}
                                onChange={(event) => setServicePackageName(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <FormControl variant="outlined">
                                    <TextField
                                        id={'sp-item-name'}
                                        label={t('fieldName.servicePackageItem.itemName')}
                                        type={'text'}
                                        name={'newItem'}
                                        value={newItem}
                                        error={newItemError}
                                        helperText={newItemError ? t('userMessages.codetable.servicePackage.item.alreadyExists') : t('userMessages.codetable.servicePackage.item.helperTextAdd')}
                                        onChange={updateNewItem}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={addNewItem} disabled={newItemError || !newItem}>
                                    <Add/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'flex-start'} direction={'column'}>
                    <List>
                        {services.map((service, index) =>
                            <ServiceInlineEditor
                                service={service}
                                handleUpdate={(updatedService) => handleUpdate(index, updatedService)}
                                handleRemove={() => handleRemove(index)}
                            />,
                        )}
                    </List>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button onClick={reset}>{t('actions.discard')}</Button>
                        {props.inputServicePackage?.id && <Button onClick={handleSave}>{t('actions.save')}</Button>}
                        {!(props.inputServicePackage?.id) && <Button onClick={handleSave}>{t('actions.create')}</Button>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
