import {
    VehicleCardStateEnum,
    VehicleDelivery as VehicleDeliveryType,
    VehicleDeliveryInput as VehicleDeliveryInputType
} from "../../api/graphql/generated/schema";

export const convertDeliveryTypeToDeliveryInput = (deliveryDate: VehicleDeliveryType): VehicleDeliveryInputType => {
    return  {
        exactDate: deliveryDate.exactDate,
        plusDays: deliveryDate.plusDays,
        immediatePickUp: deliveryDate.immediatePickUp
    };
}

export const convertCardState = (state: string): VehicleCardStateEnum => {
    switch (state){
        case VehicleCardStateEnum.InProgress:
            return VehicleCardStateEnum.InProgress
        case VehicleCardStateEnum.AwaitApproval:
            return VehicleCardStateEnum.AwaitApproval
        case VehicleCardStateEnum.Approved:
            return VehicleCardStateEnum.Approved
        case VehicleCardStateEnum.Published:
            return VehicleCardStateEnum.Published
        case VehicleCardStateEnum.Sold:
            return VehicleCardStateEnum.Sold
        case VehicleCardStateEnum.Archived:
            return VehicleCardStateEnum.Archived
        case VehicleCardStateEnum.Unarchive:
            return VehicleCardStateEnum.Unarchive
        default:
            throw Error("Unsupported enum type");
    }
}
